import { useCallback } from "react"

import { useSearchParams } from "react-router-dom"

export function useUrlParams() {
    const [searchParams, setSearchParams] = useSearchParams()

    const deleteParam = useCallback((paramKey, replace = true) => {
        searchParams.delete(paramKey)
        setSearchParams(searchParams, { replace })
    }, [searchParams, setSearchParams])

    const deleteParams = useCallback((replace = true) => {
        setSearchParams(new URLSearchParams(), { replace })
    }, [setSearchParams])

    const getParam = useCallback((paramKey) => {
        return searchParams.get(paramKey)
    }, [searchParams])

    const getParams = useCallback(() => {
        return searchParams
    }, [searchParams])

    const setParam = useCallback((paramKey, value, replace = true) => {
        searchParams.set(paramKey, value)
        setSearchParams(searchParams, { replace })
    }, [searchParams, setSearchParams])

    const setParams = useCallback((params, replace = true) => {
        setSearchParams(params, { replace })
    }, [setSearchParams])

    return ({
        deleteParam,
        deleteParams,
        getParam,
        getParams,
        setParam,
        setParams,
    })
}