import { PropTypes } from "@l2r-front/l2r-proptypes"
import { ChipListSkeleton, ListItem } from "@l2r-front/l2r-ui"

import * as Styled from "./IncidentTags.styled"

export const IncidentTagsSkeleton = (props) => {
    const {
        tags,
        titleVariant,
    } = props

    return <ListItem>
        <Styled.IconSkeletonWrapper>
            <Styled.IconSkeleton variant="circular" />
        </Styled.IconSkeletonWrapper>
        <Styled.Elements>
            <Styled.SubtitleSkeleton variant={titleVariant || "button"} />
            <ChipListSkeleton tagsCount={tags} />
        </Styled.Elements>
    </ListItem>
}

IncidentTagsSkeleton.propTypes = {
    tags: PropTypes.number,
    titleVariant: PropTypes.string,
}