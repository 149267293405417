import { Menu as UiMenu, MenuItem, styled } from "@l2r-front/l2r-ui"

export const Menu = styled(UiMenu)(({ theme }) => ({
    "& .MuiMenu-paper": {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        marginTop: theme.spacing(1),
        "&:before": {
            content: "''",
            display: "block",
            position: "absolute",
            top: 0,
            right: 20,
            width: 10,
            height: 10,
            backgroundColor: theme.palette.background.paper,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
        },
    },
}))

export const LogoutMenuItem = styled(MenuItem)(({ theme }) => ({
    marginTop: theme.spacing(2),
}))
