import {
    removeValueForArray,
} from "@l2r-front/l2r-utils"

import { YEARS_FILTER, TECHNIQUES_FILTER, STATUSES_FILTER } from "../constants/filters"
import { statusesSettings } from "../constants/status"

export const roadworksFiltersConfig = {
    [YEARS_FILTER]: {
        getUrlParams: (value) => Array.isArray(value) ? value.join(",") : value,
        getValueList: (key, values) => {
            return values.map((value, index) => ({
                key: key,
                index: index,
                label: value.toString(),
                value: value,
            }))
        },
        removeValue: removeValueForArray,
        label: "forms.techniquesFiltersForm.inputs.years",
        inQueryParam: "year__in",
        queryParam: "year",
    },
    [TECHNIQUES_FILTER]: {
        getUrlParams: (value) => Array.isArray(value) ? value.join(",") : value,
        getValueList: (key, values, config = {}) => {
            const techniqueCodes = config.techniqueCodes || []
            return values.map((value, index) => ({
                key: key,
                index: index,
                label: techniqueCodes.find(tc => tc.code === value)?.acronym || "",
                value: value,
            }))
        },
        removeValue: (filters, valueToRemove) => filters.filter(f => f !== valueToRemove.value),
        label: "forms.techniquesFiltersForm.inputs.techniques",
        inQueryParam: "technique_code__in",
        queryParam: "technique_code",
    },
    [STATUSES_FILTER]: {
        getUrlParams: (value) => Array.isArray(value) ? value.join(",") : value,
        getValueList: (key, values, config = {}) => {
            const t = config.t
            const namespace = config.namespace
            return values.map((value, index) => ({
                key: key,
                index: index,
                label: t ? t(namespace, statusesSettings[value]?.label) : "",
                value: value,
            }))
        },
        removeValue: removeValueForArray,
        label: "forms.techniquesFiltersForm.inputs.statuses.label",
        inQueryParam: "status__in",
        queryParam: "status",
    },
}