import { Button as UiButton, styled } from "@l2r-front/l2r-ui"

export const Wrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(8),
}))

export const Button = styled(UiButton)(({ selected, theme }) => ({
    textTransform: "initial",
    padding: theme.spacing(3, 5, 3, 5),
    justifyContent: "flex-start",

    backgroundColor: selected && theme.palette.common.white,
    color: selected && theme.palette["texts/text-primary"].main,
    fontWeight: selected && theme.typography["H3"].fontWeight,
}))

export const ColorIcon = styled("div")(({ color, theme }) => ({
    backgroundColor: theme.palette[color].main,
    borderRadius: "50%",
    height: 12,
    marginRight: theme.spacing(5),
    width: 12,
}))

export const OptionText = styled("span")({
    margin: 0,
})

export const ListSubheader = styled("div")(({ theme, variant }) => ({
    ...theme.typography?.[variant],
    color: theme.palette["objects/object-black"].main,
}))

export const IconWrapper = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    marginRight: theme.spacing(5),
}))