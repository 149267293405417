export const FILTERS_SEARCH_PARAM_PREFIX = "filter-"
export const PAGE_SEARCH_PARAM_PREFIX = "page"
export const TAGS_FILTER_SEARCH_PARAM_KEY = `${FILTERS_SEARCH_PARAM_PREFIX}tags`
export const DATE_FILTER_SEARCH_PARAM_KEY = `${FILTERS_SEARCH_PARAM_PREFIX}date_`
export const timeSlot = ["timeSlotStartHour", "timeSlotEndHour"]
export const TIMESLOT_PARAM = "date_timeSlot"
export const PERIOD_PARAM = "date_period"
export const DATE_PARAMS = [TIMESLOT_PARAM, PERIOD_PARAM]
export const period = ["periodStartDate", "periodEndDate"]
export const DATE_KEYS = { timeSlot, period }
export const DATE_FORMATS = {
    timeSlot: "HH-mm-ss",
    period: "yyyy-MM",
}
export const INITIAL_TIMESLOT_FILTER = new Date("1984-05-22T00:00:00")