import * as yup from "yup"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"

export const validationSchema = (t) => yup.object({
    firstName: yup
        .string()
        .required(t(I18N_NAMESPACE, "forms.profileForm.fields.firstName.requireMessage")),
    lastName: yup
        .string()
        .required(t(I18N_NAMESPACE, "forms.profileForm.fields.lastName.requireMessage")),
    avatar: yup
        .mixed()
        .test("fileFormat", t(I18N_NAMESPACE, "forms.profileForm.fields.avatar.fileFormat"), (value) => {
            return !value || typeof value === "string" || value?.type?.includes("image/")
        }),
})
