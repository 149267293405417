import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Typography, useIsMobileDevice } from "@l2r-front/l2r-ui"

import * as Styled from "./RoadAssesmentList.styled"

export const RoadAssesmentList = (props) => {
    const {
        listKey,
        title,
        children,
    } = props

    const isMobile = useIsMobileDevice()

    return (
        <Styled.Container id={listKey} isMobile={isMobile}>
            {title && <Styled.Title id={`${listKey}-title`}>
                <Typography variant="H2">{title}</Typography>
            </Styled.Title>}
            <Styled.ListWrapper>
                <Styled.List>
                    {children}
                </Styled.List>
            </Styled.ListWrapper>
        </Styled.Container>
    )
}

RoadAssesmentList.propTypes = {
    listKey: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node,
}