import { Button as UiButton, Skeleton as UiSkeleton, styled } from "@l2r-front/l2r-ui"

export const Skeleton = styled(UiSkeleton)({
    height: "100%",
    transform: "scale(1)",
    width: "100%",
})

export const MessageWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
})

export const MessageButton = styled(UiButton)(({ theme }) => ({
    margin: theme.spacing(4, "auto"),
    width: "fit-content",
}))
