import { styled } from "@mui/material"
import { LegendLabel as VisxLegendLabel, LegendItem as VisxLegendItem } from "@visx/legend"

export const Title = styled("div")({
    fontSize: 12,
    marginBottom: 2,
})

export const LegendWrapper = styled("div")(({ theme }) => ({
    lineHeight: "0.9em",
    color: theme.palette["texts/text-primary"].main,
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    fontSize: 10,
    padding: 5,
    border: `1px solid ${theme.palette["texts/text-primary"].main}`,
    borderRadius: 14,
    width: "fit-content",
    textAlign: "center",
}))

export const LabelWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
})

export const LegendLabel = styled(VisxLegendLabel)(({ theme }) => ({
    align: "left",
    margin: theme.spacing(0, 0, 0, 1) + " !important",
    fontFamily: theme.typography.fontFamily,
}))

export const LegendItem = styled(VisxLegendItem)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
}))