import { useMemo, useState } from "react"

import { createFilterOptions } from "@mui/material/Autocomplete"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { AddIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../constants/i18n"
import * as Styled from "./ChipAdd.styled"

const filter = createFilterOptions()

export const ChipAdd = (props) => {

    const [value, setValue] = useState(null)
    const [displayInput, setDisplayInput] = useState(false)

    const {
        className,
        chipList,
        id,
        onValueSelected,
        options,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    const displayedAddableChip = useMemo(() => {
        return options.filter(addableChip => {
            return chipList.every(chip => chip !== addableChip)
        })
    }, [chipList, options])

    if (displayInput) {
        return (
            <Styled.Autocomplete
                className={className}
                clearOnBlur
                value={value}
                freeSolo
                handleHomeEndKeys
                openOnFocus
                options={displayedAddableChip}
                onClose={() => {
                    setDisplayInput(false)
                }}
                selectOnFocus
                onChange={(_, newValue) => {
                    if (typeof newValue === "string") {
                        setValue({
                            title: newValue,
                        })
                        onValueSelected(newValue)
                        setDisplayInput(false)
                    } else if (newValue && newValue.inputValue) {
                        setValue({
                            title: newValue.inputValue,
                        })
                        onValueSelected(newValue.inputValue)
                        setDisplayInput(false)
                    } else {
                        setValue(newValue)
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params)

                    const { inputValue } = params
                    const isExisting = options.some((option) => {
                        const optionValue = typeof option === "string" ? option.toLowerCase()
                            : option.title?.toLocaleLowerCase()
                        return inputValue.toLocaleLowerCase() === optionValue
                    })

                    if (inputValue !== "" && !isExisting) {
                        filtered.push({
                            inputValue,
                            title: `${t(I18N_NAMESPACE, "components.chipAdd.add")} "${inputValue}"`,
                        })
                    }

                    return filtered
                }}
                getOptionLabel={(option) => {
                    if (typeof option === "string") {
                        return option
                    }
                    return option.title
                }}
                renderInput={(params) => (
                    <Styled.TextField
                        {...params}
                        autoFocus
                        color="cta-bg/cta-bg-primary"
                        style={Styled.TextFieldStyle}
                        inputProps={{
                            ...params.inputProps,
                            maxLength: 25,
                        }}
                        InputProps={{
                            ...params.InputProps,
                            style: Styled.TextFieldInputStyle,
                            type: "search",
                        }}
                        InputLabelProps={{
                            style: Styled.TextFieldInputLabelStyle,
                        }}
                        variant="outlined"
                    />
                )}
            />
        )
    }

    return (
        <Styled.IconButton className={className} color="objects/object-lightgrey" id={id} onClick={() => {
            setDisplayInput(v => !v)
            setValue(null)
        }}>
            <AddIcon color="cta-bg/cta-bg-primary" />
        </Styled.IconButton>
    )
}

ChipAdd.propTypes = {
    chipList: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string.isRequired,
    onValueSelected: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}
