import { string, shape, arrayOf, number } from "prop-types"

export const geoJsonPoint = shape({
    type: string.isRequired,
    coordinates: arrayOf(number.isRequired).isRequired,
})

export const linearLocation = shape({
    end: number.isRequired,
    road: string.isRequired,
    start: number.isRequired,
    typeid: number.isRequired,
})
