import { Line } from "@visx/shape"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { logiroadBlack } from "../../theme"

const MIN_TOP = 5

export const ChartToolTipValueMarkers = (props) => {

    const {
        colors,
        height,
        tooltipLeft,
        tooltipData,
    } = props

    return (
        <g>
            <Line
                from={{ x: tooltipLeft, y: 0 }}
                to={{ x: tooltipLeft, y: height }}
                stroke={logiroadBlack}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
            />
            {
                tooltipData.data
                    .filter(v => !Number.isNaN(v?.top))
                    .map((value, i) => {
                        const top = value.top < MIN_TOP ? MIN_TOP
                            : value.top

                        return <g key={Math.random()}>
                            <circle
                                cx={tooltipLeft}
                                cy={top + 1}
                                fill={colors[i]}
                                fillOpacity={0.1}
                                r={4}
                                stroke={colors[0]}
                                strokeOpacity={0.1}
                                strokeWidth={2}
                                pointerEvents="none"
                            />
                            <circle
                                cx={tooltipLeft}
                                cy={top}
                                fill={colors[i]}
                                stroke="white"
                                strokeWidth={2}
                                pointerEvents="none"
                                r={4}
                            />
                        </g>
                    })
            }
        </g>
    )
}

ChartToolTipValueMarkers.propTypes = {
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
    height: PropTypes.number.isRequired,
    tooltipLeft: PropTypes.number.isRequired,
    tooltipData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({
            top: PropTypes.number.isRequired,
            value: PropTypes.string.isRequired,
        })),
        top: PropTypes.number.isRequired,
        time: PropTypes.string.isRequired,
    }).isRequired,
}