import {
    StatusAdjustmentsIcon,
    StatusAuditIcon,
    StatusClosedIcon,
    StatusErrorIcon,
    StatusNewIcon,
    StatusNotUsableIcon,
    StatusOngoingIcon,
} from "@l2r-front/l2r-icons"

import {
    INCIDENT_STATUS_ADJUSTMENTS,
    INCIDENT_STATUS_AUDIT,
    INCIDENT_STATUS_CLOSED,
    INCIDENT_STATUS_CLOSED_WITHOUT_ADJUSTMENTS,
    INCIDENT_STATUS_ERROR,
    INCIDENT_STATUS_NEW,
    INCIDENT_STATUS_NOT_USABLE,
    INCIDENT_STATUS_ONGOING,
} from "./event"

export const incidentStatusSettings = {
    [INCIDENT_STATUS_NEW]: {
        icon: StatusNewIcon,
        color: "palette.common.white",
        label: "components.incidentDetails.status.new",
    },
    [INCIDENT_STATUS_ERROR]: {
        icon: StatusErrorIcon,
        color: "palette.common.white",
        label: "components.incidentDetails.status.error",
    },
    [INCIDENT_STATUS_NOT_USABLE]: {
        icon: StatusNotUsableIcon,
        color: "palette.common.white",
        label: "components.incidentDetails.status.notUsable",
    },
    [INCIDENT_STATUS_ONGOING]: {
        icon: StatusOngoingIcon,
        color: "palette.common.white",
        label: "components.incidentDetails.status.inProgress",
    },
    [INCIDENT_STATUS_AUDIT]: {
        icon: StatusAuditIcon,
        color: "palette.common.white",
        label: "components.incidentDetails.status.audit",
    },
    [INCIDENT_STATUS_ADJUSTMENTS]: {
        icon: StatusAdjustmentsIcon,
        color: "palette.common.white",
        label: "components.incidentDetails.status.adjustments",
    },
    [INCIDENT_STATUS_CLOSED_WITHOUT_ADJUSTMENTS]: {
        icon: StatusClosedIcon,
        color: "palette.common.white",
        label: "components.incidentDetails.status.endedWithoutAdjustments",
    },
    [INCIDENT_STATUS_CLOSED]: {
        icon: StatusClosedIcon,
        color: "palette.common.white",
        label: "components.incidentDetails.status.ended",
    },
}