import { styled } from "@mui/material/styles"
import { Form as FormikForm } from "formik"

import {
    FormikButtonSubmit as UiFormikButtonSubmit,
    FormHelperText as UiFormHelperText,
    FormikCheckbox as UiFormikCheckbox,
} from "@l2r-front/l2r-ui"

export const SubmitWrapper = styled("div")(({ theme }) => ({
    alignItems: "baseline",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: theme.spacing(8),
}))

export const FormikButtonSubmit = styled(UiFormikButtonSubmit)(({ theme }) => ({
    marginLeft: theme.spacing(6),
    width: "fit-content",
}))

export const FormHelperText = styled(UiFormHelperText)({
    position: "relative",
    top: -20,
})

export const Form = styled(FormikForm)({
    display: "flex",
    flexDirection: "column",
})

export const FormikCheckbox = styled(UiFormikCheckbox)({
    marginLeft: "auto",
})