import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { CategoryIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { CustomSelector, FormikSelect, MenuItem } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { incidentTypeConfig } from "../../utils/eventFiltersConfig"

export const IncidentTypeFilter = (props) => {

    const { t } = useTranslation([I18N_NAMESPACE])
    const {
        className,
        id,
    } = props

    const typeOptions = useMemo(() => {
        return Object.entries(incidentTypeConfig).map(([key, value]) => {
            const typeLabel = t(I18N_NAMESPACE, value.label)
            return (
                <MenuItem
                    id={`type-${key}`}
                    key={`type-${key}`}
                    value={key}
                >
                    {typeLabel}
                </MenuItem>
            )
        })
    }, [t])

    return <FormikSelect
        id={id}
        select={CustomSelector}
        className={className}
        label={t(I18N_NAMESPACE, "containers.incidentFilters.inputs.incidentType.label")}
        name="incidentType"
        icon={CategoryIcon}
        title={t(I18N_NAMESPACE, "containers.incidentFilters.inputs.incidentType.label")}
        displayEmpty
    >
        <MenuItem
            id="type-none"
            key={`incidentType-${t(I18N_NAMESPACE, "containers.incidentFilters.inputs.incidentType.none")}`}
            value={null}
        >
            {t(I18N_NAMESPACE, "containers.incidentFilters.inputs.incidentType.none")}
        </MenuItem>
        {typeOptions}
    </FormikSelect>
}

IncidentTypeFilter.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
}