import * as yup from "yup"

import { I18N_NAMESPACE } from "../../constants/i18n"

export const validationSchema = (t) => yup.object({
    email: yup
        .string()
        .email(t(I18N_NAMESPACE, "forms.recoverPasswordForm.fields.email.validityMessage"))
        .required(t(I18N_NAMESPACE, "forms.recoverPasswordForm.fields.email.requireMessage")),
})
