import { SIDEBAR_WIDTH, styled } from "@l2r-front/l2r-ui"

export const PageWrapper = styled("div")(({ theme }) => {
    return ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "hidden",
        width: `calc((100% - ${SIDEBAR_WIDTH}px) / 2)`,
        height: "100%",
        padding: theme.spacing(0, 2),
    })
})

export const Wrapper = styled("div")(({ height }) => ({
    height: height - 30,
    marginTop: 30,
}))