import Typography from "@mui/material/Typography"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./DrawerHeaderTitle.styled"


export function DrawerHeaderTitle(props) {
    const {
        title,
        className,
    } = props

    return (
        <Styled.Div className={className}>
            <Typography variant="h4">
                {title}
            </Typography>
        </Styled.Div>
    )
}

DrawerHeaderTitle.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
}