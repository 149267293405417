import {
    Chip,
    IconButton as UiIconButton,
    styled,
} from "@l2r-front/l2r-ui"

export const TitleSideComponentWrapper = styled("div")({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
})

export const IconButton = styled(UiIconButton)(() => ({
    marginLeft: "auto",
}))

export const Count = styled(Chip)(({ theme }) => ({
    color: theme.palette["objects/object-black"].main,
    marginLeft: theme.spacing(3),
}))