import {
    Typography,
    Skeleton as UiSkeleton,
    ListItem as UiListItem,
    styled,
} from "@l2r-front/l2r-ui"

export const Elements = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    marginTop: 10,
    color: theme.palette["objects/object-black"].main,
}))

export const IconWrapper = styled("div")(({ theme }) => ({
    alignSelf: "baseline",
    borderRadius: theme.spacing(1),
    boxSizing: "content-box",
    height: theme.components.icons.defaultHeight,
    marginRight: theme.spacing(5),
    padding: theme.spacing(2, 2, 2, 0),
    width: theme.components.icons.defaultWidth,
}))

export const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(3),
}))

export const SubtitleSkeleton = styled(UiSkeleton)(({ theme, variant }) => {
    const size = theme.typography?.[variant]?.lineHeight
    const margin = "1px"
    return ({
        margin: `${margin} 0 ${margin} 0`,
        width: "40%",
        height: `calc(${size} - (2 * ${margin}))`,
        marginBottom: `calc(${margin} + ${theme.spacing(1)})`,
    })
})

export const IconSkeleton = styled(UiSkeleton)(({ theme }) => ({
    width: theme.components.icons.defaultWidth,
    height: theme.components.icons.defaultHeight,
    marginRight: theme.spacing(5),
    alignSelf: "baseline",
}))

export const IconSkeletonWrapper = styled("div")({
    height: "100%",
    alignSelf: "baseline",
})

export const ListItem = styled(UiListItem)(({ theme }) => ({
    padding: 0,
    marginTop: theme.spacing(9),
}))