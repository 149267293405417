import { useTranslation } from "@l2r-front/l2r-i18n"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useUserMutation } from "../../../../common/hooks/mutations/auth/useUserMutation"
import { ProfileForm } from "./ProfileForm.form"

export const ProfileFormPost = () => {
    const { t } = useTranslation(I18N_NAMESPACE)
    const { mutateAsync: updateUser } = useUserMutation({
        successSnackbarMessage: t(I18N_NAMESPACE, "forms.profileForm.snackbar.success"),
        errorSnackbarMessage: t(I18N_NAMESPACE, "forms.profileForm.snackbar.success"),
    })

    return <ProfileForm onSubmit={updateUser} />
}