import { theme } from "./theme"

export const chartsTheme = {
    "color": [
        theme.palette["cta-bg/cta-bg-primary"].main,
        theme.palette["surfaces/surface-clair"].main,
    ],
    "backgroundColor": "rgba(0, 0, 0, 0)",
    "textStyle": {
        "fontFamily": "Poppins",
        "color": "#262527",
    },
    "title": {
        "textStyle": {
            "color": "#262527",
        },
        "subtextStyle": {
            "color": "#262527",
        },
    },
    "grid": {
        "top": 20,
        "bottom": 20,
        "left": 65,
        "right": 20,
    },
    "line": {
        "itemStyle": {
            "borderWidth": 1,
        },
        "lineStyle": {
            "width": 2,
        },
        "symbolSize": 4,
        "symbol": "emptyCircle",
        "smooth": false,
    },
    "radar": {
        "itemStyle": {
            "borderWidth": 1,
        },
        "lineStyle": {
            "width": 2,
        },
        "symbolSize": 4,
        "symbol": "emptyCircle",
        "smooth": false,
    },
    "bar": {
        "itemStyle": {
            "barBorderWidth": 0,
            "barBorderColor": "#D7D7D7",
        },
    },
    "pie": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#D7D7D7",
        },
    },
    "scatter": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#D7D7D7",
        },
    },
    "boxplot": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#D7D7D7",
        },
    },
    "parallel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#D7D7D7",
        },
    },
    "sankey": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#D7D7D7",
        },
    },
    "funnel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#D7D7D7",
        },
    },
    "gauge": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#D7D7D7",
        },
    },
    "candlestick": {
        "itemStyle": {
            "color": "#eb5454",
            "color0": "#47b262",
            "borderColor": "#eb5454",
            "borderColor0": "#47b262",
            "borderWidth": 1,
        },
    },
    "graph": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#D7D7D7",
        },
        "lineStyle": {
            "width": 1,
            "color": "#aaa",
        },
        "symbolSize": 4,
        "symbol": "emptyCircle",
        "smooth": false,
        "label": {
            "color": "#eee",
        },
    },
    "map": {
        "itemStyle": {
            "areaColor": "#eee",
            "borderColor": "#444",
            "borderWidth": 0.5,
        },
        "label": {
            "color": "#262527",
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(255,215,0,0.8)",
                "borderColor": "#444",
                "borderWidth": 1,
            },
            "label": {
                "color": "rgb(100,0,0)",
            },
        },
    },
    "geo": {
        "itemStyle": {
            "areaColor": "#eee",
            "borderColor": "#444",
            "borderWidth": 0.5,
        },
        "label": {
            "color": "#262527",
        },
        "emphasis": {
            "itemStyle": {
                "areaColor": "rgba(255,215,0,0.8)",
                "borderColor": "#444",
                "borderWidth": 1,
            },
            "label": {
                "color": "rgb(100,0,0)",
            },
        },
    },
    "categoryAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#D7D7D7",
                "width": 2,
            },
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#D7D7D7",
                "width": 2,
            },
        },
        "axisLabel": {
            "show": true,
            "color": "#000000",
            "fontWeight": "bold",
            "lineHeight": 14,
        },
        "splitLine": {
            "show": false,
            "lineStyle": {
                "color": ["#D7D7D7"],
                "type": "dashed",
            },
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.2)",
                    "rgba(210,219,238,0.2)",
                ],
            },
        },
    },
    "valueAxis": {
        "axisLine": {
            "show": false,
            "lineStyle": {
                "color": "#D7D7D7",
                "width": 2,
            },
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#D7D7D7",
                "width": 2,
            },
        },
        "axisLabel": {
            "show": true,
            "color": "#000000",
            "fontWeight": "bold",
            "lineHeight": 14,
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": ["#D7D7D7"],
                "type": "dashed",
            },
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.2)",
                    "rgba(210,219,238,0.2)",
                ],
            },
        },
    },
    "logAxis": {
        "axisLine": {
            "show": false,
            "lineStyle": {
                "color": "#D7D7D7",
                "width": 2,
            },
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#D7D7D7",
                "width": 2,
            },
        },
        "axisLabel": {
            "show": true,
            "color": "#000000",
            "fontWeight": "bold",
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#D7D7D7",
                ],
                "type": "dashed",
            },
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.2)",
                    "rgba(210,219,238,0.2)",
                ],
            },
        },
    },
    "timeAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#D7D7D7",
                "width": 2,
            },
        },
        "axisTick": {
            "show": true,
            "lineStyle": {
                "color": "#D7D7D7",
                "width": 2,
            },
        },
        "axisLabel": {
            "show": true,
            "color": "#6E7079",
            "fontWeight": "bold",
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "#D7D7D7",
                ],
                "type": "dashed",
            },
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "rgba(250,250,250,0.2)",
                    "rgba(210,219,238,0.2)",
                ],
            },
        },
    },
    "toolbox": {
        "iconStyle": {
            "borderColor": "#999",
        },
        "emphasis": {
            "iconStyle": {
                "borderColor": "#666",
            },
        },
    },
    "legend": {
        "textStyle": {
            "color": "#333",
        },
    },
    "tooltip": {
        "axisPointer": {
            "lineStyle": {
                "color": "#D7D7D7",
                "width": 1,
            },
            "crossStyle": {
                "color": "#D7D7D7",
                "width": 1,
            },
            shadowStyle: {
                "color": "#D7D7D7",
                opacity: .3,
            },
        },
    },
    "timeline": {
        "lineStyle": {
            "color": "#DAE1F5",
            "width": 2,
        },
        "itemStyle": {
            "color": "#A4B1D7",
            "borderWidth": 1,
        },
        "controlStyle": {
            "color": "#A4B1D7",
            "borderColor": "#A4B1D7",
            "borderWidth": 1,
        },
        "checkpointStyle": {
            "color": "#316bf3",
            "borderColor": "fff",
        },
        "label": {
            "color": "#A4B1D7",
        },
        "emphasis": {
            "itemStyle": {
                "color": "#FFF",
            },
            "controlStyle": {
                "color": "#A4B1D7",
                "borderColor": "#A4B1D7",
                "borderWidth": 1,
            },
            "label": {
                "color": "#A4B1D7",
            },
        },
    },
    "visualMap": {
        "color": [
            "#bf444c",
            "#d88273",
            "#f6efa6",
        ],
    },
    "dataZoom": {
        "handleSize": "undefined%",
        "textStyle": {},
    },
    "markPoint": {
        "label": {
            "color": "#eee",
        },
        "emphasis": {
            "label": {
                "color": "#eee",
            },
        },
    },
}