import { format, isDate } from "date-fns"

import { useDateLocale } from "./useDateLocale"

export function useDateFormatter() {
    const locale = useDateLocale()

    return (date, formatString) => {
        if (!isDate(date)) {
            return date
        }

        return format(date, formatString, { locale: locale })
    }
}