import { PropTypes } from "@l2r-front/l2r-proptypes"
import { AppBar as UiAppBar } from "@l2r-front/l2r-ui"

import * as Styled from "./AppBar.styled"

export function AppBar(props) {
    const {
        searchInputComponent,
        children,
        ...uiAppBarProps
    } = props

    return (
        <Styled.AppBar {...uiAppBarProps}>
            <Styled.Toolbar>
                {searchInputComponent}
                {children}
            </Styled.Toolbar>
        </Styled.AppBar>
    )
}

AppBar.propTypes = {
    ...UiAppBar.propTypes,
    logoUrl: PropTypes.string,
    searchInputComponent: PropTypes.node,
}