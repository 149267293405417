import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { SvraiContextProvider } from "./common/contexts/SvraiContext"
import { SvraiDesktopRouter } from "./common/routers/SvraiDesktopRouter"
import { SvraiMobileRouter } from "./common/routers/SvraiMobileRouter/SvraiMobileRouter"

export function L2rAppSvrai() {
    const isMobile = useIsMobileDevice()

    return (
        <SvraiContextProvider>
            {
                isMobile ? <SvraiMobileRouter /> : <SvraiDesktopRouter />
            }
        </SvraiContextProvider>
    )
}
