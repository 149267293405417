import {
    Autocomplete as MuiAutocomplete,
    IconButton as MuiIconButton,
    TextField as MuiTextField,
} from "@mui/material"
import { styled } from "@mui/material/styles"


export const TextField = styled(MuiTextField)(({ theme }) => ({
    "& MuiAutoComplete-input": {
        height: 32,
        width: 200,
        "& input + fieldset": {
            borderColor: theme["cta-bg/cta-bg-primary"],
        },
    },
}))

const width = 200
const height = 32

export const TextFieldStyle = {
    width,
    height,
}
export const TextFieldInputStyle = {
    borderRadius: 16,
    fontSize: "13px",
    height,
    padding: "0px 28px 0px 14px",
}
export const TextFieldInputLabelStyle = {
    height,
}

export const Autocomplete = styled(MuiAutocomplete)({
    alignSelf: "center",
})

export const IconButton = styled(MuiIconButton)(({ color, theme }) => ({
    backgroundColor: theme.palette[color].main,
    height: 32,
    width: 32,
}))