import { SIDEBAR_WIDTH, styled } from "@l2r-front/l2r-ui"

export const PageContainer = styled("div")(({ theme }) => ({
    height: "100%",
    backgroundColor: theme.errorBackground,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const WrapperRight = styled("div")({
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "flex-end",
    position: "relative",
    width: "100%",
})

export const Logo = styled("img")(() => ({
    height: 40,
}))

export const WrapperLeft = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    height: "100%",
    position: "relative",
    width: `calc(${SIDEBAR_WIDTH}px - ${theme.spacing(9)})`,
}))