import { styled, Link as UiLink } from "@mui/material"

export const Link = styled(UiLink, { shouldForwardProp: prop => prop !== "linkColor" })(({ theme, linkColor }) => {
    const color = linkColor ? theme.palette[linkColor].main : theme.palette["cta-bg/cta-bg-primary"].main
    return {
        color: color,
        textDecorationColor: color,
        width: "fit-content",
    }
})
