import { createContext } from "react"

import { STATUSES_FILTER, TECHNIQUES_FILTER, YEARS_FILTER } from "../../constants/filters"

export const initialState = {
    filters: {
        [STATUSES_FILTER]: [],
        [YEARS_FILTER]: [],
        [TECHNIQUES_FILTER]: [],
    },
    selectedTechnique: null,
    selectedRoadwork: null,
    selectedSegmentUuid: null,
}

const initialDispatch = {}

export const RoadworksStateContext = createContext(initialState)
export const RoadworksDispatchContext = createContext(initialDispatch)