import { SearchIcon as MuiSearchIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./SearchInput.styled"

export const SearchInput = (props) => {
    const {
        onChange,
        placeholder,
        ...otherProps
    } = props

    return (
        <Styled.Search {...otherProps}>
            <Styled.SearchIconWrapper>
                <MuiSearchIcon color="objects/object-lightgrey" />
            </Styled.SearchIconWrapper>
            <Styled.InputBase
                placeholder={placeholder}
                inputProps={{ "aria-label": "search" }}
                onChange={onChange}
            />
        </Styled.Search>
    )
}

SearchInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
}

