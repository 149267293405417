import { useTranslation } from "@l2r-front/l2r-i18n"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { RoadworksSidebarContent } from "../RoadworksSidebarContent"
import * as Styled from "./RoadworksSidebar.styled"

export function RoadworksSidebar() {

    const { t } = useTranslation([I18N_NAMESPACE])
    const isMobile = useIsMobileDevice()

    return (
        <Styled.SidebarContent
            id="works-sidebar"
            title={t(I18N_NAMESPACE, "containers.worksSidebar.title")}>
            <RoadworksSidebarContent isMobile={isMobile} />
        </Styled.SidebarContent>
    )
}
