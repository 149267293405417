import { useMemo } from "react"

import { ToggleButtonGroup as MuiToggleButtonGroup } from "@mui/material"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./ToggleButtonGroup.styled"

export const ToggleButtonGroup = (props) => {
    const {
        options,
        value,
        onChange,
        ...toggleButtonGroupProps
    } = props

    const buttons = useMemo(() => {
        return options.map((option) => {
            return (
                <Styled.ToggleButton key={option.value} value={option.value}>
                    {option.component}
                </Styled.ToggleButton>)
        })
    }, [options])

    return (
        <Styled.ToggleButtonGroup
            value={value}
            onChange={onChange}
            color="primary"
            {...toggleButtonGroupProps}
        >
            {buttons}
        </Styled.ToggleButtonGroup>
    )
}

ToggleButtonGroup.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.any.isRequired,
        component: PropTypes.object.isRequired,
    })).isRequired,
    ...MuiToggleButtonGroup.propTypes,
}