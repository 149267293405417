import { HelpOutlineRoundedIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { IconButton, ListItem } from "@l2r-front/l2r-ui"

import * as Styled from "./RoadInfoItem.styled"

export const RoadInfoItem = (props) => {
    const {
        children,
        className,
        label,
        icon,
        onClick,
        selected,
        stat,
        tooltipText,
        ...listItemProps
    } = props

    const Icon = icon

    return (
        <Styled.ListItemButton className={className} selected={selected} {...listItemProps} onClick={onClick}>
            <Icon />
            <Styled.Label id="road-info-item-name" selected={selected}>{label}</Styled.Label>
            {tooltipText &&
                <Styled.Tooltip disableFocusListener title={tooltipText}>
                    <IconButton>
                        <HelpOutlineRoundedIcon />
                    </IconButton>
                </Styled.Tooltip>
            }
            {children}
            {stat}
        </Styled.ListItemButton>
    )
}

RoadInfoItem.defaultProps = {
    selected: false,
}

RoadInfoItem.propTypes = {
    ...ListItem.propTypes,
    icon: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.object.isRequired]).isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    stat: PropTypes.string,
    tooltipText: PropTypes.string,
}

