import _ from "lodash"

export function getPointSymbolLayerConfig(config) {
    const baseConfig = {
        type: "symbol",
        filter: [
            "all",
            ["==", ["geometry-type"], "Point"],
            ["!", ["has", "point_count"]],
        ],
        layout: {
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
        },
    }

    return _.merge(baseConfig, config)
}

export function getPointCircleLayerConfig(config) {
    const baseConfig = {
        type: "circle",
        filter: [
            "all",
            ["==", ["geometry-type"], "Point"],
            ["!", ["has", "point_count"]],
        ],
    }

    return _.merge(baseConfig, config)
}