import { Typography, styled } from "@l2r-front/l2r-ui"

export const ErrorPageWrapper = styled("div")({
    display: "flex",
    height: "100%",
    margin: "0 5%",
    width: "90%",

    "@media(max-width: 768px)": {
        flexDirection: "column",
        height: "90%",
        margin: "5%",
    },
})

export const Message = styled(Typography)(({ theme }) => ({
    fontSize: "bold",
    marginBottom: theme.spacing(6),
    "@media(max-width: 768px)": {
        marginBottom: theme.spacing(3),
        whiteSpace: "pre-line",
    },
}))

export const MessageWrapper = styled("div")({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    textAlign: "center",
    width: "40%",

    "@media(max-width: 768px)": {
        width: "100%",
    },
})

export const Background = styled("img")({
    objectFit: "contain",
    margin: "auto",
    maxHeight: 600,
    width: "60%",

    "@media(max-width: 768px)": {
        width: "100%",
    },
})