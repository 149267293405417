import { useMemo } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./DetailElements.styled"

export function DetailElementsSkeleton(props) {

    const { detailCount } = props

    const incidentDetails = useMemo(() => {
        return new Array(detailCount).fill(true)
    }, [detailCount])

    return <>
        {
            incidentDetails.map((_, index) => <Styled.Skeleton
                variant="Small"
                key={`detail-skeleton-${index}`}
            />)
        }
    </>
}

DetailElementsSkeleton.propTypes = {
    detailCount: PropTypes.number.isRequired,
}