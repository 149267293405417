
export const onMutationSuccessFeedback = (config, setSnackbar, values) => {
    if (config) {
        if (config.onSuccess) {
            config.onSuccess(values)
        }

        if (config.successSnackbarMessage) {
            setSnackbar({
                label: config.successSnackbarMessage,
                severity: "success",
                anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom",
                },
                duration: config.successSnackbarDuration ?? 5000,
            })
        }
    }
    return null
}

export const onMutationErrorFeedback = (config, setSnackbar, error) => {
    if (config) {
        if (config.onError) {
            config.onError(error)
        }

        if (config.errorSnackbarMessage) {
            setSnackbar({
                label: config.errorSnackbarMessage,
                severity: "error",
                anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom",
                },
                duration: 5000,
            })
        }
    }
    return null
}
