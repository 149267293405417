import { useTranslation } from "@l2r-front/l2r-i18n"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import * as Styled from "./RoadworkTask.styled"

export function RoadworkTaskError() {

    const { t } = useTranslation(I18N_NAMESPACE)

    return <Styled.RoadworkTaskWrapper>
        <Styled.ErrorText>
            {t(I18N_NAMESPACE, "components.roadworkTask.error")}
        </Styled.ErrorText>
    </Styled.RoadworkTaskWrapper>
}