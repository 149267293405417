import { Fab as UiFab, styled } from "@l2r-front/l2r-ui"

export const Fab = styled(UiFab)(({ color, theme }) => ({
    backgroundColor: theme.palette[color].main,
    position: "relative",
    "&:hover": {
        backgroundColor: theme.palette[color].main,
        opacity: ".8",
    },
}))

export const Wrapper = styled("div")(({ theme }) => ({
    position: "absolute",
    bottom: theme.spacing(10),
    right: theme.spacing(3),
    zIndex: theme.zIndex.mobileStepper,
}))
