import { Link as L2RLink } from "react-router-dom"

import { SIDEBAR_WIDTH, styled } from "@l2r-front/l2r-ui"

export const WrapperRight = styled("div")({
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "flex-end",
    position: "relative",
    width: "100%",
})

export const Logo = styled("img")(() => ({
    height: 36,
}))

export const WrapperLeft = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    height: "100%",
    position: "relative",
    width: `calc(${SIDEBAR_WIDTH}px - ${theme.spacing(9)})`,
}))

export const Link = styled(L2RLink)({
    alignItems: "center",
    display: "flex",
    height: "100%",
})