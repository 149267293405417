import { styled } from "@mui/material/styles"

import { Skeleton as UiSkeleton, Typography } from "@l2r-front/l2r-ui"

export const Container = styled("div")({
    height: "100%",
    width: "100%",
})

export const DateContainer = styled("div")(({ theme }) => ({
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "black",
    borderTopLeftRadius: 4,
    padding: theme.spacing(0, 2),
}))

export const DateLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette["texts/text-invert"].main,
}))

export const LoadingContainer = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-clair"].main,
    position: "absolute",
    top: 0,
    height: "100%",
    width: "100%",
    zIndex: 90,
}))

export const Skeleton = styled(UiSkeleton)({
    height: "100%",
    transform: "scale(1)",
    width: "100%",
})

export const ErrorMessage = styled(Typography)({
    fontSize: 21,
})

export const ErrorContainer = styled("div")(({ theme }) => ({
    position: "absolute",
    backgroundColor: theme.errorBackground,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    zIndex: 90,
}))