import { Skeleton as UiSkeleton, styled, Typography } from "@l2r-front/l2r-ui"

const YEARGRADING_HEIGHT = 42
const YEARGRADING_WIDTH = 64

export const Wrapper = styled("div")(({ backgroundColor }) => (({ theme }) => ({
    alignItems: "center",
    backgroundColor: backgroundColor,
    borderRadius: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(0, 4),
    width: YEARGRADING_WIDTH,
})))

export const ErrorWrapper = styled(Wrapper)(({ theme }) => ({
    backgroundColor: theme.palette["texts/text-error"].main,
    height: YEARGRADING_HEIGHT,
}))

export const Text = styled(Typography)(({ color }) => ({
    color: color,
    textAlign: "center",
}))

export const Skeleton = styled(UiSkeleton)({
    height: YEARGRADING_HEIGHT,
    transform: "scale(1)",
    width: YEARGRADING_WIDTH,
})