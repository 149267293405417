import { PropTypes } from "@l2r-front/l2r-proptypes"

import { FavoriteTagsList } from "../../containers/FavoriteTagsList"
import * as Styled from "./IncidentTags.styled"

export function IncidentTags(props) {

    const { tags } = props

    return (
        <Styled.TagsWrapper>
            <FavoriteTagsList initialTags={tags} />
        </Styled.TagsWrapper>
    )
}

IncidentTags.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
} 