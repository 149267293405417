export const EVENT_TYPE_GPS = "GPS"
export const EVENT_TYPE_INCIDENT = "INCIDENT"
export const EVENT_TYPE_EVENT = "EVENT"

export const EVENT_TYPE_LIST = [
    EVENT_TYPE_GPS,
    EVENT_TYPE_INCIDENT,
    EVENT_TYPE_EVENT,
]

export const EVENT_INFOS_CONDITIONS = "conditions"
export const EVENT_INFOS_LOCATION = "location"
export const EVENT_INFOS_STATUS = "status"
export const EVENT_INFOS_TAGS = "tags"
export const EVENT_INFOS_TIME = "interval"
export const EVENT_INFOS_HOURS = "hours"
export const EVENT_INFOS_DATE = "date"
export const EVENT_INFOS_PUBLICATION_STATUS = "publicationStatus"
export const EVENT_INFOS_INCIDENT_TYPE = "incidentType"
export const EVENT_INFOS_LIST = [
    EVENT_INFOS_CONDITIONS,
    EVENT_INFOS_LOCATION,
    EVENT_INFOS_STATUS,
    EVENT_INFOS_INCIDENT_TYPE,
    EVENT_INFOS_TAGS,
    EVENT_INFOS_TIME,
]

export const EVENT_LIGHT_CONDITION = "lightCondition"
export const LIGHT_CONDITION_DAY = "DAY"
export const LIGHT_CONDITION_NIGHT = "NIGHT"
export const LIGHT_CONDITION_LIST = [LIGHT_CONDITION_DAY, LIGHT_CONDITION_NIGHT]

export const EVENT_AREA_CONDITION = "area"
export const AREA_CONDITION_URBAN = "URBAN"
export const AREA_CONDITION_RURAL = "RURAL"
export const AREA_CONDITION_LIST = [AREA_CONDITION_URBAN, AREA_CONDITION_RURAL]
export const EVENT_CONDITION_LIST = [EVENT_LIGHT_CONDITION]


export const INCIDENT_STATUS_NEW = "NEW"
export const INCIDENT_STATUS_ERROR = "ERROR"
export const INCIDENT_STATUS_NOT_USABLE = "NOT_USABLE"
export const INCIDENT_STATUS_ONGOING = "ONGOING"
export const INCIDENT_STATUS_ADJUSTMENTS = "ADJUSTMENTS"
export const INCIDENT_STATUS_AUDIT = "AUDIT"
export const INCIDENT_STATUS_CLOSED = "CLOSED"

export const INCIDENT_STATUS_CLOSED_WITHOUT_ADJUSTMENTS = "CLOSED_WITHOUT_ADJUSTMENTS"

export const INCIDENT_STATUS_LIST = [
    INCIDENT_STATUS_NEW,
    INCIDENT_STATUS_ONGOING,
    INCIDENT_STATUS_ADJUSTMENTS,
    INCIDENT_STATUS_AUDIT,
    INCIDENT_STATUS_NOT_USABLE,
    INCIDENT_STATUS_CLOSED_WITHOUT_ADJUSTMENTS,
    INCIDENT_STATUS_CLOSED,
    INCIDENT_STATUS_ERROR,
]

export const INCIDENT_TYPE_RULE_4 = "RULE_4"
export const INCIDENT_TYPE_RULE_5 = "RULE_5"
export const INCIDENT_TYPE_RULE_6 = "RULE_6"

export const INCIDENT_TYPE_LIST = [
    INCIDENT_TYPE_RULE_4,
    INCIDENT_TYPE_RULE_5,
    INCIDENT_TYPE_RULE_6,
]

