import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { useRoadworksQuery } from "../../../../common/hooks/queries/useRoadworksQuery"
import { roadworksQueryKeys } from "./queryKeys"

export function useYearsStatsQuery(
    year,
    config = {},
) {
    const { selectedNetwork } = useNetworksStateContext()
    const url = "roadworks/stats/years/"
    const queryKey = roadworksQueryKeys["years_stats"]()

    return useRoadworksQuery(
        url,
        queryKey,
        { project: selectedNetwork },
        config,
    )
}
