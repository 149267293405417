import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ListRoundedIcon, MapRoundedIcon } from "@l2r-front/l2r-icons"
import { MobileModulePage } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { PARAM_KEY_ROADWAY } from "../../../../common/constants/urlParams"
import { RoadwayLayerIndicator } from "../../containers/RoadwayLayerIndicator"
import { RoadwayMapLayersRouterMobile } from "../../routers/RoadwayMapLayersRouter"
import * as Styled from "./MobileRoadwayPage.styled"

export function MobileRoadwayPage() {

    const { t } = useTranslation(I18N_NAMESPACE)

    const roadwayListTabs = useMemo(() => ([
        {
            element: <Styled.RoadwayNotations />,
            icon: <ListRoundedIcon />,
            id: "tab-notations",
            label: t(I18N_NAMESPACE, "pages.roadway.tabs.notations"),
        },
        {
            element: <Styled.Map layerIndicator={<RoadwayLayerIndicator />} >
                <RoadwayMapLayersRouterMobile />
            </Styled.Map>,
            icon: <MapRoundedIcon />,
            id: "tab-map",
            label: t(I18N_NAMESPACE, "pages.roadway.tabs.map"),
            fullScreen: true,
        },
    ]), [t])

    return (
        <MobileModulePage paramKey={PARAM_KEY_ROADWAY} tabsConfig={roadwayListTabs} />
    )
}