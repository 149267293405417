import { styled, Typography } from "@mui/material"

export const Container = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    height: 45,
}))

export const LabeledData = styled("div", { shouldForwardProp: (prop) => prop !== "noMargin" })(({ noMargin, theme }) => ({
    display: "flex",
    flexDirection: "column",
    marginLeft: !noMargin && theme.spacing(3),
    marginRight: "auto",
    width: "100%",
    minWidth: 0,
    textAlign: "left",
}))

export const ValueLabel = styled(Typography)(() => ({
    display: "flex",
    alignItems: "center",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
}))
