import _ from "lodash"

export function getLineLayerConfig(config) {
    const baseConfig = {
        type: "line",
        layout: {
            "line-join": "round",
            "line-cap": "butt",
        },
    }

    return _.merge(baseConfig, config)
}
