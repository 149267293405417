import { useMemo } from "react"

import { PinIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useTheme } from "@l2r-front/l2r-ui"

import {
    INCIDENT_TYPE_RULE_4,
    INCIDENT_TYPE_RULE_5,
    INCIDENT_TYPE_RULE_6,
    INCIDENT_TYPE_LIST,
} from "../../constants/event"

const incidentTypesSettings = {
    [INCIDENT_TYPE_RULE_4]:
    {
        icon: PinIcon,
        color: "incidentDefaultColor",
    },
    [INCIDENT_TYPE_RULE_5]:
    {
        icon: PinIcon,
        color: "incidentDefaultColor",
    },
    [INCIDENT_TYPE_RULE_6]:
    {
        icon: PinIcon,
        color: "incidentDefaultColor",
    },
}

export const IncidentTypeIcon = (props) => {
    const {
        type,
        ...iconProps
    } = props
    const theme = useTheme()

    const Icon = useMemo(() => {
        return incidentTypesSettings[type].icon
    }, [type])

    const color = useMemo(() => {
        return theme[incidentTypesSettings[type].color]
    }, [type, theme])

    return (
        <Icon style={{ color: color }} {...iconProps} />
    )
}

IncidentTypeIcon.propTypes = {
    type: PropTypes.oneOf(INCIDENT_TYPE_LIST).isRequired,
}