import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import * as Styled from "./RoadworkDetailsForm.styled"

export function RoadworkDetailsFormError(props) {

    const { className } = props
    const { t } = useTranslation(I18N_NAMESPACE)

    return <Styled.RoadworkDetailWrapper className={className}>
        <Styled.ErrorText>
            {t(I18N_NAMESPACE, "components.roadworkDetail.error")}
        </Styled.ErrorText>
    </Styled.RoadworkDetailWrapper>
}

RoadworkDetailsFormError.propTypes = {
    className: PropTypes.string,
}