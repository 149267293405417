import {
    Chip as UiTag,
    List as UiList,
    ListItem as UiListItem,
    Skeleton as UiSkeleton,
    Typography,
    styled,
    SIDEBAR_SPACING,
    SIDEBAR_SPACING_MOBILE,
    styledForMobile,
} from "@l2r-front/l2r-ui"

export const Header = styledForMobile("div")(({ theme, isMobile }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 40,
    padding: isMobile ? theme.spacing(0, SIDEBAR_SPACING_MOBILE, 0, SIDEBAR_SPACING_MOBILE) : theme.spacing(0, SIDEBAR_SPACING, 0, SIDEBAR_SPACING),
}))

export const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette["objects/object-black"].main,
}))

export const ListWrapper = styled("div")({
    flex: "1 1 auto",
    overflow: "auto",
})

export const List = styledForMobile(UiList)(({ theme, isMobile }) => ({
    padding: isMobile ? theme.spacing(0, SIDEBAR_SPACING_MOBILE, 0, SIDEBAR_SPACING_MOBILE) : theme.spacing(0, SIDEBAR_SPACING, 0, SIDEBAR_SPACING),
    marginBottom: theme.spacing(5),
}))

export const Tag = styled(UiTag)(({ theme }) => ({
    margin: theme.spacing(1),
}))

export const ListItem = styled(UiListItem)({
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
})

export const TitleSkeleton = styled(UiSkeleton)(({ theme, variant = "h5" }) => {
    const titleLineHeight = theme.typography?.[variant]?.lineHeight

    return ({
        height: titleLineHeight,
        width: "40%",
    })
})

export const Wrapper = styled("div")({
    height: "100%",
    overflowY: "scroll",
    width: "100%",
})