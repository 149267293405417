import { useCallback } from "react"

import { useNavigate } from "react-router-dom"

import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"


import * as Styled from "./IncidentsFiltersMobilePage.styled"

export function IncidentsFiltersMobilePage() {
    const navigate = useNavigate()
    const navigateWithSearchParams = useNavigateWithSearchParams()

    const onCloseFilters = useCallback((searchParams) => {
        if (searchParams) {
            const searchParamsUrl = Array.from(searchParams?.entries() || {}).reduce((acc, [k, v], i) => {
                return `${acc}${i !== 0 ? "&" : ""}${k}=${v}`
            }, "?")
            navigate(`..${searchParamsUrl}`)
        } else {
            navigateWithSearchParams("..")
        }
    }, [navigate, navigateWithSearchParams])

    return <Styled.MobileIncidentFilters displayed close={onCloseFilters} />
}