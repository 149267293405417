import { useMemo } from "react"

import { Link, useNavigate } from "react-router-dom"

import { useAuthenticationDispatchContext } from "@l2r-front/l2r-auth"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { LogoutIcon, SettingsIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useBlockingNavigate } from "@l2r-front/l2r-query"
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@l2r-front/l2r-ui"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../constants/i18n"
import * as Styled from "./AccountMenu.styled"

export const AccountMenu = (props) => {
    const {
        anchorEl,
        onClose,
        open,
        ...menuProps
    } = props

    const { t } = useTranslation([I18N_NAMESPACE])
    const { getParams } = useUrlParams()
    const searchParams = getParams()
    const navigate = useNavigate()
    const _open = useMemo(() => {
        return open === undefined ? Boolean(anchorEl) : open
    }, [open, anchorEl])
    const { logout } = useAuthenticationDispatchContext()
    const blockingNavigate = useBlockingNavigate(() => navigate("/"), logout)

    return (
        <Styled.Menu
            anchorEl={anchorEl}
            open={_open}
            onClose={onClose}
            onClick={onClose}
            {...menuProps}>
            <MenuItem component={Link} to={`/account_settings?${searchParams}`}>
                <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                    {t(I18N_NAMESPACE, "containers.accountMenu.settings")}
                </ListItemText>
            </MenuItem>
            <Styled.LogoutMenuItem onClick={blockingNavigate} id="logout-button">
                <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                    {t(I18N_NAMESPACE, "containers.accountMenu.logout")}
                </ListItemText>
            </Styled.LogoutMenuItem>
        </Styled.Menu>
    )
}

AccountMenu.propTypes = {
    ...Menu.propTypes,
    onClose: PropTypes.func,
    open: PropTypes.bool,
}

AccountMenu.defaultProps = {
    open: undefined,
    anchorEl: null,
}