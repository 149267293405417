import { useEffect, useMemo } from "react"

import { useDateFormatter } from "@l2r-front/l2r-i18n"

import { useImageCollectionStateContext, useImageCollectionDispatchContext } from "../../contexts/ImageCollectionContext"
import { useNearestImageInCollection } from "../../hooks/queries/imageCollection/useNearestImageInCollection"
import { usePhoto } from "../../hooks/queries/imageCollection/usePhoto"
import { PhotoSphereViewer } from "../PhotoSphereViewer/PhotoSphereViewer"
import { PanoramicViewerError } from "./PanoramicViewer.error"
import * as Styled from "./PanoramicViewer.styled"

export function PanoramicViewer(props) {

    const { initialPosition, date } = props

    const dateFormatter = useDateFormatter()
    const { imageCollection, selectedImageCollectionRoad, displayedImagePosition } = useImageCollectionStateContext()
    const { setDisplayedImagePosition } = useImageCollectionDispatchContext()

    const {
        imageFeature,
        isLoading: isLoadingImageCollections,
        isError: isErrorImageCollection,
    } = useNearestImageInCollection(imageCollection, selectedImageCollectionRoad, initialPosition)

    const {
        data: panoData,
        isLoading: isLoadingPhoto,
        isError: isErrorPhoto,
    } = usePhoto(imageFeature?.properties?.photo__uuid)

    const imageUrl = useMemo(() => {
        if (imageFeature?.properties?.photo__uuid && panoData && !isErrorPhoto) {
            const blob = new Blob([panoData], { type: "application/octet-stream" })
            const url = URL.createObjectURL(blob)
            return url
        }
    }, [panoData, imageFeature?.properties?.photo__uuid, isErrorPhoto])

    useEffect(() => {
        if (imageFeature
            && displayedImagePosition?.lat !== imageFeature.geometry.coordinates[1]
            && displayedImagePosition?.lng !== imageFeature.geometry.coordinates[0]) {
            setDisplayedImagePosition({ lat: imageFeature.geometry.coordinates[1], lng: imageFeature.geometry.coordinates[0] })
        } else if (isLoadingPhoto && !imageFeature) {
            setDisplayedImagePosition(null)
        }
    }, [imageFeature, displayedImagePosition, setDisplayedImagePosition, isLoadingPhoto])

    const isLoading = isLoadingImageCollections || (isLoadingPhoto && !!imageFeature)
    const isError = isErrorImageCollection || isErrorPhoto

    return (
        <>
            <Styled.Container id="panoramic-viewer">
                <PhotoSphereViewer imageUrl={imageUrl} />

                <Styled.DateContainer>
                    {date && <Styled.DateLabel>{dateFormatter(new Date(date), "MMMM y")}</Styled.DateLabel>}
                </Styled.DateContainer>
            </Styled.Container>
            {isLoading && <Styled.LoadingContainer><Styled.Skeleton /></Styled.LoadingContainer>}
            {(isError || (!isLoading && !imageFeature)) && <PanoramicViewerError />}
        </>
    )

}