import { useState, useCallback } from "react"

import { useFormikContext } from "formik"

import { useTranslation, useDateFormatter } from "@l2r-front/l2r-i18n"
import { AccessTimeFilledIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { ClickAwayListener, CustomOpenButton, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { EVENT_INFOS_DATE } from "../../constants/event"
import { TimeSlotFilter } from "../TimeSlotFilter"
import * as Styled from "./HoursFilter.styled"

export const HoursFilter = (props) => {

    const {
        className,
        id,
    } = props

    const [anchorEl, setAnchorEl] = useState(null)
    const { t } = useTranslation(I18N_NAMESPACE)
    const dateFormatter = useDateFormatter()
    const isMobile = useIsMobileDevice()

    const formikState = useFormikContext()
    const formattedTimeSlotStartHour = dateFormatter(formikState.values.date.timeSlotStartHour, "HH")
    const formattedTimeSlotEndHour = dateFormatter(formikState.values.date.timeSlotEndHour, "HH")

    const handleChangeStart = useCallback((event) => {
        formikState.setFieldValue(EVENT_INFOS_DATE, { ...formikState.values.date, timeSlotStartHour: event })
    }, [formikState])

    const handleChangeEnd = useCallback((event) => {
        formikState.setFieldValue(EVENT_INFOS_DATE, { ...formikState.values.date, timeSlotEndHour: event })
    }, [formikState])


    const handleFilterButtonClick = useCallback((event) => {
        const newAnchor = anchorEl ? null : event.currentTarget
        setAnchorEl(newAnchor)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setAnchorEl])

    const open = Boolean(anchorEl)
    const popperId = open ? "hoursfilter-popper" : undefined

    const handleClickAway = useCallback(() => {
        setAnchorEl(null)
    }, [])

    return (
        <>
            <CustomOpenButton
                className={className}
                id={id}
                title={t(I18N_NAMESPACE, "containers.hoursFilter.title")}
                icon={AccessTimeFilledIcon}
                value={`${formattedTimeSlotStartHour}h - ${formattedTimeSlotEndHour}h`}
                open={open}
                onClick={handleFilterButtonClick}
            />
            <Styled.Popper
                id={popperId}
                open={open}
                anchorEl={anchorEl}>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Styled.Paper elevation={25} isMobile={isMobile}>
                        <Styled.FilterWrapper>
                            <TimeSlotFilter
                                endTimeValue={formikState.values.date.timeSlotEndHour}
                                onChangeStart={handleChangeStart}
                                onChangeEnd={handleChangeEnd}
                                startTimeValue={formikState.values.date.timeSlotStartHour}
                            />
                        </Styled.FilterWrapper>
                    </Styled.Paper>
                </ClickAwayListener>
            </Styled.Popper>
        </>
    )
}

HoursFilter.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
}