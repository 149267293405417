import { PropTypes } from "@l2r-front/l2r-proptypes"

import { RoadInfoItem as RoadInfoItemComponent } from "../../components/RoadInfoItem"

export const RoadInfoItem = (props) => {

    const {
        children,
        className,
        ...roadInfoProps
    } = props

    return (
        <RoadInfoItemComponent className={className} {...roadInfoProps} >
            {children}
        </RoadInfoItemComponent>
    )
}

RoadInfoItem.propTypes = RoadInfoItemComponent.propTypes & {
    children: PropTypes.node,
}