import Axios from "axios"
import Qs from "qs"

export const l2rApiAxiosInstance = Axios.create({
    baseURL: process.env["NX_PUBLIC_API_L2R"],
    paramsSerializer: function (params) {
        return Qs.stringify(params, { arrayFormat: "comma" })
    },
})

l2rApiAxiosInstance.interceptors.request.use(
    (config) => {
        const headers = {}
        const item = localStorage.getItem("authentication")
        if (item) {
            const authentication = JSON.parse(item)

            if (authentication.accessToken) {
                headers.Authorization = `Bearer ${authentication.accessToken}`
            }
        }

        return {
            ...config,
            headers: {
                ...config.headers,
                ...headers,
            },
        }
    },
)
