import { Typography, Skeleton as UiSkeleton, styled } from "@l2r-front/l2r-ui"

export const TagsWrapper = styled("div")({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
})

export const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(5),
}))

export const TitleSkeleton = styled(UiSkeleton)(({ theme, variant = "h5" }) => {
    const titleLineHeight = theme.typography?.[variant]?.lineHeight

    return ({
        height: titleLineHeight,
        width: "40%",
    })
})

export const SubtitleSkeleton = styled(UiSkeleton)(({ theme, variant }) => {
    const size = theme.typography?.[variant]?.lineHeight
    const margin = "1px"
    return ({
        margin: `${margin} 0 ${margin} 0`,
        width: "40%",
        height: `calc(${size} - (2 * ${margin}))`,
        marginBottom: `calc(${margin} + ${theme.spacing(1)})`,
    })
})

export const IconSkeleton = styled(UiSkeleton)(({ theme }) => ({
    width: theme.components.icons.defaultWidth,
    height: theme.components.icons.defaultHeight,
    marginRight: theme.spacing(5),
    alignSelf: "baseline",
}))

export const IconSkeletonWrapper = styled("div")({
    height: "100%",
    alignSelf: "baseline",
})

export const Elements = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
})