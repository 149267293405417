
import { useGeoServerFeaturesList } from "@l2r-front/l2r-geodata"

import { roadworksQueryKeys } from "./queryKeys"

export function useNetworkRoadworkLayer(networkRoadworkLayer, queryParameters, config = {}) {
    const parameters = {
        ...queryParameters,
        layer: networkRoadworkLayer,
        as_geojson: true,
    }
    const { enabled = true } = config
    const queryKey = roadworksQueryKeys["layer"](networkRoadworkLayer)

    return useGeoServerFeaturesList(
        queryKey,
        parameters,
        {
            ...config,
            enabled: !!networkRoadworkLayer && enabled,
        },
    )
}
