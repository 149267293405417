import { createContext } from "react"

export const initialState = {
    imageLayerDisplayed: false,
    imageIdClicked: null,
    segmentIdClicked: null,
    imagePopupRef: null,
    currentYear: null,
}

const initialDispatch = {}

export const ImageCollectionStateContext = createContext(initialState)
export const ImageCollectionDispatchContext = createContext(initialDispatch)