import { useEffect } from "react"

import * as turf from "@turf/turf"

import { useMapContext } from "@l2r-front/l2r-map"

import { useNetworksStateContext } from "../../contexts/NetworksContext"


export const MapAdapterBoundingBox = () => {

    const { selectedNetwork } = useNetworksStateContext()
    const [mapState, mapDispatch] = useMapContext()
    const { storedBoundingBox } = mapState
    const { getMapRef, setMapBoundingBoxToFit, restoreMapBoundingBox } = mapDispatch
    const mapRef = getMapRef()

    useEffect(() => {
        if (mapRef) {
            if (storedBoundingBox) {
                restoreMapBoundingBox()
            } else {
                const [minLng, minLat, maxLng, maxLat] = turf.bbox(selectedNetwork.boundingBox)
                setMapBoundingBoxToFit({
                    minLng,
                    minLat,
                    maxLng,
                    maxLat,
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapRef, selectedNetwork.boundingBox])

    return null
}