import { useMemo } from "react"

import { sanitizeData } from "@l2r-front/l2r-geodata"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { Source, useMapDispatchContext } from "@l2r-front/l2r-map"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useRoadwayStateContext } from "../../contexts/RoadwayContext"
import { useNetworkGradingLayer } from "../../hooks/queries/networkGradings/useNetworkGradingLayer"

export function NetworkGradingSource(props) {
    const { t } = useTranslation(I18N_NAMESPACE)
    const { setError: setMapError } = useMapDispatchContext()
    const { selectedNetworkYearlyStatus } = useRoadwayStateContext()

    const { data } = useNetworkGradingLayer(selectedNetworkYearlyStatus?.layer, {}, {
        onError: () => setMapError(true),
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.notationLayer.error"),
    })

    // Need to copy id into properties because of that mapbox issue: 
    // link:https://github.com/mapbox/mapbox-gl-js/issues/2716
    const sanitizedData = useMemo(() => {
        return sanitizeData(data)
    }, [data])

    if (!sanitizedData) {
        return null
    }

    return (
        <Source {...props} type="geojson" data={sanitizedData} tolerance={.001} />
    )
}

NetworkGradingSource.propTypes = Source.propTypes