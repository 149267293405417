import { styled } from "@l2r-front/l2r-ui"

import { MobileMap as L2rMap } from "../../../../common/containers/Map"
import { IncidentPaginatedList as L2rIncidentPaginatedList } from "../../containers/IncidentPaginatedList"

export const Map = styled(L2rMap)({
    height: "100%",
    width: "100%",
})

export const IncidentPaginatedList = styled(L2rIncidentPaginatedList)(({ theme }) => ({
    paddingTop: theme.spacing(6),
}))
