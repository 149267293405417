import { styled, ToggleButton as MuiToggleButton, ToggleButtonGroup as MuiToggleButtonGroup } from "@mui/material"

const TOGGLE_BUTTON_GROUP_HEIGHT = 36

export const ToggleButtonGroup = styled(MuiToggleButtonGroup)((({ theme }) => ({
    overflowX: "auto",

    "&.MuiToggleButtonGroup-root": {
        border: 0,
        outline: `1px solid ${theme.palette["border/border-primary"].main}`,
        borderRadius: theme.spacing(3),
        height: TOGGLE_BUTTON_GROUP_HEIGHT,

        ".MuiToggleButtonGroup-grouped": {
            border: 0,
            borderRadius: theme.spacing(3),
        },

        ".MuiToggleButtonGroup-grouped:not(:last-of-type)": {
            ":before": {
                content: "''",
                position: "absolute",
                right: 0,
                bottom: TOGGLE_BUTTON_GROUP_HEIGHT / 4,
                width: 1,
                height: TOGGLE_BUTTON_GROUP_HEIGHT / 2 - 1,
                borderRight: `1px solid ${theme.palette["colors/gris/400"].main}`,
            },
        },
    },
})))

export const ToggleButton = styled(MuiToggleButton)((({ theme }) => ({

    "&.MuiToggleButton-root": {

        ".MuiTypography-root": {
            textTransform: "none",
            color: "black",
            ...theme.typography["Regular"],
        },

    },

    "&.Mui-selected": {
        background: theme.palette["border/border-primary"].main,
        borderTopLeftRadius: `${theme.spacing(3)}`,
        borderBottomLeftRadius: `${theme.spacing(3)}`,
        borderTopRightRadius: `${theme.spacing(3)}`,
        borderBottomRightRadius: `${theme.spacing(3)}`,

        "& + .Mui-selected": {
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
        },

        "&:has(+ .Mui-selected)": {
            borderTopRightRadius: "0",
            borderBottomRightRadius: "0",
        },

        "&:not(:has(+ .Mui-selected))": {
            ":before": {
                display: "none",
            },
        },

        ".MuiTypography-root": {
            color: theme.palette["texts/text-invert"].main,
        },

        "svg": {
            fill: theme.palette["texts/text-invert"].main,
        },

        "&:hover": {
            backgroundColor: theme.palette["cta-bg/cta-bg-primary"].dark,
            color: theme.palette["cta-bg/cta-bg-primary"].dark,
            "@media (hover: none)": {
                backgroundColor: theme.palette["cta-bg/cta-bg-primary"].main,
            },
        },

    },
})))