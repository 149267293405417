import { useCallback } from "react"

import { useParams } from "react-router-dom"
import { useCopyToClipboard } from "react-use"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useNetworksStateContext, useRoleIsReadOnly } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useAlertsDispatchContext } from "@l2r-front/l2r-query"
import { Typography, useIsMobileDevice } from "@l2r-front/l2r-ui"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"


import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { IncidentDetails, IncidentDetailsSkeleton } from "../../components/IncidentDetails"
import {
    EVENT_INFOS_CONDITIONS,
    EVENT_INFOS_INCIDENT_TYPE,
    EVENT_INFOS_STATUS,
    EVENT_INFOS_TAGS,
    EVENT_INFOS_TIME,
} from "../../constants/event"
import { useEventQuery } from "../../hooks/queries/events/useEvent"
import { IncidentDetailsSidebarContentError } from "./IncidentDetailsSidebarContent.error"
import * as Styled from "./IncidentDetailsSidebarContent.styled"


export function IncidentDetailsSidebarContent(props) {

    const {
        className,
        showDetailsButton,
        showAnalysisButton,
    } = props
    const { eventId } = useParams()
    const { data: incident, isLoading, isError } = useEventQuery(eventId)
    const { selectedNetwork } = useNetworksStateContext()
    const readOnly = useRoleIsReadOnly()
    const { setSnackbar } = useAlertsDispatchContext()
    const [_, copyToClipboard] = useCopyToClipboard()
    const isMobile = useIsMobileDevice()
    const navigate = useNavigateWithSearchParams()
    const { t } = useTranslation([I18N_NAMESPACE])

    const openAnalysis = useCallback(() => {
        const params = "popup,width=700,height=800,left=100,top=100"
        window.open(
            `/${selectedNetwork.slug}/safety/incidents/${eventId}/analysis?projectCache=analysis&device=${isMobile ? "mobile" : "desktop"}`,
            "analysis",
            params)

    }, [selectedNetwork, eventId, isMobile])

    const displayedElements = [
        EVENT_INFOS_TAGS,
        EVENT_INFOS_CONDITIONS,
        EVENT_INFOS_STATUS,
        EVENT_INFOS_INCIDENT_TYPE,
        EVENT_INFOS_TIME,
    ]

    const shareIncident = useCallback(() => {
        copyToClipboard(window.location.href)
        setSnackbar({ label: t(I18N_NAMESPACE, "containers.incidentDetailsSidebar.snackbar.share"), severity: "success" })
    }, [copyToClipboard, setSnackbar, t])

    if (isLoading) {
        return <Styled.IncidentDetailsSidebarWrapper>
            <IncidentDetailsSkeleton displayedElements={displayedElements} />
        </Styled.IncidentDetailsSidebarWrapper>
    }

    if (isError || !incident) {
        return <IncidentDetailsSidebarContentError />
    }

    const navigateDetails = () => {
        return navigate("details")
    }

    return (
        <Styled.IncidentDetailsSidebarWrapper className={className}>
            <IncidentDetails
                displayedElements={displayedElements}
                incident={incident}
            />
            <Styled.ButtonsWrapper variant="text" isMobile={isMobile}>
                {!isMobile && <Styled.ShareWrapper
                    aria-label="share-button"
                    id="share-button"
                    onClick={() => shareIncident()}
                >
                    <Styled.ShareText variant="Regular">
                        {t(I18N_NAMESPACE, "containers.incidentDetailsSidebar.share")}
                    </Styled.ShareText>
                    <Styled.LinkIcon color="cta-bg/cta-bg-primary" />
                </Styled.ShareWrapper>}
                {!isMobile && <Styled.Wrapper>
                    {showDetailsButton && <Styled.Button
                        id="incident-details-button"
                        onClick={navigateDetails}
                        variant="contained"
                    >
                        <Typography variant="Regular">
                            {t(I18N_NAMESPACE, "containers.incidentDetailsSidebar.showDetails")}
                        </Typography>
                    </Styled.Button>}
                    {showAnalysisButton && !readOnly && <Styled.Button
                        id="incident-details-analysis-button"
                        onClick={openAnalysis}
                        variant="contained"
                    >
                        <Typography variant="Regular">
                            {t(I18N_NAMESPACE, "containers.incidentDetailsSidebar.showAnalysis")}
                        </Typography>
                    </Styled.Button>}
                </Styled.Wrapper>}
            </Styled.ButtonsWrapper>
        </Styled.IncidentDetailsSidebarWrapper >
    )
}

IncidentDetailsSidebarContent.propTypes = {
    showDetailsButton: PropTypes.bool,
}

IncidentDetailsSidebarContent.defaultProps = {
    showDetailsButton: false,
}


