import { SIDEBAR_WIDTH, styled } from "@l2r-front/l2r-ui"

import { Map as MapComponent } from "../../containers/Map"

export const Wrapper = styled("div")({
    display: "flex",
    width: "100%",
    height: "100%",
})

export const Map = styled(MapComponent)(() => {
    return ({
        height: "100%",
        width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
        marginLeft: "auto",
    })
})