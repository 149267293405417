import { styled, Skeleton, Typography } from "@l2r-front/l2r-ui"

import { RoadInfoItem as L2rRoadInfoItem } from "../RoadInfoItem"

export const RoadInfoItem = styled(L2rRoadInfoItem)({
    "& > span": {
        textTransform: "capitalize !important",
    },
    height: "auto",
    minHeight: 52,
})

export const RoadInfoItemSkeleton = styled(Skeleton)({
    height: 52,
})

export const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette["texts/text-error"].main,
}))