import { useRoadworksQuery } from "../../../../common/hooks/queries/useRoadworksQuery"
import { constructTechniqueAcronym } from "../../utils/roadworkUtils"
import { roadworksQueryKeys } from "./queryKeys"
import { useTaskTypes } from "./useTaskType"

export function useGetTechniquesQuery(
    queryParameters,
    config = {},
) {
    const url = "roadworks/techniques/"
    const queryKey = roadworksQueryKeys["techniques"](queryParameters)

    const {
        data: techniques,
        isLoading: isLoadingTechniques,
        isError: isErrorTechniques,
    } = useRoadworksQuery(
        url,
        queryKey,
        queryParameters,
        config,
    )

    const {
        data: taskTypes,
        isLoading: isLoadingTaskTypes,
        isError: isErrorTaskTypes,
    } = useTaskTypes()

    const isLoading = isLoadingTechniques || isLoadingTaskTypes
    const isError = isErrorTechniques || isErrorTaskTypes

    const data = techniques?.map(technique => {
        const techniqueAcronym = constructTechniqueAcronym(technique, taskTypes)
        return {
            ...technique,
            acronym: techniqueAcronym,
        }
    })

    return { data, isLoading, isError }
}