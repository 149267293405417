import { PropTypes } from "@l2r-front/l2r-proptypes"

import { TimeSlotFilter as L2rTimeSlotFilter } from "../../components/TimeSlotFilter"

export const TimeSlotFilter = (props) => {
    const {
        endTimeValue,
        onChangeStart,
        onChangeEnd,
        startTimeValue,

    } = props

    return (
        <L2rTimeSlotFilter
            startTimeValue={startTimeValue}
            endTimeValue={endTimeValue}
            onStartTimeValueChange={onChangeStart}
            onEndTimeValueChange={onChangeEnd}
        />
    )
}

TimeSlotFilter.propTypes = {
    endTimeValue: PropTypes.date.isRequired,
    onChangeStart: PropTypes.func.isRequired,
    onChangeEnd: PropTypes.func.isRequired,
    startTimeValue: PropTypes.date.isRequired,
}