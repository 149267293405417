import { SvgIcon } from "@mui/material"

import { ReactComponent as Icon } from "./rubber.svg"

export const RubberIcon = (props) => {
    return (
        <SvgIcon component={Icon} {...props} inheritViewBox />
    )
}

