import { useMemo } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useTheme } from "@l2r-front/l2r-ui"
import { slugify } from "@l2r-front/l2r-utils"

import { INCIDENT_STATUS_LIST } from "../../constants/event"
import { incidentStatusSettings } from "../../constants/incidentStatusSettings"


export const IncidentStatusIcon = (props) => {
    const {
        status,
        ...iconProps
    } = props

    const theme = useTheme()

    const Icon = useMemo(() => {
        return incidentStatusSettings[status].icon
    }, [status])

    const color = useMemo(() => {
        if (iconProps?.color) {
            return iconProps.color
        }
        return incidentStatusSettings[status].color
            .split(".")
            .reduce((acc, v) => {
                return acc?.[v] ? acc[v] : acc
            }, theme)
    }, [iconProps, status, theme])

    return (
        <Icon id={`incident-status-icon-${slugify(status)}`} style={{ color: color }} {...iconProps} />
    )
}

IncidentStatusIcon.propTypes = {
    status: PropTypes.oneOf(INCIDENT_STATUS_LIST).isRequired,
}