import { SvgIcon } from "@mui/material"

import { ReactComponent as Icon } from "./stabilo.svg"

export const PaintIcon = (props) => {
    return (
        <SvgIcon component={Icon} {...props} inheritViewBox />
    )
}

