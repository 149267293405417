import { useMemo } from "react"

import { useRoutes } from "react-router-dom"

import { Sidebar as L2rSidebar } from "@l2r-front/l2r-ui"

import { IncidentDetailsSidebar } from "../../containers/IncidentDetailsSidebar"
import { IncidentsSidebar } from "../../containers/IncidentsSidebar"


export function IncidentsSidebarRouter() {

    const sidebarRoutes = useMemo(() => ([
        { path: "/", element: <IncidentsSidebar /> },
        { path: "/:eventId", element: <IncidentDetailsSidebar showDetailsButton /> },
        { path: "/:eventId/details", element: <IncidentDetailsSidebar showAnalysisButton /> },
    ]), [])

    return (
        <L2rSidebar id="sidebar">
            {useRoutes(sidebarRoutes)}
        </L2rSidebar>
    )
}
