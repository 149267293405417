export const getQueryParamsForArray = (key, value) => {
    if (!value || value.length === 0) {
        return null
    }

    return [
        {
            name: key,
            value: value,
        },
    ]
}

export const getValueListForArray = (key, value) => {
    if (!value) {
        return []
    }

    return value.map((v, index) => {
        return {
            key: key,
            index: index,
            label: v,
            value: v,
        }
    })
}

export const removeValueForArray = (value, valueToRemove) => {
    value.splice(valueToRemove.index, 1)
    return value
}

export const getQueryParamsForAlias = (_, value, config) => {
    if (!config[value]) {
        return null
    }

    return Object.entries(config[value].queryParams).map(([paramKey, paramValue]) => {
        return {
            name: paramKey,
            value: paramValue,
        }
    })
}

export const getValueListForAlias = (key, value, config, t, namespace) => {
    if (!config[value]) {
        return []
    }

    return [{
        key: key,
        label: t(namespace, config[value].label),
        value: value,
    }]
}