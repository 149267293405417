import { Route, Routes } from "react-router-dom"

import { CityDesktopLayout } from "../../layouts/CityDesktopLayout"

export function CityDesktopRouter() {
    return (
        <Routes>
            <Route
                path="/*"
                element={
                    <CityDesktopLayout />
                }
            />
        </Routes>
    )
}