import { Tooltip, styled, tooltipClasses } from "@mui/material"

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[28],
        borderRadius: theme.spacing(3),
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: 14,
        padding: theme.spacing(3),
    },
}))