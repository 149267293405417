import { Typography, Button as UiButton, styled } from "@l2r-front/l2r-ui"

export const WrapperLeft = styled("div")(() => ({
    alignItems: "center",
    display: "flex",
    height: "100%",
    position: "relative",
}))

export const Title = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(9),
}))

export const Button = styled(UiButton)({
    minWidth: "unset",
    width: "fit-content",
    textTransform: "initial",
    padding: 0,
})