import * as yup from "yup"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"

export const validationSchema = (t) => yup.object({
    newPassword: yup
        .string()
        .min(8, t(I18N_NAMESPACE, "forms.recoverPasswordDoneForm.fields.password.minLengthMessage"))
        .matches(/\D/, t(I18N_NAMESPACE, "forms.recoverPasswordDoneForm.fields.password.needLetterMessage"))
        .required(t(I18N_NAMESPACE, "forms.changePasswordForm.fields.newPassword.requireMessage")),
    confirmNewPassword: yup
        .string()
        .required(t(I18N_NAMESPACE, "forms.changePasswordForm.fields.confirmNewPassword.requireMessage"))
        .oneOf([yup.ref("newPassword"), null], t(I18N_NAMESPACE, "forms.changePasswordForm.errors.passwordsMustMatch")),

})
