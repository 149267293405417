import { InputBase as UiInputBase } from "@mui/material"
import { styled, alpha } from "@mui/material/styles"

export const Search = styled("div")(({ theme }) => {
    return ({
        position: "relative",
        borderRadius: 4,
        boxShadow: theme.shadows[0],
        "&:hover": {
            backgroundColor: alpha(theme.palette["objects/object-lightgrey"].main, 0.1),
        },
        width: "100%",
    })
})

export const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const InputBase = styled(UiInputBase)(({ theme }) => ({
    width: "100%",
    height: "100%",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        [theme.breakpoints.up("sm")]: {
            width: "100%",
        },
    },
}))
