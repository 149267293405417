import { useEffect, useRef } from "react"

import { InputAdornment } from "@mui/material"
import MuiTextField from "@mui/material/TextField"

import { VisibilityIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./TextField.styled"

export const TextField = (props) => {
    const {
        className,
        children,
        label,
        labelOutside,
        labelVariant,
        iconStart,
        iconEnd,
        selectInputEnd,
        ...textFieldProps
    } = props

    const inputRef = useRef()

    useEffect(() => {
        if (inputRef?.current && selectInputEnd) {
            const textArea = inputRef?.current?.firstChild?.firstChild
            if (textArea) {
                textArea.setSelectionRange(textArea.value.length, textArea.value.length)
            }
        }
    }, [selectInputEnd])

    return (
        <Styled.Wrapper>
            {label && labelOutside && <Styled.InputLabel
                color="texts/text-primary"
                typography={labelVariant || "h5"}
            >
                {label}
            </Styled.InputLabel>}
            <Styled.TextField
                ref={inputRef}
                className={className}
                label={labelOutside ? null : label}
                {...textFieldProps}
                FormHelperTextProps={{
                    id: textFieldProps.error && `${textFieldProps.id}-error`,
                }}
                InputProps={{
                    startAdornment: iconStart ? (
                        <InputAdornment position="start">{iconStart}</InputAdornment>
                    ) : null,
                    endAdornment: iconEnd ? (
                        <InputAdornment position="end">{iconEnd}</InputAdornment>
                    ) : null,
                }}>
                {children}
                {textFieldProps.type === "password" && <VisibilityIcon />}
            </Styled.TextField>
        </Styled.Wrapper>
    )
}

TextField.propTypes = MuiTextField.propTypes & {
    labelOutside: PropTypes.bool,
    labelVariant: PropTypes.string,
    iconEnd: PropTypes.node,
    iconStart: PropTypes.node,
}

TextField.defaultProps = {
    labelOutside: true,
}
