import { useQueryClient } from "@tanstack/react-query"

import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

import { roadworksQueryKeys } from "../../queries/queryKeys"
import { useRoadworksProject } from "../../queries/useRoadworksProjectQuery"
import { useRoadworksMutation } from "./useRoadworksMutation"

export function useRoadworkCreate(
    queryParameters,
    config = {},
) {
    const url = "roadworks/"
    const { data: roadworkProject } = useRoadworksProject()
    const { enabled = true } = config
    const queryClient = useQueryClient()

    return useRoadworksMutation(
        url,
        axiosInstance.post,
        {
            ...queryParameters,
        },
        {
            ...config,
            enabled: roadworkProject && enabled,
            onSuccess: (newRoadwork) => {
                queryClient.setQueryData(roadworksQueryKeys["roadwork"](newRoadwork.uuid), newRoadwork)
                config?.onSuccess(newRoadwork)
            },
        },
    )
}