import { styled, Typography } from "@l2r-front/l2r-ui"

import { EPD_TAG_HEIGHT } from "../../constants/layout"

export const ArrowLeft = styled("div")(({ color, width }) => ({
    width: width,
    height: EPD_TAG_HEIGHT,
    background: color,
    marginLeft: EPD_TAG_HEIGHT,
    position: "relative",
    textAlign: "right",
    ":after": {
        content: "''",
        position: "absolute",
        borderBottom: `${EPD_TAG_HEIGHT / 2}px solid transparent`,
        borderRight: `${EPD_TAG_HEIGHT / 2}px solid ${color}`,
        borderTop: `${EPD_TAG_HEIGHT / 2}px solid transparent`,
        height: 0,
        width: 0,
        left: 0,
        transform: "translateX(-100%)",
    },
}))

export const ArrowRight = styled("div")(({ color, width }) => ({
    width: width,
    height: EPD_TAG_HEIGHT,
    background: color,
    marginRight: EPD_TAG_HEIGHT,
    position: "relative",
    textAlign: "left",
    ":before": {
        content: "''",
        position: "absolute",
        borderBottom: `${EPD_TAG_HEIGHT / 2}px solid transparent`,
        borderLeft: `${EPD_TAG_HEIGHT / 2}px solid ${color}`,
        borderTop: `${EPD_TAG_HEIGHT / 2}px solid transparent`,
        height: 0,
        width: 0,
        marginLeft: width,
    },
}))

export const LabelLeft = styled(Typography)(({ color, theme }) => ({
    color: color,
    marginLeft: theme.spacing(2),
    lineHeight: `${EPD_TAG_HEIGHT}px`,
}))

export const LabelRight = styled(Typography)(({ color, theme }) => ({
    color: color,
    marginRight: theme.spacing(2),
    lineHeight: `${EPD_TAG_HEIGHT}px`,
}))