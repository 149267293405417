import {
    findNetworkConditionByYear,
} from "./network_conditions"

export const damagesStatsCD91Small2023 = {
    "count": {
        "AABEA": {
            "1": 8,
            "10": 16,
            "20": 13,
            "30": 9,
        },
        "ABBAA": {
            "1": 1,
            "20": 2,
            "30": 1,
        },
        "ACBDA": {
            "None": 2,
        },
        "AFBAC": {
            "1": 22,
            "10": 30,
            "20": 16,
            "30": 34,
        },
        "AFEAC": {
            "1": 52,
            "10": 38,
            "20": 45,
            "30": 55,
        },
        "AFFBA": {
            "10": 2,
            "20": 2,
            "30": 3,
        },
        "AHHAB": {
            "None": 161,
        },
        "AIBAB": {
            "1": 20,
            "10": 17,
            "20": 20,
            "30": 15,
        },
        "AJGAB": {
            "1": 43,
            "10": 44,
            "20": 47,
            "30": 39,
            "None": 6,
        },
        "AKBAA": {
            "1": 19,
            "10": 16,
            "20": 11,
            "30": 19,
        },
        "AKDAA": {
            "1": 20,
            "10": 14,
            "20": 12,
            "30": 16,
        },
    },
    "length": {
        "AABEA": {
            "1": 8,
            "10": 16,
            "20": 13,
            "30": 9,
        },
        "ABBAA": {
            "1": 46,
            "20": 75,
            "30": 12,
        },
        "ACBDA": {
            "None": 151,
        },
        "AFBAC": {
            "1": 22,
            "10": 30,
            "20": 16,
            "30": 34,
        },
        "AFEAC": {
            "1": 2293,
            "10": 1711,
            "20": 2320,
            "30": 2526,
        },
        "AFFBA": {
            "10": 102,
            "20": 160,
            "30": 84,
        },
        "AHHAB": {
            "None": 6373,
        },
        "AIBAB": {
            "1": 20,
            "10": 17,
            "20": 20,
            "30": 15,
        },
        "AJGAB": {
            "1": 1392,
            "10": 1430,
            "20": 1384,
            "30": 1083,
            "None": 200,
        },
        "AKBAA": {
            "1": 101,
            "10": 73,
            "20": 94,
            "30": 87,
        },
        "AKDAA": {
            "1": 20,
            "10": 14,
            "20": 12,
            "30": 16,
        },
    },
}

export const allDamagesStats = {
    [findNetworkConditionByYear("cd91_small", 2023).uuid]: damagesStatsCD91Small2023,
}

export const getDamagesStatsByNetworkCondition = (networkCondition) => {
    return allDamagesStats[networkCondition]
}