import {
    INTERPOLATION_ZOOM_MAX,
    INTERPOLATION_ZOOM_MIN,
    LINE_LAYER_SIZE_OFFSET,
    LINE_LAYER_SIZE,
    SELECTED_LINE_LAYER_SIZE,
    CIRCLE_LAYER_SIZE,
    SELECTED_CIRCLE_LAYER_SIZE,
} from "../../constants/layers"

export function getInterpolatedLineLayerWidth(baseLayerWidth = LINE_LAYER_SIZE) {
    return ["interpolate",
        ["linear"],
        ["zoom"],
        1,
        1,
        INTERPOLATION_ZOOM_MIN,
        baseLayerWidth - LINE_LAYER_SIZE_OFFSET,
        INTERPOLATION_ZOOM_MAX,
        baseLayerWidth,
    ]
}

export function getInterpolatedSelectedLineLayerWidth(featureUuid = null) {
    return ["interpolate",
        ["linear"],
        ["zoom"],
        1,
        1,
        INTERPOLATION_ZOOM_MIN,
        ["case",
            ["==", ["get", "uuid", ["properties"]], featureUuid],
            SELECTED_LINE_LAYER_SIZE - LINE_LAYER_SIZE_OFFSET,
            LINE_LAYER_SIZE - LINE_LAYER_SIZE_OFFSET,
        ],
        INTERPOLATION_ZOOM_MAX,
        ["case",
            ["==", ["get", "uuid", ["properties"]], featureUuid],
            SELECTED_LINE_LAYER_SIZE,
            LINE_LAYER_SIZE,
        ],
    ]
}

export function getInterpolatedSelectableLineLayerWidth(road = null, baseWidth = LINE_LAYER_SIZE) {
    return ["interpolate",
        ["linear"],
        ["zoom"],
        1,
        1,
        INTERPOLATION_ZOOM_MIN,
        ["case",
            ["==", ["get", "road", ["at", 0, ["get", "linearLocation"]]], road],
            SELECTED_LINE_LAYER_SIZE - LINE_LAYER_SIZE_OFFSET,
            baseWidth - LINE_LAYER_SIZE_OFFSET,
        ],
        INTERPOLATION_ZOOM_MAX,
        ["case",
            ["==", ["get", "road", ["at", 0, ["get", "linearLocation"]]], road],
            SELECTED_LINE_LAYER_SIZE,
            baseWidth,
        ],
    ]
}

export function getInterpolatedSelectableCircleLayerRadius(road = null) {
    return ["interpolate",
        ["linear"],
        ["zoom"],
        1,
        1,
        INTERPOLATION_ZOOM_MIN,
        ["case",
            ["==", ["get", "road", ["at", 0, ["get", "linearLocation"]]], road],
            (SELECTED_CIRCLE_LAYER_SIZE) - LINE_LAYER_SIZE_OFFSET,
            (LINE_LAYER_SIZE / 2 + 2) - LINE_LAYER_SIZE_OFFSET,
        ],
        INTERPOLATION_ZOOM_MAX,
        ["case",
            ["==", ["get", "road", ["at", 0, ["get", "linearLocation"]]], road],
            (SELECTED_CIRCLE_LAYER_SIZE),
            (CIRCLE_LAYER_SIZE),
        ],
    ]
}