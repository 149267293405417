import { styled } from "@l2r-front/l2r-ui"

import { LegalFooter as L2RLegalFooter } from "../../components/LegalFooter"

export const AnonymousLayoutWrapper = styled("div")({
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
})

export const Image = styled("img")({
    height: "100%",
    objectFit: "cover",
    width: "180%",
})

export const Container = styled("div")({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "10% 5% 0 5%",
    width: "100%",
})

export const LegalFooter = styled(L2RLegalFooter)(({ theme }) => ({
    marginTop: "auto",
    marginBottom: theme.spacing(4),
}))
