import { QueryKey } from "@tanstack/react-query"

//https://tkdodo.eu/blog/effective-react-query-keys

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type QueryKeyFactory = ((...args: any) => QueryKey)
export type QueryKeyDictionary = { [key: string]: QueryKeyFactory }

export function queryKeyFactory(rootQueryKey: string, additionalQueryKeys: QueryKeyDictionary): QueryKeyDictionary {
    const baseQueryKeys = {
        all: () => [rootQueryKey] as const,
        detail: (id: number) => [...baseQueryKeys.details(), id] as const,
        details: () => [...baseQueryKeys.all(), "detail"] as const,
        list: (filters = {}) => [...baseQueryKeys.lists(), filters] as const,
        lists: () => [...baseQueryKeys.all(), "list"] as const,
        paginatedList: (filters = {}, page: number) => [...baseQueryKeys.lists(), filters, page] as const,
    }

    return { ...baseQueryKeys, ...additionalQueryKeys }
}