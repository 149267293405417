import { styled } from "@mui/material/styles"

import { Chip as UiChip, ChipSkeleton as UiChipSkeleton } from "../Chip"
import { ChipAdd as L2rChipAdd } from "../ChipAdd"

export const Chip = styled(UiChip)(({ color, theme, variant }) => ({
    borderColor: theme.palette[color].main,
    margin: theme.spacing(1),
    padding: theme.spacing(4, 1),

    "& .MuiChip-deleteIcon": {
        color: variant === "outlined" && theme.palette["cta-bg/cta-bg-primary"].main,
    },
}))

export const ChipSkeletonsWrapper = styled("div")({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
})

export const ChipSkeleton = styled(UiChipSkeleton)(({ theme }) => ({
    margin: theme.spacing(1),
}))

export const ChipAdd = styled(L2rChipAdd)(({ theme }) => ({
    margin: theme.spacing(0, 1),
}))

export const ChipsWrapper = styled("div")({
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
})