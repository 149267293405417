import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { FormikToggleButtonGroup, Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { eventPublicationStatusConfig } from "../../utils/eventFiltersConfig"


export const IncidentPublicationToggleGroup = (props) => {
    const { t } = useTranslation([I18N_NAMESPACE])

    const incidentPublicationStatusOptions = useMemo(() => {
        return Object.entries(eventPublicationStatusConfig).map(([statusKey, statusValue]) => {
            return {
                value: statusKey,
                component: <Typography variant="Small">{t(I18N_NAMESPACE, statusValue.label)}</Typography>,
            }
        })
    }, [t])

    return (
        <FormikToggleButtonGroup
            name="publicationStatus"
            exclusive
            options={incidentPublicationStatusOptions}
            {...props}
        />
    )
}