import { styled } from "@l2r-front/l2r-ui"

export const Wrapper = styled("div")(({ height }) => ({
    height: height ?? "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
}))

export const Video = styled("video")(({ theme }) => ({
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.common.black,
}))

export const ErrorContainer = styled("div")(({ theme }) => ({
    backgroundColor: theme.errorBackground,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
}))