import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { RoadDesktopRouter } from "./common/routers/RoadDesktopRouter"
import { RoadMobileRouter } from "./common/routers/RoadMobileRouter"
import { RoadwayContextProvider } from "./modules/roadway/contexts/RoadwayContext"
import { RoadwayStreetViewContextProvider } from "./modules/roadway/contexts/RoadwayStreetViewContext"
import { RoadworksContextProvider } from "./modules/roadworks/contexts/RoadworksContext"

export function L2rAppRoad() {

    const isMobile = useIsMobileDevice()

    return (
        <RoadwayContextProvider>
            <RoadworksContextProvider>
                <RoadwayStreetViewContextProvider>
                    {isMobile ? <RoadMobileRouter /> : <RoadDesktopRouter />}
                </RoadwayStreetViewContextProvider>
            </RoadworksContextProvider>
        </RoadwayContextProvider>
    )
}
