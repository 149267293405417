import { useAlertsContext } from "@l2r-front/l2r-query"
import { Snackbar as UiSnackbar } from "@l2r-front/l2r-ui"

export const Snackbar = (props) => {
    const alerts = useAlertsContext()
    const [alertsState, dispatchAlerts] = alerts
    const alert = alertsState?.displaySnackbar
    const { closeSnackbar } = dispatchAlerts
    if (!alert || !dispatchAlerts) {
        return null
    }

    return <UiSnackbar
        anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
        }}
        {...alert}
        {...props}
        close={closeSnackbar}
        id="snackbar"
    />
}