import { useContext } from "react"

import { AlertsDispatchContext, AlertsStateContext } from "./AlertsContext.context"

export const useAlertsStateContext = () => {
    const context = useContext(AlertsStateContext)
    if (context === undefined) {
        throw new Error("useAlertsStateContext must be used within an AlertsContextProvider")
    }
    return context
}

export const useAlertsDispatchContext = () => {
    const context = useContext(AlertsDispatchContext)
    if (context === undefined) {
        throw new Error("useAlertsDispatchContext must be used within an AlertsContextProvider")
    }
    return context
}

export const useAlertsContext = () => {
    return [useAlertsStateContext(), useAlertsDispatchContext()]
}
