import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { ChangeEmailFormPost } from "../../forms/ChangeEmailForm"
import * as Styled from "./AccountSettingsEmailPage.styled"

export const AccountSettingsEmailPage = () => {
    const isMobile = useIsMobileDevice()

    return (
        <Styled.Content isMobile={isMobile}>
            <ChangeEmailFormPost />
        </Styled.Content>
    )
}