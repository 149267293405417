import { useTranslation } from "@l2r-front/l2r-i18n"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useChangePasswordMutation } from "../../hooks/mutations/useChangePassword"
import { ChangePasswordForm } from "./ChangePasswordForm.form"

export const ChangePasswordFormPost = (props) => {

    const { className } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    const { mutateAsync: changePassword } = useChangePasswordMutation({
        successSnackbarMessage: t(I18N_NAMESPACE, "forms.changePasswordForm.snackbar.success"),
        errorSnackbarMessage: t(I18N_NAMESPACE, "forms.changePasswordForm.snackbar.error"),
    })

    return <ChangePasswordForm autoFocus className={className} onSubmit={changePassword} />
}