import { Navigate, Route, Routes } from "react-router-dom"

import { RoadwayMobileRoutes } from "../../../modules/roadway/routers/RoadwayRoutes"
import { RoadworksMobileRoutes } from "../../../modules/roadworks/routers/RoadworksRoutes"
import { MODULE_ROADWAY, MODULE_ROADWORKS } from "../../constants/modules"

export function RoadMobileRouter() {

    return (
        <Routes>
            <Route
                path={`/${MODULE_ROADWAY}/*`}
                element={<RoadwayMobileRoutes />}
            />
            <Route
                path={`/${MODULE_ROADWORKS}/*`}
                element={<RoadworksMobileRoutes />}
            />
            <Route
                path="/*"
                element={<Navigate replace to={`${MODULE_ROADWAY}`} />}
            />
        </Routes>
    )
}
