export const networkGradingsCD91Small = [
    {
        "uuid": "3f3ee966-48b2-45ea-97e0-f77fc9683941",
        "createdAt": "2023-03-02T09:02:39.977000",
        "updatedAt": "2023-04-05T13:05:22.473000",
        "survey": "e26884f8-7951-4e71-b07b-452952494ee6",
        "yearRelative": 0,
        "year": 2023,
        "isSimulated": false,
        "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
        "gradesWeightedAverage": {
            "gradeGlobal": 6.99261136275398,
            "gradeSurface": 6.075131357577327,
            "gradeStructure": 7.745033001164747,
        },
    },
    {
        "uuid": "09c78b1f-e94f-4ef7-b43f-55f77a7e4efa",
        "createdAt": "2023-03-02T09:02:52.841000",
        "updatedAt": "2023-04-05T13:29:34.741000",
        "survey": "e26884f8-7951-4e71-b07b-452952494ee6",
        "yearRelative": 1,
        "year": 2024,
        "isSimulated": true,
        "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
        "gradesWeightedAverage": {
            "gradeGlobal": 6.60447262844571,
            "gradeSurface": 5.811316681765239,
            "gradeStructure": 7.25507519088909,
        },
    },
    {
        "uuid": "44f1b54a-3d3e-470c-ad52-29e636e284aa",
        "createdAt": "2023-03-02T09:03:09.383000",
        "updatedAt": "2023-04-05T13:34:07.470000",
        "survey": "e26884f8-7951-4e71-b07b-452952494ee6",
        "yearRelative": 2,
        "year": 2025,
        "isSimulated": true,
        "layer": "7483628c-6464-4c85-9699-2d715c129727",
        "gradesWeightedAverage": {
            "gradeGlobal": 7.115973857900867,
            "gradeSurface": 6.40088391354989,
            "gradeStructure": 7.702816099391743,
        },
    },
]

export const networkGradingsMulhouse = [
    {
        "uuid": "0b3a1877-09bb-43b9-a9b7-53523fc0cfc6",
        "createdAt": "2023-03-02T09:03:09.383000",
        "updatedAt": "2023-04-05T13:34:07.470000",
        "survey": "0ea4f371-f6e7-4aee-ad49-41974315a235",
        "yearRelative": 1,
        "year": 2025,
        "isSimulated": false,
        "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
        "gradesWeightedAverage": {
            "gradeGlobal": 4.511694299861247,
            "gradeSurface": 3.8255006374182097,
            "gradeStructure": 5.0748613527352635,
        },
    },
    {
        "uuid": "03894265-c5b9-4cf2-b3d1-c85c27a75f5d",
        "createdAt": "2023-03-02T09:03:09.383000",
        "updatedAt": "2023-04-05T13:34:07.470000",
        "survey": "0ea4f371-f6e7-4aee-ad49-41974315a235",
        "yearRelative": 2,
        "year": 2026,
        "isSimulated": true,
        "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
        "gradesWeightedAverage": {
            "gradeGlobal": 4.371745293318026,
            "gradeSurface": 3.6628148881172566,
            "gradeStructure": 4.952825312194057,
        },
    },
    {
        "uuid": "a72dcc38-38f2-4bf6-b4fa-c7ea434e612c",
        "createdAt": "2023-03-02T09:03:09.383000",
        "updatedAt": "2023-04-05T13:34:07.470000",
        "survey": "0ea4f371-f6e7-4aee-ad49-41974315a235",
        "yearRelative": 0,
        "year": 2024,
        "isSimulated": true,
        "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
        "gradesWeightedAverage": {
            "gradeGlobal": 4.2666770790046415,
            "gradeSurface": 3.5601184330076983,
            "gradeStructure": 4.846471450003169,
        },
    },
]

export const allRoadNetworkGradings =
{
    "cd91_small": networkGradingsCD91Small,
    "mulhouse": networkGradingsMulhouse,
}

export const getRoadNetworkGradings = (networkSlug, year = null) => {
    const networkGradings = allRoadNetworkGradings[networkSlug]

    return networkGradings.filter(ng => (!year || ng.year === parseInt(year)))
}
