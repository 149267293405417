import { PropTypes } from "@l2r-front/l2r-proptypes"
import { handleRoundNumber } from "@l2r-front/l2r-utils"

import { Kpi } from "../Kpi"


export const KpiNumber = (props) => {
    const {
        color,
        path,
        significantNumber,
        value,
        ...kpiProps
    } = props

    const roundedValue = handleRoundNumber(value, significantNumber)

    return (
        <Kpi
            color={color}
            path={path}
            value={roundedValue}
            {...kpiProps}
        />
    )
}

KpiNumber.propTypes = {
    ...Kpi.propTypes,
    path: PropTypes.string,
    significantNumber: PropTypes.number,
    value: PropTypes.number.isRequired,
}

KpiNumber.defaultProps = {
    significantNumber: 2,
}
