import { useCallback } from "react"

import { useAlertsContext } from "@l2r-front/l2r-query"
import { ConfirmDialog as UiConfirmDialog } from "@l2r-front/l2r-ui"

export function ConfirmAlert() {
    const alerts = useAlertsContext()
    const [alertsState, dispatchAlerts] = alerts
    const alert = alertsState?.displayAlert

    const close = useCallback(() => {
        if (alert?.cancelCallback) {
            alert?.cancelCallback()
        }
        dispatchAlerts.closeAlert()
    }, [alert, dispatchAlerts])

    if (!alert || !dispatchAlerts) {
        return null
    }

    return <UiConfirmDialog
        cancelText={alert.cancelText}
        close={close}
        closeWithCallbacks={dispatchAlerts.closeAlertWithCallback}
        confirmText={alert.confirmText}
        id={alert?.alertId}
        open={alert.open}
        text={alert.alertText}
        title={alert.alertTitle} />
}
