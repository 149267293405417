import { Navigate, Route, Routes } from "react-router-dom"

import { IncidentsMobileRoutes } from "../../../modules/incidents/routers/IncidentsRoutes"
import { MODULE_INCIDENTS } from "../../constants/modules"

export function SvraiMobileRouter() {

    return (
        <Routes>
            <Route
                path={`/${MODULE_INCIDENTS}/*`}
                element={<IncidentsMobileRoutes />}
            />
            <Route
                path="/*"
                element={<Navigate replace to={`${MODULE_INCIDENTS}`} />}
            />
        </Routes>
    )
}
