import { string } from "prop-types"

import { isUrl } from "../utils/isUrl"

export const url = function urlValidator(props, propName, componentName, ...rest) {
    const { [propName]: propValue } = props
    if (propValue == null) {
        return null
    }

    const stringError = string(props, propName, componentName, ...rest)
    if (stringError) {
        return stringError
    }

    if (!isUrl(propValue)) {
        return new TypeError(`Invalid prop '${propName}' supplied to '${componentName}'. Invalid url.`)
    }

    return null
}

url.isRequired = function requiredUrlValidator(...args) {
    const stringError = string.isRequired(...args)
    if (stringError) {
        return stringError
    }

    return url(...args)
}

