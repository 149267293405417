import { ArrowDropDownIcon, ArrowDropUpIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { CustomSelectInput } from "../CustomSelectInput/CustomSelectInput"
import * as Styled from "./CustomOpenButton.styled"

export const CustomOpenButton = (props) => {
    const {
        className,
        title,
        icon,
        id,
        value,
        open,
        onClick,
    } = props

    return (
        <Styled.Container className={className} onClick={onClick} id={id}>
            <CustomSelectInput
                title={title}
                icon={icon}
                value={value}
            />
            {open ? <ArrowDropUpIcon color="objects/object-black" /> : <ArrowDropDownIcon color="objects/object-black" />}
        </Styled.Container>
    )
}

CustomOpenButton.propsType = {
    className: PropTypes.string,
    id: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    ...CustomSelectInput.propsType,
}