import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useMultiYearGrading } from "../../hooks/queries/networkGradings/useMultiYearGrading"
import * as Styled from "./RoadMultiYearGrading.styled"

export function RoadMultiYearGrading(props) {

    const { className } = props

    const { data, isError, isLoading } = useMultiYearGrading()

    const emptyYearsGrading = Array(5).fill("empty")

    if (isLoading) {
        return <Styled.Wrapper>
            {emptyYearsGrading.map((_, index) => <Styled.YearGradingBadgeSkeleton className={className} key={index} />)}
        </Styled.Wrapper>
    }

    if (isError) {
        return <Styled.Wrapper>
            {emptyYearsGrading.map((_, index) => <Styled.YearGradingBadgeError className={className} key={index} />)}
        </Styled.Wrapper>
    }

    return <Styled.Wrapper>
        {data.map(year => <Styled.FeaturesYearGrading year={year} className={className} key={year} />)}
    </Styled.Wrapper>
}


RoadMultiYearGrading.propTypes = {
    className: PropTypes.string,
}