import { createContext } from "react"

export const initialContext = {
    blockingAlerts: [],
    displayAlert: {
        cancelText: "alertContext.alert.cancel",
        confirmText: "alertContext.alert.confirm",
        open: false,
    },
    displaySnackbar: {
        error: "alertContext.snackbar.error",
        open: false,
        success: "alertContext.snackbar.success",
    },
}

const initialDispatchContext = {}

export const AlertsStateContext = createContext(initialContext)

export const AlertsDispatchContext = createContext(initialDispatchContext)
