import { useTheme } from "@emotion/react"

import { useUrlParams } from "@l2r-front/l2r-utils"

import { useMediaQuery } from "./useMediaQuery"


export function useIsMobileDevice() {
    const theme = useTheme()
    const { getParam } = useUrlParams()
    const forcedDevice = getParam("device")

    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"))
    const isMobileDevice = !isLargeScreen

    return (isMobileDevice && forcedDevice !== "desktop") || forcedDevice === "mobile"
}