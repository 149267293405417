import { oneOf, shape, string } from "prop-types"

import { linearLocation } from "../linearLocation"

export const technique = shape({
    acronym: string.isRequired,
    code: string.isRequired,
    cost: string.isRequired,
    impact: oneOf([10, 20, 30, 40]).isRequired,
    linearLocation: linearLocation,
})

export const techniqueForm = shape({
    acronym: string,
    code: string,
    cost: string,
    impact: oneOf([10, 20, 30, 40]),
    linearLocation: linearLocation,
})
