import { useParams } from "react-router-dom"

import { IncidentDetailsLayers } from "../../containers/IncidentDetailsLayers"
import { IncidentsLayers } from "../../containers/IncidentsLayers"

export const IncidentsMapLayersRouterMobile = () => {
    const { eventId } = useParams()

    if (eventId) {
        return <>
            <IncidentsLayers />
            <IncidentDetailsLayers />
        </>
    }

    return <IncidentsLayers />
}