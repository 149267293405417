import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { useRoadworksQuery } from "../../../../common/hooks/queries/useRoadworksQuery"
import { roadworksQueryKeys } from "./queryKeys"

export function useTaskType(taskCode, config = {}) {
    const { enabled = true } = config
    const url = `task_types/${taskCode}/`
    const { selectedNetwork } = useNetworksStateContext()
    const queryKey = roadworksQueryKeys["taskType"](taskCode ?? null)

    return useRoadworksQuery(
        url,
        queryKey,
        {},
        {
            ...config,
            enabled: !!selectedNetwork?.slug && enabled,
        },
    )
}

export function useTaskTypes(config = {}) {
    const url = "task_types/"
    const queryKey = roadworksQueryKeys["taskType"]()

    return useRoadworksQuery(
        url,
        queryKey,
        {},
        config,
    )
}
