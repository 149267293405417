import { SvgIcon } from "@mui/material"

import { ReactComponent as Icon } from "./hand.svg"

export const HandIcon = (props) => {
    return (
        <SvgIcon component={Icon} {...props} inheritViewBox />
    )
}

