import Paper from "@mui/material/Paper"


export function DrawerHeader(props) {
    return (
        <Paper {...props} />
    )
}

DrawerHeader.propTypes = Paper.propTypes

DrawerHeader.defaultProps = {
    elevation: 5,
    square: true,
}
