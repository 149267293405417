import { styled, FormikButtonSubmit, Skeleton as UiSkeleton, Typography } from "@l2r-front/l2r-ui"

import { TaskImpactBadge as L2RTaskImpactBadge } from "../../components/TaskImpactBadge"

export const RoadworkDetailWrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-variation"].main,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(5),
    padding: theme.spacing(12, 8, 5, 8),
    width: "100%",
}))

export const YearWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    marginBottom: theme.spacing(11),
}))

export const TechniqueWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(5),
}))

export const Wrapper = styled("div")(() => ({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
}))

export const TaskImpactBadge = styled(L2RTaskImpactBadge)({
    height: "fit-content",
})

export const DoneInfoContainer = styled("div")(({ theme }) => ({
    borderRadius: theme.spacing(3),
    marginBottom: theme.spacing(4),
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "top",
    backgroundColor: theme.palette["objects/object-grey"].main,
    padding: theme.spacing(5, 3),
}))

export const DoneInfo = styled("div")(({ theme }) => ({
    color: theme.palette["texts/text-secondary"].main,
    display: "flex",
    flexDirection: "column",
}))

export const ButtonWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(8),
    justifyContent: "flex-end",
    marginTop: theme.spacing(5),
}))

export const SubmitButton = styled(FormikButtonSubmit)(() => ({
    width: "fit-content",
}))

export const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette["texts/text-error"].main,
    textAlign: "center",
}))

export const RoadworkDetailSkeleton = styled(UiSkeleton)({
    transform: "scale(1)",
    height: "100%",
    width: "100%",
    flex: 1,
})