import { useCallback, useMemo, useState } from "react"

import { GoogleMap, StreetViewPanorama, StreetViewService, useLoadScript } from "@react-google-maps/api"

import { useImageCollectionDispatchContext } from "../../contexts/ImageCollectionContext"
import { StreetViewError } from "./StreetView.error"
import * as Styled from "./StreetView.styled"


export const StreetView = (props) => {

    const { initialPosition } = props
    const [streetViewState, setStreetViewState] = useState(null)
    const [streetViewError, setStreetViewError] = useState(false)
    const [streetViewRef, setStreetViewRef] = useState(null)
    const { setDisplayedImagePosition } = useImageCollectionDispatchContext()

    const position = useMemo(() => {
        return {
            lat: initialPosition?.[1],
            lng: initialPosition?.[0],
        }
    }, [initialPosition])

    const onLoad = useCallback((streetViewService) => {
        return streetViewService.getPanorama({
            location: position,
            radius: 50,
        }, (data, status) => {
            if (status === "OK" && !!data) {
                setStreetViewState(data)
            } else {
                setStreetViewError(true)
            }
        })
    }, [position])

    const mapStyle = useMemo(() => {
        const displayed = streetViewState && !streetViewError

        return ({
            ...Styled.mapContainerStyle,
            display: displayed ? "block" : "none",
        })
    }, [streetViewError, streetViewState])

    const onPositionChanged = useCallback(() => {
        if (streetViewRef) {
            setDisplayedImagePosition({ lat: streetViewRef.getPosition().lat(), lng: streetViewRef.getPosition().lng() })
        }
    }, [streetViewRef, setDisplayedImagePosition])

    const message = useMemo(() => {
        return streetViewError ? <StreetViewError />
            : !streetViewState ? <Styled.Skeleton />
                : null
    }, [streetViewError, streetViewState])

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env["NX_GOOGLEMAPTOKEN"],
    })

    if (loadError || !position.lat || !position.lng) {
        return <StreetViewError />
    }

    if (!isLoaded) {
        return <Styled.Skeleton />
    }

    return <>
        <GoogleMap
            center={position}
            id="google-map"
            mapContainerStyle={mapStyle}
            zoom={17}
        >
            {
                streetViewState?.tiles && <StreetViewPanorama
                    options={{
                        enableCloseButton: false,
                        addressControl: true,
                        imageDateControl: true,
                        fullscreenControl: false,
                    }}
                    position={position}
                    streetViewPanorama={streetViewState}
                    visible
                    onLoad={e => {
                        setStreetViewRef(e)
                        if (!streetViewState?.listener) {
                            setStreetViewState(previousState => ({
                                ...previousState,
                                listener: true,
                            }))
                        }
                    }}
                    onPositionChanged={onPositionChanged}
                />
            }
            <StreetViewService
                onLoad={onLoad}
            />
        </GoogleMap>
        {message}
    </>
}