import { hex } from "wcag-contrast"

export const getContrastTextColor = (hexColor) => {
    const white = "#FFF"
    const black = "#000"

    if (!hexColor) {
        return white
    }

    const whiteContrast = hex(hexColor, white)
    const blackContrast = hex(hexColor, black)

    if (whiteContrast >= blackContrast) {
        return white
    } else {
        return black
    }
}

function hexToRgb(hex) {
    hex = hex.replace(/^#/, "")
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)

    return { r, g, b }
}

function rgbToHex(rgb) {
    return "#" + (1 << 24 | rgb.r << 16 | rgb.g << 8 | rgb.b).toString(16).slice(1)
}

function blendColorChannel(channel, opacity, backgroundColor = 255) {
    return backgroundColor - opacity * (backgroundColor - channel)
}

export const getColorWithOpacity = (hexColor, opacity) => {
    const rgb = hexToRgb(hexColor)
    const backgroundColor = (rgb.r === 255 && rgb.g === 255 && rgb.b === 255) ? 0 : 255
    const rgbBlended = {
        r: blendColorChannel(rgb.r, opacity, backgroundColor),
        g: blendColorChannel(rgb.g, opacity, backgroundColor),
        b: blendColorChannel(rgb.b, opacity, backgroundColor),
    }
    return rgbToHex(rgbBlended)
}

export const selectLayerColorOpacity = (color, opacity, selectedRoad = null) => {
    return ["case",
        ["!=", ["typeof", selectedRoad], "string"],
        color,
        ["==", ["get", "road", ["at", 0, ["get", "linearLocation"]]], selectedRoad],
        color,
        getColorWithOpacity(color, opacity),
    ]
}