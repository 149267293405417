import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./RoadworksList.styled"

export function RoadworksList(props) {
    const {
        className,
        roadworksItems,
    } = props

    return (
        <Styled.Scrollable>
            <Styled.Grid className={className} container spacing={5} id="roadworks-list">
                {roadworksItems.map((item, index) => {
                    return <Styled.GridItem
                        item={true}
                        key={`roadworks-card-${index}`}
                        xs={6}
                    >
                        {item}
                    </Styled.GridItem>
                })}
            </Styled.Grid>
        </Styled.Scrollable>
    )
}

RoadworksList.propTypes = {
    className: PropTypes.string,
    roadworksItems: PropTypes.arrayOf(PropTypes.object).isRequired,
}