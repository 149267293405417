import {
    AccessTimeFilledIcon,
    CategoryIcon,
    //   CategoryIcon,
    ForestIcon,
    LocalOfferIcon,
    PushPinIcon,
    VisibilityIcon,
} from "@l2r-front/l2r-icons"
import { slugify } from "@l2r-front/l2r-utils"

import { IncidentStatusIcon } from "../components/IncidentStatusIcon"
import {
    EVENT_INFOS_CONDITIONS,
    EVENT_INFOS_STATUS,
    EVENT_INFOS_LOCATION,
    EVENT_INFOS_TAGS,
    EVENT_INFOS_TIME,
    EVENT_INFOS_INCIDENT_TYPE,
    // EVENT_INFOS_TRIGGERED_RULE,
    EVENT_INFOS_PUBLICATION_STATUS,
} from "./event"

export const incidentDetailsSettings = {
    [EVENT_INFOS_STATUS]: {
        icon: (props) => <IncidentStatusIcon color="main" {...props} />,
        title: "components.incidentDetails.status.title",
    },
    [EVENT_INFOS_LOCATION]: {
        icon: (props) => <PushPinIcon id={`incident-detail-icon-${slugify(EVENT_INFOS_LOCATION)}`} {...props} />,
        title: "components.incidentDetails.location.title",
    },
    [EVENT_INFOS_CONDITIONS]: {
        icon: (props) => <ForestIcon id={`incident-detail-icon-${slugify(EVENT_INFOS_CONDITIONS)}`} {...props} />,
        title: "components.incidentDetails.conditions.title",
    },
    [EVENT_INFOS_TIME]: {
        icon: (props) => <AccessTimeFilledIcon id={`incident-detail-icon-${slugify(EVENT_INFOS_TIME)}`} {...props} />,
        title: "components.incidentDetails.time.title",
    },
    [EVENT_INFOS_TAGS]: {
        icon: (props) => <LocalOfferIcon id={`incident-detail-icon-${slugify(EVENT_INFOS_TAGS)}`} {...props} />,
        title: "components.incidentDetails.tags.title",
    },
    [EVENT_INFOS_INCIDENT_TYPE]: {
        icon: (props) => <CategoryIcon id={`incident-detail-icon-${slugify(EVENT_INFOS_TAGS)}`} {...props} />,
        title: "components.incidentDetails.incidentType.title",
    },
    // [EVENT_INFOS_TRIGGERED_RULE]: {
    //     title: "components.incidentDetails.triggeredRule.title",
    //     icon: CategoryIcon,
    // },
    [EVENT_INFOS_PUBLICATION_STATUS]: {
        icon: VisibilityIcon,
        title: "components.incidentDetails.publicationStatus.title",
    },
}
