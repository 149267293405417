import { createContext } from "react"

export const initialStateContext = {
    selectedNetworkSlug: null,
    selectedLinearLocations: null,
    networkAccessStatus: null,
    roadSearched: null,
}
const initialDispatchContext = {}

export const NetworksStateContext = createContext(initialStateContext)
export const NetworksDispatchContext = createContext(initialDispatchContext)
