import {
    styled,
    KpiCard as UiKpiCard,
    KpiError as KpiErrorUi,
    KpiSkeleton as KpiSkeletonUi,
    Typography,
} from "@l2r-front/l2r-ui"

export const KpiCard = styled(UiKpiCard)({
    justifyContent: "flex-start",
})

export const KpiSkeleton = styled(KpiSkeletonUi)(() => ({
    width: "100%",
    height: "100%",
}))

export const KpiError = styled(KpiErrorUi)(() => ({
    width: "100%",
    height: "100%",
}))

export const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette["objects/object-black"].main,
    marginBottom: theme.spacing(2),
    width: "100%",
    textAlign: "left",
}))

export const Desc = styled(Typography)(({ theme }) => ({
    color: theme.palette["objects/object-black"].main,
    marginBottom: theme.spacing(2),
    width: "100%",
    textAlign: "left",
}))

export const ChartContainer = styled("div")(() => ({
    width: "100%",
    height: "70%",
    maxHeight: 200,
}))