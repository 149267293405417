import {
    SIDEBAR_SPACING,
    SIDEBAR_SPACING_MOBILE,
    styledForMobile,
} from "@l2r-front/l2r-ui"

import { IncidentFilters as L2rIncidentFilters } from "../IncidentFilters"

export const HeaderWrapper = styledForMobile("div")(({ theme, isMobile }) => ({
    margin: isMobile ? theme.spacing(0, SIDEBAR_SPACING_MOBILE, 4, SIDEBAR_SPACING_MOBILE) : theme.spacing(0, SIDEBAR_SPACING, 4, SIDEBAR_SPACING),
}))

export const IncidentFilters = styledForMobile(L2rIncidentFilters)(({ theme, isMobile }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    boxShadow: theme.shadows[27],
    left: 0,
    position: "absolute",
    padding: isMobile ? theme.spacing(0, SIDEBAR_SPACING_MOBILE) : theme.spacing(0, SIDEBAR_SPACING),
    right: 0,
    zIndex: theme.zIndex.appBar + 1,
}))