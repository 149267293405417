import { useContext } from "react"

import { useDateFormatter } from "@l2r-front/l2r-i18n"

import { ProjectStateContext } from "./ProjectContext.context"

export const useProjectStateContext = () => {
    const context = useContext(ProjectStateContext)
    if (context === undefined) {
        throw new Error("useProjectStateContext must be used within an ProjectContextProvider")
    }
    return context
}

export function useProjectDateFilters() {
    const { projectStartDate, projectEndDate } = useContext(ProjectStateContext)
    const dateFormatter = useDateFormatter()
    const apiFormattedProjectStartDate = dateFormatter(projectStartDate, "yyyy-MM")
    const apiFormattedProjectEndDate = dateFormatter(projectEndDate, "yyyy-MM")

    return {
        date_from: apiFormattedProjectStartDate,
        date_to: apiFormattedProjectEndDate,
    }
}