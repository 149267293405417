import {
    SIDEBAR_SPACING,
    SIDEBAR_SPACING_MOBILE,
    List as UiList,
    Pagination as UiPagination,
    styled,
    styledForMobile,
} from "@l2r-front/l2r-ui"

export const ListWrapper = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
}))

export const List = styledForMobile(UiList)(({ theme, isMobile }) => ({
    flex: "1 1 auto",
    overflow: "auto",
    padding: isMobile ? theme.spacing(0, SIDEBAR_SPACING_MOBILE, 0, SIDEBAR_SPACING_MOBILE) : theme.spacing(0, SIDEBAR_SPACING, 0, SIDEBAR_SPACING),
}))

export const Pagination = styled(UiPagination)(({ theme }) => ({
    flex: "0 0 auto",
    margin: "auto auto",
    padding: theme.spacing(2, 0, 2),
}))

export const ErrorContainer = styled("div")(() => ({
    width: "100%",
    textAlign: "center",
}))
