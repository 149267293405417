import { useQueryClient } from "@tanstack/react-query"
import { useLocalStorage } from "@uidotdev/usehooks"

import {
    useL2rQuery,
    useL2rQueries,
    useL2rMutation,
    l2rApiAxiosInstance as axiosInstance,
    STATUS_CODE_FORBIDDEN,
    STATUS_CODE_UNAUTHORIZED,
} from "@l2r-front/l2r-query"

import { initialTokens } from "../../contexts/AuthenticationContext/AuthenticationContext.context"
import { useLogout } from "../useLogout"

export const useAuthenticatedQuery = (url, queryKey, queryParameters, config, initialData) => {
    const queryClient = useQueryClient()
    const [localState, setLocalState] = useLocalStorage("authentication", initialTokens)
    const logout = useLogout()
    const { mutateAsync: refreshTokenMutation, isPending: isLoadingToken } = useL2rMutation("auth/token/refresh/", axiosInstance.post)

    const query = useL2rQuery(
        url,
        queryKey,
        queryParameters,
        config,
        initialData)
    const status = query?.error?.response?.status

    if (!query.isFetching && status === STATUS_CODE_UNAUTHORIZED && !!localState.accessToken && !isLoadingToken) {
        refreshTokenMutation({ refresh: localState.refreshToken })
            .then(response => {
                const { access, refresh } = response
                setLocalState({
                    accessToken: access,
                    refreshToken: refresh,
                })
                query.refetch()
            })
            .catch(() => {
                logout()
            })
    } else {
        if (status === STATUS_CODE_FORBIDDEN) {
            queryClient.invalidateQueries({ queryKey: ["user"] })
            queryClient.refetchQueries({ queryKey: ["user"] })
        }
    }

    if (isLoadingToken) {
        return getQueryWhenRefreshingToken(query)
    } else {
        return query
    }
}

export function useAuthenticatedQueries(queriesData) {
    const queryClient = useQueryClient()
    const [localState, setLocalState] = useLocalStorage("authentication", initialTokens)
    const logout = useLogout()
    const { mutateAsync: refreshTokenMutation, isPending: isLoadingToken } = useL2rMutation("auth/token/refresh/", axiosInstance.post)

    const queries = useL2rQueries(queriesData)
    const queriesFetching = queries?.some(query => query?.isFetching)
    const accessError = queries?.some(query => query?.error?.response?.status === STATUS_CODE_UNAUTHORIZED)

    if (!queriesFetching && accessError && !!localState.accessToken && !isLoadingToken) {
        refreshTokenMutation({ refresh: localState.refreshToken })
            .then(response => {
                const { access, refresh } = response
                setLocalState({
                    accessToken: access,
                    refreshToken: refresh,
                })
                queries?.forEach(query => query?.refetch())
            })
            .catch(() => {
                logout()
            })
    } else {
        queries?.forEach(query => {
            if (query?.error?.response?.status === STATUS_CODE_FORBIDDEN) {
                queryClient.invalidateQueries({ queryKey: ["user"] })
                queryClient.refetchQueries({ queryKey: ["user"] })
            }
        })

    }

    if (isLoadingToken) {
        return queries.map(query => {
            return getQueryWhenRefreshingToken(query)
        })
    } else {
        return queries
    }
}

const getQueryWhenRefreshingToken = (query) => {
    return ({
        ...query,
        error: undefined,
        fetchStatus: "fetching",
        isError: false,
        isLoading: true,
        isFetched: false,
        isFetching: true,
        isPending: true,
        isSuccess: false,
        refetch: () => { },
        status: "pending",
    })
}