import { Navigate, Route, Routes } from "react-router-dom"

import { VerticalSigningMobileRoutes } from "../../../modules/verticalSigning/routers/VerticalSigningRoutes"
import { MODULE_VERTICAL_SIGNING } from "../../constants/modules"

export function CityMobileRouter() {

    return (
        <Routes>
            <Route
                path={`/${MODULE_VERTICAL_SIGNING}/*`}
                element={<VerticalSigningMobileRoutes />}
            />
            <Route
                path="/*"
                element={<Navigate replace to={`${MODULE_VERTICAL_SIGNING}`} />}
            />
        </Routes>
    )
}
