import {
    Badge as UiBadge,
    MobileStepper as UiMobileStepper,
    StepLabel as UiStepLabel,
    Step as UiStep,
    StepButton as UiStepButton,
    Stepper as UiStepper,
    Typography,
    styled,
    styledForMobile,
} from "@l2r-front/l2r-ui"

import { STEPPER_HEIGHT } from "../../constants/layout"

export const ReportStepWrapper = styledForMobile("div")(({ isMobile, theme }) => ({
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(isMobile === "true" ? 8 : 7),
    height: "100%",
    width: "100%",
}))

export const StepLabel = styled(UiStepLabel)(({ activestep, theme }) => {
    const color = activestep ? theme.palette["objects/object-black"].main : theme.palette["objects/object-grey"].main

    return ({
        "& > span > span": {
            color: `${color} !important`,
            fontWeight: activestep ? "bold !important" : "normal",
        },
    })
})

export const Step = styled(UiStep)({
    fontWeight: "bold",
})

export const Stepper = styled(UiStepper)({
    height: STEPPER_HEIGHT,
})

export const StepButton = styled(UiStepButton)(({ pointer }) => ({
    "& .MuiStepLabel-root": {
        cursor: `${pointer} !important`,
    },
}))

export const ErrorMessage = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    textAlign: "center",
}))

export const MobileStepper = styled(UiMobileStepper)(({ theme }) => ({
    marginTop: theme.spacing(8),
    maxWidth: "unset !important",
    width: "100%",

    ".MuiMobileStepper-dotActive": {
        backgroundColor: theme.palette["cta-bg/cta-bg-primary"].main,
    },
}))

export const Badge = styled(UiBadge)(({ theme }) => ({
    marginRight: theme.spacing(6),

    "& > .MuiBadge-badge ": {
        borderRadius: "50%",
        position: "relative !important",
        transform: "scale(1) !important",
        height: "24px",
        width: "24px",
    },
}))

export const MobileTitle = styled("div")({
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
})
