import { useGeoServerFeaturesList } from "@l2r-front/l2r-geodata"

import { imageCollectionQueryKeys } from "./queryKeys"

export function useImageCollectionLayer(imageCollection, queryParameters, config = {}) {
    const parameters = {
        ...queryParameters,
        layer: imageCollection?.layer,
        as_geojson: true,
    }
    const { enabled } = config
    const queryKey = imageCollectionQueryKeys["layer"](parameters)

    return useGeoServerFeaturesList(
        queryKey,
        parameters,
        {
            ...config,
            enabled: !!imageCollection?.layer && enabled,
        },
    )
}