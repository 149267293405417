export const quartersStats = {
    2024: {
        "count": {
            2024: {
                50: 700,
                100: [400, 300, 0, 0],
            },
        },
        "cost": {
            2024: {
                50: "67677000000.76",
                100: ["78687", "98944565818.98987", "128.9", "0"],
            },
        },
    },
    2025: {
        "count": {
            2025: {
                50: 500,
                100: [350, 220, 10, 20],
            },
        },
        "cost": {
            2025: {
                50: "12000",
                100: ["261", "326", "125", "25"],
            },
        },
    },
    2026: {
        "count": {
            2026: {
                50: 682,
                100: [123, 975, 256, 479],
            },
        },
        "cost": {
            2026: {
                50: "65193",
                100: ["2468", "35543", "124", "762"],
            },
        },
    },
}

export const quartersStats2 = {
    2024: {
        "count": {
            2024: {
                50: 700,
                100: [400, 300, 0, 0],
            },
        },
        "cost": {
            2024: {
                50: "67677000000.76",
                100: ["786870", "98944565818.98987", "128.9", "0"],
            },
        },
    },
    2025: {
        "count": {
            2025: {
                50: 500,
                100: [350, 220, 10, 20],
            },
        },
        "cost": {
            2025: {
                50: "12000",
                100: ["261", "326", "125", "25"],
            },
        },
    },
    2026: {
        "count": {
            2026: {
                50: 682,
                100: [123, 975, 256, 479],
            },
        },
        "cost": {
            2026: {
                50: "65193",
                100: ["2468", "35543", "124", "762"],
            },
        },
    },
}