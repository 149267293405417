import { getNearestFeature } from "@l2r-front/l2r-geodata"

import { useImageCollectionLayer } from "./useImageCollectionLayer"

export function useNearestImageInCollection(imageCollection, road, position, maxDistance = 0.02) {
    const {
        data: collectionLayer,
        isLoading,
        isError,
    } = useImageCollectionLayer(imageCollection, { linear_location_road: road })

    const imageFeature = getNearestFeature(collectionLayer, position, maxDistance)

    return { imageFeature, isLoading, isError }
}