import { arrayOf, number, shape, string } from "prop-types"

export const chartData = arrayOf(shape({
    color: string,
    displayedData: arrayOf(
        shape({
            x: number.isRequired,
            y: number.isRequired,
        }).isRequired,
    ).isRequired,
    data: arrayOf(
        shape({
            x: number.isRequired,
            y: number.isRequired,
        }).isRequired,
    ).isRequired,
    name: string.isRequired,
}))

