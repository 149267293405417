import { useTranslation } from "react-i18next"

export function useGetLocaleFromList(localesList, fallback) {
    const { i18n } = useTranslation()

    if (!i18n.languages) {
        return fallback
    }

    for (const lng of i18n.languages) {
        if (Array.isArray(localesList) && localesList.indexOf(lng) >= 0) {
            return lng
        }
        if (localesList[lng]) {
            return localesList[lng]
        }
    }

    return fallback

}