import {
    styled,
    Typography,
    KpiError as KpiErrorUi,
    KpiSkeleton as KpiSkeletonUi,
} from "@l2r-front/l2r-ui"

import { EPD_TAG_HEIGHT } from "../../constants/layout"

export const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(8),
    width: "100%",
    textAlign: "left",
}))

export const Container = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(4),
    width: "100%",
}))

export const EPDContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    alignContent: "baseline",
}))

export const NotationContainer = styled("div")(() => ({
    height: "100%",
}))

export const Marker = styled("div")(({ theme, index }) => ({
    marginTop: (EPD_TAG_HEIGHT + 4) * index,
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(3),
}))

export const AuditLabel = styled(Typography)(() => ({
    lineHeight: `${EPD_TAG_HEIGHT}px`,
}))

export const KpiError = styled(KpiErrorUi)(() => ({
    width: "100%",
    height: "100%",
}))

export const KpiSkeleton = styled(KpiSkeletonUi)(() => ({
    width: "100%",
    height: "100%",
}))

