import { applyMatrixToPoint } from "@visx/zoom"

export function constrainZoom(transformMatrix, _, width, height) {
    const { scaleX, scaleY, translateX, translateY } = transformMatrix
    if (scaleX < 1) {
        transformMatrix.scaleX = 1
    }

    if (scaleY < 1) {
        transformMatrix.scaleY = 1
    }

    if (translateX > 0) {
        transformMatrix.translateX = 0
    }
    if (translateY > 0) {
        transformMatrix.translateY = 0
    }
    const max = applyMatrixToPoint(transformMatrix, {
        x: width,
        y: height,

    })

    if (max.x < width) {
        transformMatrix.translateX = translateX + Math.abs(max.x - width)
    }
    return transformMatrix
}