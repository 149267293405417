import { Skeleton as MuiSkeleton, styled } from "@mui/material"

export const Skeleton = styled(MuiSkeleton)(({ theme, variant }) => {
    const size = theme.typography?.[variant]?.lineHeight
    const margin = "1px"
    return ({
        margin: `${margin} 0 ${margin} 0`,
        width: "40%",
        height: `calc(${size} - (2 * ${margin}))`,
    })
})

export const ElementsStoryWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
})
