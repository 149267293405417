import { styled } from "@l2r-front/l2r-ui"

import { MobileMap as L2rMap } from "../../../../common/containers/Map"
import { IncidentCharts as L2rIncidentCharts } from "../../containers/IncidentCharts"
import { IncidentDetailsSidebar } from "../../containers/IncidentDetailsSidebar"
import { IncidentVideo as L2rIncidentVideo } from "../../containers/IncidentVideo"

export const IncidentDetails = styled(IncidentDetailsSidebar)(({ theme }) => ({
    paddingTop: theme.spacing(6),
}))

export const Map = styled(L2rMap)({
    height: "100%",
    width: "100%",
})

export const IncidentVideo = styled(L2rIncidentVideo)({
    height: "100%",
    width: "100%",
})

export const IncidentCharts = styled(L2rIncidentCharts)(({ theme }) => ({
    padding: theme.spacing(4),
}))