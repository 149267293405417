import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { useRoadworksQueries, useRoadworksQuery } from "../../../../common/hooks/queries/useRoadworksQuery"
import { roadworksQueryKeys } from "./queryKeys"

export function useTask(
    taskUuid,
    config = {},
) {
    const { enabled = true } = config
    const url = `tasks/${taskUuid}/`
    const { selectedNetwork } = useNetworksStateContext()
    const queryKey = roadworksQueryKeys["task"](taskUuid)

    return useRoadworksQuery(
        url,
        queryKey,
        {},
        {
            ...config,
            enabled: !!selectedNetwork?.slug && enabled,
        },
    )
}

export function useTasks(
    tasksUuid = [],
    config = {},
) {
    const { enabled = true } = config
    const { selectedNetwork } = useNetworksStateContext()
    const queryConfig = {
        ...config,
        enabled: !!selectedNetwork?.slug && enabled,
    }

    const queries = tasksUuid.map(taskUuid => {
        const url = `tasks/${taskUuid}/`
        const queryKey = roadworksQueryKeys["task"](taskUuid)
        return ({
            url,
            queryKey,
            queryParameters: {},
            config: queryConfig,
        })
    })

    const queriesData = useRoadworksQueries(queries)
    const isError = queriesData.some(queryData => queryData.error)
    const isLoading = queriesData.some(queryData => queryData.isLoading)

    const data = (isLoading || isError) ? null : queriesData.map(query => query.data)

    return ({
        data,
        isError,
        isLoading,
    })
}