import { useMemo } from "react"

import { Navigate, useRoutes } from "react-router-dom"

import { RoadwayDesktopRoutes } from "../../../modules/roadway/routers/RoadwayRoutes"
import { RoadworksDesktopRoutes } from "../../../modules/roadworks/routers/RoadworksRoutes"
import { MODULE_ROADWAY, MODULE_ROADWORKS } from "../../constants/modules"

export const RoadContentRouter = () => {

    const roadRoutes = useMemo(() => ([
        {
            path: `/${MODULE_ROADWAY}/*`,
            element: <RoadwayDesktopRoutes />,
        },
        {
            path: `/${MODULE_ROADWORKS}/*`,
            element: <RoadworksDesktopRoutes />,
        },
        {
            path: "*",
            element: <Navigate replace to={`${MODULE_ROADWAY}`} />,
        },
    ]), [])

    return useRoutes(roadRoutes)
}