import { useCallback } from "react"

import { useAlertsContext } from "../contexts/AlertsContext"

export function useBlockingNavigate(navigateFunc, cb) {
    const alerts = useAlertsContext()
    const [alertsState, dispatchAlerts] = alerts

    const blockingNavigate = useCallback((value) => {
        if (alertsState?.blockingAlerts?.length) {
            navigateFunc(value)
            dispatchAlerts.setBlockingAlerts(prevState => {
                const newState = [...prevState]
                const lastBlockingAlert = newState[newState.length - 1]
                const newLastBlockingAlert = {
                    ...lastBlockingAlert,
                    callback: () => cb(value),
                }
                newState.splice(newState.length - 1, 1, newLastBlockingAlert)
                return newState
            })
            return
        } else {
            cb(value)
        }
    }, [alertsState, cb, dispatchAlerts, navigateFunc])

    return blockingNavigate
}