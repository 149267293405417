import { styled } from "@l2r-front/l2r-ui"

export const AccountSettingsCategory = styled("div")(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(5, 0),
    gap: theme.spacing(5),
}))

export const AccountSettingsList = styled("div")(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(3),
}))

