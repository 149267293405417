import { StrictMode, Suspense } from "react"

import * as ReactDOMClient from "react-dom/client"

import { enableMocking } from "@l2r-front/l2r-mock"

import { App } from "./app"
import { LoadingPage } from "./common/pages/LoadingPage"

enableMocking().then(() => {
    const rootEl = document.getElementById("root")
    const root = ReactDOMClient.createRoot(rootEl)

    root.render(
        <StrictMode>
            <Suspense
                fallback={
                    <LoadingPage />
                }
            >
                <App />
            </Suspense>
        </StrictMode>,
    )
})
