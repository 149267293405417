import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { useNetworkConditions } from "./useNetworkConditions"

export function useNetworkConditionForYearlyStatus(yearlyStatus) {
    const { selectedNetwork } = useNetworksStateContext()
    const {
        isLoading,
        isError,
        data: networkConditions,
    } = useNetworkConditions(
        selectedNetwork?.slug,
        {
            survey: yearlyStatus?.survey,
            year: yearlyStatus?.year,
        },
    )

    const networkCondition = networkConditions?.[0]

    return { isLoading, isError, networkCondition }
}
