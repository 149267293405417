import { useCallback, useMemo, useState } from "react"

import { useTranslation } from "react-i18next"

import { ViewModuleRoundedIcon, FolderIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { MobileNetworkSelector } from "../../components/MobileNetworkSelector"
import { I18N_NAMESPACE } from "../../constants/i18n"
import { BOTTOM_APPBAR_TRANSITION_DURATION } from "../../constants/layout"
import { MobileModulesSelector } from "../MobileModulesSelector"
import * as Styled from "./MobileBottomAppBar.styled"

const initialState = {
    open: false,
    selectorValue: null,
}

export const MobileBottomAppBar = (props) => {

    const {
        className,
    } = props
    const [selector, setSelector] = useState(initialState)
    const { t } = useTranslation(I18N_NAMESPACE)

    const removeSelectorValue = useCallback(async () => {
        await setTimeout(() => {
            setSelector(initialState)
        }, BOTTOM_APPBAR_TRANSITION_DURATION)
    }, [])

    const handleChange = useCallback(async (_, newSelectorValue) => {
        if (newSelectorValue === selector.selectorValue) {
            setSelector(v => ({ ...v, open: false }))
            await removeSelectorValue()
            return
        }
        setSelector({ open: true, selectorValue: newSelectorValue })
    }, [removeSelectorValue, selector])

    const closeSelector = useCallback(async () => {
        setSelector(v => ({ ...v, open: false }))
        await removeSelectorValue()
    }, [removeSelectorValue])


    const Selector = useMemo(() => {
        if (selector.selectorValue) {
            switch (selector.selectorValue) {
                case "modules":
                    return () => <MobileModulesSelector close={closeSelector} />
                case "networks":
                    return () => <MobileNetworkSelector close={closeSelector} />
                default:
                    return null
            }
        }
    }, [closeSelector, selector])

    return (
        <>
            <Styled.SwipeableDrawer
                anchor="bottom"
                open={selector.open}
                onOpen={(e) => e}
                onClose={closeSelector}
            >
                <Styled.Wrapper >
                    <Styled.Puller width={30} />
                    <Styled.Box >
                        {Selector && <Selector />}
                    </Styled.Box>
                </Styled.Wrapper>
            </Styled.SwipeableDrawer>
            <Styled.BottomNavigation
                id="bottom-bar"
                className={className}
                onChange={handleChange}
                showLabels
                value={selector.selectorValue}
            >
                <Styled.BottomNavigationAction
                    color="cta-bg/cta-bg-primary"
                    id="network-selector"
                    label={t("containers.mobileAppbar.networks.label")}
                    value="networks"
                    icon={<FolderIcon />}
                />
                <Styled.BottomNavigationAction
                    id="module-selector"
                    label={t("containers.mobileAppbar.modules.label")}
                    value="modules"
                    icon={<ViewModuleRoundedIcon />}
                />
            </Styled.BottomNavigation>
        </>
    )
}

MobileBottomAppBar.propTypes = {
    className: PropTypes.string,
}