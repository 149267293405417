import MuiLink from "@mui/material/Link"
import { Link as RouterLink } from "react-router-dom"

export function Link(props) {
    const {
        children,
        href,
        ...linkProps
    } = props

    return (
        <MuiLink component={RouterLink} to={href} {...linkProps}>
            {children}
        </MuiLink>
    )
}

Link.propTypes = MuiLink.propTypes