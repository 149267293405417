import { useMemo } from "react"

import { Formik, Form } from "formik"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes, allowEmptyString } from "@l2r-front/l2r-proptypes"
import { STATUS_CODE_UNAUTHORIZED } from "@l2r-front/l2r-query"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { validationSchema } from "./LoginForm.schema"
import * as Styled from "./LoginForm.styled"

export const LoginForm = (props) => {
    const {
        initialValues,
        linkToRecoverPassword,
        onSubmit,
        className,
    } = props

    const { t } = useTranslation([I18N_NAMESPACE])

    const formikProps = useMemo(() => {
        return {
            validationSchema: validationSchema(t),
            initialValues: initialValues,
            onSubmit: async (values, { setStatus }) => {
                try {
                    setStatus(null)
                    await onSubmit(values)
                } catch (error) {
                    if (error.response?.status === STATUS_CODE_UNAUTHORIZED) {
                        setStatus(t(I18N_NAMESPACE, "forms.loginForm.errors.incorrectCredentials"))
                        return
                    }
                    setStatus(t(I18N_NAMESPACE, "forms.loginForm.errors.unknown"))
                }
            },
        }
    }, [initialValues, onSubmit, t])

    return (
        <Formik {...formikProps}>
            {(formikState) => (
                <Form className={className}>
                    {formikState.status && (
                        <Styled.FormHelperText error id="submit-error">{formikState.status}</Styled.FormHelperText>
                    )}

                    <Styled.FormikTextField
                        autoFocus
                        name="email"
                        id="email"
                        type="email"
                        label={t(I18N_NAMESPACE, "forms.loginForm.fields.email.label")}
                    />
                    <Styled.FormikTextField
                        type="password"
                        id="password"
                        name="password"
                        label={t(I18N_NAMESPACE, "forms.loginForm.fields.password.label")}
                    />
                    <Styled.SubmitWrapper>
                        {linkToRecoverPassword}
                        <Styled.FormikButtonSubmit>
                            {t(I18N_NAMESPACE, "forms.loginForm.actions.login")}
                        </Styled.FormikButtonSubmit>
                    </Styled.SubmitWrapper>
                </Form>
            )}
        </Formik>
    )
}

LoginForm.defaultProps = {
    initialValues: {
        email: "",
        password: "",
    },
}

LoginForm.propTypes = {
    className: PropTypes.string,
    initialValues: PropTypes.exact({
        email: allowEmptyString(PropTypes.email.isRequired),
        password: PropTypes.string.isRequired,
    }),
    onSubmit: PropTypes.func.isRequired,
}