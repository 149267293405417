import * as turf from "@turf/turf"

export function computeLinearLengthOnSegment(segmentStart, segmentEnd, linearStart, linearEnd) {
    if (linearEnd <= segmentStart) {
        return 0
    }
    if (linearStart >= segmentEnd) {
        return 0
    }
    return Math.min(linearEnd, segmentEnd) - Math.max(linearStart, segmentStart)
}

export function getPreviousSegmentsTotalLength(feature, segmentIndex) {
    return turf.segmentReduce(feature, (previousValue, cs, fi, mfi, gi, si) => {
        if (si < segmentIndex) {
            return previousValue + turf.length(cs)
        } else {
            return previousValue
        }
    }, 0)
}