import Skeleton from "@mui/material/Skeleton"


export const ListItemSkeleton = (props) => (
    <Skeleton
        animation="wave"
        variant="rectangular"
        {...props}
    />
)

ListItemSkeleton.propTypes = Skeleton.propTypes