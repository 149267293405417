
import {
    IconButton as MuiIconButton,
    styled,
    Typography as UiTypography,
} from "@l2r-front/l2r-ui"

export const ButtonWrapper = styled("div")(({ disabled, theme }) => ({
    alignItems: "center",
    border: `1px dashed ${theme.palette["border/border-gris"].main}`,
    borderRadius: theme.spacing(3),
    cursor: disabled ? "default" : "pointer",
    display: "flex",
    padding: theme.spacing(4, 3),
    position: "relative",
    justifyContent: "space-between",

    "&:hover": {
        "& > button": {
            backgroundColor: !disabled ? theme.palette["colors/vert logiroad/200"].main
                : theme.palette["cta-bg/cta-bg-disabled"].main,
        },
    },
}))

export const IconButton = styled(MuiIconButton)(({ color, theme }) => ({
    backgroundColor: theme.palette[color].main,
    height: 32,
    width: 32,
}))

export const Typography = styled(UiTypography)(({ color, theme }) => ({
    color: theme.palette[color].main,
}))