export const EPD_TAG_HEIGHT = 24
export const EPD_TAG_START_WIDTH = 50

export const GRADE_TICK_LABEL_HEIGHT = 20
export const GRADE_TICK_LABEL_WIDTH = 24

export const DATE_COLUMN_WIDTH = 75

export const GRADE_ANNOTATION_HEIGHT = 45
export const GRADE_ANNOTATION_WIDTH = 30
export const GRADE_ANNOTATION_GAP = 5