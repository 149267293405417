import { useEffect } from "react"

import { Route, Routes } from "react-router-dom"

import { useNetworksDispatchContext } from "@l2r-front/l2r-networks"

import { RoadwaySidebarRouter } from "../../../modules/roadway/routers/RoadwaySidebarRouter"
import { RoadworksSidebarRouter } from "../../../modules/roadworks/routers/RoadworksSidebarRouter"
import { MODULE_ROADWAY, MODULE_ROADWORKS } from "../../constants/modules"

export const SidebarRouter = () => {
    const { setRoadSearched } = useNetworksDispatchContext()

    useEffect(() => {
        return () => setRoadSearched(null)
    }, [setRoadSearched])

    return <Routes>
        <Route
            element={<RoadwaySidebarRouter />}
            path={`/${MODULE_ROADWAY}/*`}
        />
        <Route
            element={<RoadworksSidebarRouter />}
            path={`/${MODULE_ROADWORKS}/*`}
        />
    </Routes>
}