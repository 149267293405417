import { useMemo } from "react"

import * as turf from "@turf/turf"

import { useTranslation } from "@l2r-front/l2r-i18n"
import {
    POINT_LAYER_SIZE,
    LINE_LAYER_SIZE,
    ENDFLAG_ICON_ID,
    INCIDENT_DETAILS_END_POINT,
    INCIDENT_DETAILS_POINT,
    INCIDENT_DETAILS_TRAJECTORY,
    INCIDENT_DETAILS_END_POINT_SOURCE,
    INCIDENT_DETAILS_POINT_SOURCE,
    INCIDENT_DETAILS_TRAJECTORY_SOURCE,
    Layer,
    PLACE_ICON_ID,
    Source,
    useMapDispatchContext,
    getLineLayerConfig,
    getPointSymbolLayerConfig,
    getInterpolatedLineLayerWidth,
    useOrderingLayers,
} from "@l2r-front/l2r-map"
import { useTheme } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useSvraiStateContext } from "../../../../common/contexts/SvraiContext"
import { useEventQuery } from "../../hooks/queries/events/useEvent"
import { useEventLinestringQuery } from "../../hooks/queries/events/useEventLinestring"

export function IncidentDetailsLayers() {

    const theme = useTheme()
    const { selectedIncidentUuid } = useSvraiStateContext()
    const { t } = useTranslation(I18N_NAMESPACE)
    const { setError: setMapError } = useMapDispatchContext()

    const { data } = useEventLinestringQuery(selectedIncidentUuid, {
        onError: () => setMapError(true),
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.incidentDetailsLayer.error"),
    })
    const { data: incident } = useEventQuery(selectedIncidentUuid, {}, {
        onError: () => setMapError(true),
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.incidentDetailsLayer.error"),
    })

    const incidentDetailTrajectoryLayerConfig = useMemo(() => {
        return getLineLayerConfig(
            {
                paint: {
                    "line-color": theme.palette.map.primary,
                    "line-width": getInterpolatedLineLayerWidth(LINE_LAYER_SIZE),
                },
            },
        )
    }, [theme])
    const incidentDetailLayerConfig = useMemo(() => {
        return getPointSymbolLayerConfig(
            {
                layout: {
                    "icon-image": PLACE_ICON_ID,
                    "icon-size": POINT_LAYER_SIZE[0],
                    "icon-anchor": "bottom",
                },
                paint: {
                    "icon-color": theme.palette.map.primary,
                },
            },
        )
    }, [theme])
    const incidentEndPointLayerConfig = useMemo(() => {
        return getPointSymbolLayerConfig(
            {
                layout: {
                    "icon-image": ENDFLAG_ICON_ID,
                    "icon-size": POINT_LAYER_SIZE[0],
                    "icon-anchor": "bottom",
                },
            },
        )
    }, [])

    const incidentPositionData = useMemo(() => {
        return {
            type: "Feature",
            geometry: incident?.triggerMain?.pointStart,
        }
    }, [incident])

    const lastTrajectoryPointData = useMemo(() => {
        if (!data?.linestring) {
            return null
        }

        const allCoords = turf.coordAll(data.linestring)
        const lastPointCoords = allCoords[allCoords.length - 1]
        return {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: lastPointCoords,
            },
        }
    }, [data?.linestring])

    useOrderingLayers([INCIDENT_DETAILS_TRAJECTORY, INCIDENT_DETAILS_END_POINT, INCIDENT_DETAILS_POINT])

    if (!incident || !data) {
        return null
    }

    return (
        <>
            <Source id={INCIDENT_DETAILS_TRAJECTORY_SOURCE} type="geojson" data={{ ...data.linestring }} >
                <Layer
                    {...incidentDetailTrajectoryLayerConfig}
                    id={INCIDENT_DETAILS_TRAJECTORY}
                />
            </Source>
            <Source id={INCIDENT_DETAILS_END_POINT_SOURCE} type="geojson" data={{ ...lastTrajectoryPointData }}>
                <Layer
                    {...incidentEndPointLayerConfig}
                    id={INCIDENT_DETAILS_END_POINT}
                />
            </Source>
            <Source id={INCIDENT_DETAILS_POINT_SOURCE} type="geojson" data={{ ...incidentPositionData }}>
                <Layer
                    {...incidentDetailLayerConfig}
                    id={INCIDENT_DETAILS_POINT}
                />
            </Source>
        </>
    )
}

