import { styled, Typography } from "@l2r-front/l2r-ui"

export const Container = styled("div")(({ theme }) => ({
    position: "relative",
    height: "48px",
    width: "100%",
    backgroundColor: theme.palette["objects/object-lightgrey"].main,
    alignItems: "center",
    marginTop: theme.spacing(2),
    cursor: "pointer",
}))

export const Content = styled("div")(() => ({
    width: "100%",
    height: "100%",
    display: "flex",
    flexBasis: "row",
    alignItems: "center",
}))

export const Icon = styled("div")(({ theme }) => ({
    marginLeft: theme.spacing(8),
    height: theme.components.icons.defaultHeight,
    width: theme.components.icons.defaultWidth,
}))

export const Label = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(5),
}))

export const SelectedMarker = styled("div")(({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: 8,
    height: "100%",
    backgroundColor: theme.palette["cta-bg/cta-bg-primary"].main,
}))