import {
    FormikTextField as UiFormikTextField,
    FormikButtonSubmit as UiFormikButtonSubmit,
    Typography,
    FormikImageField as UiFormikImageField,
    styled,
    styledForMobile,
} from "@l2r-front/l2r-ui"

export const Container = styledForMobile("div")(({ theme, isMobile }) => ({
    position: "relative",
    height: "100%",
    maxWidth: isMobile ? undefined : theme.spacing(100),
}))

export const SubmitWrapper = styledForMobile("div")(({ theme, isMobile }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    alignItems: "baseline",
    marginTop: theme.spacing(5),
    position: isMobile ? "absolute" : "relative",
    bottom: isMobile ? theme.spacing(5) : 0,
    width: "100%",
}))

export const FormikButtonSubmit = styledForMobile(UiFormikButtonSubmit)(({ isMobile }) => ({
    width: isMobile ? "100%" : "fit-content",
}))

export const FormikTextField = styled(UiFormikTextField)(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    marginBottom: theme.spacing(5),
}))

export const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(5),
}))

export const FormikImageField = styled(UiFormikImageField)(({ theme }) => ({
    margin: theme.spacing(5, 0, 5, 0),
}))
