export const networkRoadworksMulhouse = [
    {
        "uuid": "c82eb7fd-6376-4110-8ee5-31da17092ed9",
        "createdAt": "2023-09-21T13:05:11.556000",
        "updatedAt": "2023-09-21T13:05:11.556000",
        "programmation": "6e00d748-2023-47dc-9eae-36758061c99d",
        "yearRelative": 0,
        "year": 2022,
        "layer": "031cf7c7-436b-4518-bade-be83724deafc",
        "techniques": [
            {
                "code": "ENROP",
                "acronym": "ENROP",
                "impact": 10,
            },
            {
                "code": "ENROP_GRAVI_PONTA_PURGE",
                "acronym": "ENROP+GRAVI+PONTA+PURGE",
                "impact": 10,
            },
            {
                "code": "ENROP_PONTA",
                "acronym": "ENROP+PONTA",
                "impact": 10,
            },
            {
                "code": "ENROP_PONTA_PURGE",
                "acronym": "ENROP+PONTA+PURGE",
                "impact": 10,
            },
            {
                "code": "GB_FRAIS_TAPIS",
                "acronym": "GB+FRAIS+TAPIS",
                "impact": 40,
            },
            {
                "code": "GB_TAPIS",
                "acronym": "GB+TAPIS",
                "impact": 40,
            },
            {
                "code": "GRAVI_PONTA",
                "acronym": "GRAVI+PONTA",
                "impact": 10,
            },
            {
                "code": "PONTA",
                "acronym": "PONTA",
                "impact": 10,
            },
            {
                "code": "PONTA_PURGE",
                "acronym": "PONTA+PURGE",
                "impact": 10,
            },
            {
                "code": "TAPIS",
                "acronym": "TAPIS",
                "impact": 20,
            },
            {
                "code": "TAPIS_PURGE",
                "acronym": "TAPIS+PURGE",
                "impact": 20,
            },
        ],
    },
    {
        "uuid": "80c27d39-33f8-4183-8bfe-8013a634b405",
        "createdAt": "2023-09-21T13:07:49.893000",
        "updatedAt": "2023-09-21T13:07:49.893000",
        "programmation": "6e00d748-2023-47dc-9eae-36758061c99d",
        "yearRelative": 1,
        "year": 2023,
        "layer": "59f3c3f9-add4-46c3-8890-d1c48473e0c1",
        "techniques": [
            {
                "code": "ENROP",
                "acronym": "ENROP",
                "impact": 10,
            },
            {
                "code": "ENROP_PONTA",
                "acronym": "ENROP+PONTA",
                "impact": 10,
            },
            {
                "code": "GB_TAPIS",
                "acronym": "GB+TAPIS",
                "impact": 40,
            },
            {
                "code": "GB_TAPIS_PURGE",
                "acronym": "GB+TAPIS+PURGE",
                "impact": 40,
            },
            {
                "code": "GRAVI_PONTA",
                "acronym": "GRAVI+PONTA",
                "impact": 10,
            },
            {
                "code": "PONTA",
                "acronym": "PONTA",
                "impact": 10,
            },
            {
                "code": "PONTA_PURGE",
                "acronym": "PONTA+PURGE",
                "impact": 10,
            },
            {
                "code": "PURGE",
                "acronym": "PURGE",
                "impact": 10,
            },
            {
                "code": "TAPIS",
                "acronym": "TAPIS",
                "impact": 20,
            },
        ],
    },
    {
        "uuid": "e54c8273-aee7-42e9-804b-cc0bf8a7e874",
        "createdAt": "2023-09-21T13:08:35.498000",
        "updatedAt": "2023-09-21T13:08:35.498000",
        "programmation": "6e00d748-2023-47dc-9eae-36758061c99d",
        "yearRelative": 2,
        "year": 2024,
        "layer": "67eab29b-be48-4919-ab82-08c7e4fe88e2",
        "techniques": [
            {
                "code": "ENROP",
                "acronym": "ENROP",
                "impact": 10,
            },
            {
                "code": "ENROP_GRAVI",
                "acronym": "ENROP+GRAVI",
                "impact": 10,
            },
            {
                "code": "ENROP_PONTA",
                "acronym": "ENROP+PONTA",
                "impact": 10,
            },
            {
                "code": "GB_FRAIS_TAPIS",
                "acronym": "GB+FRAIS+TAPIS",
                "impact": 40,
            },
            {
                "code": "GB_TAPIS",
                "acronym": "GB+TAPIS",
                "impact": 40,
            },
            {
                "code": "GRAVI_PONTA",
                "acronym": "GRAVI+PONTA",
                "impact": 10,
            },
            {
                "code": "PONTA",
                "acronym": "PONTA",
                "impact": 10,
            },
            {
                "code": "PONTA_PURGE",
                "acronym": "PONTA+PURGE",
                "impact": 10,
            },
            {
                "code": "TAPIS",
                "acronym": "TAPIS",
                "impact": 20,
            },
            {
                "code": "TAPIS_PURGE",
                "acronym": "TAPIS+PURGE",
                "impact": 20,
            },
        ],
    },
    {
        "uuid": "c8610724-cb61-49b9-b1a5-ddf4127539de",
        "createdAt": "2023-09-21T13:09:09.069000",
        "updatedAt": "2023-09-21T13:09:09.069000",
        "programmation": "6e00d748-2023-47dc-9eae-36758061c99d",
        "yearRelative": 3,
        "year": 2025,
        "layer": "231f4e14-2bd2-453e-b2e2-648e8c4d46ca",
        "techniques": [
            {
                "code": "ENROP_GRAVI_PONTA_PURGE",
                "acronym": "ENROP+GRAVI+PONTA+PURGE",
                "impact": 10,
            },
            {
                "code": "ENROP_GRAVI_PURGE",
                "acronym": "ENROP+GRAVI+PURGE",
                "impact": 10,
            },
            {
                "code": "ENROP_PONTA",
                "acronym": "ENROP+PONTA",
                "impact": 10,
            },
            {
                "code": "ENROP_PONTA_PURGE",
                "acronym": "ENROP+PONTA+PURGE",
                "impact": 10,
            },
            {
                "code": "ENROP_PURGE",
                "acronym": "ENROP+PURGE",
                "impact": 10,
            },
            {
                "code": "GB_FRAIS_TAPIS",
                "acronym": "GB+FRAIS+TAPIS",
                "impact": 40,
            },
            {
                "code": "GB_TAPIS",
                "acronym": "GB+TAPIS",
                "impact": 40,
            },
            {
                "code": "GB_TAPIS_PURGE",
                "acronym": "GB+TAPIS+PURGE",
                "impact": 40,
            },
            {
                "code": "GRAVI_PONTA",
                "acronym": "GRAVI+PONTA",
                "impact": 10,
            },
            {
                "code": "PONTA",
                "acronym": "PONTA",
                "impact": 10,
            },
            {
                "code": "PONTA_PURGE",
                "acronym": "PONTA+PURGE",
                "impact": 10,
            },
            {
                "code": "PURGE",
                "acronym": "PURGE",
                "impact": 10,
            },
            {
                "code": "TAPIS",
                "acronym": "TAPIS",
                "impact": 20,
            },
            {
                "code": "TAPIS_PURGE",
                "acronym": "TAPIS+PURGE",
                "impact": 20,
            },
        ],
    },
    {
        "uuid": "a1965b42-9430-4d22-862a-50f6b0b9554e",
        "createdAt": "2023-09-21T13:09:39.079000",
        "updatedAt": "2023-09-21T13:09:39.079000",
        "programmation": "6e00d748-2023-47dc-9eae-36758061c99d",
        "yearRelative": 4,
        "year": 2026,
        "layer": "831a65dd-8214-4e74-b4d4-29d069e512e8",
        "techniques": [
            {
                "code": "ENROP_GRAVI_PONTA",
                "acronym": "ENROP+GRAVI+PONTA",
                "impact": 10,
            },
            {
                "code": "FRAIS_TAPIS",
                "acronym": "FRAIS+TAPIS",
                "impact": 30,
            },
            {
                "code": "GB_FRAIS_TAPIS",
                "acronym": "GB+FRAIS+TAPIS",
                "impact": 40,
            },
            {
                "code": "GB_TAPIS",
                "acronym": "GB+TAPIS",
                "impact": 40,
            },
            {
                "code": "GRAVI_PONTA",
                "acronym": "GRAVI+PONTA",
                "impact": 10,
            },
            {
                "code": "PONTA",
                "acronym": "PONTA",
                "impact": 10,
            },
            {
                "code": "PURGE",
                "acronym": "PURGE",
                "impact": 10,
            },
            {
                "code": "TAPIS",
                "acronym": "TAPIS",
                "impact": 20,
            },
        ],
    },
]

export const allNetworkRoadworks = {
    mulhouse: networkRoadworksMulhouse,
}

export const getRoadworksBySlug = (networkSlug) => {
    return allNetworkRoadworks[networkSlug]
}