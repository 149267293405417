import { Skeleton } from "@mui/material"

export const AvatarSkeleton = (props) => {
    const { size } = props
    
    return <Skeleton variant="circular" width={size} height={size}/>
}

AvatarSkeleton.defaultProps = {
    size: 40,
}