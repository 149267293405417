import { useGeoServerQueries, useGeoServerQuery } from "./useGeoServerQuery"

export function useGeoServerFeaturesList(queryKey, queryParameters, config) {
    const url = "features/"

    const parameters = {
        ...queryParameters,
        geometry__isnull: !!queryParameters.geometry__isnull,
    }

    return useGeoServerQuery(
        url,
        queryKey,
        parameters,
        config,
    )
}

export function useGeoServerFeaturesLists(queries) {

    const queriesData = queries.map(query => ({
        url: "features",
        ...query,
    }))

    return useGeoServerQueries(queriesData)
}
