import { useMemo } from "react"

import { Form, Formik } from "formik"
import { useWindowSize } from "react-use"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { STATUS_CODE_BAD_REQUEST } from "@l2r-front/l2r-query"
import { FormikTextField, Modal, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { validationSchema } from "./PasswordFormModal.schema"
import * as Styled from "./PasswordFormModal.styled"

export const PasswordFormModal = (props) => {

    const {
        className,
        formValues,
        onSubmit,
        onError,
        setFormValues,
        title,
        desc,
    } = props

    const isMobile = useIsMobileDevice()
    const { width: windowWidth } = useWindowSize()
    const { t } = useTranslation(I18N_NAMESPACE)

    const formikProps = useMemo(() => {
        return {
            validationSchema: validationSchema(t),
            initialValues: {
                ...formValues,
                password: "",
            },
            onSubmit: async (values, { setStatus, setSubmitting }) => {
                try {
                    setStatus(null)
                    await onSubmit(values)
                    setSubmitting(false)
                    setFormValues(null)
                } catch (error) {
                    const unknownError = t(I18N_NAMESPACE, "forms.changeEmailForm.errors.unknown")
                    if (error.response?.status === STATUS_CODE_BAD_REQUEST) {
                        const passwordErrors = error?.response?.data?.password
                        if (passwordErrors) {
                            setStatus(passwordErrors.join("\n"))
                        } else {
                            onError(error?.response?.data)
                            setSubmitting(false)
                            setFormValues(null)
                        }
                        return
                    }
                    setStatus(unknownError)
                }
            },
        }
    }, [t, formValues, onSubmit, setFormValues, onError])

    return <Modal
        className={className}
        open={!!formValues}
        onClose={() => setFormValues(null)}
    >
        <Styled.Box isMobile={isMobile} windowWidth={windowWidth}>
            <Styled.Title variant="H2">{title}</Styled.Title>
            {desc && <Styled.Desc variant="Regular">{desc}</Styled.Desc>}
            <Formik {...formikProps}>
                {(formikState) => {
                    return <Form className={className}>
                        {formikState.status && (
                            <Styled.FormHelperText error>{formikState.status}</Styled.FormHelperText>
                        )}
                        <FormikTextField
                            autoFocus
                            fullWidth
                            variant="outlined"
                            label={t(I18N_NAMESPACE, "forms.passwordFormModal.fields.password.label")}
                            labelVariant="H3"
                            margin="normal"
                            name="password"
                            type="password"
                        />
                        <Styled.SubmitWrapper>
                            <Styled.FormikButtonSubmit>
                                {t(I18N_NAMESPACE, "forms.passwordFormModal.actions.submit")}
                            </Styled.FormikButtonSubmit>
                        </Styled.SubmitWrapper>
                    </Form>
                }}
            </Formik>
        </Styled.Box>
    </Modal>
}

PasswordFormModal.propTypes = {
    className: PropTypes.string,
    formValues: PropTypes.object,
    setFormValues: PropTypes.func.isRequired,
    title: PropTypes.node,
}
