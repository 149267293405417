export const surveysCD91Small = [
    {
        "uuid": "e26884f8-7951-4e71-b07b-452952494ee6",
        "createdAt": "2023-03-02T08:54:09.431000",
        "updatedAt": "2023-05-30T11:41:12.626000",
        "project": "94c4dc9a-9d62-443f-96fe-eb2ec6cdd76c",
        "date": "2023-03-02",
        "collectionPhoto": null,
        "collectionPanorama": null,
    },
]

export const surveysMulhouse = [
    {
        "uuid": "0f06d44b-7116-4de5-aef0-5ab7326a42b4",
        "createdAt": "2023-03-02T08:54:09.431000",
        "updatedAt": "2023-05-30T11:41:12.626000",
        "project": "34be01ee-140f-4fce-9548-40ed080ae7d8",
        "date": "2021-01-01",
        "collectionPhoto": null,
        "collectionPanorama": null,
    },
    {
        "uuid": "0ea4f371-f6e7-4aee-ad49-41974315a235",
        "createdAt": "2023-03-02T08:54:09.431000",
        "updatedAt": "2023-11-30T16:26:51.178652",
        "project": "34be01ee-140f-4fce-9548-40ed080ae7d8",
        "date": "2024-01-01",
        "collectionPhoto": "7b9f8f80-a779-4d63-b595-8700a970e5b3",
        "collectionPanorama": "7c1fa6c8-13b8-4fa8-9c3e-1f32f892e894",
    },
]

export const allRoadSurveys = {
    "cd91_small": surveysCD91Small,
    "mulhouse": surveysMulhouse,
}

export const getRoadSurveysBySlug = (networkSlug) => {
    return allRoadSurveys[networkSlug]
}