export const roadworkProjectEmpty = {
    "uuid": "cb00db89-6352-4934-a94d-2ced0020064e",
    "createdAt": "2023-11-20T09:11:01.269715",
    "updatedAt": "2023-11-20T09:11:01.269725",
    "currency": {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€",
    },
    "roadworksYears": [],
}

const roadProjectMulhouse = {
    "budgets": {
        2011: 0,
        2012: 10,
        2013: 20,
        2014: 30,
        2015: 40,
        2024: 90000000000,
    },
    "uuid": "34be01ee-140f-4fce-9548-40ed080ae7d8",
    "createdAt": "2023-03-02T08:52:55.457000",
    "currency": {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€",
    },
    "updatedAt": "2023-03-02T08:52:55.457000",
    "layer": "095be615-a8ad-4c33-8e9c-c7612fbf6c9f",
    "roadworksYears": [
        2022,
        2023,
        2024,
        2025,
        2026,
    ],
}

const allRoadProjects = {
    "empty": roadworkProjectEmpty,
    "mulhouse": roadProjectMulhouse,
}

export const getRoadworksProjectBySlug = (networkSlug) => {
    return allRoadProjects[networkSlug]
}