import { SELECTED_LINE_LAYER_SIZE, getPointCircleLayerConfig, getInterpolatedLineLayerWidth } from "@l2r-front/l2r-map"

export const getImageCollectionConfig = () => {
    return getPointCircleLayerConfig({
        "paint": {
            "circle-color": "#FFFFFF",
            "circle-radius": getInterpolatedLineLayerWidth(SELECTED_LINE_LAYER_SIZE / 2 - 2),
        },
    })
}

export const getImageCollectionBorderConfig = () => {
    return getPointCircleLayerConfig({
        "paint": {
            "circle-color": "#000000",
            "circle-radius": getInterpolatedLineLayerWidth(SELECTED_LINE_LAYER_SIZE / 2),
        },
    })
}

export const getImageSelectedConfig = () => {
    return getPointCircleLayerConfig({
        "paint": {
            "circle-color": "#000000",
            "circle-radius": getInterpolatedLineLayerWidth(SELECTED_LINE_LAYER_SIZE / 2 - 2),
        },
    })
}

export const getImageSelectedBorderConfig = () => {
    return getPointCircleLayerConfig({
        "paint": {
            "circle-color": "#FFFFFF",
            "circle-radius": getInterpolatedLineLayerWidth(SELECTED_LINE_LAYER_SIZE / 2),
        },
    })
}

