import {
    INCIDENT_STATUS_ADJUSTMENTS,
    INCIDENT_STATUS_AUDIT,
    INCIDENT_STATUS_CLOSED,
    INCIDENT_STATUS_CLOSED_WITHOUT_ADJUSTMENTS,
    INCIDENT_STATUS_ERROR,
    INCIDENT_STATUS_NEW,
    INCIDENT_STATUS_NOT_USABLE,
    INCIDENT_STATUS_ONGOING,
} from "../constants/event"
import { incidentStatusSettings } from "../constants/incidentStatusSettings"


export const incidentReportStatusSettings = {
    [INCIDENT_STATUS_NOT_USABLE]: {
        label: incidentStatusSettings[INCIDENT_STATUS_NOT_USABLE].label,
        queryParams: {
            report_current_step: 1,
            report_is_closed: true,
        },
    },
    [INCIDENT_STATUS_NEW]: {
        label: incidentStatusSettings[INCIDENT_STATUS_NEW].label,
        queryParams: {
            report_is_closed: false,
            report_current_step: 1,
        },
    },
    [INCIDENT_STATUS_CLOSED_WITHOUT_ADJUSTMENTS]: {
        label: incidentStatusSettings[INCIDENT_STATUS_CLOSED_WITHOUT_ADJUSTMENTS].label,
        queryParams: {
            report_current_step: 2,
            report_is_closed: true,
        },
    },
    [INCIDENT_STATUS_ONGOING]: {
        label: incidentStatusSettings[INCIDENT_STATUS_ONGOING].label,
        queryParams: {
            report_current_step: 2,
            report_is_closed: false,
        },
    },
    [INCIDENT_STATUS_ADJUSTMENTS]: {
        label: incidentStatusSettings[INCIDENT_STATUS_ADJUSTMENTS].label,
        queryParams: {
            report_current_step: 3,
            report_is_closed: false,
        },
    },
    [INCIDENT_STATUS_CLOSED]: {
        label: incidentStatusSettings[INCIDENT_STATUS_CLOSED].label,
        queryParams: {
            report_current_step: 4,
            report_is_closed: true,
        },
    },
    [INCIDENT_STATUS_AUDIT]: {
        label: incidentStatusSettings[INCIDENT_STATUS_AUDIT].label,
        queryParams: {
            report_current_step: 4,
            report_is_closed: false,
        },
    },
    [INCIDENT_STATUS_ERROR]: {
        label: incidentStatusSettings[INCIDENT_STATUS_ERROR].label,
        error: true,
    },
}

export const getStatusFromReport = (report) => {
    const currentStep = report.reportSteps.length
    const isClosed = report.isClosed

    const reportStatus = Object.entries(incidentReportStatusSettings)
        .find(([_, statusValues]) => {
            const sameCurrentStep = statusValues?.queryParams?.report_current_step === currentStep
            const sameClosureStatus = statusValues?.queryParams?.report_is_closed === isClosed
            return sameClosureStatus && sameCurrentStep
        })

    if (reportStatus) {
        const [statusKey, _] = reportStatus
        return statusKey ?? INCIDENT_STATUS_ERROR
    }

    return INCIDENT_STATUS_ERROR
}


export const getReportValuesFromStatus = (status) => {
    return incidentReportStatusSettings[status]
}
