import { IMAGE_COLLECTION_TYPE_PANO } from "../../../constants/imageCollectionsTypes"
import { useListImageCollections } from "./useListImageCollections"

export function useLatestImageCollection(network, year) {

    const {
        data: imageCollections,
        isLoading,
        isError,
    } = useListImageCollections(network, year)

    const latestImageCollection = imageCollections?.sort((colA, colB) => {
        const dateA = new Date(colA.date).getTime()
        const dateB = new Date(colB.date).getTime()

        if (dateA > dateB) {
            return -1
        } else if (dateA < dateB) {
            return 1
        } else {
            if (colA.type === IMAGE_COLLECTION_TYPE_PANO) {
                return -1
            } else {
                return 1
            }
        }
    })[0]

    return { data: latestImageCollection, isLoading, isError }
}