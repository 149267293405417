import React, { useCallback } from "react"

import { Select } from "@mui/material"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { CustomSelectInput } from "../CustomSelectInput/CustomSelectInput"
import * as Styled from "./CustomSelector.styled"

export const CustomSelector = (props) => {
    const {
        children,
        icon,
        noMargin,
        title,
        renderFunction,
        valueVariant,
        ...selectProps
    } = props

    const getLabelsFromValues = useCallback((values) => {
        if (!Array.isArray(values)) {
            values = [values]
        }

        const childrenArray = React.Children.toArray(children)
        const matchingChildren = childrenArray.filter(child => values.indexOf(child.props.value) >= 0)
        return matchingChildren.map(child => {
            const children = renderFunction ? renderFunction(child.props.children) : child.props.children
            return children
        })
    }, [children, renderFunction])

    return (
        <Styled.Select
            renderValue={(valueToRender) => {
                const labelsToRender = getLabelsFromValues(valueToRender)

                return <CustomSelectInput
                    icon={icon}
                    noMargin={noMargin}
                    title={title}
                    value={selectProps.multiple ? labelsToRender.join(", ") : labelsToRender}
                    valueVariant={valueVariant}
                />
            }}
            {...selectProps}
        >
            {children}
        </Styled.Select>
    )
}

CustomSelector.propTypes = {
    icon: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
    ]),
    noMargin: PropTypes.bool,
    title: PropTypes.string,
    valueVariant: PropTypes.string,
    ...Select.propTypes,
}