import { LoginBackground } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useMediaQuery, useTheme } from "@l2r-front/l2r-ui"

import * as Styled from "./AnonymousLayout.styled"

export const AnonymousLayout = (props) => {
    const {
        className,
        children,
    } = props

    const theme = useTheme()

    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"))

    return <Styled.AnonymousLayoutWrapper>
        {isLargeScreen && <Styled.Image src={LoginBackground} onMouseDown={(event) => event.preventDefault()} id="background-image" />}
        <Styled.Container className={className}>
            {children}
            <Styled.LegalFooter />
        </Styled.Container>
    </Styled.AnonymousLayoutWrapper>
}

AnonymousLayout.propTypes = {
    children: PropTypes.node.isRequired,
}
