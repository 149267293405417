import { useRoadQuery } from "../../../../../common/hooks/queries/useRoadQuery"
import { surveysQueryKeys } from "./queryKeys"

export function useSurveysQuery(queryParameters, config = {}) {
    const url = "surveys/"
    const queryKey = surveysQueryKeys["list"](queryParameters)

    return useRoadQuery(
        url,
        queryKey,
        queryParameters,
        config,
    )
}