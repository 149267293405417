import { format } from "date-fns"

import { handleRoundNumber } from "@l2r-front/l2r-utils"

const THOUSAND = { value: Math.pow(10, 3), unit: "k" }
const MILLION = { value: Math.pow(10, 6), unit: "m" }
const BILLION = { value: Math.pow(10, 9), unit: "M" }


export function formatNumber(num) {
    const number = typeof num === "string" ? parseFloat(num) : num
    if (!(typeof number === "number")) {
        return null
    }
    const roundedNumber = handleRoundNumber(parseFloat(number), 0)
    const numString = roundedNumber.toString()

    return [...numString].reverse().reduce((acc, v, index) => {
        return index > 0 && index % 3 === 0 ? `${v} ${acc}` : `${v}${acc}`
    }, "")
}

export function formatNumberWithUnit(number, significantNumber = 1) {

    const value = typeof numOrString === "number" ? number : parseFloat(number)

    if (isNaN(value)) {
        throw new Error("Invalid number")
    }

    if (value >= BILLION.value) {
        return `${(value / BILLION.value).toFixed(significantNumber)} ${BILLION.unit}`
    } else if (value >= MILLION.value) {
        return `${(value / MILLION.value).toFixed(significantNumber)} ${MILLION.unit}`
    } else if (value >= THOUSAND.value) {
        return `${(value / THOUSAND.value).toFixed(significantNumber)} ${THOUSAND.unit}`
    } else {
        return `${value.toFixed(1)} `
    }
}

export function formatRoadworkDoneDate(dateString) {
    if (dateString) {
        return format(new Date(dateString), "dd.MM.yy")
    } else {
        return ""
    }
}