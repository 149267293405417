import { useCallback } from "react"

import { useEffectOnce } from "react-use"

export const RecenterButton = (props) => {

    const {
        onRecenter,
    } = props

    const createRecenterButton = useCallback((el) => {
        const recenterButton = document.createElement("button")
        recenterButton.className = "mapboxgl-ctrl-recenter"
        recenterButton.id = "mapboxgl-ctrl-recenter-button"
        recenterButton.type = "button"
        recenterButton.addEventListener("click", onRecenter)

        const icon = document.createElement("span")
        icon.className = "mapboxgl-ctrl-icon"
        icon.setAttribute("aria-hidden", "true")
        icon.style.backgroundImage = "url('assets/resources/recenter.svg')"

        recenterButton.appendChild(icon)
        const previousButton = document?.getElementsByClassName("mapboxgl-ctrl-zoom-in")?.[0]

        el.insertBefore(recenterButton, previousButton)
    }, [onRecenter])

    useEffectOnce(() => {
        const existingRecenterButton = document.getElementById("mapboxgl-ctrl-recenter-button")
        const el = document?.getElementsByClassName("mapboxgl-ctrl-group")?.[0]

        if (el && !existingRecenterButton) {
            createRecenterButton(el)
        }
    }, [onRecenter])

    return null
}
