import { styled } from "@mui/material"

import { CheckCircleIcon as UiCheckCircleIcon } from "@l2r-front/l2r-icons"

export const Wrapper = styled("div")({
    height: 24,
    position: "relative",
    width: 24,
})

export const Number = styled("div")(({ theme }) => ({
    color: theme.palette["surfaces/surface-primary"].main,
    cursor: "pointer",
    fontSize: 14,
    height: "100%",
    left: 0,
    lineHeight: "22px",
    position: "absolute",
    textAlign: "center",
    top: 0,
    width: "100%",
}))

export const CheckCircleIcon = styled(UiCheckCircleIcon)({
    cursor: "pointer",
})