import {Link, styled} from "@l2r-front/l2r-ui"

import { LoginFormPost } from "../../forms/LoginForm"

export const PageWrapper = styled("div")({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
})

export const LoginForm = styled(LoginFormPost)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}))

export const LinksWrapper = styled(Link)(({ theme }) => ({
    color: theme.palette["cta-bg/cta-bg-primary"].main,
    textDecorationColor: theme.palette["cta-bg/cta-bg-primary"].main,
}))
