import { DescriptionIcon } from "@l2r-front/l2r-icons"

import * as Styled from "./MobileReportFAB.styled"

export function MobileReportFAB(props) {

    const {
        onClick,
    } = props

    return <Styled.Wrapper>
        <Styled.Fab color="cta-bg/cta-bg-primary" size="small" onClick={onClick} id="incident-details-analysis-button">
            <DescriptionIcon color="surfaces/surface-primary" id="incidents-filters-button" />
        </Styled.Fab>
    </Styled.Wrapper>
} 