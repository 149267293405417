import { useCallback } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useAlertsDispatchContext } from "@l2r-front/l2r-query"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { ReportStep as L2rReportStep } from "../../components/ReportStep"
import { useReportContext } from "../../contexts/ReportContext"
import { reportStepConfig } from "../../propTypes"

export const ReportStep = (props) => {

    const {
        displayToggle,
        handleBack,
        handleNext,
        handleSubmit,
        nextButtonLabel,
        previousButtonDisabled,
        stepConfig,
        ...reportStepProps
    } = props

    const [state, dispatch] = useReportContext()
    const { setSnackbar } = useAlertsDispatchContext()

    const { currentStepData, displayInput } = state
    const { toggleDisplayInput, updateReportStep } = dispatch
    const { t } = useTranslation(I18N_NAMESPACE)

    const configDefaultToggleState = stepConfig?.toggle?.defaultToggleState
    const toggleKey = stepConfig?.toggle?.toggleKey

    const handleToggle = useCallback(async () => {
        const currentValue = currentStepData?.values?.[toggleKey] ?? configDefaultToggleState

        await updateReportStep({
            values: {
                content: currentStepData?.values?.content || "",
                [toggleKey]: !currentValue,
            },
            isDraft: true,
        })
        setSnackbar({
            label: t(I18N_NAMESPACE, "containers.reportSteps.snackbar.save"),
            severity: "success",
            anchorOrigin: {
                horizontal: "left",
                vertical: "bottom",
            },
        })

    }, [configDefaultToggleState, currentStepData, setSnackbar, t, toggleKey, updateReportStep])

    return <L2rReportStep
        displayInput={displayInput}
        displayToggle={displayToggle}
        handleBack={handleBack}
        handleNext={handleNext}
        handleSubmit={handleSubmit}
        handleToggle={handleToggle}
        nextButtonLabel={nextButtonLabel}
        previousButtonDisabled={previousButtonDisabled}
        stepConfig={stepConfig}
        toggleDisplayInput={toggleDisplayInput}
        toggleKey={toggleKey}
        {...reportStepProps}
    />
}


ReportStep.propTypes = {
    displayToggle: PropTypes.bool.isRequired,
    handleBack: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    nextButtonLabel: PropTypes.string,
    previousButtonDisabled: PropTypes.bool.isRequired,
    stepConfig: reportStepConfig,
}
