import { Typography } from "@mui/material"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./CustomSelectInput.styled"

export const CustomSelectInput = (props) => {
    const {
        icon,
        noMargin,
        title,
        value,
        valueVariant,
    } = props

    const Icon = icon

    return (
        <Styled.Container>
            {icon && <Icon color="cta-bg/cta-bg-primary" />}
            <Styled.LabeledData noMargin={noMargin}>
                {title && <Typography variant="H4 MAJ">{title}</Typography>}
                <Styled.ValueLabel variant={valueVariant || "body2"}>{value}</Styled.ValueLabel>
            </Styled.LabeledData>
        </Styled.Container>
    )
}

CustomSelectInput.propTypes = {
    noMargin: PropTypes.bool,
    icon: PropTypes.elementType,
    title: PropTypes.string,
    value: PropTypes.node.isRequired,
    valueVariant: PropTypes.string,
}