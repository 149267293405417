import { useCallback } from "react"

import { useNavigate } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ErrorBackground } from "@l2r-front/l2r-icons"
import { CTAButton } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"
import * as Styled from "./ErrorPage.styled"

export const ErrorPage = (props) => {

    const {
        resetError,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const navigate = useNavigate()
    const reset = useCallback(() => {
        resetError()
        navigate("/")
    }, [navigate, resetError])

    return <Styled.ErrorPageWrapper>
        <Styled.Background src={ErrorBackground} alt={t(I18N_NAMESPACE, "pages.error.imageAlt")} />
        <Styled.MessageWrapper>
            <Styled.Message variant="H1">{t(I18N_NAMESPACE, "pages.error.message")}</Styled.Message>
            <CTAButton onClick={reset} variant="outlined">
                {t(I18N_NAMESPACE, "pages.error.returnButton")}
            </CTAButton>
        </Styled.MessageWrapper>
    </Styled.ErrorPageWrapper>
}