import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { AuthenticatedDesktopLayout } from "../AuthenticatedDesktopLayout/AuthenticatedDesktopLayout"
import { AuthenticatedMobileLayout } from "../AuthenticatedMobileLayout/AuthenticatedMobileLayout"

export function AuthenticatedLayout(props) {
    const { children } = props

    const isMobile = useIsMobileDevice()

    if (isMobile) {
        return (
            <AuthenticatedMobileLayout>
                {children}
            </AuthenticatedMobileLayout>
        )
    } else {
        return (
            <AuthenticatedDesktopLayout>
                {children}
            </AuthenticatedDesktopLayout>
        )
    }
}

AuthenticatedLayout.propTypes = {
    children: PropTypes.node.isRequired,
}
