import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker"

import { useDateLocale } from "@l2r-front/l2r-i18n"

export const DatePicker = (props) => {
    const locale = useDateLocale()

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <MuiDatePicker {...props} />
        </LocalizationProvider>
    )
}

DatePicker.propTypes = {
    ...MuiDatePicker.propTypes,
}

DatePicker.defaultProps = MuiDatePicker.defaultProps