import { Route, Routes } from "react-router-dom"

import { VerticalSigningSidebarRouter } from "../../../modules/verticalSigning/routers/VerticalSigningSidebarRouter"
import { MODULE_VERTICAL_SIGNING } from "../../constants/modules"

export const SidebarRouter = () => {

    return <Routes>
        <Route
            element={<VerticalSigningSidebarRouter />}
            path={`/${MODULE_VERTICAL_SIGNING}/*`}
        />
    </Routes>
}