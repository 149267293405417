import { Navigate, Route, Routes } from "react-router-dom"

import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { AccountSettingsEmailPage } from "../pages/AccountSettingsEmailPage"
import { AccountSettingsPasswordPage } from "../pages/AccountSettingsPasswordPage"
import { AccountSettingsProfilePage } from "../pages/AccountSettingsProfilePage"
import { MobileAccountSettingsHomePage } from "../pages/MobileAccountSettingsHomePage"

export const AccountSettingsRouter = () => {
    const isMobile = useIsMobileDevice()

    return (
        <Routes>
            <Route path="/" element={isMobile ? <MobileAccountSettingsHomePage /> : <AccountSettingsProfilePage />} />
            <Route path="/profile" element={isMobile ? <AccountSettingsProfilePage /> : <Navigate replace to="/account_settings" />} />
            <Route path="/email" element={<AccountSettingsEmailPage />} />
            <Route path="/password" element={<AccountSettingsPasswordPage />} />
            <Route path="*" element={<Navigate replace to="/account_settings" />} />
        </Routes>
    )
}