// 1xx - Informational response
export const STATUS_CODE_CONTINUE = 100
export const STATUS_CODE_SWITCHING_PROTOCOLS = 101
export const STATUS_CODE_PROCESSING = 102
// 2xx - Successful
export const STATUS_CODE_OK = 200
export const STATUS_CODE_CREATED = 201
export const STATUS_CODE_ACCEPTED = 202
export const STATUS_CODE_NON_AUTHORITATIVE_INFORMATION = 203
export const STATUS_CODE_NO_CONTENT = 204
export const STATUS_CODE_RESET_CONTENT = 205
export const STATUS_CODE_PARTIAL_CONTENT = 206
export const STATUS_CODE_MULTI_STATUS = 207
// 3xx - Redirection
export const STATUS_CODE_MULTIPLE_CHOICES = 300
export const STATUS_CODE_MOVED_PERMANENTLY = 301
export const STATUS_CODE_MOVED_TEMPORARILY = 302
export const STATUS_CODE_SEE_OTHER = 303
export const STATUS_CODE_NOT_MODIFIED = 304
export const STATUS_CODE_USE_PROXY = 305
export const STATUS_CODE_TEMPORARY_REDIRECT = 307
export const STATUS_CODE_PERMANENT_REDIRECT = 308
// 4xx - Client error
export const STATUS_CODE_BAD_REQUEST = 400
export const STATUS_CODE_UNAUTHORIZED = 401
export const STATUS_CODE_PAYMENT_REQUIRED = 402
export const STATUS_CODE_FORBIDDEN = 403
export const STATUS_CODE_NOT_FOUND = 404
export const STATUS_CODE_METHOD_NOT_ALLOWED = 405
export const STATUS_CODE_NOT_ACCEPTABLE = 406
export const STATUS_CODE_PROXY_AUTHENTICATION_REQUIRED = 407
export const STATUS_CODE_REQUEST_TIMEOUT = 408
export const STATUS_CODE_CONFLICT = 409
export const STATUS_CODE_GONE = 410
export const STATUS_CODE_LENGTH_REQUIRED = 411
export const STATUS_CODE_PRECONDITION_FAILED = 412
export const STATUS_CODE_REQUEST_TOO_LONG = 413
export const STATUS_CODE_REQUEST_URI_TOO_LONG = 414
export const STATUS_CODE_UNSUPPORTED_MEDIA_TYPE = 415
export const STATUS_CODE_REQUESTED_RANGE_NOT_SATISFIABLE = 416
export const STATUS_CODE_EXPECTATION_FAILED = 417
export const STATUS_CODE_IM_A_TEAPOT = 418
export const STATUS_CODE_INSUFFICIENT_SPACE_ON_RESOURCE = 419
export const STATUS_CODE_METHOD_FAILURE = 420
export const STATUS_CODE_UNPROCESSABLE_ENTITY = 422
export const STATUS_CODE_LOCKED = 423
export const STATUS_CODE_FAILED_DEPENDENCY = 424
export const STATUS_CODE_PRECONDITION_REQUIRED = 428
export const STATUS_CODE_TOO_MANY_REQUESTS = 429
export const STATUS_CODE_REQUEST_HEADER_FIELDS_TOO_LARGE = 431
export const STATUS_CODE_UNAVAILABLE_FOR_LEGAL_REASONS = 451
// 5xx - Server error
export const STATUS_CODE_INTERNAL_SERVER_ERROR = 500
export const STATUS_CODE_NOT_IMPLEMENTED = 501
export const STATUS_CODE_BAD_GATEWAY = 502
export const STATUS_CODE_SERVICE_UNAVAILABLE = 503
export const STATUS_CODE_GATEWAY_TIMEOUT = 504
export const STATUS_CODE_HTTP_VERSION_NOT_SUPPORTED = 505
export const STATUS_CODE_INSUFFICIENT_STORAGE = 507
export const STATUS_CODE_NETWORK_AUTHENTICATION_REQUIRED = 511
