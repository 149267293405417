import { useCallback, useMemo } from "react"

import { useNetworksContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./MobileNetworkSelector.styled"

export function MobileNetworkSelector(props) {
    const {
        close,
    } = props

    const [networksState, networksDispatch] = useNetworksContext()
    const { networks, selectedNetwork } = networksState
    const { swapToNetwork } = networksDispatch

    const changeselectedNetworkSlug = useCallback((newNetworkSlug) => {
        if (newNetworkSlug !== selectedNetwork.slug) {
            swapToNetwork(newNetworkSlug)
        }

        close()
    }, [selectedNetwork, swapToNetwork, close])

    const networksList = useMemo(() => {
        if (!networks) {
            return null
        }

        return networks.map(network => {
            const selected = network.slug === selectedNetwork.slug
            return <Styled.Button
                color={"objects/object-black"}
                key={network.slug}
                id={`network-${network.slug}`}
                onClick={() => changeselectedNetworkSlug(network.slug)}
                selected={selected}
                variant="text"
            >
                {network.name}
            </Styled.Button>
        })
    }, [changeselectedNetworkSlug, networks, selectedNetwork])

    return (
        <Styled.Wrapper id="networks-list-container">
            {networksList}
        </Styled.Wrapper>
    )
}

MobileNetworkSelector.propTypes = {
    close: PropTypes.func.isRequired,
}
