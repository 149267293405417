import { arrayOf, bool, number, shape, string } from "prop-types"

import { damageType } from "./damageType"

export const networkGrading = shape({
    createdAt: string.isRequired,
    damageTypes: arrayOf(damageType)    ,
    damageLayer: string,
    gradesWeightedAverage: shape({
        gradeGlobal: number.isRequired,
        gradeStructure: number.isRequired,
        gradeSurface: number.isRequired,
    }).isRequired,
    isSimulated: bool.isRequired,
    layer: string.isRequired,
    notationLayer: string.isRequired,
    survey: string.isRequired,
    updatedAt: string.isRequired,
    uuid: string.isRequired,
    year: number.isRequired,
    yearRelative: number.isRequired,
})