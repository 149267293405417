export const isUrl = (string, allowedProtocols = ["http", "https", "blob"]) => {
    let url

    try {
        url = new URL(string)
    } catch (_) {
        return false
    }

    const protocol = url.protocol.slice(0, -1) // https: -> https

    return allowedProtocols.includes(protocol)
}