import { useCallback, useMemo, useState } from "react"

import { IconButton } from "@mui/material"
import { useField, useFormikContext } from "formik"

import { VisibilityIcon, VisibilityOffIcon } from "@l2r-front/l2r-icons"

import { TextField } from "../../components/TextField"

export function FormikTextField(props) {
    const [fieldProps, metaProps] = useField(props)
    const { setStatus } = useFormikContext()

    const handleChange = useCallback((event) => {
        fieldProps.onChange(event)
        setStatus()
        props.onChange?.(event)
    }, [fieldProps, setStatus, props])

    const handleBlur = useCallback((event) => {
        fieldProps.onBlur(event)
        props.onBlur?.(event)
    }, [fieldProps, props])

    const isPassword = useMemo(() => {
        return props.type === "password"
    }, [props.type])

    const [showPassword, setShowPassword] = useState(!isPassword)

    return (
        <TextField
            {...props}
            {...fieldProps}
            {...((metaProps.error && metaProps.touched) ? {
                error: true,
                helperText: metaProps.error,
            } : {})}
            onBlur={handleBlur}
            onChange={handleChange}
            iconEnd={isPassword ? (
                <IconButton onClick={() => setShowPassword(previousState => !previousState)} id={`show-${props.id}`}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>) : props.iconEnd}
            type={(isPassword && showPassword) ? "text" : props.type}
        />
    )
}

FormikTextField.propTypes = TextField.propTypes

FormikTextField.defaultProps = {
    fullWidth: true,
    variant: "outlined",
    color: "border/border-primary",
    labelVariant: "H3",
    margin: "normal",
}