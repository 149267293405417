import { useMemo } from "react"

import { useMyUserContext } from "@l2r-front/l2r-auth"
import { Avatar as UiAvatar, AvatarSkeleton } from "@l2r-front/l2r-ui"


export const MyAvatar = () => {
    const { user, isLoading } = useMyUserContext()

    const fullName = useMemo(() => {
        if (!user) {
            return "Anonymous"
        }
        return `${user.firstName} ${user.lastName}`
    }, [user])

    if (isLoading) {
        return <AvatarSkeleton />
    }

    return <UiAvatar src={user?.avatar} alt={fullName} />
}

