import { useMemo } from "react"

import { Button } from "@mui/material"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./CTAButton.styled"

export const CTAButton = (props) => {

    const element = useMemo(() => {
        const {
            className,
            color,
            variant,
            onClick,
            children,
            ...otherProps
        } = props

        return (variant === "link") ?
            <Styled.Link
                className={className}
                linkColor={color}
                component="button"
                href="#"
                onClick={onClick}
                role="button"
                {...otherProps}
            >
                {children}
            </Styled.Link>
            :
            <Button
                className={className}
                color={color}
                onClick={onClick}
                variant={variant}
                {...otherProps}
            >
                {children}
            </Button>

    }, [props])

    return element
}

CTAButton.propTypes = {
    ...Button.propTypes,
    onClick: PropTypes.func.isRequired,
}

CTAButton.defaultProps = {
    variant: "contained",
    color: "cta-bg/cta-bg-primary",
}

