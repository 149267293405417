import { useCallback, useState } from "react"

import { useNavigate } from "react-router-dom"

import { Trans, useTranslation } from "@l2r-front/l2r-i18n"

import { HeaderWithLogo } from "../../components/HeaderWithLogo"
import { I18N_NAMESPACE } from "../../constants/i18n"
import { RecoverPasswordFormPost } from "../../forms/RecoverPasswordForm"
import * as Styled from "./RecoverPasswordPage.styled"

export const RecoverPasswordPage = () => {
    const [email, setEmail] = useState(null)

    const { t } = useTranslation([I18N_NAMESPACE])
    const navigate = useNavigate()

    const displayEmailSentMessage = useCallback((_, variables) => {
        setEmail(variables.email)
    }, [setEmail])

    return (
        <Styled.PageWrapper>
            <HeaderWithLogo>
                {email ? (
                    <>
                        <Trans namespace={I18N_NAMESPACE} i18nKey="pages.recoverPasswordPage.confirmationMessage">
                            "We have sent you a recover link at <strong>{{ email }}</strong>"
                        </Trans>
                        <Styled.Button onClick={() => navigate("/")} variant="outlined">
                            {t(I18N_NAMESPACE, "forms.recoverPasswordDoneForm.actions.return")}
                        </Styled.Button>
                    </>
                ) : (
                    <>
                        <Styled.Title
                            variant="H2"
                            id="recover-password-title"
                        >
                            {t(I18N_NAMESPACE, "pages.recoverPasswordPage.title")}
                        </Styled.Title>
                        <Styled.Title
                            id="recover-password-label"
                        >
                            {t(I18N_NAMESPACE, "pages.recoverPasswordPage.label")}
                        </Styled.Title>
                        <RecoverPasswordFormPost onSuccess={displayEmailSentMessage} />
                    </>
                )}
            </HeaderWithLogo>
        </Styled.PageWrapper>
    )
}