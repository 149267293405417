import {
    FormikButtonSubmit as UiFormikButtonSubmit,
    FormikTextField as UiFormikField,
    FormHelperText as UiFormHelperText,
    styled,
} from "@l2r-front/l2r-ui"

export const SubmitWrapper = styled("div")({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
})

export const FormikButtonSubmit = styled(UiFormikButtonSubmit)({
    width: "fit-content",
})

export const FormikTextField = styled(UiFormikField)(({ theme }) => ({
    marginBottom: theme.spacing(8),
}))

export const FormHelperText = styled(UiFormHelperText)({
    position: "relative",
    top: -20,
})
