import { useEffect } from "react"

import { useMapDispatchContext } from "@l2r-front/l2r-map"

import { Map } from "./Map"

export const MobileMap = (props) => {

    const { storeMapBoundingBox } = useMapDispatchContext()

    useEffect(() => {
        return () => storeMapBoundingBox()
    }, [storeMapBoundingBox])

    return <Map {...props} />
}