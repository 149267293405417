import { useCallback } from "react"

import { useField, useFormikContext } from "formik"

import { AutoCompleteWithChips } from "../../components/AutoCompleteWithChips"

export const FormikAutoCompleteWithChips = (props) => {

    const {
        setFieldValue,
        ...autoCompleteProps
    } = props

    const [fieldProps, metaProps] = useField(props)
    const { setStatus } = useFormikContext()

    const handleChange = useCallback((_, value) => {
        setFieldValue(props.name, value)
        setStatus()
    }, [props, setFieldValue, setStatus])

    return <AutoCompleteWithChips
        {...autoCompleteProps}
        {...fieldProps}
        {...((metaProps.error && metaProps.touched) ? {
            error: true,
            helperText: metaProps.error,
        } : {})}
        onChange={handleChange}
    />
}