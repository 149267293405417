import { useCallback } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { DatePicker } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import * as Styled from "./MonthYearFilter.styled"

export const MonthYearFilter = (props) => {
    const {
        startDateValue,
        endDateValue,
        onStartDateValueChange,
        onEndDateValueChange,
        minDate,
        maxDate,
    } = props

    const { t } = useTranslation([I18N_NAMESPACE])

    const renderInput = useCallback((params) => {
        return <Styled.TextField {...params} />
    }, [])

    return (
        <Styled.FiltersContainer>
            <DatePicker
                label={t(I18N_NAMESPACE, "components.monthYearFilter.startDateLabel")}
                views={["year", "month"]}
                value={startDateValue}
                onChange={onStartDateValueChange}
                minDate={minDate}
                maxDate={maxDate}
                name="periodStartDate"
                renderInput={renderInput}
            />
            <DatePicker
                label={t(I18N_NAMESPACE, "components.monthYearFilter.endDateLabel")}
                views={["year", "month"]}
                value={endDateValue}
                onChange={onEndDateValueChange}
                minDate={minDate}
                maxDate={maxDate}
                name="periodEndDate"
                renderInput={renderInput}
            />
        </Styled.FiltersContainer>
    )
}

MonthYearFilter.propTypes = {
    startDateValue: PropTypes.date.isRequired,
    endDateValue: PropTypes.date.isRequired,
    onStartDateValueChange: PropTypes.func.isRequired,
    onEndDateValueChange: PropTypes.func.isRequired,
    minDate: PropTypes.date,
    maxDate: PropTypes.date,
}