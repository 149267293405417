import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./IncidentDetail.styled"

export const IncidentDetail = (props) => {

    const {
        icon,
        id,
        title,
        children,
    } = props

    const Icon = icon

    return <Styled.ListItem id={id}>
        <Styled.IconWrapper>
            <Icon color="cta-bg/cta-bg-primary" />
        </Styled.IconWrapper>
        <Styled.Elements>
            {title && <Styled.Title variant="H1">
                {title}
            </Styled.Title>}
            {children}
        </Styled.Elements>
    </Styled.ListItem>
}

IncidentDetail.propTypes = {
    children: PropTypes.node,
    icon: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.object.isRequired]).isRequired,
    title: PropTypes.string,
}