import {
    Button as UiButton,
    ButtonGroup as UiButtonGroup,
    Card as UiCard,
    IconButton as UiIconButton,
    Skeleton as UiSkeleton,
    styled,
    styledForMobile,
    Typography as UiTypography,
} from "@l2r-front/l2r-ui"

import { TECHNIQUE_BUTTON_CONTAINER_HEIGHT } from "../../constants/layout"
import { TaskImpactBadge } from "../TaskImpactBadge"

const CARD_MINHEIGHT = 125
const CARD_WIDTH = 195
const CARD_MOBILE_WIDTH = 150

export const Card = styled(UiCard, { shouldForwardProp: (prop) => prop !== "selected" && prop !== "disabled" })(({ selected, disabled, theme }) => ({
    backgroundColor: disabled ? theme.palette["objects/object-grey"].main : "white",
    border: `solid 2px ${selected ? theme.palette["border/border-primary"].main : "transparent"}`,
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    minHeight: CARD_MINHEIGHT,
    height: "100%",
    width: "100%",

    ":hover": {
        boxShadow: theme.shadows[4],
        cursor: "pointer",
    },
}))

export const TextWrapper = styled("div")({
    display: "flex",
    justifyContent: "space-between",
})

export const BadgeWrapper = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
})

export const ColoredBadge = styled(TaskImpactBadge)({
    height: "fit-content",
})

export const DoneAtWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
}))

export const TechniqueWrapper = styled("div")({
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
})

export const ButtonsWrapper = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: TECHNIQUE_BUTTON_CONTAINER_HEIGHT,
}))

export const Element = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(2),
    textAlign: "left",
}))

export const DetailButton = styled(UiButton)(({ theme }) => ({
    height: `calc(${theme.components.icons.defaultHeight}px + ${theme.spacing(1)})`,
    padding: theme.spacing(1),
    width: `calc(${theme.components.icons.defaultWidth}px + ${theme.spacing(1)})`,
    minWidth: `calc(${theme.components.icons.defaultWidth}px + ${theme.spacing(1)})`,
}))

export const Skeleton = styledForMobile(UiSkeleton, ["alignRight"])(({ alignRight, isMobile, theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginLeft: !!alignRight && "auto",
    minHeight: CARD_MINHEIGHT,
    padding: theme.spacing(3),
    transform: "scale(1)",
    width: isMobile ? CARD_MOBILE_WIDTH : CARD_WIDTH,
}))

export const Error = styled(UiTypography)(({ theme }) => ({
    color: theme.palette["surfaces/surface-error"].main,
    margin: "auto",
}))

export const ButtonWrapper = styled("div")({
    display: "flex",
})

export const IconButton = styled(UiIconButton)(({ theme }) => ({
    border: `1px solid ${theme.palette["border/border-primary"].main}80`,
    borderRadius: theme.spacing(0, 1, 1, 0),

    "&:hover": {
        border: `1px solid ${theme.palette["colors/vert logiroad/800"].main}`,
    },
}))

export const ButtonGroup = styled(UiButtonGroup)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    display: "flex",
}))
