import { useCallback } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useAlertsDispatchContext } from "@l2r-front/l2r-query"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import {
    RoadworkTaskComponent,
    RoadworkTaskError,
    RoadworkTaskSkeleton,
} from "../../components/RoadworkTask"
import { useRoadworksStateContext } from "../../contexts/RoadworksContext"
import { RoadworkTaskSubForm } from "../../forms/RoadworkTaskSubForm"
import { useTaskType, useTaskTypes } from "../../hooks/queries/useTaskType"

export function RoadworkTask(props) {

    const {
        closed,
        deleteTask,
        disableTaskTypes,
        index,
        isDeletable,
        isEditable,
        isEditing,
        initialValues,
        isRoadworkCreation,
        localizedTaskAllowed,
        onCancel,
        onChange,
        onValidate,
        setExtendedTask,
        setInEditionTask,
        task,
    } = props

    const {
        data: taskType,
        isLoading: isLoadingTaskType,
        isError: isErrorTaskType,
    } = useTaskType(task?.taskType, { enabled: !!task?.taskType })

    const {
        data: taskTypes,
        isLoading: isLoadingTaskTypes,
        isError: isErrorTaskTypes,
    } = useTaskTypes()

    const { currency } = useRoadworksStateContext()
    const { setAlert } = useAlertsDispatchContext()
    const { t } = useTranslation(I18N_NAMESPACE)

    const handleOnEdit = useCallback(() => {
        setInEditionTask(index, true)
    }, [setInEditionTask, index])

    const handleOnCancel = useCallback(() => {
        onCancel()
        setInEditionTask(null, false)
    }, [onCancel, setInEditionTask])

    const handleOnValidate = useCallback(() => {
        onValidate()
        setInEditionTask(null, false)
    }, [onValidate, setInEditionTask])

    const onDelete = useCallback(() => {
        setAlert({
            alertTitle: t(I18N_NAMESPACE, "containers.roadworkTask.alert.title"),
            callback: deleteTask,
            severity: "warning",
        })
    }, [deleteTask, setAlert, t])

    if (isErrorTaskType || isErrorTaskTypes) {
        return <RoadworkTaskError />
    }

    if (isLoadingTaskType || isLoadingTaskTypes || !currency?.symbol) {
        return <RoadworkTaskSkeleton />
    }

    return <>
        {
            !isEditing && <RoadworkTaskComponent
                closed={closed}
                currency={currency}
                editionEnabled={isEditable}
                id={`task-${task.uuid || index}`}
                isDeletable={isDeletable}
                onDelete={onDelete}
                onCommentToggled={(toggled) => setExtendedTask(toggled ? index : null)}
                onEdit={handleOnEdit}
                task={task}
                taskType={taskType}
            />
        }
        {
            isEditing && <RoadworkTaskSubForm
                index={index}
                disableTaskTypes={disableTaskTypes}
                initialValues={initialValues}
                isRoadworkCreation={isRoadworkCreation}
                localizedTasksAllowed={localizedTaskAllowed}
                task={task}
                tasksInfo={taskTypes}
                onChange={onChange}
                onCancel={handleOnCancel}
                onValidate={handleOnValidate}
            />
        }
    </>
}

RoadworkTask.propTypes = {
    closed: PropTypes.bool,
    deleteTask: PropTypes.func.isRequired,
    disableTaskTypes: PropTypes.arrayOf(PropTypes.string),
    index: PropTypes.number.isRequired,
    isDeletable: PropTypes.bool,
    isEditable: PropTypes.bool,
    isEditing: PropTypes.bool,
    initialValues: PropTypes.roadworkForm.isRequired,
    isRoadworkCreation: PropTypes.bool,
    localizedTaskAllowed: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    setInEditionTask: PropTypes.func.isRequired,
    task: PropTypes.task.isRequired,
}

RoadworkTask.defaultProps = {
    closed: true,
    disableTaskTypes: [],
    isEditable: false,
    isEditing: false,
    localizedTaskAllowed: true,
}