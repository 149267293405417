import {
    Box as UiBox,
    FormikButtonSubmit as UiFormikButtonSubmit,
    FormHelperText as UiFormHelperText,
    Typography,
    styled,
    styledForMobile,
} from "@l2r-front/l2r-ui"

const MODAL_MAX_WIDTH = 500

export const Box = styledForMobile(UiBox, ["windowWidth"])(({ theme, isMobile, windowWidth }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    border: "1px solid #000",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    left: "50%",
    padding: theme.spacing(10),
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    width: isMobile ? Math.min(MODAL_MAX_WIDTH, windowWidth) : MODAL_MAX_WIDTH,
}))

export const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}))

export const Desc = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(5),
}))

export const SubmitWrapper = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(5),
    width: "100%",
}))

export const FormikButtonSubmit = styled(UiFormikButtonSubmit)({
    width: "100%",
})

export const FormHelperText = styled(UiFormHelperText)({
    position: "relative",
    top: -20,
})