import { useCallback } from "react"

import { Button as MuiButton } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useIsMobileDevice } from "../../hooks/useIsMobileDevice"
import * as Styled from "./Kpi.styled"


export const Kpi = (props) => {
    const {
        color,
        icon,
        path,
        title,
        value,
        ...buttonProps
    } = props

    const navigate = useNavigate()
    const isMobile = useIsMobileDevice()
    const handleClick = useCallback(() => {
        if (path) {
            navigate(path)
        }
    }, [navigate, path])

    return (
        <Styled.KpiCard color={color} onClick={handleClick} {...buttonProps}>
            {!isMobile && <Styled.Dummy />}
            <Styled.IconWrapper isMobile={isMobile}>{icon}</Styled.IconWrapper>
            <Styled.Data variant="H1" isMobile={isMobile}>{value}</Styled.Data>
            <Styled.Title isMobile={isMobile} variant="Small">{title}</Styled.Title>
        </Styled.KpiCard>
    )
}

Kpi.defaultProps = {
    color: "surfaces/surface-clair",
}

Kpi.propTypes = {
    ...MuiButton.propTypes,
    icon: PropTypes.node.isRequired,
    path: PropTypes.string,
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
    ]).isRequired,
}
