import { styled } from "@mui/material/styles"

import { Skeleton as UiSkeleton, Typography } from "@l2r-front/l2r-ui"

export const Skeleton = styled(UiSkeleton)({
    height: "100%",
    transform: "scale(1)",
    width: "100%",
})

export const mapContainerStyle = {
    height: "100%",
    width: "100%",
}

export const ErrorMessage = styled(Typography)({
    fontSize: 21,
})

export const ErrorContainer = styled("div")(({ theme }) => ({
    backgroundColor: theme.errorBackground,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
}))