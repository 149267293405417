import { useCallback } from "react"

import { Autocomplete } from "@mui/material"
import { useField, useFormikContext } from "formik"

export const FormikAutoComplete = (props) => {

    const {
        onChange,
        ...autoCompleteProps
    } = props

    const [fieldProps, metaProps] = useField(props)
    const { setStatus, setFieldValue } = useFormikContext()

    const handleChange = useCallback((_, value) => {
        setFieldValue(props.name, value)
        setStatus()
        onChange?.(value)
    }, [props, onChange, setFieldValue, setStatus])

    return <Autocomplete
        {...autoCompleteProps}
        {...fieldProps}
        {...((metaProps.error && metaProps.touched) ? {
            error: true,
            helperText: metaProps.error,
        } : {})}
        onChange={handleChange}
    />
}