import { useContext } from "react"

import { NetworksStateContext, NetworksDispatchContext } from "./NetworksContext.context"


export const useNetworksStateContext = () => {
    const context = useContext(NetworksStateContext)
    if (context === undefined) {
        throw new Error("useNetworksStateContext must be used within an NetworksContextProvider")
    }
    return context
}

export const useNetworksDispatchContext = () => {
    const context = useContext(NetworksDispatchContext)
    if (context === undefined) {
        throw new Error("useNetworksDispatchContext must be used within an NetworksContextProvider")
    }
    return context
}

export const useNetworksContext = () => {
    return [useNetworksStateContext(), useNetworksDispatchContext()]
}

export const useRoleIsReadOnly = () => {
    const context = useContext(NetworksStateContext)
    if (context === undefined) {
        throw new Error("useRoleIsReadOnly must be used within an NetworksContextProvider")
    }

    return context.selectedNetwork && context.selectedNetwork.role === "READER"
}