import React, { useCallback } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

export const ComponentsChainer = (props) => {

    const {
        components,
        children,
    } = props
    const renderProvider = useCallback((providers, children) => {
        const [provider, ...restProviders] = providers

        if (provider) {
            return React.cloneElement(
                provider,
                null,
                renderProvider(restProviders, children),
            )
        }

        return children
    }, [])

    return renderProvider(components, children)
}

ComponentsChainer.propTypes = {
    components: PropTypes.arrayOf(PropTypes.node),
    children: PropTypes.node,
}