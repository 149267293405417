export const FILTERS_SEARCH_PARAM_PREFIX = "filters-"
export const TECHNIQUES_FILTER = "techniques"
export const STATUSES_FILTER = "statuses"
export const YEARS_FILTER = "years"

export const FILTERS = [
    STATUSES_FILTER,
    TECHNIQUES_FILTER,
    YEARS_FILTER,
]
