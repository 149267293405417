import { useMemo, useEffect, useCallback } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { LocalSeeIcon, ListRoundedIcon, MapRoundedIcon } from "@l2r-front/l2r-icons"
import { MobileImageViewerContainer } from "@l2r-front/l2r-images"
import { MobileModulePage } from "@l2r-front/l2r-ui"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { PARAM_KEY_ROADWORKS_DETAILS } from "../../constants/mobileParamKeys"
import { RoadworksDetailsMapLayersMobile } from "../../routers/RoadworksMapLayersRouter"
import * as Styled from "./MobileRoadworksDetailPage.styled"

export function MobileRoadworksDetailPage() {

    const { t } = useTranslation(I18N_NAMESPACE)
    const { setParam } = useUrlParams()

    const roadworksListTabs = useMemo(() => ([
        {
            element: <Styled.DetailWorksPanel />,
            icon: <ListRoundedIcon />,
            id: "tab-lists",
            label: t(I18N_NAMESPACE, "pages.works.tabs.works"),
        },
        {
            element: <Styled.Map >
                <RoadworksDetailsMapLayersMobile />
            </Styled.Map>,
            icon: <MapRoundedIcon />,
            id: "tab-map",
            label: t(I18N_NAMESPACE, "pages.works.tabs.map"),
            fullScreen: true,
        },
        {
            element: <MobileImageViewerContainer />,
            icon: <LocalSeeIcon />,
            id: "tab-photo",
            label: t(I18N_NAMESPACE, "pages.roadwayDetail.tabs.photo"),
            fullScreen: true,
        },
    ]), [t])

    const onImageClicked = useCallback(() => {
        setParam(PARAM_KEY_ROADWORKS_DETAILS, "tab-photo")
    }, [setParam])

    useEffect(() => {
        document.addEventListener("imageClick", onImageClicked)

        return () => {
            document.removeEventListener("imageClick", onImageClicked)
        }
    }, [onImageClicked])

    return (
        <MobileModulePage paramKey={PARAM_KEY_ROADWORKS_DETAILS} tabsConfig={roadworksListTabs} />
    )
}