import { styled, Skeleton as UiSkeleton, Typography } from "@l2r-front/l2r-ui"

export const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette["surfaces/surface-error"].main,
}))

export const Skeleton = styled(UiSkeleton)({
    height: "100%",
    width: 50,
})

export const Text = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    minWidth: "fit-content",
}))