import * as turf from "@turf/turf"

import { getPreviousSegmentsTotalLength } from "@l2r-front/l2r-geodata"

export const parametricsToLinearLocations = (roadFeatures, featuresWithParametrics) => {
    const linearLocations = []

    turf.featureEach(roadFeatures, function (roadFeature, featureIndex) {
        const roadFeatureLength = turf.length(roadFeature)
        const roadFeatureLinearLocation = roadFeature.linear_location[0]
        const roadFeatureLinearLength = roadFeatureLinearLocation.end - roadFeatureLinearLocation.start
        const segmentsDrawn = featuresWithParametrics.filter(f => f.properties.featureIndex === featureIndex)

        if (segmentsDrawn.length) {
            const segmentsDrawnLinearLocations = []
            segmentsDrawn.forEach(segment => {
                const accumulatedRelativeLength = getPreviousSegmentsTotalLength(roadFeature, segment.properties.segmentIndex) / roadFeatureLength
                const roadSegment = turf.lineString(roadFeature.geometry.coordinates.slice(segment.properties.segmentIndex, segment.properties.segmentIndex + 2))
                const roadSegmentRelativeLength = turf.length(roadSegment) / roadFeatureLength
                const segmentLinearLocationStart = roadFeatureLinearLocation.start + (accumulatedRelativeLength + segment.properties.start * roadSegmentRelativeLength) * roadFeatureLinearLength
                const segmentLinearLocationEnd = roadFeatureLinearLocation.start + (accumulatedRelativeLength + segment.properties.end * roadSegmentRelativeLength) * roadFeatureLinearLength
                segmentsDrawnLinearLocations.push([segmentLinearLocationStart, segmentLinearLocationEnd])
            })
            segmentsDrawnLinearLocations.sort((a, b) => a[0] - b[0])

            const mergedSegmentsDrawnLinearLocations = [segmentsDrawnLinearLocations[0]]
            for (let i = 1; i < segmentsDrawnLinearLocations.length; i++) {
                const linearLocation = segmentsDrawnLinearLocations[i]
                const previousLinearLocation = mergedSegmentsDrawnLinearLocations[mergedSegmentsDrawnLinearLocations.length - 1]
                if (Math.abs(linearLocation[0] - previousLinearLocation[1]) < 0.1) {
                    previousLinearLocation[1] = linearLocation[1]
                } else {
                    mergedSegmentsDrawnLinearLocations.push(linearLocation)
                }
            }
            const adjustedLinearLocations = mergedSegmentsDrawnLinearLocations.filter(linearLocation => Math.abs(linearLocation[1] - linearLocation[0]) >= 1)
                .map(linearLocation => [Math.max(Math.round(linearLocation[0]), roadFeatureLinearLocation.start), Math.min(Math.round(linearLocation[1]), roadFeatureLinearLocation.end)])
            linearLocations.push(...adjustedLinearLocations)
        }
    })

    return linearLocations
}