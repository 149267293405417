import { CircularProgress } from "@l2r-front/l2r-ui"

import * as Styled from "./LoadingPage.styled"

export const LoadingPage = () => {
    return (
        <Styled.PageContainer>
            <CircularProgress />
        </Styled.PageContainer>
    )
}