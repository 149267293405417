import { PropTypes } from "@l2r-front/l2r-proptypes"

import { GLOBAL_GRADE_TYPE } from "../../constants/roadInfoConfig"
import { useNetworkYearGrading } from "../../hooks/queries/networkGradings/useNetworkYearGrading"
import * as Styled from "./NetworkYearGrading.styled"

export function NetworkYearGrading(props) {

    const {
        className,
        year,
    } = props

    const { data, isLoading, isError } = useNetworkYearGrading(year)

    if (isLoading) {
        return <Styled.YearGradingBadgeSkeleton className={className} />
    }

    if (isError) {
        return <Styled.YearGradingBadgeError className={className} />
    }

    return <Styled.YearGradingBadge
        className={className}
        grade={data?.[0]?.gradesWeightedAverage?.[GLOBAL_GRADE_TYPE]}
        year={year}
    />
}


NetworkYearGrading.propTypes = {
    className: PropTypes.string,
    year: PropTypes.number.isRequired,
}