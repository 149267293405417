import { InputLabel } from "@mui/material"
import MuiFormControl from "@mui/material/FormControl"

import * as Styled from "./Select.styled"

export const Select = (props) => {
    const {
        children,
        label,
        value,
        onChange,
        MenuProps,
        ...formProps
    } = props

    return (
        <Styled.FormControl {...formProps}>
            <InputLabel>{label}</InputLabel>
            <Styled.Select labelId="select-label" label={label} MenuProps={MenuProps} value={value} onChange={onChange}>
                {children}
            </Styled.Select>
        </Styled.FormControl>
    )
}

Select.propTypes = MuiFormControl.propTypes