import { useEffect, useMemo } from "react"

import * as turf from "@turf/turf"

import { useGeoServerRetrieveFeature } from "@l2r-front/l2r-geodata"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { CANVAS_MIN_BBOX_WIDTH } from "../../constants/bboxMinWidth"
import { useNetworksStateContext } from "../../contexts/NetworksContext"

export function MapAdapterFeature(props) {

    const { featureId } = props

    const { getMapRef, setMapBoundingBoxToFit } = useMapDispatchContext()
    const mapRef = getMapRef()
    const { selectedNetwork } = useNetworksStateContext()

    const { data: segment, refetch } = useGeoServerRetrieveFeature(featureId)

    useEffect(() => {
        if (featureId && selectedNetwork) {
            refetch()
        }
    }, [featureId, selectedNetwork, refetch])

    const roadBoundingBox = useMemo(() => {
        if (!segment) {
            return null
        }

        const roadFeature = turf.featureCollection([segment])
        const [minLng, minLat, maxLng, maxLat] = turf.bbox(roadFeature)
        const lngOffset = Math.max((CANVAS_MIN_BBOX_WIDTH - (maxLng - minLng)), 0) / 2

        return ({
            minLng: minLng - lngOffset,
            minLat,
            maxLng: maxLng + lngOffset,
            maxLat,
        })
    }, [segment])

    useEffect(() => {
        if (roadBoundingBox && mapRef) {
            setMapBoundingBoxToFit(roadBoundingBox)
        }
    }, [mapRef, roadBoundingBox, setMapBoundingBoxToFit])

    return null
}

MapAdapterFeature.propTypes = {
    featureId: PropTypes.string,
}