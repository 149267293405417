import { useQueryClient } from "@tanstack/react-query"

import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { roadworksQueryKeys } from "../../queries/queryKeys"
import { useRoadworksProject } from "../../queries/useRoadworksProjectQuery"

export function useOnSuccessRoadwork(roadwork) {
    const queryClient = useQueryClient()
    const { selectedNetwork } = useNetworksStateContext()
    const { data: roadworkProject } = useRoadworksProject()

    return ({
        onSuccess: (data) => {
            if (queryClient.getQueryData(roadworksQueryKeys["roadwork"](roadwork.uuid))) {
                queryClient.setQueryData(roadworksQueryKeys["roadwork"](roadwork.uuid), data)
            }
            const roadworksQueryKeyFilter = roadworksQueryKeys.list()
            roadworksQueryKeyFilter.push({ network: selectedNetwork.slug })
            queryClient.invalidateQueries({ queryKey: roadworksQueryKeyFilter})

            const techniquesQueryKeyFilter = roadworksQueryKeys.techniques()
            techniquesQueryKeyFilter.push({ network: selectedNetwork.slug })
            queryClient.invalidateQueries({ queryKey: techniquesQueryKeyFilter })

            const roadworksLayerQueryKeyFilter = roadworksQueryKeys["layer"](roadworkProject.layer)
            queryClient.invalidateQueries({ queryKey: roadworksLayerQueryKeyFilter })

            if (roadwork.feature) {
                const roadworkFeatureQueryKeyFilter = roadworksQueryKeys.section(roadwork.feature)
                queryClient.invalidateQueries({ queryKey: roadworkFeatureQueryKeyFilter })
            }

            const yearsStatsQueryKey = roadworksQueryKeys["years_stats"]()
            queryClient.invalidateQueries({ queryKey: yearsStatsQueryKey })

            const quartersStatsQueryKey = roadworksQueryKeys["quartersStats"]()
            queryClient.invalidateQueries({ queryKey: quartersStatsQueryKey })
        },
    })
}
