import { styled } from "@l2r-front/l2r-ui"

export const ScrollableWrapper = styled("div")(({ theme }) => ({
    cursor: "grab",
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    overflowX: "auto",
    paddingRight: theme.spacing(2),
    width: "100%",
}))

export const ChartWrapper = styled("div")(({ theme }) => ({
    minWidth: 500,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: theme.spacing(0, 5),
}))