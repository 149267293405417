import { useEffect } from "react"

import { useDebounce } from "react-use"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { SaveIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Typography, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useReportContext } from "../../contexts/ReportContext"
import { reportStepConfig } from "../../propTypes"
import * as Styled from "./ReportStep.styled"

export const ReportStepForm = (props) => {

    const {
        className,
        closeForm,
        formikState,
        handleSubmit,
        lastSavedCredentials,
        stepConfig,
    } = props

    const [useReportState, reportDispatch] = useReportContext()

    const { currentStepData, formValues } = useReportState
    const { setFormValues } = reportDispatch
    const { t } = useTranslation(I18N_NAMESPACE)
    const isMobile = useIsMobileDevice()

    useDebounce(
        () => {
            const formikStateValue = formikState?.values?.[stepConfig.type]
            const currentValue = currentStepData?.values?.content || ""
            if (formikStateValue !== currentValue) {
                handleSubmit(formikState.values)
                setFormValues(formikState.values)
            }
        },
        2000,
        [formikState, handleSubmit, setFormValues],
    )

    useEffect(() => {
        const formContextValue = formValues?.[stepConfig.type]
        const formikStateValue = formikState?.values?.[stepConfig.type]

        if (formikStateValue !== formContextValue) {
            setFormValues(formikState.values)
        }

    }, [currentStepData, formValues, formikState?.values, handleSubmit, isMobile, setFormValues, stepConfig])

    return <Styled.Form className={className}>
        <Styled.FormikTextField
            autoFocus
            margin="normal"
            multiline
            name={stepConfig.type}
            selectInputEnd
            type={stepConfig.type}
            variant="outlined"
        />
        <Styled.FormButtonsWrapper>
            {lastSavedCredentials && <Typography variant="Small">
                {t(I18N_NAMESPACE, "components.reportStep.lastSavedCredentials", lastSavedCredentials)}
            </Typography>}
            <Styled.IconButton
                color="cta-bg/cta-bg-primary"
                onClick={async () => {
                    await handleSubmit(formikState.values)
                    closeForm(formikState)
                }}>
                <SaveIcon />
            </Styled.IconButton>
        </Styled.FormButtonsWrapper>
    </Styled.Form>
}

ReportStepForm.propTypes = {
    className: PropTypes.string,
    closeForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    lastSavedCredentials: PropTypes.shape({
        author: PropTypes.string,
        date: PropTypes.string,
    }),
    stepConfig: reportStepConfig.isRequired,
}

