import { Form as FormikForm } from "formik"

import {
    FormikButtonSubmit as UiFormikButtonSubmit,
    FormHelperText as UiFormHelperText,
    FormikTextField as UiFormikField,
    styled,
} from "@l2r-front/l2r-ui"


export const FormikButtonSubmit = styled(UiFormikButtonSubmit)({
    width: "fit-content",
    alignSelf: "flex-end",
})

export const Form = styled(FormikForm)({
    display: "flex",
    flexDirection: "column",
})

export const ButtonsWrapper = styled("div")({
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
})

export const FormikTextField = styled(UiFormikField)(({ theme }) => ({
    marginBottom: theme.spacing(8),
}))
export const FormHelperText = styled(UiFormHelperText)({
    position: "relative",
    top: -20,
})
