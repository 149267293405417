import { getLineLayerConfig, getPointCircleLayerConfig, LINE_LAYER_SIZE } from "@l2r-front/l2r-map"
import { selectLayerColorOpacity } from "@l2r-front/l2r-utils"

export const damagesLegend = [
    {
        value: 1,
        color: "#24BE66",
        labelKey: "containers.damagesLayer.legend.scale.fair",
    },
    {
        value: 10,
        color: "#FFF068",
        labelKey: "containers.damagesLayer.legend.scale.good",
    },
    {
        value: 20,
        color: "#F2994A",
        labelKey: "containers.damagesLayer.legend.scale.poor",
    },
    {
        value: 30,
        color: "#EB5757",
        labelKey: "containers.damagesLayer.legend.scale.serious",
    },
    {
        value: null,
        color: "#317EDE",
        labelKey: "containers.damagesLayer.legend.scale.noScale",
    },
]

export const getDamagesLegendConfig = (t) => {
    return damagesLegend.map(legendItem => {
        return {
            color: legendItem.color,
            label: t(legendItem.labelKey),
        }
    })
}

export const damagesLayerColorConfig = (currentDisplayedDamageType, selectedRoad = null) => {
    const legendStyles = damagesLegend.flatMap((legendItem) => {
        return [
            ["==", ["get", "severity"], legendItem.value],
            selectLayerColorOpacity(legendItem.color, 0.5, selectedRoad),
        ]
    })
    legendStyles.push(damagesLegend[0].color)

    return [
        "case",
        ["!=", ["get", "damageType"], `${currentDisplayedDamageType}`],
        "transparent",
        ["==", ["typeof", ["get", "severity"]], "null"],
        selectLayerColorOpacity(damagesLegend[damagesLegend.length - 1].color, 0.5, selectedRoad),
    ].concat(legendStyles)
}

export const getDamagesLineLayerStyle = (currentDisplayedDamageType, selectedRoad = null) => {
    return getLineLayerConfig({
        "paint": {
            "line-color": damagesLayerColorConfig(currentDisplayedDamageType, selectedRoad),
            "line-width": LINE_LAYER_SIZE,
        },
    })
}

export const getDamagesPointLayerStyle = (currentDisplayedDamageType, selectedRoad = null) => {
    return getPointCircleLayerConfig({
        "paint": {
            "circle-color": damagesLayerColorConfig(currentDisplayedDamageType, selectedRoad),
            "circle-radius": LINE_LAYER_SIZE,
        },
    })
}
