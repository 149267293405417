
import { useMemo } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { getContrastTextColor } from "@l2r-front/l2r-utils"

import { EPD_TAG_START_WIDTH } from "../../constants/layout"
import * as Styled from "./EPDTag.styled"

export function EPDTag(props) {
    const {
        label,
        color,
        width,
        direction,
    } = props

    const textColor = useMemo(() => {
        return getContrastTextColor(color)
    }, [color])

    return (
        <>
            {direction === "left" &&
                <Styled.ArrowLeft color={color} width={width}>
                    <Styled.LabelRight variant="H2" color={textColor}>{label}</Styled.LabelRight>
                </Styled.ArrowLeft>}
            {direction === "right" &&
                <Styled.ArrowRight color={color} width={width}>
                    <Styled.LabelLeft variant="H2" color={textColor}>{label}</Styled.LabelLeft>
                </Styled.ArrowRight>}
        </>
    )
}

EPDTag.propTypes = {
    label: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    width: PropTypes.number,
    direction: PropTypes.oneOf(["left", "right"]),
}

EPDTag.defaultProps = {
    width: EPD_TAG_START_WIDTH,
    direction: "right",
}