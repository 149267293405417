import { useCallback, useEffect, useMemo, useState } from "react"

import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useRoleIsReadOnly } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useAlertsDispatchContext } from "@l2r-front/l2r-query"
import { ChipList } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useProjectStateContext } from "../../../../common/contexts/ProjectContext"
import { useEventTagsUpdate } from "../../hooks/mutations/events/useEventTagsUpdate"

export function FavoriteTagsList(props) {
    const { initialTags } = props
    const { eventId } = useParams()
    const { t } = useTranslation(I18N_NAMESPACE)
    const { setAlert } = useAlertsDispatchContext()

    const readOnly = useRoleIsReadOnly()
    const { projectTags } = useProjectStateContext()
    const mutation = useEventTagsUpdate(eventId, {}, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.favoriteTagsList.snackbar.error"),
        successSnackbarMessage: t(I18N_NAMESPACE, "containers.favoriteTagsList.snackbar.success"),
    })
    const [displayedTags, setDisplayedTags] = useState(initialTags.map(v => ({ label: v, value: v })))

    const sortTags = useCallback((tagsArray) => {
        return tagsArray?.sort((tag1, tag2) => {
            return tag1.localeCompare(tag2, undefined, { sensitivity: "base" })
        })
    }, [])

    useEffect(() => {
        if (mutation?.data) {
            const sortedTags = sortTags(mutation?.data?.tags)
            setDisplayedTags(sortedTags?.map(v => ({ label: v, value: v })))
        }
    }, [initialTags, mutation?.data, sortTags])

    const updateTags = useCallback((tags) => {
        mutation.mutate({ tags: tags })
    }, [mutation])

    const onAdd = (newTag) => {
        const tagList = displayedTags.map(v => v.value)
        const newChipList = tagList.concat(newTag)
        updateTags(newChipList)
    }

    const deleteTag = useCallback((tagIndex) => {
        const chipList = displayedTags.map(v => v.value)
        chipList.splice(tagIndex, 1)
        updateTags(chipList)
    }, [displayedTags, updateTags])

    const onDelete = (tagIndex) => {
        const tag = displayedTags[tagIndex].value
        setAlert({
            alertTitle: t(I18N_NAMESPACE, "components.incidentDetails.alert.title", { tag: tag }),
            alertText: t(I18N_NAMESPACE, "components.incidentDetails.alert.text", { tag: tag }),
            callback: () => deleteTag(tagIndex),
            severity: "warning",
        })
    }

    const addableTags = useMemo(() => {
        const filteredNetworkTags = projectTags.filter(tag => !displayedTags.includes(tag))
        return sortTags(filteredNetworkTags)
    }, [displayedTags, projectTags, sortTags])

    return <ChipList
        onAdd={!readOnly && onAdd}
        onDelete={!readOnly && onDelete}
        options={addableTags}
        values={displayedTags}
        variant="outlined"
    />
}

FavoriteTagsList.propTypes = {
    initialTags: PropTypes.arrayOf(PropTypes.string).isRequired,
}