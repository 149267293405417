export const STATUS_DONE = 100
export const STATUS_TODO = 50
export const STATUSES = [STATUS_TODO, STATUS_DONE]

export const statusesSettings = {
    [STATUS_TODO]: {
        label: "forms.techniquesFiltersForm.inputs.statuses.todo",
        order: 2,
    },
    [STATUS_DONE]: {
        label: "forms.techniquesFiltersForm.inputs.statuses.done",
        order: 1,
    },
}