import { useContext } from "react"

import { AuthenticationStateContext, AuthenticationDispatchContext } from "./AuthenticationContext.context"

export const useAuthenticationStateContext = () => {
    const context = useContext(AuthenticationStateContext)
    if (context === undefined) {
        throw new Error("useAuthenticationStateContext must be used within an AuthenticationContextProvider")
    }
    return context
}

export const useAuthenticationDispatchContext = () => {
    const context = useContext(AuthenticationDispatchContext)
    if (context === undefined) {
        throw new Error("useAuthenticationDispatchContext must be used within an AuthenticationContextProvider")
    }
    return context
}

export const useAuthenticationContext = () => {
    return [useAuthenticationStateContext(), useAuthenticationDispatchContext()]
}
