import MapboxDraw from "@mapbox/mapbox-gl-draw"

/**
 * This class extends the MapboxDraw to add custom functionalities like custom buttons
 * to the Draw Bar.
 */
export class StabiloDraw extends MapboxDraw {

    constructor({ onAdded, props }) {
        super(props)
        this.parentOnAdd = this.onAdd
        this.onAdded = onAdded
        // @ts-expect-error the only way to override this method from MapboxDraw. We have to delete and re-add it below
        delete this.onAdd
    }

    onAdd(map) {
        // the default buttons
        this._container = this.parentOnAdd(map)
        this.onAdded(this._container)
        return this._container
    }
}
