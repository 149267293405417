import { CustomSelector, MenuItem as UiMenuItem, ListSubheader as UiListSubheader, styled } from "@l2r-front/l2r-ui"

export const Select = styled(CustomSelector)({
    maxWidth: 360,
})

export const MenuItem = styled(UiMenuItem)(({ theme }) => ({
    alignItems: "center",
    display: "flex",

    "&.Mui-selected": {
        backgroundColor: `${theme.palette.common.white} !important`,
        color: theme.palette["texts/text-primary"].main,
        fontWeight: theme.typography["H3"].fontWeight,
        "&:hover": {
            backgroundColor: `${theme.palette.common.white} !important`,
            color: theme.palette["texts/text-primary"].main,
            fontWeight: theme.typography["H3"].fontWeight,
        },
    },
}))

export const ColorIcon = styled("div")(({ color, theme }) => ({
    backgroundColor: theme.palette[color].main,
    borderRadius: "50%",
    height: 12,
    marginRight: theme.spacing(5),
    width: 12,
}))

export const OptionText = styled("span")({
    margin: 0,
})

export const ListSubheader = styled(UiListSubheader)(({ theme, variant }) => ({
    ...theme.typography?.[variant],
    color: theme.palette["objects/object-black"].main,
}))

export const IconWrapper = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    marginRight: theme.spacing(5),
}))