import { useMemo } from "react"

import { useGetTechniquesQuery } from "./useGetTechniquesQuery"
import { useRoadworksProject } from "./useRoadworksProjectQuery"

export function useGetProjectTechniqueCodes(config = {}) {
    const { enabled = true } = config
    const { data: project, isLoading: isLoadingProject, isError: isErrorProject } = useRoadworksProject()
    const { data: techniques, isLoading: isLoadingTechniques, isError: isErrorTechniques } = useGetTechniquesQuery(
        {},
        { enabled: enabled && !!project },
    )

    const techniqueCodes = useMemo(() => {
        if (techniques) {
            return techniques.reduce((uniqueTechniques, technique) => {
                if (!uniqueTechniques.find(t => t.code === technique.code)) {
                    uniqueTechniques.push({ code: technique.code, acronym: technique.acronym })
                }
                return uniqueTechniques
            }, [])
        }
    }, [techniques])

    return { data: techniqueCodes, isLoading: isLoadingProject || isLoadingTechniques, isError: isErrorProject || isErrorTechniques }
}