import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers/TimePicker"

import { useDateLocale } from "@l2r-front/l2r-i18n"

export const TimePicker = (props) => {
    const locale = useDateLocale()

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <MuiTimePicker {...props} />
        </LocalizationProvider>
    )
}

TimePicker.propTypes = MuiTimePicker.propTypes