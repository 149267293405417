import { useGeoServerFeaturesList } from "@l2r-front/l2r-geodata"

import { damagesQueryKeys } from "./queryKeys"

export function useDamagesLayer(networkCondition, queryParameters, config = {}) {
    const parameters = {
        ...queryParameters,
        layer: networkCondition?.layer,
        as_geojson: true,
    }
    const { enabled } = config
    const queryKey = damagesQueryKeys["layer"]({ uuid: networkCondition?.uuid, ...queryParameters })

    return useGeoServerFeaturesList(
        queryKey,
        parameters,
        {
            ...config,
            enabled: !!networkCondition?.layer && enabled,
        },
    )
}
