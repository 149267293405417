import {
    ListItemButton as UiListItemButton,
    Typography,
    styled,
} from "@l2r-front/l2r-ui"

const ITEM_HEIGHT = 96

export const ListItemButton = styled(UiListItemButton)(({ theme }) => ({
    display: "flex",
    height: ITEM_HEIGHT,
    marginBottom: theme.spacing(3),
    padding: 0,
    width: "100%",
}))

export const ListItem = styled("div")(({ theme }) => ({
    alignItems: "start",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2, 0, 2, 2),
}))

export const ListItemHeader = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    height: "30px",
    justifyContent: "start",
    marginBottom: theme.spacing(3),
    width: "100%",
}))

export const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette["objects/object-black"].main,
}))

export const SecondaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette["texts/text-placeholder"].main,
}))

export const Wrapper = styled("div")({
})

export const IconWrapper = styled("div")(() => ({
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    display: "flex",
    height: "100%",
}))