import { useCallback, useEffect, useMemo, useState } from "react"

import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useReportStepUpdate } from "../../hooks/mutations/reports/useReportStepUpdate"
import { useEventQuery } from "../../hooks/queries/events/useEvent"
import { useReportStepQuery } from "../../hooks/queries/reports/useReportStep"
import { ReportDispatchContext, ReportStateContext } from "./ReportContext.context"


export const ReportContextProvider = (props) => {
    const { children } = props
    const { eventId } = useParams()
    const { data: event, isError: eventError } = useEventQuery(eventId, {}, { enabled: !!eventId })
    const { t } = useTranslation(I18N_NAMESPACE)
    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const [formValues, setFormValues] = useState(null)
    const [displayInput, setDisplayInput] = useState(false)

    const currentStepId = useMemo(() => {
        return event?.report?.reportSteps?.[currentStepIndex]
    }, [currentStepIndex, event])

    const {
        data: currentStepData,
        isError: stepError,
    } = useReportStepQuery(currentStepId, {}, { enabled: !!currentStepId })

    useEffect(() => {
        if (event?.report?.reportSteps.length) {
            setCurrentStepIndex(event?.report?.reportSteps.length - 1)
        }
    }, [event])

    const onNextStep = useCallback(() => {
        setCurrentStepIndex(currentStep => currentStep + 1)
    }, [])


    const onPreviousStep = useCallback(() => {
        setCurrentStepIndex(currentStep => currentStep - 1)
    }, [])

    const { mutateAsync: updateReportStep } = useReportStepUpdate(currentStepId, eventId, {}, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.reportSteps.snackbar.error"),
        successSnackbarMessage: t(I18N_NAMESPACE, "containers.reportSteps.snackbar.save"),
    })

    const toggleDisplayInput = useCallback((value) => {
        setDisplayInput(previousState => value ?? !previousState)
    }, [setDisplayInput])

    const dispatchValue = useMemo(() => {
        return {
            onNextStep,
            onPreviousStep,
            setFormValues,
            setCurrentStepIndex,
            toggleDisplayInput,
            updateReportStep,
        }
    }, [onNextStep, onPreviousStep, setFormValues, setCurrentStepIndex, toggleDisplayInput, updateReportStep])
    useEffect(() => {
        if (currentStepData) {
            setFormValues({ [reportStepsConfig[currentStepIndex].type]: (currentStepData?.values?.content || "") })
        }
    }, [currentStepData, currentStepIndex])

    useEffect(() => {
        if (currentStepData) {
            setFormValues({ [reportStepsConfig[currentStepIndex].type]: (currentStepData?.values?.content || "") })
        }
    }, [currentStepData, currentStepIndex])

    const stateValue = useMemo(() => {
        return ({
            currentStepData,
            currentStepId,
            currentStepIndex,
            displayInput,
            error: eventError || stepError,
            event,
            formValues,
            reportStepsConfig,
        })
    }, [currentStepData, currentStepId, currentStepIndex, displayInput, event, eventError, formValues, stepError])

    return (
        <ReportStateContext.Provider value={stateValue}>
            <ReportDispatchContext.Provider value={dispatchValue}>
                {children}
            </ReportDispatchContext.Provider>
        </ReportStateContext.Provider>
    )
}

ReportStateContext.propTypes = {
    children: PropTypes.node.isRequired,
}



export const reportStepsConfig = [{
    title: "containers.reportSteps.preAnalysis.title",
    type: "preAnalysis",
    toggle: {
        active: "next",
        defaultToggleState: true,
        toggleKey: "isRelevant",
        title: "containers.reportSteps.preAnalysis.toggle",
    },
},
{
    title: "containers.reportSteps.analysis.title",
    type: "analysis",
    toggle: {
        active: "next",
        defaultToggleState: true,
        toggleKey: "needsAdjustments",
        title: "containers.reportSteps.analysis.toggle",
    },
},
{
    title: "containers.reportSteps.improvementPlan.title",
    type: "improvementPlan",
},
{
    title: "containers.reportSteps.audit.title",
    type: "audit",
}]