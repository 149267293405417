import { useCallback, useMemo } from "react"

import { useFormikContext } from "formik"
import { cloneDeep } from "lodash"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { InfoOutlineIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { CTAButton, FormikTextField, MenuItem, Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { TaskImpactBadge } from "../../components/TaskImpactBadge"
import { MINIMAL_IMPACT, impacts, impactsOrder, otherTaskCodePrefix } from "../../constants/impacts"
import { roadworksQuantityUnits } from "../../constants/units"
import * as Styled from "./RoadworkTaskSubForm.styled"

export function RoadworkTaskSubForm(props) {

    const { t } = useTranslation(I18N_NAMESPACE)

    const {
        className,
        disableTaskTypes,
        index,
        initialValues,
        isRoadworkCreation,
        onCancel,
        onChange,
        onValidate,
        localizedTasksAllowed,
        task,
        tasksInfo,
    } = props

    const { setFieldValue, values } = useFormikContext()
    const taskValue = values.tasks[index]
    const formContainsValidTask = initialValues?.tasks.some(task => !!task.taskType)

    const handleInputChange = useCallback((inputName, inputValue, previousvalues) => {
        const newValues = cloneDeep(previousvalues)
        newValues.tasks[index] = {
            ...newValues.tasks[index],
            [inputName]: inputValue,
        }
        onChange(inputName, newValues)
    }, [index, onChange])

    const handleNumberValueChange = useCallback((inputName, inputValue, previousvalues, defaultValue, setFieldValue) => {
        if (!inputValue) {
            setFieldValue(`tasks.${index}.${inputName}`, defaultValue)
            handleInputChange(inputName, defaultValue, previousvalues)
        } else if (!inputValue || inputValue !== previousvalues[inputName]) {
            const floatValue = parseFloat(inputValue)?.toFixed(2)
            if (!floatValue || floatValue < 0) {
                setFieldValue(`tasks.${index}.${inputName}`, defaultValue)
                handleInputChange(inputName, defaultValue, previousvalues)
            } else {
                const stringValue = floatValue.toString()
                setFieldValue(`tasks.${index}.${inputName}`, stringValue)
                handleInputChange(inputName, stringValue, previousvalues)
            }
        }
    }, [handleInputChange, index])

    const handleUnitChange = useCallback((inputValue, previousvalues, setFieldValue) => {
        if (!inputValue) {
            const defaultValue = roadworksQuantityUnits(t)[0]
            setFieldValue(`tasks.${index}.unit`, defaultValue)
            handleInputChange("input", defaultValue, previousvalues)
        }
    }, [handleInputChange, index, t])

    const impactCategory = useCallback((impact, impactName) => {
        return <Styled.TaskCategory key={`cat-${impact}`}>
            <Typography variant="H3">{t(I18N_NAMESPACE, impactName)}</Typography>
            <TaskImpactBadge impact={impact} variant="outlined" />
        </Styled.TaskCategory>
    }, [t])

    const taskTypesOptions = useMemo(() => {
        const sortedTasksInfo = tasksInfo?.sort((taskA, taskB) => {
            if (taskA.impact !== taskB.impact) {
                return impactsOrder[taskB.impact] - impactsOrder[taskA.impact]
            }
            if (taskA.code.startsWith(otherTaskCodePrefix)) {
                return 1
            }
            return taskA.name < taskB.name ? -1 : 1
        })
        const sortedImpact = Object.entries(impacts).sort((impactA, impactB) => {
            return impactsOrder[impactB[0]] - impactsOrder[impactA[0]]
        })

        return Array.from(sortedImpact).reduce((options, [impact, impactInfo]) => {
            const impactInt = parseInt(impact)
            options.push(impactCategory(impactInt, impactInfo.text))
            const tasksOfImpact = sortedTasksInfo?.filter(task => task.impact === impactInt)
            if (impactInt === MINIMAL_IMPACT && !localizedTasksAllowed) {
                options.push(<Styled.InfoContainer key="info-noLTasks">
                    <InfoOutlineIcon color="objects/object-primary" />
                    <Styled.InfoText id={"no-l-task"} key={"no-l-task"}>
                        {t(I18N_NAMESPACE, "forms.roadworkTaskForm.noLTasks")}
                    </Styled.InfoText>
                </Styled.InfoContainer>)
            } else {
                tasksOfImpact?.forEach(taskInfo => {
                    options.push(
                        <MenuItem
                            key={taskInfo.code}
                            value={taskInfo.code}
                            disabled={disableTaskTypes.includes(taskInfo.code)}>
                            {taskInfo.name}
                        </MenuItem>)
                })
            }

            return options
        }, [])
    }, [tasksInfo, impactCategory, localizedTasksAllowed, t, disableTaskTypes])

    return <Styled.FormWrapper className={className} id="task-form">
        <Styled.InputsWrapper>
            <Styled.TaskTypeContainer>
                <Styled.TaskTypeSelect
                    id="taskType-select"
                    name={`tasks.${index}.taskType`}
                    value={taskValue.taskType ?? ""}
                    label={t(I18N_NAMESPACE, "forms.roadworkTaskForm.task")}
                    MenuProps={{ sx: { maxWidth: 475 } }}
                    onChange={(value) => handleInputChange("taskType", value, values)}
                >
                    {taskTypesOptions}
                </Styled.TaskTypeSelect>
                <TaskImpactBadge id="taskImpact-badge" impact={tasksInfo.find(ti => {
                    return ti.code === task.taskType
                })?.impact} variant="outlined" />
            </Styled.TaskTypeContainer>
            <Styled.QuantitySelect
                id="quantity-input"
                name={`tasks.${index}.quantity`}
                type="number"
                label={t(I18N_NAMESPACE, "forms.roadworkTaskForm.quantity")}
                labelOutside={false}
                iconEnd={
                    <Styled.UnitSelect
                        id="unit-select"
                        name={`tasks.${index}.unit`}
                        disableClearable
                        freeSolo
                        openOnFocus
                        forcePopupIcon
                        autoSelect
                        options={roadworksQuantityUnits(t)}
                        renderInput={(params) => (
                            <Styled.TextFieldInput
                                {...params}
                                variant="outlined"
                                onBlur={(e) => handleUnitChange(e.target.value, values, setFieldValue)}
                                inputProps={{
                                    ...params.inputProps,
                                    maxLength: 10,
                                }} />
                        )}
                        sx={{ border: 0 }}
                    >
                    </Styled.UnitSelect>
                }
                onBlur={(e) => handleNumberValueChange("quantity", e.target.value, values, "0.00", setFieldValue)}
            />
            <FormikTextField
                id="cost-input"
                name={`tasks.${index}.cost`}
                type="number"
                label={t(I18N_NAMESPACE, "forms.roadworkTaskForm.cost")}
                labelOutside={false}
                iconEnd="€"
                onBlur={(e) => handleNumberValueChange("cost", e.target.value, values, "0.00", setFieldValue)}
            />
            <FormikTextField name={`tasks.${index}.comment`}
                id="comment-input"
                label={t(I18N_NAMESPACE, "forms.roadworkTaskForm.comment")}
                labelOutside={false}
                multiline
                rows={2}
                onBlur={(e) => handleInputChange("comment", e.target.value, values)}
            />
        </Styled.InputsWrapper>
        <Styled.ButtonsWrapper>
            {(!isRoadworkCreation || formContainsValidTask) && <CTAButton
                id="cancel-task-edit-button"
                onClick={onCancel}
                variant="outlined"
            >
                {t(I18N_NAMESPACE, "forms.roadworkTaskForm.cancel")}
            </CTAButton>}
            <CTAButton disabled={!task.taskType} id="submit-task-edit-button" onClick={onValidate}>
                {t(I18N_NAMESPACE, "forms.roadworkTaskForm.submit")}
            </CTAButton>
        </Styled.ButtonsWrapper>
    </Styled.FormWrapper >
}

RoadworkTaskSubForm.propTypes = {
    className: PropTypes.string,
    initialValues: PropTypes.roadworkForm.isRequired,
    isRoadworkCreation: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    localizedTasksAllowed: PropTypes.bool,
    task: PropTypes.task.isRequired,
    tasksInfo: PropTypes.arrayOf(PropTypes.taskType).isRequired,
}

RoadworkTaskSubForm.defaultProps = {
    localizedTasksAllowed: true,
}
