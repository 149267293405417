import { useMemo } from "react"

import { useRoutes } from "react-router-dom"

import { Sidebar as L2RSidebar } from "@l2r-front/l2r-ui"

import { RoadwayDetailSidebar } from "../../containers/RoadwayDetailSidebar"
import { RoadwaySidebar } from "../../containers/RoadwaySidebar"

export function RoadwaySidebarRouter() {

    const sidebarRoutes = useMemo(() => ([
        { path: "/", element: <RoadwaySidebar /> },
        { path: "/:road/*", element: <RoadwayDetailSidebar /> },
    ]), [])

    return <L2RSidebar>{useRoutes(sidebarRoutes)}</L2RSidebar>
}
