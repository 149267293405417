import { useMemo } from "react"

import { sanitizeData } from "@l2r-front/l2r-geodata"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { Source, useMapDispatchContext } from "@l2r-front/l2r-map"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useRoadwayStateContext } from "../../contexts/RoadwayContext"
import { useDamagesLayer } from "../../hooks/queries/damages/useDamagesLayer"
import { useNetworkConditions } from "../../hooks/queries/networkConditions/useNetworkConditions"

export function DamagesSource(props) {
    const {
        damageType,
        ...layerProps
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const { setError: setMapError } = useMapDispatchContext()

    const {
        selectedNetwork,
    } = useNetworksStateContext()

    const {
        selectedNetworkYearlyStatus,
    } = useRoadwayStateContext()

    const {
        data: networkConditions,
    } = useNetworkConditions(
        selectedNetwork?.slug,
        {
            survey: selectedNetworkYearlyStatus?.survey,
            year: selectedNetworkYearlyStatus?.year,
        },
        {
            enabled: !!selectedNetwork && !!selectedNetworkYearlyStatus,
            onError: () => setMapError(true),
            errorSnackbarMessage: t(I18N_NAMESPACE, "containers.notationLayer.error"),
        })

    const networkCondition = networkConditions?.[0]

    const { data } = useDamagesLayer(networkCondition, { data__damage_type: damageType }, {
        onError: () => setMapError(true),
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.notationLayer.error"),
        enabled: !!networkCondition,
    })

    // Need to copy id into properties because of that mapbox issue:
    // link:https://github.com/mapbox/mapbox-gl-js/issues/2716
    const sanitizedData = useMemo(() => {
        return sanitizeData(data)
    }, [data])

    if (!sanitizedData) {
        return null
    }

    return (
        <Source {...layerProps} type="geojson" data={sanitizedData} tolerance={.001} />
    )
}

DamagesSource.propTypes = Source.propTypes
