import { useCallback } from "react"

import { useNavigate } from "react-router-dom"

import { ArrowRightRoundedIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { ListItem } from "@l2r-front/l2r-ui"

import * as Styled from "./MobileAccountSettingsMenuItem.styled"

export const MobileAccountSettingsMenuItem = (props) => {
    const {
        label,
        desc,
        to,
        ...listItemProps
    } = props

    const navigate = useNavigate()

    const handleClick = useCallback(() => {
        navigate(to)
    }, [to, navigate])

    return (
        <Styled.ListItemButton {...listItemProps} onClick={handleClick}>
            <Styled.ListItem>
                <Styled.ListItemHeader>
                    <Styled.PrimaryTypography id="accountSettings-label" variant="Regular">
                        {label}
                    </Styled.PrimaryTypography>
                </Styled.ListItemHeader>
                <Styled.SecondaryTypography id="accountSettings-desc" variant="Small">
                    {desc}
                </Styled.SecondaryTypography>
            </Styled.ListItem>
            <Styled.IconWrapper>
                <ArrowRightRoundedIcon color="objects/object-black" fontSize="large" />
            </Styled.IconWrapper>
        </Styled.ListItemButton>
    )
}

MobileAccountSettingsMenuItem.propTypes = {
    ...ListItem.propTypes,
    label: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
}