import { useCallback, useState } from "react"

import { useField, useFormikContext } from "formik"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { ImageField } from "../../components/ImageField"

export function FormikImageField(props) {
    const [fieldProps, metaProps] = useField(props)
    const { setStatus } = useFormikContext()
    const [filePreview, setFilePreview] = useState(null)
    const formikProps = useFormikContext()

    const handleChange = useCallback((event) => {
        const selectedFile = event.target.files[0]
        formikProps.setFieldTouched(fieldProps.name, true)
        formikProps.setFieldValue(fieldProps.name, selectedFile)
        const objectUrl = URL.createObjectURL(selectedFile)
        setFilePreview(objectUrl)
        setStatus()
    }, [fieldProps, formikProps, setStatus])

    return (
        <ImageField
            filePreview={filePreview}
            handleChange={handleChange}
            {...((metaProps.error && metaProps.touched) ? {
                error: true,
                helperText: metaProps.error,
            } : {})}
            {...props}
        />
    )
}

FormikImageField.propTypes = {
    alt: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    className: PropTypes.string,
    filePreview: PropTypes.string,
    imageSource: PropTypes.string,
}