import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useRecoverPasswordDoneMutation } from "../../hooks/mutations/auth/useRecoverPasswordDoneMutation"
import { RecoverPasswordDoneForm } from "./RecoverPasswordDoneForm.form"

export const RecoverPasswordDoneFormPost = (props) => {
    const {
        token,
        className,
        onSuccess,
    } = props

    const { mutateAsync: recoverPassword } = useRecoverPasswordDoneMutation({
        onSuccess,
    })

    return (
        <RecoverPasswordDoneForm
            initialValues={{
                token,
                password: "",
                confirmPassword: "",
            }}
            className={className}
            onSubmit={recoverPassword}
        />
    )
}

RecoverPasswordDoneFormPost.propTypes = {
    className: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
}