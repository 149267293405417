export const techniquesMulhouse = [
    { code: "PLR1", cost: "11000", impact: 30, year: 2023, status: 100 },
    { code: "PONTA", cost: "13023.86", impact: 10, year: 2023, status: 50 },
    { code: "PONTA", cost: "2059", impact: 10, year: 2023, status: 100 },
    { code: "PLR2", cost: "3121", impact: 30, year: 2023, status: 50 },
    { code: "PLR1", cost: "130.1", impact: 30, year: 2024, status: 50 },
    { code: "PL1", cost: "1103812", impact: 20, year: 2023, status: 50 },
    { code: "PLS1", cost: "12014", impact: 40, year: 2023, status: 50 },
    { code: "PLR1", cost: "9862", impact: 30, year: 2025, status: 50 },
    { code: "PLS1", cost: "1456", impact: 40, year: 2026, status: 50 },
    { code: "PLR2_PLS1", cost: "1476", impact: 30, year: 2026, status: 50 },
    { code: "PL1", cost: "143", impact: 20, year: 2025, status: 50 },
    { code: "PONTA", cost: "786", impact: 10, year: 2024, status: 50 },
]

export const allTechniques = {
    "mulhouse": techniquesMulhouse,
}

const TESTED_FILTERS = [
    {
        single: "year",
        multiple: "year__in",
    },
    {
        single: "technique_code",
        multiple: "technique_code__in",
    },
    {
        single: "status",
        multiple: "status__in",
    },
]

export function getFiltersParams(url) {
    return TESTED_FILTERS.reduce((acc, filter) => {
        const filterValue = url.searchParams.get(filter.single) || url.searchParams.get(filter.multiple)
        if (!filterValue) {
            return acc
        }
        const newValue = {
            filterKey: filter.single,
            filterValue: filterValue.split(","),
        }
        if (newValue.filterKey === "status") {
            newValue.filterValue = newValue.filterValue.map(value => parseInt(value))
        }
        return acc.concat(newValue)
    }, [])
}

function applyFilters(filtersParams, initialData) {
    return filtersParams.reduce((acc, filter) => {
        if (filter.filterKey === "year") {
            return acc.filter(technique => {
                return filter.filterValue.some(value => technique.year === parseInt(value))
            })
        }
        if (filter.filterKey === "technique_code") {
            return acc.filter(technique => {
                return filter.filterValue.some(value => technique.code === value)
            })
        }
        if (filter.filterKey === "status") {
            return acc.filter(technique => {
                return filter.filterValue.some(value => technique.status === value)
            })
        }
        return []
    }, initialData)
}

export const getTechniques = (networkSlug, filterParams) => {
    const networkTechniques = allTechniques[networkSlug]

    if (filterParams) {
        return applyFilters(filterParams, networkTechniques)
    } else {
        return networkTechniques
    }
}

