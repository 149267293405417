export const referentialCD91Small =
{
    "type": "FeatureCollection",
    "features": [
        {
            "id": "ddd8d4cf-704c-4cef-922b-d25dd0187893",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.23276001119596,
                        48.60054255300864,
                    ],
                    [
                        2.23239292589501,
                        48.600603155982306,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2128,
                    "end": 2156,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 28.0,
                "identifier": "308",
            },
        },
        {
            "id": "70cd2831-fd09-4b86-abf3-c1b54fe3438e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.224207974722415,
                        48.601685346871626,
                    ],
                    [
                        2.223671254822997,
                        48.60175111441524,
                    ],
                    [
                        2.223190204019646,
                        48.601812744987484,
                    ],
                    [
                        2.220938370513528,
                        48.602109278950074,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 0,
                    "end": 246,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 246.0,
                "identifier": "22",
            },
        },
        {
            "id": "1c7c953c-1530-4d8c-ba7a-6f40146080ef",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245671371299025,
                        48.59859745302959,
                    ],
                    [
                        2.245646585621087,
                        48.598623383254136,
                    ],
                    [
                        2.245623194897372,
                        48.598646623628575,
                    ],
                    [
                        2.245582045143288,
                        48.59867874707006,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 246,
                    "end": 257,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 11.0,
                "identifier": "46",
            },
        },
        {
            "id": "7864ee55-8458-479f-ae3c-c084d9ca36c6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.233311532827964,
                        48.60048313683567,
                    ],
                    [
                        2.233008583172303,
                        48.60051716788332,
                    ],
                    [
                        2.232800643205323,
                        48.60054641310926,
                    ],
                    [
                        2.232772098014973,
                        48.60055072695697,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 257,
                    "end": 297,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 40.0,
                "identifier": "58",
            },
        },
        {
            "id": "96a5d382-d612-4a33-ba0a-db40489ceabd",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220938370513528,
                        48.602109278950074,
                    ],
                    [
                        2.219225956778939,
                        48.602340043001156,
                    ],
                    [
                        2.219099561790573,
                        48.60235720524593,
                    ],
                    [
                        2.218277327671386,
                        48.60246785263441,
                    ],
                    [
                        2.216522834511342,
                        48.60269920114888,
                    ],
                    [
                        2.215755120975147,
                        48.602791299141366,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 297,
                    "end": 687,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 390.0,
                "identifier": "111",
            },
        },
        {
            "id": "1ff269bd-361e-4c7b-8e6e-baaa010a4965",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245103768329297,
                        48.59883403795686,
                    ],
                    [
                        2.245061676900529,
                        48.598837369282826,
                    ],
                    [
                        2.24501691213587,
                        48.598837984955715,
                    ],
                    [
                        2.24496819573179,
                        48.59883047949692,
                    ],
                    [
                        2.24492356065399,
                        48.598822100343675,
                    ],
                    [
                        2.244884350048308,
                        48.59881375558154,
                    ],
                    [
                        2.244837067618137,
                        48.59880086179623,
                    ],
                    [
                        2.244758698362738,
                        48.59878057428525,
                    ],
                    [
                        2.24472905843712,
                        48.59876689282027,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 687,
                    "end": 716,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 29.0,
                "identifier": "123",
            },
        },
        {
            "id": "01623559-c31a-4e9a-ab81-32c1bf34dbca",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235031286121271,
                        48.60057695941257,
                    ],
                    [
                        2.235014841414818,
                        48.60058854803871,
                    ],
                    [
                        2.2349478377561,
                        48.60062589903744,
                    ],
                    [
                        2.234887706792464,
                        48.60065699724004,
                    ],
                    [
                        2.234829155333361,
                        48.60067281302529,
                    ],
                    [
                        2.234736686662043,
                        48.600689310324604,
                    ],
                    [
                        2.234656554838362,
                        48.60069689125261,
                    ],
                    [
                        2.23455889806271,
                        48.600697162960984,
                    ],
                    [
                        2.23447355192483,
                        48.60069031726204,
                    ],
                    [
                        2.234381490752197,
                        48.60067893051124,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 716,
                    "end": 768,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 52.0,
                "identifier": "125",
            },
        },
        {
            "id": "d7cba6ba-4763-4032-adc9-1f14ebc9c1e5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245582045143288,
                        48.59867874707006,
                    ],
                    [
                        2.245551847893061,
                        48.59870374341778,
                    ],
                    [
                        2.245514857094177,
                        48.59872959626284,
                    ],
                    [
                        2.245479313082328,
                        48.59874916133655,
                    ],
                    [
                        2.24545612957295,
                        48.598758010008424,
                    ],
                    [
                        2.245431564023473,
                        48.59876864903846,
                    ],
                    [
                        2.245383866770249,
                        48.598784538804196,
                    ],
                    [
                        2.245381115661667,
                        48.59878722004918,
                    ],
                    [
                        2.245334774490483,
                        48.59880311839129,
                    ],
                    [
                        2.245283047718611,
                        48.598816283888176,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 768,
                    "end": 795,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 27.0,
                "identifier": "127",
            },
        },
        {
            "id": "03a5baf7-de37-4969-9bde-ec29bf7a9692",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.22996734275581,
                        48.60091673114543,
                    ],
                    [
                        2.228775574180138,
                        48.601072736865156,
                    ],
                    [
                        2.228608420765912,
                        48.60109504250642,
                    ],
                    [
                        2.228520084156046,
                        48.60110706395541,
                    ],
                    [
                        2.228405940491304,
                        48.60112161676504,
                    ],
                    [
                        2.228336643209723,
                        48.6011301632288,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 795,
                    "end": 918,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 123.0,
                "identifier": "141",
            },
        },
        {
            "id": "b5a9645c-2178-4b65-8faa-a38a4829b503",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.237312702935555,
                        48.599915127124426,
                    ],
                    [
                        2.23727201868392,
                        48.59991486647162,
                    ],
                    [
                        2.236973012266558,
                        48.59995792846346,
                    ],
                    [
                        2.236850697275528,
                        48.59997513566261,
                    ],
                    [
                        2.236456760069837,
                        48.60001758777583,
                    ],
                    [
                        2.236361567663236,
                        48.60003496842742,
                    ],
                    [
                        2.235804629626861,
                        48.60009436393035,
                    ],
                    [
                        2.235723129595221,
                        48.600102836351724,
                    ],
                    [
                        2.235614375322645,
                        48.60012012934084,
                    ],
                    [
                        2.235519051149617,
                        48.600146504088436,
                    ],
                    [
                        2.235423595563605,
                        48.60018187352981,
                    ],
                    [
                        2.235355131504332,
                        48.60022641187673,
                    ],
                    [
                        2.235176598965976,
                        48.60037819046304,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 918,
                    "end": 1090,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 172.0,
                "identifier": "185",
            },
        },
        {
            "id": "99344210-062f-4d13-ab69-99facaa20c65",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244720934729476,
                        48.59876594171856,
                    ],
                    [
                        2.244572151507556,
                        48.598738010831866,
                    ],
                    [
                        2.244504345885771,
                        48.59873758053705,
                    ],
                    [
                        2.244436540265101,
                        48.598737150203604,
                    ],
                    [
                        2.244368604868397,
                        48.59874571462258,
                    ],
                    [
                        2.244273547193098,
                        48.5987541068276,
                    ],
                    [
                        2.244219043075124,
                        48.59877175204023,
                    ],
                    [
                        2.244150847968668,
                        48.59879830591574,
                    ],
                    [
                        2.244069091646932,
                        48.59882477364001,
                    ],
                    [
                        2.243987335242564,
                        48.59885124130758,
                    ],
                    [
                        2.243892017596039,
                        48.59887762278658,
                    ],
                    [
                        2.243238222615819,
                        48.599071371982134,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1090,
                    "end": 1208,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 118.0,
                "identifier": "214",
            },
        },
        {
            "id": "abd0e34a-47fd-48a2-8b9f-b0815610fadb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235176598965976,
                        48.60037819046304,
                    ],
                    [
                        2.235148424980802,
                        48.6004499743583,
                    ],
                    [
                        2.235120776397827,
                        48.60048577917099,
                    ],
                    [
                        2.235093390526168,
                        48.600503594440596,
                    ],
                    [
                        2.235065610497884,
                        48.60054839400652,
                    ],
                    [
                        2.235024531606149,
                        48.60057511688804,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1208,
                    "end": 1233,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 25.0,
                "identifier": "216",
            },
        },
        {
            "id": "7140b227-c587-4945-9e88-6ff8cb3e8850",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245704591021183,
                        48.598268423990575,
                    ],
                    [
                        2.245731194862849,
                        48.59830457502327,
                    ],
                    [
                        2.24573054715197,
                        48.598349549001135,
                    ],
                    [
                        2.245743590017591,
                        48.598385614104714,
                    ],
                    [
                        2.24574307185669,
                        48.598421593284826,
                    ],
                    [
                        2.24572886309848,
                        48.59846648133586,
                    ],
                    [
                        2.245714783861307,
                        48.59850237458936,
                    ],
                    [
                        2.245700704604838,
                        48.59853826784009,
                    ],
                    [
                        2.245672934692484,
                        48.598583069952845,
                    ],
                    [
                        2.245659244051239,
                        48.59859197881593,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1233,
                    "end": 1271,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 38.0,
                "identifier": "231",
            },
        },
        {
            "id": "2091f7c9-2440-49d0-bd18-07a71bdef8bc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.234116037096767,
                        48.60056028010274,
                    ],
                    [
                        2.233994509140938,
                        48.6005235157602,
                    ],
                    [
                        2.23389984131605,
                        48.60050491537012,
                    ],
                    [
                        2.233832296677201,
                        48.6004864894643,
                    ],
                    [
                        2.23377818204015,
                        48.600477145571816,
                    ],
                    [
                        2.233710505868009,
                        48.600467714363226,
                    ],
                    [
                        2.233534468949251,
                        48.60044858990041,
                    ],
                    [
                        2.233303395893349,
                        48.60048308443274,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1271,
                    "end": 1333,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 62.0,
                "identifier": "256",
            },
        },
        {
            "id": "e0e41b3b-8219-479d-8e1a-004d36403ba0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.228329928620117,
                        48.60112562189855,
                    ],
                    [
                        2.227446692231247,
                        48.60123683661013,
                    ],
                    [
                        2.226454426488252,
                        48.601383318866816,
                    ],
                    [
                        2.225217776375166,
                        48.60154619391246,
                    ],
                    [
                        2.22419850807454,
                        48.6016834860596,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1333,
                    "end": 1644,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 311.0,
                "identifier": "266",
            },
        },
        {
            "id": "fccba551-c6c4-443f-ba1b-db402fd87a53",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245276293069956,
                        48.59881444194264,
                    ],
                    [
                        2.245235480005451,
                        48.59882317880245,
                    ],
                    [
                        2.245167544627809,
                        48.59883174367674,
                    ],
                    [
                        2.245099738879783,
                        48.59883131372108,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1644,
                    "end": 1657,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 13.0,
                "identifier": "269",
            },
        },
        {
            "id": "9d42b958-4b45-4af3-b6f8-1af6d0b16714",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.234371997625077,
                        48.60067886945981,
                    ],
                    [
                        2.234291022503224,
                        48.60065136183328,
                    ],
                    [
                        2.234210178992939,
                        48.60061485938499,
                    ],
                    [
                        2.234169888813415,
                        48.600587613373634,
                    ],
                    [
                        2.234116037096767,
                        48.60056028010274,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1657,
                    "end": 1680,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 23.0,
                "identifier": "271",
            },
        },
        {
            "id": "a8c640b8-abdb-41c7-a3dc-7c0c6bf84adc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.239038401376328,
                        48.599692284464005,
                    ],
                    [
                        2.237312702935555,
                        48.599915127124426,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1680,
                    "end": 1810,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 130.0,
                "identifier": "273",
            },
        },
        {
            "id": "06e95aee-3949-46fa-88d6-3b27b580f31b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.243238222615819,
                        48.599071371982134,
                    ],
                    [
                        2.243020593236648,
                        48.59911496640686,
                    ],
                    [
                        2.242394955547445,
                        48.599236925108734,
                    ],
                    [
                        2.24172902179892,
                        48.59933163702944,
                    ],
                    [
                        2.241660954944716,
                        48.599349194682844,
                    ],
                    [
                        2.24157945702026,
                        48.59935767111846,
                    ],
                    [
                        2.241511520351494,
                        48.599366233902686,
                    ],
                    [
                        2.241443583660515,
                        48.599374796648085,
                    ],
                    [
                        2.241362085661038,
                        48.599383272934695,
                    ],
                    [
                        2.241294018616715,
                        48.59940083037758,
                    ],
                    [
                        2.241226081845299,
                        48.599409392998595,
                    ],
                    [
                        2.241158145051668,
                        48.59941795558085,
                    ],
                    [
                        2.241090208235824,
                        48.59942651812421,
                    ],
                    [
                        2.241022271397766,
                        48.59943508062877,
                    ],
                    [
                        2.240954334537494,
                        48.599443643094496,
                    ],
                    [
                        2.240886397655007,
                        48.599452205521395,
                    ],
                    [
                        2.240764215528945,
                        48.59946042196654,
                    ],
                    [
                        2.240641902945548,
                        48.59947763306782,
                    ],
                    [
                        2.240587527279526,
                        48.59948628182589,
                    ],
                    [
                        2.240478775893605,
                        48.59950357926738,
                    ],
                    [
                        2.240356463115618,
                        48.599520790074784,
                    ],
                    [
                        2.240234150257242,
                        48.59953800075626,
                    ],
                    [
                        2.239704344575991,
                        48.599597587621865,
                    ],
                    [
                        2.239310151227137,
                        48.59965803874843,
                    ],
                    [
                        2.239228652441652,
                        48.5996665135726,
                    ],
                    [
                        2.239038401376328,
                        48.599692284464005,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1810,
                    "end": 2128,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 318.0,
                "identifier": "280",
            },
        },
        {
            "id": "85506e8c-5036-4504-a7ac-d6eee7d4c2f8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.23239292589501,
                        48.600603155982306,
                    ],
                    [
                        2.230694424586443,
                        48.6008170826605,
                    ],
                    [
                        2.23023240338809,
                        48.60087706547046,
                    ],
                    [
                        2.230205280079046,
                        48.60087689009054,
                    ],
                    [
                        2.229960641277874,
                        48.60091129043044,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2156,
                    "end": 2339,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 183.0,
                "identifier": "310",
            },
        },
        {
            "id": "3ed7952a-9851-4c77-a36d-f7a652e98518",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260670350036127,
                        48.592227295561585,
                    ],
                    [
                        2.260933261997554,
                        48.59233507689815,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2534,
                    "end": 2557,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 23.0,
                "identifier": "156",
            },
        },
        {
            "id": "34e57398-a404-4ff8-ab59-82bbe8abae12",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220422289710097,
                        48.58930777003707,
                    ],
                    [
                        2.220462697921512,
                        48.58932602611808,
                    ],
                    [
                        2.220503106161091,
                        48.589344282185095,
                    ],
                    [
                        2.220502570753402,
                        48.58938026151938,
                    ],
                    [
                        2.220502035344977,
                        48.58941624085266,
                    ],
                    [
                        2.220488075110151,
                        48.58944313655342,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4879,
                    "end": 4897,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 18.0,
                "identifier": "342",
            },
        },
        {
            "id": "e958e1a6-7d3c-40bf-84c0-9d46da4045c1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251634370899419,
                        48.587517366147935,
                    ],
                    [
                        2.251723316164496,
                        48.587745516303016,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 743,
                    "end": 769,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 26.0,
                "identifier": "56",
            },
        },
        {
            "id": "668aac70-3d82-4304-be8a-e3cad792c95d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.225015677677535,
                        48.590365076393226,
                    ],
                    [
                        2.225267381442702,
                        48.59039999907401,
                    ],
                    [
                        2.225443567781628,
                        48.59040654315413,
                    ],
                    [
                        2.225621096760776,
                        48.590413995278034,
                    ],
                    [
                        2.226307962941572,
                        48.59036538744037,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 0,
                    "end": 96,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 96.0,
                "identifier": "12",
            },
        },
        {
            "id": "cabc023b-b7c2-48ae-a8e3-9a394be6460b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227016415923118,
                        48.59032411239502,
                    ],
                    [
                        2.227046218994584,
                        48.590326105080194,
                    ],
                    [
                        2.227243834184816,
                        48.59035077704485,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 96,
                    "end": 113,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 17.0,
                "identifier": "13",
            },
        },
        {
            "id": "107123a1-3b6c-407d-b773-b4852e473f67",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245281025038835,
                        48.58926289247146,
                    ],
                    [
                        2.245443851200478,
                        48.58887890952822,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 113,
                    "end": 157,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 44.0,
                "identifier": "15",
            },
        },
        {
            "id": "069d5f26-79b8-4908-a3f0-9cf1d777d41b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.240007382242809,
                        48.59105186099514,
                    ],
                    [
                        2.241028423738649,
                        48.591055677307864,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 157,
                    "end": 232,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 75.0,
                "identifier": "16",
            },
        },
        {
            "id": "3d7f4f74-8d88-450a-aa43-ec841e2450e7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.266503486283121,
                        48.595276019595815,
                    ],
                    [
                        2.266605163071593,
                        48.59527844519859,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 232,
                    "end": 240,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 8.0,
                "identifier": "23",
            },
        },
        {
            "id": "69351d98-bbfd-4cad-ba45-948e3fb193fc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.265178429598887,
                        48.59499348096393,
                    ],
                    [
                        2.265554040651322,
                        48.595092951733825,
                    ],
                    [
                        2.265668873703221,
                        48.59512424529097,
                    ],
                    [
                        2.265899984492157,
                        48.595180544044865,
                    ],
                    [
                        2.266150256571084,
                        48.59522436660889,
                    ],
                    [
                        2.26633562880861,
                        48.595254295236636,
                    ],
                    [
                        2.266503486283121,
                        48.595276019595815,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 240,
                    "end": 343,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 103.0,
                "identifier": "24",
            },
        },
        {
            "id": "0477dd38-5a62-4508-9332-0d88e804a2e3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.252364117327363,
                        48.589115087823,
                    ],
                    [
                        2.252809846590139,
                        48.58952269219267,
                    ],
                    [
                        2.252947183755752,
                        48.58959102181851,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 343,
                    "end": 412,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 69.0,
                "identifier": "25",
            },
        },
        {
            "id": "56879bc7-e510-4ffd-aff2-1e779ba3464f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245647367592548,
                        48.58839982985365,
                    ],
                    [
                        2.245884585655773,
                        48.58784000197121,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 412,
                    "end": 477,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 65.0,
                "identifier": "26",
            },
        },
        {
            "id": "1c838a6a-2f59-44d8-8998-fedc1977b94c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260037981848081,
                        48.59197058838523,
                    ],
                    [
                        2.260195750208991,
                        48.59203363903523,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 477,
                    "end": 491,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 14.0,
                "identifier": "27",
            },
        },
        {
            "id": "01b21c07-e815-48de-a041-e1310d211778",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.257958336814422,
                        48.5911660243644,
                    ],
                    [
                        2.258236407759132,
                        48.591255915012326,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 491,
                    "end": 514,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 23.0,
                "identifier": "30",
            },
        },
        {
            "id": "d36aae93-7c63-4480-a9b4-02273798e55a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.259706264016828,
                        48.591838088850466,
                    ],
                    [
                        2.260037981848081,
                        48.59197058838523,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 514,
                    "end": 543,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 29.0,
                "identifier": "36",
            },
        },
        {
            "id": "6f072b15-7ea0-4ed5-92a1-ec759f04093a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.243393046767975,
                        48.59135859323156,
                    ],
                    [
                        2.244331143087356,
                        48.59147250062359,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 543,
                    "end": 613,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 70.0,
                "identifier": "41",
            },
        },
        {
            "id": "70b6ea2d-623a-4c02-98f8-fc02e1932123",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.262359016053809,
                        48.593164323280675,
                    ],
                    [
                        2.2626585117207,
                        48.59346753264736,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 613,
                    "end": 653,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 40.0,
                "identifier": "48",
            },
        },
        {
            "id": "e7e571fc-5763-475e-9574-946ee7cd5908",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.264105386710302,
                        48.594631525064706,
                    ],
                    [
                        2.264197254166368,
                        48.5946563811499,
                    ],
                    [
                        2.264361575647428,
                        48.59473655824936,
                    ],
                    [
                        2.2644719728534,
                        48.59479391287071,
                    ],
                    [
                        2.264590594797886,
                        48.5948450212633,
                    ],
                    [
                        2.264728390608184,
                        48.5948827545382,
                    ],
                    [
                        2.265178429598887,
                        48.59499348096393,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 653,
                    "end": 743,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 90.0,
                "identifier": "54",
            },
        },
        {
            "id": "7a077405-2a04-4113-b270-eb5f8db5d62c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.270071032374565,
                        48.59540679592416,
                    ],
                    [
                        2.271568940771172,
                        48.59535480140166,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 769,
                    "end": 880,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 111.0,
                "identifier": "57",
            },
        },
        {
            "id": "c13b1f60-44f7-45a0-aa1d-b567fc806cf8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244331143087356,
                        48.59147250062359,
                    ],
                    [
                        2.244551560390846,
                        48.590951251349125,
                    ],
                    [
                        2.244559324153404,
                        48.59093276323434,
                    ],
                    [
                        2.244567007277791,
                        48.59091446713234,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 880,
                    "end": 944,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 64.0,
                "identifier": "59",
            },
        },
        {
            "id": "687bde85-d229-4cbf-b7f2-0b803a07e6d0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245443851200478,
                        48.58887890952822,
                    ],
                    [
                        2.245647367592548,
                        48.58839982985365,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 944,
                    "end": 999,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 55.0,
                "identifier": "60",
            },
        },
        {
            "id": "c76addf7-9584-4e61-b723-7668fb683bd2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244621742678838,
                        48.590786176394204,
                    ],
                    [
                        2.244749474864165,
                        48.59048563187474,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 999,
                    "end": 1034,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 35.0,
                "identifier": "65",
            },
        },
        {
            "id": "f7af6755-0b74-42ca-b414-76533acd5729",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.233311930114299,
                        48.59109887872599,
                    ],
                    [
                        2.233625567115902,
                        48.591072112355405,
                    ],
                    [
                        2.233827966425582,
                        48.59104822745192,
                    ],
                    [
                        2.234546584455256,
                        48.590961095039695,
                    ],
                    [
                        2.234849149363289,
                        48.59094954690734,
                    ],
                    [
                        2.235393236627949,
                        48.59092785536282,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1034,
                    "end": 1189,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 155.0,
                "identifier": "68",
            },
        },
        {
            "id": "d7a960c5-e4a8-410c-949a-c196a5718fb4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.218411516972762,
                        48.58938723867919,
                    ],
                    [
                        2.218587873483341,
                        48.589382099632104,
                    ],
                    [
                        2.21929893900858,
                        48.58943804136965,
                    ],
                    [
                        2.219710894959912,
                        48.58945603548539,
                    ],
                    [
                        2.220037900239427,
                        48.58944198653382,
                    ],
                    [
                        2.220308119576359,
                        48.58941676985485,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1189,
                    "end": 1330,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 141.0,
                "identifier": "69",
            },
        },
        {
            "id": "5b9656fe-475e-4186-bac3-811402125d8f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.268101259803128,
                        48.595453172626684,
                    ],
                    [
                        2.268328972528086,
                        48.595461768982595,
                    ],
                    [
                        2.268826950025991,
                        48.59544233975162,
                    ],
                    [
                        2.269961143770789,
                        48.59540972029014,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1330,
                    "end": 1467,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 137.0,
                "identifier": "70",
            },
        },
        {
            "id": "1fb1382b-acc7-4a6b-9f4a-de9cfee27018",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244567007277791,
                        48.59091446713234,
                    ],
                    [
                        2.244595059451889,
                        48.5908498763283,
                    ],
                    [
                        2.244621742678838,
                        48.590786176394204,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1467,
                    "end": 1482,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 15.0,
                "identifier": "82",
            },
        },
        {
            "id": "41f5b619-2c41-409f-a189-c619ef8c1463",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.24992923168966,
                        48.58737799396907,
                    ],
                    [
                        2.250307106215488,
                        48.587408261432564,
                    ],
                    [
                        2.250453380342712,
                        48.58741997754777,
                    ],
                    [
                        2.250855634529942,
                        48.58745219593709,
                    ],
                    [
                        2.251114313509566,
                        48.58747361439046,
                    ],
                    [
                        2.251634370899419,
                        48.587517366147935,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1482,
                    "end": 1609,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 127.0,
                "identifier": "85",
            },
        },
        {
            "id": "12ecc653-fcf6-4820-ad64-470c367bbe76",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.272927829586206,
                        48.59515710968319,
                    ],
                    [
                        2.272964292413546,
                        48.59516812712343,
                    ],
                    [
                        2.272989919542462,
                        48.59517817881344,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1609,
                    "end": 1614,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 5.0,
                "identifier": "88",
            },
        },
        {
            "id": "045f1385-61a1-4da8-874a-d5129b3d4b4f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.254489680129126,
                        48.590111647320676,
                    ],
                    [
                        2.256025772580531,
                        48.590607924105534,
                    ],
                    [
                        2.256096011319825,
                        48.59062725400289,
                    ],
                    [
                        2.257633013725252,
                        48.59106144530328,
                    ],
                    [
                        2.257870597170255,
                        48.59113759081121,
                    ],
                    [
                        2.257958336814422,
                        48.5911660243644,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1614,
                    "end": 1895,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 281.0,
                "identifier": "101",
            },
        },
        {
            "id": "e4217838-3aff-4571-85a3-019a6d37ab49",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227502345364066,
                        48.59038394004777,
                    ],
                    [
                        2.227699762059131,
                        48.59042210350482,
                    ],
                    [
                        2.228994247297491,
                        48.590733650587765,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1895,
                    "end": 2012,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 117.0,
                "identifier": "110",
            },
        },
        {
            "id": "e695c158-a5d6-4bfa-9c9e-5b749212b61c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.2626585117207,
                        48.59346753264736,
                    ],
                    [
                        2.26319854091862,
                        48.59402156227389,
                    ],
                    [
                        2.263527440420881,
                        48.5943589815197,
                    ],
                    [
                        2.263584825309199,
                        48.59442500461815,
                    ],
                    [
                        2.263665464761674,
                        48.594476778535366,
                    ],
                    [
                        2.263769358781453,
                        48.59451430322588,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2012,
                    "end": 2155,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 143.0,
                "identifier": "115",
            },
        },
        {
            "id": "bcfe63cd-6e62-4fb0-9081-fd400decdc42",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.238252460816153,
                        48.59115938575024,
                    ],
                    [
                        2.238549471634791,
                        48.59115678834373,
                    ],
                    [
                        2.238690748282967,
                        48.59113970069248,
                    ],
                    [
                        2.239327730410625,
                        48.591070908207,
                    ],
                    [
                        2.239745691098463,
                        48.59105018979785,
                    ],
                    [
                        2.240007382242809,
                        48.59105186099514,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2155,
                    "end": 2285,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 130.0,
                "identifier": "128",
            },
        },
        {
            "id": "188a15d3-f264-45cd-bb6b-c40ec8497ac6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.272815998691309,
                        48.5952023041872,
                    ],
                    [
                        2.27282017916184,
                        48.595194233675734,
                    ],
                    [
                        2.272836688735993,
                        48.595177242869134,
                    ],
                    [
                        2.272858572446654,
                        48.5951638831325,
                    ],
                    [
                        2.272884424321541,
                        48.595157744119184,
                    ],
                    [
                        2.272915625367478,
                        48.59515703513983,
                    ],
                    [
                        2.272927829586206,
                        48.59515710968319,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2285,
                    "end": 2296,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 11.0,
                "identifier": "129",
            },
        },
        {
            "id": "c8fee346-362c-4582-ba59-871e466dc1ac",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.252072967855186,
                        48.58861309886525,
                    ],
                    [
                        2.252162199402648,
                        48.58882145976953,
                    ],
                    [
                        2.252232480474539,
                        48.58893254818788,
                    ],
                    [
                        2.252304092029148,
                        48.58904544404637,
                    ],
                    [
                        2.252364117327363,
                        48.589115087823,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2296,
                    "end": 2356,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 60.0,
                "identifier": "144",
            },
        },
        {
            "id": "64a8d2fd-ca60-46fd-95ec-8efe0750f19e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.241028423738649,
                        48.591055677307864,
                    ],
                    [
                        2.241248016499596,
                        48.591061575168204,
                    ],
                    [
                        2.241781855714192,
                        48.591091964014645,
                    ],
                    [
                        2.242447209285567,
                        48.5912176421248,
                    ],
                    [
                        2.242970714367253,
                        48.59130643171166,
                    ],
                    [
                        2.243393046767975,
                        48.59135859323156,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2356,
                    "end": 2534,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 178.0,
                "identifier": "153",
            },
        },
        {
            "id": "aad53acb-212c-4182-bab1-360fa1d3b19b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.262097744337868,
                        48.59293961208504,
                    ],
                    [
                        2.26225338931049,
                        48.59305752015017,
                    ],
                    [
                        2.262359016053809,
                        48.593164323280675,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2557,
                    "end": 2589,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 32.0,
                "identifier": "157",
            },
        },
        {
            "id": "f80a8b84-7977-4818-87af-748440740e9d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.230987966507441,
                        48.59098673329258,
                    ],
                    [
                        2.23158324720736,
                        48.59108053466871,
                    ],
                    [
                        2.231800061224829,
                        48.59109092986846,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2589,
                    "end": 2650,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 61.0,
                "identifier": "166",
            },
        },
        {
            "id": "e8bc8b97-2e9c-49ac-b242-7f79f07fbae8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.267079533202802,
                        48.59529845854273,
                    ],
                    [
                        2.267242004769074,
                        48.59531745001087,
                    ],
                    [
                        2.26781027894929,
                        48.595410902923135,
                    ],
                    [
                        2.268094542494381,
                        48.59544863351858,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2650,
                    "end": 2727,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 77.0,
                "identifier": "168",
            },
        },
        {
            "id": "10bfea33-ac06-46c4-a253-0ccb5f148c55",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.231800061224829,
                        48.59109092986846,
                    ],
                    [
                        2.232057684580599,
                        48.59109259228999,
                    ],
                    [
                        2.233020250821476,
                        48.59110779439676,
                    ],
                    [
                        2.23330525587662,
                        48.59109163918851,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2727,
                    "end": 2838,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 111.0,
                "identifier": "171",
            },
        },
        {
            "id": "5691ef28-2d2f-4891-b38d-ad337177e2a5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.264026288555832,
                        48.59447001436469,
                    ],
                    [
                        2.264053282271756,
                        48.59447917688393,
                    ],
                    [
                        2.264080023233877,
                        48.59450632908383,
                    ],
                    [
                        2.264093330535626,
                        48.59452440260305,
                    ],
                    [
                        2.264106511469352,
                        48.594551470963594,
                    ],
                    [
                        2.264119692416701,
                        48.594578539322086,
                    ],
                    [
                        2.264119439666555,
                        48.594596529007816,
                    ],
                    [
                        2.264105626827692,
                        48.59461443486377,
                    ],
                    [
                        2.264105500450138,
                        48.5946234297064,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2838,
                    "end": 2858,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 20.0,
                "identifier": "172",
            },
        },
        {
            "id": "2551f045-1047-49e0-9d85-ee12573d3d73",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235382428748355,
                        48.59092508722974,
                    ],
                    [
                        2.236047611165768,
                        48.59087538435953,
                    ],
                    [
                        2.236223879142496,
                        48.590876515576234,
                    ],
                    [
                        2.236670804338019,
                        48.5909153653152,
                    ],
                    [
                        2.237036244598194,
                        48.59096268675853,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2858,
                    "end": 2981,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 123.0,
                "identifier": "175",
            },
        },
        {
            "id": "961f2d58-d343-4820-9fbc-c5b19d92ca64",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245082295523098,
                        48.58969162577583,
                    ],
                    [
                        2.245278339223788,
                        48.589261076304076,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2981,
                    "end": 3031,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 50.0,
                "identifier": "177",
            },
        },
        {
            "id": "98956d20-24b8-4175-ae60-a31f91dd79b6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.222677507175272,
                        48.58993422794307,
                    ],
                    [
                        2.223055953105312,
                        48.590017660219495,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3031,
                    "end": 3060,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 29.0,
                "identifier": "178",
            },
        },
        {
            "id": "0665eff3-c77f-45e2-9ff4-86288e5cf1c7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223055953105312,
                        48.590017660219495,
                    ],
                    [
                        2.223150331327859,
                        48.590054259054874,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3060,
                    "end": 3068,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 8.0,
                "identifier": "179",
            },
        },
        {
            "id": "4e24961a-8ce4-40f1-a32e-186648d0d8a5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244745472013664,
                        48.59048110863794,
                    ],
                    [
                        2.245026245404668,
                        48.5898172097828,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3068,
                    "end": 3145,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 77.0,
                "identifier": "183",
            },
        },
        {
            "id": "39b5e8dd-4c14-4a99-8ff0-677a839d07f1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245881951688872,
                        48.58783458786618,
                    ],
                    [
                        2.245937997634593,
                        48.587709003253174,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3145,
                    "end": 3160,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 15.0,
                "identifier": "184",
            },
        },
        {
            "id": "d5f1e656-5ec8-452c-b23c-1c69dc65f876",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245026245404668,
                        48.5898172097828,
                    ],
                    [
                        2.245082295523098,
                        48.58969162577583,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3160,
                    "end": 3175,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 15.0,
                "identifier": "190",
            },
        },
        {
            "id": "6f190c5d-2caf-459c-9c86-3221e5e2b946",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.271567609761538,
                        48.595352994134636,
                    ],
                    [
                        2.271798634957881,
                        48.595318425193184,
                    ],
                    [
                        2.271920802543982,
                        48.595310176869965,
                    ],
                    [
                        2.272042970091606,
                        48.59530192842131,
                    ],
                    [
                        2.272151702320987,
                        48.59528460208813,
                    ],
                    [
                        2.272260309499734,
                        48.59527627050179,
                    ],
                    [
                        2.272355481335445,
                        48.59525886108003,
                    ],
                    [
                        2.2724640884284,
                        48.59525052930758,
                    ],
                    [
                        2.272559135222309,
                        48.59524211456954,
                    ],
                    [
                        2.272640496809923,
                        48.59524261174534,
                    ],
                    [
                        2.272708547937443,
                        48.595225036321736,
                    ],
                    [
                        2.272762913871228,
                        48.59521637286306,
                    ],
                    [
                        2.27280384441629,
                        48.59519863169335,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3175,
                    "end": 3268,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 93.0,
                "identifier": "194",
            },
        },
        {
            "id": "d424a27c-f0a0-448e-89a2-9fd3540a84b1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.269953057738655,
                        48.595406072454715,
                    ],
                    [
                        2.27006154016236,
                        48.59540673771832,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3268,
                    "end": 3276,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 8.0,
                "identifier": "196",
            },
        },
        {
            "id": "ae5403d9-d2d7-454e-a361-e6e1689bdbbe",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.248710336183967,
                        48.587276750856155,
                    ],
                    [
                        2.249929283207346,
                        48.587374396016855,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3276,
                    "end": 3367,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 91.0,
                "identifier": "197",
            },
        },
        {
            "id": "51acec0a-a407-4afb-b81e-722828368276",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246949973397527,
                        48.58711269887999,
                    ],
                    [
                        2.247044621653368,
                        48.587131289096206,
                    ],
                    [
                        2.247125841122561,
                        48.587140798593346,
                    ],
                    [
                        2.248710336183967,
                        48.587276750856155,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3367,
                    "end": 3498,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 131.0,
                "identifier": "200",
            },
        },
        {
            "id": "8e7bbd8f-6465-40f3-b038-aba18e1295fd",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220488075110151,
                        48.58944313655342,
                    ],
                    [
                        2.220663401156132,
                        48.589507254643536,
                    ],
                    [
                        2.220933638614959,
                        48.58957199374922,
                    ],
                    [
                        2.221312346577803,
                        48.58963744193007,
                    ],
                    [
                        2.22174515683694,
                        48.589712238371284,
                    ],
                    [
                        2.222677507175272,
                        48.58993422794307,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3498,
                    "end": 3669,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 171.0,
                "identifier": "202",
            },
        },
        {
            "id": "2b1aba82-f1a8-4220-8495-77cd49d3f5d8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220298655284184,
                        48.58941490871408,
                    ],
                    [
                        2.220299190832285,
                        48.58937892938167,
                    ],
                    [
                        2.220299458606061,
                        48.589360939715135,
                    ],
                    [
                        2.220313285031424,
                        48.5893430388682,
                    ],
                    [
                        2.220327111447339,
                        48.58932513801943,
                    ],
                    [
                        2.220367921264103,
                        48.589316409631515,
                    ],
                    [
                        2.220422289710097,
                        48.58930777003707,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3669,
                    "end": 3687,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 18.0,
                "identifier": "206",
            },
        },
        {
            "id": "607f581c-bd37-4f53-b80e-f9e00447bac4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223150331327859,
                        48.590054259054874,
                    ],
                    [
                        2.223542204409086,
                        48.590146773103086,
                    ],
                    [
                        2.223799023130384,
                        48.590202422386575,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3687,
                    "end": 3738,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 51.0,
                "identifier": "208",
            },
        },
        {
            "id": "99d390ce-0f92-4e62-b821-8e521d4ae9f5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246303195641254,
                        48.58682973814864,
                    ],
                    [
                        2.246437482165626,
                        48.5869205454639,
                    ],
                    [
                        2.246558599179702,
                        48.58698428215279,
                    ],
                    [
                        2.246679845839838,
                        48.58703902383562,
                    ],
                    [
                        2.246801222083986,
                        48.58708477051316,
                    ],
                    [
                        2.246949973397527,
                        48.58711269887999,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3738,
                    "end": 3796,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 58.0,
                "identifier": "211",
            },
        },
        {
            "id": "eed4d1d8-6066-486d-b97c-200d1ce0f9ce",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245937997634593,
                        48.587709003253174,
                    ],
                    [
                        2.246148620588371,
                        48.58720657853302,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3796,
                    "end": 3854,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 58.0,
                "identifier": "218",
            },
        },
        {
            "id": "a1988745-d6fe-4864-9626-8db678484846",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260195851843144,
                        48.59202644314925,
                    ],
                    [
                        2.260667638143122,
                        48.592227278717225,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3854,
                    "end": 3895,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 41.0,
                "identifier": "219",
            },
        },
        {
            "id": "7a2c3a74-a6c5-4e99-a141-ebedb7daa03d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246148620588371,
                        48.58720657853302,
                    ],
                    [
                        2.246135709672301,
                        48.58716151826639,
                    ],
                    [
                        2.246136227452157,
                        48.58712553875356,
                    ],
                    [
                        2.246123187119717,
                        48.58708947336124,
                    ],
                    [
                        2.246083160054717,
                        48.587044241323014,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3895,
                    "end": 3914,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 19.0,
                "identifier": "229",
            },
        },
        {
            "id": "78ad24b4-94d7-41ab-9586-569222155563",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246031128522139,
                        48.58689098482598,
                    ],
                    [
                        2.246126552727344,
                        48.5868556064956,
                    ],
                    [
                        2.246303195641254,
                        48.58682973814864,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3914,
                    "end": 3935,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 21.0,
                "identifier": "230",
            },
        },
        {
            "id": "a0e2b74f-7149-463a-893d-371779b4b450",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.263768015418775,
                        48.59451339535466,
                    ],
                    [
                        2.263781954779235,
                        48.59448649469321,
                    ],
                    [
                        2.26382288779563,
                        48.594468756601024,
                    ],
                    [
                        2.26387738083015,
                        48.59445110235158,
                    ],
                    [
                        2.263918060967959,
                        48.594451353913506,
                    ],
                    [
                        2.263985734800781,
                        48.594460767996175,
                    ],
                    [
                        2.264026288555832,
                        48.59447001436469,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3935,
                    "end": 3958,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 23.0,
                "identifier": "238",
            },
        },
        {
            "id": "71e9328e-8016-48bc-82fa-0541ab7434a4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223799023130384,
                        48.590202422386575,
                    ],
                    [
                        2.224110211212378,
                        48.590249429841094,
                    ],
                    [
                        2.224489194381047,
                        48.59029687821658,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3958,
                    "end": 4010,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 52.0,
                "identifier": "239",
            },
        },
        {
            "id": "07448f4c-9b94-47d8-88bb-590e1e4e878c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260991288624389,
                        48.592355227591895,
                    ],
                    [
                        2.261409223236956,
                        48.592528738850014,
                    ],
                    [
                        2.261570923309089,
                        48.59260170722988,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4010,
                    "end": 4061,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 51.0,
                "identifier": "241",
            },
        },
        {
            "id": "07d5078a-3bf4-4e16-90db-74783dde69fc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251713825361156,
                        48.58774545663376,
                    ],
                    [
                        2.251752829966686,
                        48.58786264578665,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4061,
                    "end": 4074,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 13.0,
                "identifier": "244",
            },
        },
        {
            "id": "acbb94cc-ef6c-47ab-b35f-3255186d9343",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.261570923309089,
                        48.59260170722988,
                    ],
                    [
                        2.261705631451685,
                        48.592665512326796,
                    ],
                    [
                        2.2618535192402,
                        48.59275638591768,
                    ],
                    [
                        2.262095057751275,
                        48.59293779630262,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4074,
                    "end": 4128,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 54.0,
                "identifier": "247",
            },
        },
        {
            "id": "9f7b8966-e9e7-423b-8e78-e75e159d5893",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.228984795698721,
                        48.59073089065636,
                    ],
                    [
                        2.229079444114002,
                        48.59074949510348,
                    ],
                    [
                        2.229269005846819,
                        48.59076871410511,
                    ],
                    [
                        2.229648394300119,
                        48.59078916153527,
                    ],
                    [
                        2.229892060330787,
                        48.59081772538755,
                    ],
                    [
                        2.230987966507441,
                        48.59098673329258,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4128,
                    "end": 4279,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 151.0,
                "identifier": "253",
            },
        },
        {
            "id": "bc419ad1-9340-4a09-ad9b-8792512531d2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.252935019347687,
                        48.58958824676608,
                    ],
                    [
                        2.253123944366242,
                        48.58965240213131,
                    ],
                    [
                        2.253164364236825,
                        48.589670647103766,
                    ],
                    [
                        2.253474806342719,
                        48.58977154700518,
                    ],
                    [
                        2.253785249644546,
                        48.58987244608857,
                    ],
                    [
                        2.254486981149475,
                        48.59011073084893,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4279,
                    "end": 4407,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 128.0,
                "identifier": "291",
            },
        },
        {
            "id": "f55f4a1e-a7f0-4a7c-9c54-37f5c4d97e2d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.22724112239995,
                        48.5903507594389,
                    ],
                    [
                        2.227498344010716,
                        48.59037941623068,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4407,
                    "end": 4426,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 19.0,
                "identifier": "299",
            },
        },
        {
            "id": "07bf5ede-b853-488e-894d-64ae86195b99",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260923871886935,
                        48.59232782207868,
                    ],
                    [
                        2.260991288624389,
                        48.592355227591895,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4426,
                    "end": 4432,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 6.0,
                "identifier": "304",
            },
        },
        {
            "id": "102ec861-b197-463b-b569-7765f74e11f6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.237036244598194,
                        48.59096268675853,
                    ],
                    [
                        2.237442362854638,
                        48.591010267688475,
                    ],
                    [
                        2.237672344134869,
                        48.59104772357995,
                    ],
                    [
                        2.238240257579362,
                        48.59115930765166,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4432,
                    "end": 4524,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 92.0,
                "identifier": "319",
            },
        },
        {
            "id": "5acfc3c2-c7ca-4841-83f4-df8408bb5f80",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.258227005477598,
                        48.59124955945983,
                    ],
                    [
                        2.259696823314086,
                        48.5918344318746,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4524,
                    "end": 4650,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 126.0,
                "identifier": "320",
            },
        },
        {
            "id": "18fce93a-a56f-485c-b7e3-833d876ca660",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246083160054717,
                        48.587044241323014,
                    ],
                    [
                        2.246031128522139,
                        48.58689098482598,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4650,
                    "end": 4667,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 17.0,
                "identifier": "323",
            },
        },
        {
            "id": "af58b5ce-4d52-49fb-adb9-d927df52c124",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.224489194381047,
                        48.59029687821658,
                    ],
                    [
                        2.225003501262026,
                        48.59036319797126,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4667,
                    "end": 4706,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 39.0,
                "identifier": "330",
            },
        },
        {
            "id": "e102f235-0f61-404e-b5eb-3ef6bac5f123",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.266605175666583,
                        48.59527754571456,
                    ],
                    [
                        2.266781333185048,
                        48.59528762651613,
                    ],
                    [
                        2.266943930501376,
                        48.59529762355084,
                    ],
                    [
                        2.267079533202802,
                        48.59529845854273,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4706,
                    "end": 4741,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 35.0,
                "identifier": "333",
            },
        },
        {
            "id": "cf0f0b9b-5272-44a0-8f66-78d81588e565",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251752829966686,
                        48.58786264578665,
                    ],
                    [
                        2.251805264624431,
                        48.58798891502641,
                    ],
                    [
                        2.252067570134959,
                        48.58861126580979,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4741,
                    "end": 4827,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 86.0,
                "identifier": "339",
            },
        },
        {
            "id": "85e8f0ca-6aa1-4eb5-bf29-eed63680cbe4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.226305291013702,
                        48.59036267136327,
                    ],
                    [
                        2.22669889825379,
                        48.59033824217315,
                    ],
                    [
                        2.227011018903668,
                        48.59032227820591,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4827,
                    "end": 4879,
                },
            ],
            "properties": {
                "layer": "f9f67e4b-b3ac-466d-aa89-3e31601da395",
                "LONGUEUR": 52.0,
                "identifier": "341",
            },
        },
    ],
}

export const referentialMulhouse = {
    "type": "FeatureCollection",
    "features": [
        {
            "id": "e15a943f-d9d2-498c-baa6-a169b69d5df1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344920534197992,
                        47.754581347838524,
                    ],
                    [
                        7.34485641593329,
                        47.75482591204211,
                    ],
                    [
                        7.344790901527913,
                        47.755199114196266,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 0,
                    "end": 69,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 69.0,
                "identifier": "1568",
            },
        },
        {
            "id": "5db0ce2a-1af0-4792-b058-23dae41868ec",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342288147055242,
                        47.75802012616558,
                    ],
                    [
                        7.34187303606848,
                        47.75836582390663,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 69,
                    "end": 118,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 49.0,
                "identifier": "1569",
            },
        },
        {
            "id": "d1b620f6-c1d2-4e04-9764-592b5e15c657",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344368004977619,
                        47.75598709823548,
                    ],
                    [
                        7.344098436997749,
                        47.756496808461335,
                    ],
                    [
                        7.343952115562578,
                        47.75661383264504,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 118,
                    "end": 195,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 77.0,
                "identifier": "1658",
            },
        },
        {
            "id": "a2d917c7-cfe6-4b7b-994b-d019d177a5fa",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.343952115562578,
                        47.75661383264504,
                    ],
                    [
                        7.342968695799731,
                        47.757453848614446,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 195,
                    "end": 314,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 119.0,
                "identifier": "1659",
            },
        },
        {
            "id": "e0e0a8ed-ddd3-4038-b65a-ed8385a56145",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342968695799731,
                        47.757453848614446,
                    ],
                    [
                        7.342288147055242,
                        47.75802012616558,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 314,
                    "end": 395,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 81.0,
                "identifier": "1660",
            },
        },
        {
            "id": "6a607efc-eb4d-41c1-a847-9747c49c1905",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.34187303606848,
                        47.75836582390663,
                    ],
                    [
                        7.340770903108703,
                        47.759293033037764,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 395,
                    "end": 527,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 132.0,
                "identifier": "1661",
            },
        },
        {
            "id": "6f63e3d2-40be-4cd8-8e92-676a8615ffab",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.340770903108703,
                        47.759293033037764,
                    ],
                    [
                        7.339725616403063,
                        47.76016645798441,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 527,
                    "end": 652,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 125.0,
                "identifier": "1662",
            },
        },
        {
            "id": "e4d94560-0ce0-4d46-977b-f1b5544c04ae",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.339725616403063,
                        47.76016645798441,
                    ],
                    [
                        7.339492505724596,
                        47.76036608771381,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 652,
                    "end": 680,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 28.0,
                "identifier": "1663",
            },
        },
        {
            "id": "5799c5a4-26b5-493f-8c2a-742699b84a01",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344790901527913,
                        47.755199114196266,
                    ],
                    [
                        7.344368004977619,
                        47.75598709823548,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 680,
                    "end": 773,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 93.0,
                "identifier": "2700",
            },
        },
        {
            "id": "3789e6ae-b259-458a-9e27-c0038445f6a6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.326439423271951,
                        47.74517768694619,
                    ],
                    [
                        7.326331549283293,
                        47.74485014945082,
                    ],
                    [
                        7.326295585076954,
                        47.744508617282484,
                    ],
                    [
                        7.326250823138051,
                        47.744233525375826,
                    ],
                    [
                        7.326213036991714,
                        47.74407250046109,
                    ],
                    [
                        7.326049938091113,
                        47.74384568404797,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 1199,
                    "end": 1351,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 152.0,
                "identifier": "2884",
            },
        },
        {
            "id": "c94abaac-91f4-47ee-bc86-e78b96f45e49",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.325680226789658,
                        47.74249684148029,
                    ],
                    [
                        7.325808186683313,
                        47.74257521741366,
                    ],
                    [
                        7.325878145922379,
                        47.7426643941158,
                    ],
                    [
                        7.325946232489619,
                        47.743007105672135,
                    ],
                    [
                        7.326069505735718,
                        47.743615541379384,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 0,
                    "end": 131,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 131.0,
                "identifier": "1210",
            },
        },
        {
            "id": "9b028125-b858-4b98-aa48-8dde033a891c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.325680226789658,
                        47.74249684148029,
                    ],
                    [
                        7.325727932583368,
                        47.742403306069086,
                    ],
                    [
                        7.325719995952641,
                        47.74227912250192,
                    ],
                    [
                        7.325581132189773,
                        47.741633656309965,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 131,
                    "end": 228,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 97.0,
                "identifier": "1211",
            },
        },
        {
            "id": "58540c6b-9122-4e01-ac32-f1b5be36251d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.322189333717003,
                        47.735146740269414,
                    ],
                    [
                        7.322047183273413,
                        47.73483809215283,
                    ],
                    [
                        7.321815467068918,
                        47.73451657367407,
                    ],
                    [
                        7.321725342383214,
                        47.734422672102234,
                    ],
                    [
                        7.321493462809912,
                        47.733986756201496,
                    ],
                    [
                        7.321258983424199,
                        47.73357476671176,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 228,
                    "end": 417,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 189.0,
                "identifier": "1212",
            },
        },
        {
            "id": "0ef46169-3608-4b5f-81eb-f9581602c33c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.325581132189773,
                        47.741633656309965,
                    ],
                    [
                        7.325470639633682,
                        47.74116415707915,
                    ],
                    [
                        7.325428668224927,
                        47.74105627161243,
                    ],
                    [
                        7.325349342210902,
                        47.74092882865213,
                    ],
                    [
                        7.325080372462998,
                        47.74066141017737,
                    ],
                    [
                        7.324487261047791,
                        47.740198984339045,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 417,
                    "end": 601,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 184.0,
                "identifier": "2604",
            },
        },
        {
            "id": "2f75527c-a1ce-4aa0-ba80-ca9ddf13d53e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.324487261047791,
                        47.740198984339045,
                    ],
                    [
                        7.324379490183967,
                        47.74008885213679,
                    ],
                    [
                        7.323792148359107,
                        47.73948217134137,
                    ],
                    [
                        7.323314661097879,
                        47.738916030077874,
                    ],
                    [
                        7.322940302825788,
                        47.738319289211574,
                    ],
                    [
                        7.322751776818435,
                        47.737893381850036,
                    ],
                    [
                        7.322615371009769,
                        47.73745808667147,
                    ],
                    [
                        7.322511922854964,
                        47.73699754407106,
                    ],
                    [
                        7.322447223058766,
                        47.73593448321938,
                    ],
                    [
                        7.322343882400412,
                        47.73551209790858,
                    ],
                    [
                        7.322189333717003,
                        47.735146740269414,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 601,
                    "end": 1199,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 598.0,
                "identifier": "2605",
            },
        },
        {
            "id": "a810355c-df87-422d-811d-db9004c852f5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.311806809056158,
                        47.741494663932016,
                    ],
                    [
                        7.310957485995647,
                        47.74096907462762,
                    ],
                    [
                        7.310160772599192,
                        47.74060637171044,
                    ],
                    [
                        7.309324318264648,
                        47.74027964905127,
                    ],
                    [
                        7.308904642504877,
                        47.740116565458315,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE DU CLAIRON",
                    "start": 0,
                    "end": 267,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 267.0,
                "identifier": "2613",
            },
        },
        {
            "id": "c176e34c-b942-4f86-8071-fa087adccf6f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.308904642504877,
                        47.740116565458315,
                    ],
                    [
                        7.308719752684468,
                        47.74004942802562,
                    ],
                    [
                        7.307906814758471,
                        47.739659801086226,
                    ],
                    [
                        7.307663653444103,
                        47.73952880865416,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE DU CLAIRON",
                    "start": 267,
                    "end": 381,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 114.0,
                "identifier": "2614",
            },
        },
        {
            "id": "b84a7f88-b0e7-4902-865f-76a9082fe8bb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.346037345455459,
                        47.75817922448471,
                    ],
                    [
                        7.345059265337103,
                        47.758889812746354,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 0,
                    "end": 108,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 108.0,
                "identifier": "1025",
            },
        },
        {
            "id": "0eaab2cd-8df5-4548-a52c-831883576cca",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344072404198844,
                        47.75961153628935,
                    ],
                    [
                        7.343218199045567,
                        47.76024375611984,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 108,
                    "end": 203,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 95.0,
                "identifier": "1026",
            },
        },
        {
            "id": "9d9df68a-75ab-418c-b6a9-02f110f4d6ab",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.339642657877272,
                        47.761839431644624,
                    ],
                    [
                        7.338895794907415,
                        47.76214549563346,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 203,
                    "end": 268,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 65.0,
                "identifier": "1027",
            },
        },
        {
            "id": "5f5733d4-7e6d-4609-92db-a00bfcd0216e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.348493093724352,
                        47.756647999452895,
                    ],
                    [
                        7.347622772086281,
                        47.757056672472494,
                    ],
                    [
                        7.347395678038717,
                        47.757197911060295,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 268,
                    "end": 371,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 103.0,
                "identifier": "1417",
            },
        },
        {
            "id": "b86d28ae-e761-4975-88ef-e5e7916f87af",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.347395678038717,
                        47.757197911060295,
                    ],
                    [
                        7.346037345455459,
                        47.75817922448471,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 371,
                    "end": 520,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 149.0,
                "identifier": "1418",
            },
        },
        {
            "id": "45f1f122-aaf7-4b37-83dd-e6d8c14fb804",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.343218199045567,
                        47.76024375611984,
                    ],
                    [
                        7.343104533120027,
                        47.76032787951689,
                    ],
                    [
                        7.342936460292257,
                        47.7604897916884,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 520,
                    "end": 555,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 35.0,
                "identifier": "1419",
            },
        },
        {
            "id": "3e368519-a559-4d5c-8e32-a216ff45fe1d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342936460292257,
                        47.7604897916884,
                    ],
                    [
                        7.342689479836517,
                        47.76058846997534,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 555,
                    "end": 577,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 22.0,
                "identifier": "1420",
            },
        },
        {
            "id": "30a57103-3706-4b65-b63e-0cc89b19e21a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.34201514280675,
                        47.76087210471184,
                    ],
                    [
                        7.34039487131051,
                        47.761534339286136,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 577,
                    "end": 719,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 142.0,
                "identifier": "1421",
            },
        },
        {
            "id": "0eef1958-f706-4995-93a7-b58ba5489de0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.33816549336465,
                        47.76243782644783,
                    ],
                    [
                        7.337516373269059,
                        47.76270798867764,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 719,
                    "end": 776,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 57.0,
                "identifier": "1422",
            },
        },
        {
            "id": "e592d644-379d-4b7b-8a10-f466b19e2f08",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.337516373269059,
                        47.76270798867764,
                    ],
                    [
                        7.337239635993132,
                        47.76282336975625,
                    ],
                    [
                        7.337146695122533,
                        47.76287457391901,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 776,
                    "end": 809,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 33.0,
                "identifier": "1423",
            },
        },
        {
            "id": "916978e5-bc45-4c07-9ad7-3e4992603337",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.349585177747567,
                        47.75612705417145,
                    ],
                    [
                        7.348493093724352,
                        47.756647999452895,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 809,
                    "end": 909,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 100.0,
                "identifier": "3008",
            },
        },
        {
            "id": "8275bb80-508b-44d5-84f4-d718360b356e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.345059265337103,
                        47.758889812746354,
                    ],
                    [
                        7.344072404198844,
                        47.75961153628935,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 909,
                    "end": 1018,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 109.0,
                "identifier": "3009",
            },
        },
        {
            "id": "b598a964-7be8-447b-b424-7a406cd75ff4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342689479836517,
                        47.76058846997534,
                    ],
                    [
                        7.34201514280675,
                        47.76087210471184,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1018,
                    "end": 1078,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 60.0,
                "identifier": "3010",
            },
        },
        {
            "id": "e9a0fe9f-2b44-4367-9d38-b9120916c633",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.34039487131051,
                        47.761534339286136,
                    ],
                    [
                        7.339642657877272,
                        47.761839431644624,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1078,
                    "end": 1144,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 66.0,
                "identifier": "3011",
            },
        },
        {
            "id": "bc15963c-3d4e-4440-9044-b5239906ab02",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.338895794907415,
                        47.76214549563346,
                    ],
                    [
                        7.33816549336465,
                        47.76243782644783,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1144,
                    "end": 1208,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 64.0,
                "identifier": "3012",
            },
        },
        {
            "id": "f0e6fb88-db15-4e1a-b280-a7021ba2f061",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.337146695122533,
                        47.76287457391901,
                    ],
                    [
                        7.336851017719187,
                        47.76303836627644,
                    ],
                    [
                        7.335347119338508,
                        47.76371997024814,
                    ],
                    [
                        7.334951610572595,
                        47.76389469027365,
                    ],
                    [
                        7.334724593112584,
                        47.76399149344383,
                    ],
                    [
                        7.334588267515528,
                        47.764031233787215,
                    ],
                    [
                        7.334433625096522,
                        47.7640633177706,
                    ],
                    [
                        7.334259477037822,
                        47.76407350234038,
                    ],
                    [
                        7.334093921265368,
                        47.76405952312471,
                    ],
                    [
                        7.333926382316667,
                        47.76402178141677,
                    ],
                    [
                        7.332132757018695,
                        47.76334966903261,
                    ],
                    [
                        7.331924444837713,
                        47.76327895791489,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1208,
                    "end": 1661,
                },
            ],
            "properties": {
                "layer": "25146602-9f3b-4b93-9b54-aaa81dbbfa82",
                "LONGUEUR": 453.0,
                "identifier": "3013",
            },
        },
    ],
}

export const allReferentials = {
    "f9f67e4b-b3ac-466d-aa89-3e31601da395": referentialCD91Small,
    "25146602-9f3b-4b93-9b54-aaa81dbbfa82": referentialMulhouse,
}

