import MuiChip from "@mui/material/Chip"

import { PropTypes } from "@l2r-front/l2r-proptypes"

export const Chip = (props) => {

    const {
        label,
        color,
        variant,
        onDelete,
        ...chipProps
    } = props

    return <MuiChip
        label={label}
        color={color}
        variant={variant}
        onDelete={onDelete}
        {...chipProps}
    />
}

Chip.propTypes = {
    ...MuiChip.propTypes,
    label: PropTypes.string.isRequired,
    color: PropTypes.string,
    variant: PropTypes.string,
    onDelete: PropTypes.func,
}