import { useRoadQuery } from "../../../../../common/hooks/queries/useRoadQuery"
import { networkGradingsQueryKeys } from "./queryKeys"

export function useNetworkYearGrading(year, queryParameters, config = {}) {
    const url = "network_gradings/"

    const parameters = { ...queryParameters, year }
    const queryKey = [...networkGradingsQueryKeys["list"](parameters)]

    return useRoadQuery(
        url,
        queryKey,
        parameters,
        config,
    )
}