import {
    Typography,
    FormikButtonSubmit as UiFormikButtonSubmit,
    FormikTextField as UiFormikTextField,
    FormHelperText as UiFormHelperText,
    styled,
    styledForMobile,
} from "@l2r-front/l2r-ui"

export const Wrapper = styledForMobile("div")(({ theme, isMobile }) => ({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "100%",
    maxWidth: isMobile ? undefined : theme.spacing(100),
}))

export const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(9),
}))

export const SubmitWrapper = styledForMobile("div")(({ theme, isMobile }) => ({
    alignItems: "baseline",
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    marginTop: theme.spacing(5),
    position: isMobile ? "absolute" : "relative",
    bottom: isMobile ? theme.spacing(5) : 0,
    width: "100%",
}))

export const FormikButtonSubmit = styledForMobile(UiFormikButtonSubmit)(({ isMobile }) => ({
    width: isMobile ? "100%" : "fit-content",
}))

export const FormikTextField = styled(UiFormikTextField)(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    marginBottom: theme.spacing(5),
}))

export const FormHelperText = styled(UiFormHelperText)(({ theme }) => ({
    position: "relative",
    top: theme.spacing(-9),
}))