import { useCallback, useEffect, useMemo, useState } from "react"

import { useBlocker } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { AlertsDispatchContext, AlertsStateContext, initialContext } from "./AlertsContext.context"

export const AlertsContextProvider = (props) => {

    const {
        children,
    } = props

    const [displayAlert, setDisplayAlert] = useState(initialContext.displayAlert)
    const [blockingAlerts, setBlockingAlerts] = useState(initialContext.blockingAlerts)
    const [displaySnackbar, setDisplaySnackbar] = useState(initialContext.displaySnackbar)
    const { t } = useTranslation(I18N_NAMESPACE)

    const navigationBlocker = useBlocker(({ currentLocation, nextLocation }) => {
        const isSameLocation = currentLocation.pathname === nextLocation.pathname
            && currentLocation.search === nextLocation.search
        return blockingAlerts.length && !isSameLocation
    })

    const setAlert = useCallback((alert) => {
        setDisplayAlert({
            ...alert,
            callback: alert.callback,
            cancelText: alert.cancelText ? alert.cancelText : t(I18N_NAMESPACE, initialContext.displayAlert.cancelText),
            confirmText: alert.confirmText ? alert.confirmText : t(I18N_NAMESPACE, initialContext.displayAlert.confirmText),
            open: true,
        })
    }, [t])

    const addBlockingAlert = useCallback((alert) => {
        setBlockingAlerts(previousState => {
            const alertIndex = previousState.findIndex(existingAlert => existingAlert?.alertId === alert.alertId)
            if (alertIndex >= 0) {
                previousState[alertIndex] = alert
                return previousState
            } else {
                return [...previousState, alert]
            }
        })
    }, [])

    const cancelBlockingAlert = useCallback((alertId) => {
        setBlockingAlerts(previousState => {
            const alertIndex = previousState.findIndex(previousStateItem => {
                return previousStateItem?.alertId === alertId
            })

            if (alertIndex !== -1) {
                const newState = [...previousState]
                newState.splice(alertIndex, 1)
                return newState
            }
            return previousState
        })
    }, [])

    const closeAlert = useCallback(() => {
        setDisplayAlert(v => ({ ...v, open: false }))
    }, [])

    const closeAlertWithCallback = useCallback(() => {
        const callback = displayAlert.callback
        closeAlert()
        callback()
    }, [closeAlert, displayAlert])

    const setSnackbar = useCallback((snackbar) => {
        setDisplaySnackbar({
            ...snackbar,
            open: true,
        })
    }, [])

    const closeSnackbar = useCallback(() => {
        setDisplaySnackbar(previousSnackbar => ({
            ...previousSnackbar,
            open: false,
        }))
    }, [])

    const dispatchValue = useMemo(() => {
        return { addBlockingAlert, cancelBlockingAlert, setAlert, setBlockingAlerts, closeAlert, closeAlertWithCallback, closeSnackbar, setSnackbar }
    }, [addBlockingAlert, cancelBlockingAlert, setAlert, closeAlert, closeAlertWithCallback, closeSnackbar, setSnackbar])

    const values = useMemo(() => {
        return ({
            blockingAlerts,
            displayAlert,
            displaySnackbar,
        })
    }, [blockingAlerts, displayAlert, displaySnackbar])

    useEffect(() => {
        if (navigationBlocker?.state === "blocked" && blockingAlerts.length) {
            const currentBlockingAlert = blockingAlerts[blockingAlerts.length - 1]
            if (currentBlockingAlert) {
                const blockingAlert = {
                    ...currentBlockingAlert,
                    cancelCallback: navigationBlocker.reset,
                    callback: () => {
                        navigationBlocker.proceed()
                        cancelBlockingAlert(currentBlockingAlert.alertId)
                        if (currentBlockingAlert?.callback) {
                            currentBlockingAlert.callback()
                        }
                    },
                }
                return setAlert(blockingAlert)
            }
        }
    }, [blockingAlerts, cancelBlockingAlert, navigationBlocker, setAlert])

    return (
        <AlertsStateContext.Provider value={values}>
            <AlertsDispatchContext.Provider value={dispatchValue}>
                {children}
            </AlertsDispatchContext.Provider>
        </AlertsStateContext.Provider>
    )
}

AlertsContextProvider.propTypes = {
    children: PropTypes.node,
}
