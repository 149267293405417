import {
    BottomNavigation as UiBottomNavigation,
    BottomNavigationAction as UiBottomNavigationAction,
    Box as UiBox,
    SwipeableDrawer as UiSwipeableDrawer,
    styled,
} from "@l2r-front/l2r-ui"

import { BOTTOM_APPBAR_HEIGHT } from "../../constants/layout"

export const SwipeableDrawer = styled(UiSwipeableDrawer)(({ theme }) => ({
    paddingBottom: BOTTOM_APPBAR_HEIGHT,
    ".MuiBackdrop-root": {
        marginBottom: BOTTOM_APPBAR_HEIGHT,
        opacity: "0 !important",
    },
    zIndex: theme.zIndex.drawer + 9,
}))

export const Box = styled(UiBox)(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    borderRadius: theme.spacing(4, 4, 0, 0),
    width: "100%",
    height: "100%",
    overflowY: "auto",
    boxShadow: theme.shadows[28],
    paddingBottom: BOTTOM_APPBAR_HEIGHT,
}))

export const Wrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    width: "100%",
}))

export const Puller = styled("div")(({ theme, width }) => ({
    backgroundColor: theme.palette["objects/object-grey"].main,
    borderRadius: theme.spacing(1),
    height: 6,
    left: `calc(50% - ${width / 2}px)`,
    position: "absolute",
    top: 8,
    width: width,
}))

export const BottomNavigation = styled(UiBottomNavigation)(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    height: BOTTOM_APPBAR_HEIGHT,
    width: "100%",
    zIndex: theme.zIndex.drawer + 10,
    boxShadow: theme.shadows[28],
}))

export const BottomNavigationAction = styled(UiBottomNavigationAction)(({ theme }) => ({
    "&.Mui-selected": {
        color: theme.palette["cta-bg/cta-bg-primary"].main,
    },
}))