import { useEffect, useRef } from "react"

import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer"

export function PhotoSphereViewer(props) {

    const { imageUrl } = props

    const photoSphereRef = useRef(null)

    useEffect(() => {
        if (imageUrl) {
            photoSphereRef?.current?.setPanorama(imageUrl)
        }
    }, [imageUrl])

    return (
        <ReactPhotoSphereViewer
            ref={photoSphereRef}
            keyboard={false}
            width="100%"
            height="100%"
        ></ReactPhotoSphereViewer>
    )

}