export const allTaskTypes = [
    {
        "code": "PONTA",
        "parent": null,
        "acronym": "PONTA",
        "name": "Pontage",
        "impact": 10,
    },
    {
        "code": "PLR1",
        "parent": null,
        "acronym": "PLR 1",
        "name": "Béton bitumineux",
        "impact": 30,
    },
    {
        "code": "PLR2",
        "parent": null,
        "acronym": "PLR 2",
        "name": "Rechargement grave bitume",
        "impact": 30,
    },
    {
        "code": "PLS1",
        "parent": null,
        "acronym": "PLS 1",
        "name": "Rechargement grave bitume",
        "impact": 30,
    },
    {
        "code": "PL1",
        "parent": null,
        "acronym": "PL 1",
        "name": "Rechargement grave bitume",
        "impact": 30,
    },
    {
        "code": "BBSG6",
        "parent": null,
        "acronym": "BBSG6",
        "name": "Béton bitumineux semi-grenu 6cm",
        "impact": 30,
    },
    {
        "code": "FRAIS",
        "parent": null,
        "acronym": "FRAIS",
        "name": "Fraisage",
        "impact": 10,
    },
    {
        "code": "ZZZ10",
        "acronym": "AUL",
        "name": "Autre localisée",
        "impact": 10,
    },
    {
        "code": "ZZZ20",
        "acronym": "AUPL",
        "name": "Autre pleine largeur",
        "impact": 20,
    },
    {
        "code": "ZZZ30",
        "acronym": "AUPLR",
        "name": "Autre pleine largeur rabotage",
        "impact": 30,
    },
    {
        "code": "ZZZ40",
        "acronym": "AUPLS",
        "name": "Autre pleine largeur structurelle",
        "impact": 40,
    },
]

export const getTaskTypeByCode = (code) => allTaskTypes.find(taskType => taskType.code === code)