import { MobileAppBar } from "../../../../common/components/MobileAppBar"
import { AccountSettingsRouter } from "../../routers/AccountSettingsRouter"
import * as Styled from "./AccountSettingsMobileLayout.styled"

export const AccountSettingsMobileLayout = () => {

    return (
        <>
            <MobileAppBar />
            <Styled.ContentWrapper>
                <AccountSettingsRouter />
            </Styled.ContentWrapper>
        </>
    )
}