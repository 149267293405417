import { useMemo } from "react"

import * as turf from "@turf/turf"
import { Source } from "react-map-gl"

import { useImageCollectionStateContext } from "../../contexts/ImageCollectionContext"

export function ImageSelectedSource(props) {

    const {
        ...sourceProps
    } = props

    const { displayedImagePosition, imageLayerDisplayed } = useImageCollectionStateContext()

    const data = useMemo(() => {
        if (displayedImagePosition) {
            const imagePoint = turf.point([displayedImagePosition.lng, displayedImagePosition.lat])
            const collection = turf.featureCollection([imagePoint])
            return collection
        }

        return null
    }, [displayedImagePosition])

    if (!displayedImagePosition || !imageLayerDisplayed) {
        return null
    }

    return (
        <Source {...sourceProps} type="geojson" data={data} tolerance={.001} />
    )
}

ImageSelectedSource.propTypes = Source.propTypes
