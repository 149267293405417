import {
    Button as UiButton,
    FormikAutoCompleteWithChips,
    styled,
} from "@l2r-front/l2r-ui"

import { IncidentTypeFilter as L2rIncidentTypeFilter } from "../../components/IncidentTypeFilter"
import { ReportStatusFilter as L2rReportStatusFilter } from "../../components/ReportStatusFilter"
import { HoursFilter as L2rHoursFilter } from "../../containers/HoursFilter"
import { IncidentPublicationToggleGroup as L2RIncidentPublicationToggleGroup } from "../../containers/IncidentPublicationToggleGroup"
import { PeriodFilter as L2rPeriodFilter } from "../../containers/PeriodFilter"

export const HoursFilter = styled(L2rHoursFilter)(({ theme }) => ({
    marginRight: theme.spacing(2),
}))

export const PeriodFilter = styled(L2rPeriodFilter)(({ theme }) => ({
    marginRight: theme.spacing(2),
}))

export const Button = styled(UiButton)({
    textDecoration: "underline",
    textTransform: "none",
    "&:hover": {
        textDecoration: "underline",
    },
})

export const SubmitButton = styled(UiButton)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    textTransform: "capitalize",
}))

export const ButtonsWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    paddingBottom: theme.spacing(10),
}))

export const Wrapper = styled("div")({
    display: "flex",
    marginLeft: "auto",
})

export const Line = styled("hr", { shouldForwardProp: false })(({ small, theme }) => ({
    border: `1px solid ${theme.palette["objects/object-grey"].main}`,
    width: "100%",
    marginTop: theme.spacing(small ? 3 : 7),
    marginBottom: theme.spacing(small ? 2 : 5),
}))

export const InputsWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing(2),
}))

export const IncidentPublicationToggleGroup = styled(L2RIncidentPublicationToggleGroup, {
    shouldForwardProp: (prop) => {
        return prop !== "small"
    },
})(({ small, theme }) => ({
    marginTop: theme.spacing(small ? 2 : 4),
}))

export const ReportStatusFilter = styled(L2rReportStatusFilter)({
    textAlign: "left",
    width: "100%",
})

export const IncidentTypeFilter = styled(L2rIncidentTypeFilter)({
    textAlign: "left",
    width: "100%",
})

export const TagFilter = styled(FormikAutoCompleteWithChips)(({ theme }) => ({
    width: "100%",
    backgroundColor: theme.palette["objects/object-lightgrey"].main,

    "& .MuiAutocomplete-popupIndicator": {
        transform: "none",
    },
}))

export const DateWrapper = styled("div")({
    display: "flex",
    justifyContent: "space-between",
})