import { useCallback } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { AlternateEmailRoundedIcon, LockRoundedIcon, PersonRoundedIcon } from "@l2r-front/l2r-icons"
import { Sidebar, SidebarContent } from "@l2r-front/l2r-ui"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { AccountSettingsMenuItem } from "../../../../common/components/AccountSettingsMenuItem/AccountSettingsMenuItem"
import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import * as Styled from "./AccountSettingsSidebar.styled"

export const AccountSettingsSidebar = () => {
    const { t } = useTranslation(I18N_NAMESPACE)

    const navigate = useNavigateWithSearchParams()

    const navigateBack = useCallback(() => {
        navigate("..")
    }, [navigate])

    return (
        <Sidebar>
            <SidebarContent
                id="sidebar-accountSettings"
                title={t(I18N_NAMESPACE, "containers.accountMenu.settings")}
                onBack={navigateBack}>
                <Styled.Content>
                    <Styled.AccountSettingsBlock>
                        <Styled.AccountSettingsCategory variant="Regular">{t(I18N_NAMESPACE, "pages.accountSettings.profile.label")}</Styled.AccountSettingsCategory>
                        <AccountSettingsMenuItem
                            icon={PersonRoundedIcon}
                            label={t(I18N_NAMESPACE, "pages.accountSettings.profile.accountInfoLabel")}
                            to=""
                        />
                        <AccountSettingsMenuItem
                            icon={AlternateEmailRoundedIcon}
                            label={t(I18N_NAMESPACE, "pages.accountSettings.profile.accountEmailLabel")}
                            to="email"
                        />
                        <AccountSettingsMenuItem
                            icon={LockRoundedIcon}
                            label={t(I18N_NAMESPACE, "pages.accountSettings.profile.accountPasswordLabel")}
                            to="password"
                        />
                    </Styled.AccountSettingsBlock>
                </Styled.Content>
            </SidebarContent>
        </Sidebar>
    )
}