import { useCallback, useState, useMemo, useEffect } from "react"

import { useMatch } from "react-router-dom"

import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"
import { useCrossTabState } from "@l2r-front/l2r-utils"

import { IMAGE_COLLECTION_TYPE_STREETVIEW } from "../../constants/imageCollectionsTypes"
import { useLatestImageCollection } from "../../hooks/queries/imageCollection/useLatestImageCollection"
import { ImageCollectionDispatchContext, ImageCollectionStateContext, initialState } from "./ImageCollectionContext.context"

export const ImageCollectionContextProvider = (props) => {
    const { children } = props
    const { selectedNetwork } = useNetworksStateContext()
    const isMobile = useIsMobileDevice()
    const matchRoadView = useMatch(":slug/:appId/:moduleId/:road/*")
    const matchPopupView = useMatch(":slug/image_viewer")

    const [selectedImageCollectionRoad, setSelectedImageCollectionRoad] = useCrossTabState("selectedImageCollectionRoad", null)
    const [selectedImageCollectionType, setSelectedImageCollectionType] = useCrossTabState("selectedImageCollectionType", IMAGE_COLLECTION_TYPE_STREETVIEW)
    const [selectedImageCurrentYear, setImageCollectionCurrentYear] = useCrossTabState("selectedImageCurrentYear", null)
    const [triggeringFeatureId, setTriggeringFeatureId] = useCrossTabState("triggeringFeatureId", null)
    const [displayedImagePosition, setDisplayedImagePosition] = useCrossTabState("displayedImagePosition", null)
    const [imageCollectionState, setImageCollectionState] = useState(initialState)

    const {
        data: imageCollection,
        isLoading: isLoadingImageCollections,
    } = useLatestImageCollection(selectedNetwork?.slug, selectedImageCurrentYear)

    const setImageLayerDisplayed = useCallback((layerDisplayed) => {
        setImageCollectionState(value => ({
            ...value,
            imageLayerDisplayed: layerDisplayed,
        }))
    }, [setImageCollectionState])

    const setImageIdClicked = useCallback((imageId) => {
        setImageCollectionState(value => ({
            ...value,
            imageIdClicked: imageId,
        }))
    }, [setImageCollectionState])

    const setSegmentIdClicked = useCallback((segmentId) => {
        setImageCollectionState(value => ({
            ...value,
            segmentIdClicked: segmentId,
        }))
    }, [setImageCollectionState])

    const setImagePopupRef = useCallback((popupRef) => {
        setImageCollectionState(value => ({
            ...value,
            imagePopupRef: popupRef,
        }))
    }, [setImageCollectionState])

    const setSegmentFeatureIdClicked = useCallback((segmentFeatureId) => {
        setSegmentIdClicked(segmentFeatureId)
    }, [setSegmentIdClicked])

    const isImageCollectionAvailable = useCallback(() => {
        return !!imageCollection && imageCollection.type !== IMAGE_COLLECTION_TYPE_STREETVIEW
    }, [imageCollection])

    useEffect(() => {
        setImageLayerDisplayed(false)
        setSelectedImageCollectionType(IMAGE_COLLECTION_TYPE_STREETVIEW)
    }, [selectedNetwork?.slug, setImageLayerDisplayed, setSelectedImageCollectionType])

    useEffect(() => {
        setSelectedImageCollectionType(imageCollection ? imageCollection.type : IMAGE_COLLECTION_TYPE_STREETVIEW)
    }, [imageCollection, setSelectedImageCollectionType])

    useEffect(() => {
        const frame = requestAnimationFrame(() => {
            if (imageCollectionState.imageIdClicked) {
                setTriggeringFeatureId(imageCollectionState.imageIdClicked)
            } else if (imageCollectionState.segmentIdClicked) {
                setTriggeringFeatureId(imageCollectionState.segmentIdClicked)
            }
            setImageIdClicked(null)
            setSegmentIdClicked(null)
            return cancelAnimationFrame(frame)
        })
    }, [imageCollectionState.imageIdClicked, setImageIdClicked, imageCollectionState.segmentIdClicked, setSegmentIdClicked, setTriggeringFeatureId])

    const resetImageCollectionType = useCallback(() => {
        const imageCollectionType = imageCollection?.type ? imageCollection?.type : IMAGE_COLLECTION_TYPE_STREETVIEW
        setSelectedImageCollectionType(imageCollectionType)
    }, [setSelectedImageCollectionType, imageCollection?.type])

    const resetImageCollectionDisplay = useCallback(() => {
        setImageLayerDisplayed(false)
        resetImageCollectionType()
        setDisplayedImagePosition(null)
    }, [setImageLayerDisplayed, setDisplayedImagePosition, resetImageCollectionType])

    const openImageViewer = useCallback(() => {
        if (!isMobile) {
            if (!imageCollectionState.imagePopupRef || imageCollectionState.imagePopupRef.closed) {
                const params = "popup,width=640,height=360,left=100,top=100"
                const imagePopupRef = window.open(
                    `/${selectedNetwork.slug}/image_viewer?device=${isMobile ? "mobile" : "desktop"}`,
                    "imageViewer",
                    params)

                setImagePopupRef(imagePopupRef)
            } else if (imageCollectionState.imagePopupRef) {
                imageCollectionState.imagePopupRef.focus()
            }
        } else {
            const event = new CustomEvent("imageClick")
            document.dispatchEvent(event)
        }
    }, [isMobile, selectedNetwork, imageCollectionState.imagePopupRef, setImagePopupRef])

    useEffect(() => {
        const handleChildWindowClosed = (event) => {
            if (event.data === "childWindowClosed") {
                resetImageCollectionDisplay()
            }
        }

        window.addEventListener("message", handleChildWindowClosed)

        return () => {
            window.removeEventListener("message", handleChildWindowClosed)
        }
    }, [resetImageCollectionDisplay])

    const onImageViewerButtonClicked = useCallback(() => {
        openImageViewer()
        setImageLayerDisplayed(true)
    }, [openImageViewer, setImageLayerDisplayed])

    const onSwitchImageLayer = useCallback((event) => {
        setImageLayerDisplayed(event.target.checked)
    }, [setImageLayerDisplayed])

    const setImageFeatureIdClicked = useCallback((imageFeatureId) => {
        setImageIdClicked(imageFeatureId)
        if (imageFeatureId) {
            openImageViewer()
        }
    }, [setImageIdClicked, openImageViewer])

    useEffect(() => {
        const road = decodeURIComponent(matchRoadView?.params.road)
        if (road && !matchPopupView?.params.slug) {
            setSelectedImageCollectionRoad(road)
        }
    }, [matchRoadView?.params.road, matchPopupView?.params.slug, setSelectedImageCollectionRoad])

    const stateValue = useMemo(() => {
        return {
            displayedImagePosition: displayedImagePosition,
            triggeringFeatureId: triggeringFeatureId,
            selectedImageCollectionType: selectedImageCollectionType,
            selectedImageCollectionRoad: selectedImageCollectionRoad,
            imageLayerDisplayed: imageCollectionState.imageLayerDisplayed,
            imageCollection: imageCollection,
            isLoadingImageCollections,
        }
    }, [
        displayedImagePosition,
        triggeringFeatureId,
        selectedImageCollectionType,
        selectedImageCollectionRoad,
        imageCollectionState.imageLayerDisplayed,
        imageCollection,
        isLoadingImageCollections,
    ])

    const dispatchValue = useMemo(() => {
        return {
            onOpenImageViewer: matchRoadView?.params.road ? onImageViewerButtonClicked : null,
            onSwitchImageLayer: matchRoadView?.params.road ? onSwitchImageLayer : null,
            setImageLayerDisplayed,
            setDisplayedImagePosition,
            resetImageCollectionType,
            resetImageCollectionDisplay,
            setTriggeringFeatureId,
            setSelectedImageCollectionType,
            setImageFeatureIdClicked,
            setSegmentFeatureIdClicked,
            isImageCollectionAvailable,
            setImageCollectionCurrentYear,
        }
    }, [
        matchRoadView?.params.road,
        onImageViewerButtonClicked,
        onSwitchImageLayer,
        setImageLayerDisplayed,
        setDisplayedImagePosition,
        resetImageCollectionType,
        resetImageCollectionDisplay,
        setSelectedImageCollectionType,
        setTriggeringFeatureId,
        setImageFeatureIdClicked,
        setSegmentFeatureIdClicked,
        isImageCollectionAvailable,
        setImageCollectionCurrentYear,
    ])

    return (
        <ImageCollectionStateContext.Provider value={stateValue}>
            <ImageCollectionDispatchContext.Provider value={dispatchValue}>
                {children}
            </ImageCollectionDispatchContext.Provider>
        </ImageCollectionStateContext.Provider>
    )
}

ImageCollectionContextProvider.propTypes = {
    children: PropTypes.node,
}