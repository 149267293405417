import { LayersIcon } from "@l2r-front/l2r-icons"
import { Card, Skeleton, styled } from "@l2r-front/l2r-ui"

export const RoadInfoItemSkeleton = styled(Skeleton)(({ theme }) => ({
    height: 70,
    padding: theme.spacing(2, 3),
    transform: "scale(1)",
    width: 170,
}))

export const LayerWrapper = styled(Card)(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2, 3),
    opacity: 1,
}))

export const Icon = styled(LayersIcon)(({ theme }) => ({
    marginRight: theme.spacing(2),
}))

export const LabelWrapper = styled("div")({
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    width: "100%",
})

export const Wrapper = styled(LabelWrapper)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}))