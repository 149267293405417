import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Typography } from "@l2r-front/l2r-ui"


import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import * as Styled from "./LayerIndicator.styled"

export function LayerIndicator(props) {
    const {
        className,
        text,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    if (!text) {
        return <Styled.RoadInfoItemSkeleton className={className} />
    }

    return <Styled.LayerWrapper className={className} id="layer-indicator">
        <Styled.Wrapper>
            <Styled.Icon color="cta-bg/cta-bg-primary" />
            <Typography variant="Small accent">
                {t(I18N_NAMESPACE, "containers.roadwayLayerIndicator.title")}
            </Typography>
        </Styled.Wrapper>
        <Styled.LabelWrapper>
            <Typography variant="Regular">
                {text}
            </Typography>
        </Styled.LabelWrapper>
    </Styled.LayerWrapper>
}

LayerIndicator.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
}