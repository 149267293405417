import { useTranslation } from "@l2r-front/l2r-i18n"
import { RestoreIcon, HandIcon, PaintIcon, RubberIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { CTAButton, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"
import * as Styled from "./StabiloControls.styled"

export const FREE_ROAM_MODE = "free_roam"
export const PAINT_MODE = "stabilo"
export const RUBBER_MODE = "rubber"

export function StabiloControls(props) {
    const {
        mode,
        onChange,
        onRestore,
        restorable,
    } = props

    const isMobile = useIsMobileDevice()
    const { t } = useTranslation(I18N_NAMESPACE)

    return <Styled.Container>
        <Styled.ModesToggle
            id="linear-edit-modes"
            exclusive
            value={mode}
            onChange={onChange}
            options={
                [
                    {
                        value: FREE_ROAM_MODE,
                        component: <HandIcon />,
                    },
                    {
                        value: PAINT_MODE,
                        component: <PaintIcon />,
                    },
                    {
                        value: RUBBER_MODE,
                        component: <RubberIcon />,
                    },
                ]
            } />
        {!isMobile ? <CTAButton id="linear-edit-reset" disabled={!restorable} variant="outlined" startIcon={<RestoreIcon />} onClick={onRestore}>
            {t(I18N_NAMESPACE, "components.stabiloControls.restore")}
        </CTAButton> :
            <Styled.RestoreButton disabled={!restorable} onClick={onRestore} >
                <RestoreIcon />
            </Styled.RestoreButton>
        }
    </Styled.Container >
}

StabiloControls.propTypes = {
    mode: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onRestore: PropTypes.func.isRequired,
    restorable: PropTypes.bool,
}

StabiloControls.defautlProps = {
    restorable: false,
}