import { impactsOrder } from "../constants/impacts"

export function sortTasks(tasks, taskTypes) {
    return tasks?.filter(task => !!task.taskType)?.sort((taskA, taskB) => {
        const taskTypeA = taskTypes?.find(taskType => taskType.code === taskA.taskType)
        const taskTypeB = taskTypes?.find(taskType => taskType.code === taskB.taskType)
        if ((typeof taskTypeA?.impact !== "number") || (typeof taskTypeB?.impact !== "number")) {
            return 0
        }
        if (impactsOrder[taskTypeB.impact] !== impactsOrder[taskTypeA.impact]) {
            return impactsOrder[taskTypeB.impact] - impactsOrder[taskTypeA.impact]
        }
        return taskA.taskType?.localeCompare(taskB.taskType)
    })
}

export const constructTechniqueAcronym = (technique, taskTypes) => {
    if (technique && taskTypes) {
        const taskCodes = technique.code.split("_").map(taskCode => ({ taskType: taskCode }))
        const sortedTasksCodes = sortTasks(taskCodes, taskTypes).map(task => task.taskType)

        const taskAcronyms = sortedTasksCodes.map(code => taskTypes?.find(type => type.code === code).acronym)
        return taskAcronyms.join(" + ")
    }
    return null
}

export const computeRoadworkLength = (linearLocation) => {
    return linearLocation?.reduce((lengthAcc, segment) => {
        return lengthAcc + Math.abs(segment.end - segment.start)
    }, 0)
}

export function sortTasksById(tasks, orderedIds) {
    return tasks?.sort((taskA, taskB) => {
        const taskAIndex = orderedIds.findIndex(id => id === taskA.uuid)
        const taskBIndex = orderedIds.findIndex(id => id === taskB.uuid)

        return taskAIndex - taskBIndex
    })
}