import {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
    useState,
} from "react"

import lodash from "lodash"
import { createPortal } from "react-dom"
import { useControl } from "react-map-gl"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useTheme } from "@l2r-front/l2r-ui"

import { FREE_ROAM_MODE, StabiloControls } from "../../components/StabiloControls"
import { SELECTED_LINE_LAYER_SIZE } from "../../constants/layers"
import { getLineLayerConfig, getInterpolatedLineLayerWidth } from "../../utils/layersConfigs"
import { StabiloDraw } from "../StabiloDraw"

export const DrawControl = forwardRef((props, ref) => {
    const {
        position,
        onAdded,
        onUpdate,
        currentMode,
        isDirty,
        onModeChange,
        onRestore,
        ...rest
    } = props

    const [container, setContainer] = useState(null)
    const theme = useTheme()

    const paintedLayerConfig = useMemo(() => {
        return lodash.merge(
            getLineLayerConfig(),
            {
                "paint": {
                    "line-color": theme.palette["colors/vert logiroad/400"].main,
                    "line-width": getInterpolatedLineLayerWidth(SELECTED_LINE_LAYER_SIZE),
                },
            },
        )
    }, [theme])

    const handleOnAdded = useCallback((parent) => {
        setContainer(parent)
        onAdded()
    }, [onAdded, setContainer])

    const drawRef = useControl(
        () =>
            new StabiloDraw({
                props: {
                    styles: [
                        {
                            "id": "painted-line",
                            ...paintedLayerConfig,
                        },
                    ],
                    ...rest,
                },
                onAdded: handleOnAdded,
            }),
        ({ map }) => {
            onUpdate && map.on("draw.paint_updated", onUpdate)
        },
        ({ map }) => {
            onUpdate && map.off("draw.paint_updated", onUpdate)
        },
        {
            position,
        },
    )

    useImperativeHandle(ref, () => drawRef, [drawRef])

    return (
        <div>
            {
                container && createPortal(
                    <StabiloControls
                        mode={currentMode}
                        onChange={onModeChange}
                        onRestore={onRestore}
                        restorable={isDirty}
                    />,
                    container)
            }
        </div>
    )
})

DrawControl.propTypes = {
    position: PropTypes.string,
    displayControlsDefault: PropTypes.bool,
    defaultMode: PropTypes.string,
    currentMode: PropTypes.string.isRequired,
    modes: PropTypes.object,
    isDirty: PropTypes.bool,
    onAdded: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onModeChange: PropTypes.func.isRequired,
    onRestore: PropTypes.func.isRequired,
}

DrawControl.defaultProps = {
    position: "top-left",
    displayControlsDefault: false,
    defaultMode: FREE_ROAM_MODE,
    isDirty: false,
}