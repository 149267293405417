import {
    styled,
    KpiCard as KpiCardUi,
    KpiError as KpiErrorUi,
    KpiSkeleton as KpiSkeletonUi,
    styledForMobile,
    Typography,
} from "@l2r-front/l2r-ui"

export const KpiSkeleton = styled(KpiSkeletonUi)(() => ({
    width: "100%",
    height: "100%",
}))

export const KpiError = styled(KpiErrorUi)(() => ({
    width: "100%",
    height: "260px !important",
    backroundColor: "blue",
}))

export const ChartContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
})

export const Title = styledForMobile(Typography)(({ theme, isMobile }) => ({
    marginRight: "auto",
    marginBottom: !isMobile && theme.spacing(6),
    textAlign: "left",
}))

export const KpiCard = styled(KpiCardUi)(({ theme }) => ({
    padding: theme.spacing(8),
}))