import { styledForMobile } from "@l2r-front/l2r-ui"

export const Content = styledForMobile("div")(({ theme, isMobile }) => ({
    display: "flex",
    flexDirection: "column",
    paddingTop: isMobile ? theme.spacing(1) : theme.spacing(13),
    paddingLeft: isMobile ? theme.spacing(1) : "14%",
    paddingRight: theme.spacing(1),
    height: "100%",
    width: "100%",
}))