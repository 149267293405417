import { useCallback, useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { ChipList } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { FILTERS } from "../../constants/filters"
import { useRoadworksDispatchContext, useRoadworksStateContext } from "../../contexts/RoadworksContext"
import { useGetProjectTechniqueCodes } from "../../hooks/queries/useGetProjectTechniqueCodes"
import { roadworksFiltersConfig } from "../../utils/roadworksFiltersConfig"

export function TechniquesFilterList(props) {

    const {
        enabledFilters,
        ...otherProps
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const { filters } = useRoadworksStateContext()
    const { applyFilters } = useRoadworksDispatchContext()

    const { data: techniqueCodes, isLoading: isLoadingTechniques, isLoading: isErrorTechniques } = useGetProjectTechniqueCodes()

    const displayedFilters = useMemo(() => {
        if (filters && techniqueCodes) {
            const config = {
                techniqueCodes: techniqueCodes,
                t: t,
                namespace: I18N_NAMESPACE,
            }
            return Object.entries(filters).map(([filterKey, filterValues]) => {
                if (!enabledFilters.includes(filterKey)) {
                    return null
                }
                return roadworksFiltersConfig[filterKey].getValueList(filterKey, filterValues, config)
            }).flat().filter(Boolean)
        }
        return null
    }, [filters, techniqueCodes, enabledFilters, t])

    const deleteFilter = useCallback((filterIndex) => {
        const filter = displayedFilters[filterIndex]
        const filterKey = filter.key
        const filterValue = [...filters[filterKey]]
        const newFilterValue = roadworksFiltersConfig[filterKey].removeValue(filterValue, filter)
        applyFilters({
            ...filters,
            [filterKey]: newFilterValue,
        })
    }, [displayedFilters, filters, applyFilters])

    if (displayedFilters && !isLoadingTechniques && !isErrorTechniques) {
        return <ChipList
            {...otherProps}
            onDelete={deleteFilter}
            values={displayedFilters}
            variant="filled"
        />
    }
    return null
}

TechniquesFilterList.defaultProps = {
    enabledFilters: FILTERS,
}

TechniquesFilterList.propTypes = {
    enabledFilters: PropTypes.arrayOf(PropTypes.oneOf(FILTERS)),
}