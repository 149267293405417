import { getYear } from "date-fns"

import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { useRoadQuery } from "../../../../../common/hooks/queries/useRoadQuery"
import { networkGradingsQueryKeys } from "./queryKeys"

export function useMultiYearGrading(config = {}) {
    const { selectedNetwork } = useNetworksStateContext()
    const url = `projects/${selectedNetwork?.slug}/`

    const queryKey = [...networkGradingsQueryKeys["list"](), "multiGradingsYears"]

    const { data, isLoading, isError } = useRoadQuery(
        url,
        [queryKey],
        { ...config, enabled: !!selectedNetwork?.slug },
    )

    const nowYear = getYear(new Date())
    const resultData = data?.networkGradingsYears
        .filter(year => nowYear <= year)
        .sort()

    return ({
        data: resultData,
        isError,
        isLoading,
    })
}