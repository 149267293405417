import {
    Toolbar as UiToolbar,
    AppBar as UiAppBar,
    styled,
} from "@l2r-front/l2r-ui"

import { APPBAR_HEIGHT } from "../../constants/layout"

export const Toolbar = styled(UiToolbar)({
    justifyContent: "space-between",
    height: "100%",
})

export const AppBar = styled(UiAppBar)(({ theme }) => ({
    background: theme.palette.common.white,
    height: APPBAR_HEIGHT,
    paddingLeft: theme.spacing(9),
    paddingRight: theme.spacing(9),
    "&> * ": {
        padding: "0 !important",
    },
}))