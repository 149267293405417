import { PropTypes } from "@l2r-front/l2r-proptypes"


export const reportStep = PropTypes.shape({
    title: PropTypes.string.isRequired,
    toggle: PropTypes.shape({
        active: PropTypes.string,
        title: PropTypes.string.isRequired,
    }),
    type: PropTypes.string.isRequired,
})
export const reportSteps = PropTypes.arrayOf(reportStep)