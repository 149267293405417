import { useMemo } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./ChipList.styled"

export function ChipListSkeleton(props) {
    const { tagsCount } = props
    const tagsSkeleton = useMemo(() => {
        return tagsCount ? new Array(tagsCount).fill(true) : null
    }, [tagsCount])

    return <Styled.ChipSkeletonsWrapper>
        {
            tagsSkeleton?.map((_, index) => <Styled.ChipSkeleton
                variant="Regular"
                key={`detail-skeleton-${index}`}
            />)
        }
    </Styled.ChipSkeletonsWrapper>
}

ChipListSkeleton.propTypes = {
    tagsCount: PropTypes.number.isRequired,
}