import { useMutation } from "@tanstack/react-query"

import { useAlertsDispatchContext } from "../contexts/AlertsContext"
import { onMutationSuccessFeedback, onMutationErrorFeedback } from "../utils/mutationFeedbacks"

async function mutationFn(
    axiosFn,
    url,
    config,
    values,
) {
    const headers = config?.headers
    const response = await axiosFn(url, values, headers)
    return response.data
}

export function useL2rMutation(
    url,
    axiosFn,
    _,
    config = {},
) {
    const { setSnackbar } = useAlertsDispatchContext()

    return useMutation({
        mutationFn: (value) => mutationFn(axiosFn, url, config, value),
        ...config,
        onSuccess: (values) => onMutationSuccessFeedback(config, setSnackbar, values),
        onError: (error) => onMutationErrorFeedback(config, setSnackbar, error),
    },
    )
}