import { useMemo } from "react"

import { useLocation, matchPath, useMatch } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { l2rApps } from "../../constants/l2rApps"
import { AvatarWithAccountMenu } from "../../containers/AvatarWithAccountMenu"
import * as Styled from "./AuthenticatedDesktopLayout.styled"

export function AuthenticatedDesktopLayout(props) {
    const { children } = props
    const { pathname } = useLocation()

    const match = useMatch("/:selectedNetwork/*")
    const selectedNetwork = match?.params?.selectedNetwork

    const customLayoutRoutes = useMemo(() => {
        return Object.values(l2rApps).reduce((routes, app) => {
            if (app.desktopCustomLayoutRoutes) {
                return routes.concat(app.desktopCustomLayoutRoutes)
            }
            return routes
        }, [])
    }, [])

    if (customLayoutRoutes.some(path => matchPath(`/:networkSlug${path}`, pathname))) {
        return <Styled.ChildrenWrapperWithoutAppBar>
            {children}
        </Styled.ChildrenWrapperWithoutAppBar>
    }

    return (
        <>
            <Styled.AppBar
                id="app-bar"
                position="fixed"
                elevation={0}
            >
                <Styled.WrapperLeft>
                    <Styled.Link to={selectedNetwork ? `/${selectedNetwork}` : "/"}>
                        <Styled.Logo id="logiroad-logo" src={"assets/resources/logiroad-center-logo.svg"} />
                    </Styled.Link>
                    <Styled.NetworkSelector id="network-selector" />
                </Styled.WrapperLeft>
                <Styled.WrapperRight>
                    <Styled.ModuleSelector id="module-selector" />
                    <AvatarWithAccountMenu />
                </Styled.WrapperRight>
            </Styled.AppBar>
            <Styled.ChildrenWrapper>
                {children}
            </Styled.ChildrenWrapper>
        </>
    )
}

AuthenticatedDesktopLayout.propTypes = {
    children: PropTypes.node.isRequired,
}
