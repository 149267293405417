import {
    styled,
} from "@l2r-front/l2r-ui"

export const TitleSideComponentWrapper = styled("div")({
    width: "100%",
})

export const PublicationWrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette["cta-bg/cta-bg-primary"].main,
    padding: theme.spacing(1, 2),
    borderRadius: 4,
    alignItems: "center",
    float: "right",
}))