import { List as UiList, SIDEBAR_SPACING, SIDEBAR_SPACING_MOBILE, styled, styledForMobile } from "@l2r-front/l2r-ui"

export const Container = styledForMobile("div")(({ isMobile, theme }) => ({
    padding: isMobile ? theme.spacing(2, SIDEBAR_SPACING_MOBILE, 0, SIDEBAR_SPACING_MOBILE)
        : theme.spacing(2, SIDEBAR_SPACING, 0, SIDEBAR_SPACING),
    width: "100%",
}))

export const Title = styledForMobile("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    marginBottom: theme.spacing(5),
}))

export const ListWrapper = styled("div")(() => ({
    height: "100%",
}))

export const List = styledForMobile(UiList)(() => ({
    padding: 0,
}))