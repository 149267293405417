import { Popup as RmglMapPopup } from "react-map-gl"

import { PropTypes } from "@l2r-front/l2r-proptypes"


export function Popup(props) {
    const {
        point,
        ...rmglProps
    } = props

    return <RmglMapPopup
        {...(point && {
            "longitude": point.coordinates[0],
            "latitude": point.coordinates[1],
        })}
        maxWidth="fit-content"
        {...rmglProps}
    />
}

Popup.propTypes = {
    ...RmglMapPopup.PropTypes,
    point: PropTypes.geoJsonPoint,
}