import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useRecoverPasswordMutation } from "../../hooks/mutations/auth/useRecoverPasswordMutation"
import { RecoverPasswordForm } from "./RecoverPasswordForm.form"

export const RecoverPasswordFormPost = (props) => {
    const {
        className,
        onSuccess,
    } = props

    const { mutateAsync: recoverPassword } = useRecoverPasswordMutation({
        onSuccess,
    })

    return (
        <RecoverPasswordForm
            className={className}
            onSubmit={recoverPassword}
        />
    )
}

RecoverPasswordFormPost.propTypes = {
    className: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
}