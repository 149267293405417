import { queryKeyFactory } from "@l2r-front/l2r-query"

export const EVENTS_ROOT_QUERY_KEY = "events"

export const eventsQueryKeys = queryKeyFactory(EVENTS_ROOT_QUERY_KEY, {
    stats: (filters = {}) => [EVENTS_ROOT_QUERY_KEY, "stats", filters] as const,
    geoJSON: (filters = {}) => [EVENTS_ROOT_QUERY_KEY, "geoJSON", filters] as const,
    linestring: (id: number) => [EVENTS_ROOT_QUERY_KEY, "detail", id, "linestring"] as const,
    charts: (id: number) => [EVENTS_ROOT_QUERY_KEY, "detail", id, "charts"] as const,
    chart: (id: number, params: string) => [EVENTS_ROOT_QUERY_KEY, "detail", id, "chart", params] as const,
})
