import { getHappyHandlers } from "../handlers/happyHandlers"

export async function enableMocking() {
    const mockEnabled = ["true", "True"].includes(process.env["NX_MOCK_ENABLED"])
    const testMode = new URLSearchParams(window.location.search).get("run_in_cypress")
    if (process.env.NODE_ENV !== "development" || !mockEnabled || testMode) {
        return
    }

    const { worker } = await import("./browser")
    return worker.start()
}

export const setHandlers = (worker, apiUrl, handlers = []) => {
    worker.resetHandlers(...getHappyHandlers(apiUrl))
    worker.use(...handlers)
}