import { Text } from "@visx/text"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { GRADE_TICK_LABEL_HEIGHT, GRADE_TICK_LABEL_WIDTH } from "../../constants/layout"

export function GradeTickLabel(props) {
    const {
        backgroundColor,
        label,
        formattedValue: _,
        textColor,
        ...otherProps
    } = props

    return (
        <>
            <svg viewBox={`0 0 ${GRADE_TICK_LABEL_WIDTH} ${GRADE_TICK_LABEL_HEIGHT}`} width={`${GRADE_TICK_LABEL_WIDTH}px`} height={`${GRADE_TICK_LABEL_HEIGHT}px`} fill={backgroundColor} {...otherProps}>
                <ellipse cx={GRADE_TICK_LABEL_WIDTH / 2} cy={GRADE_TICK_LABEL_HEIGHT / 2} rx={GRADE_TICK_LABEL_WIDTH / 2} ry={GRADE_TICK_LABEL_HEIGHT / 2} />
            </svg >
            <Text
                dx={GRADE_TICK_LABEL_WIDTH / 2}
                dy={GRADE_TICK_LABEL_HEIGHT / 2}
                fontSize={12}
                lineHeight={GRADE_TICK_LABEL_HEIGHT}
                fontWeight={600}
                fontFamily="Poppins"
                textAnchor="middle"
                verticalAnchor="middle"
                fill={textColor}
                {...otherProps}
            >
                {label}
            </Text>
        </>
    )
}

GradeTickLabel.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
}