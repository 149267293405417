import {
    styled,
    Button as UiButton,
    Typography,
} from "@mui/material"

import { SIDEBAR_SPACING, SIDEBAR_SPACING_MOBILE } from "../../constants/layout"

export const Container = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
}))

export const TitleContainer = styled("div")(({ theme, isMobile }) => ({
    display: "flex",
    flexDirection: "row",
    margin: isMobile ? theme.spacing(0, SIDEBAR_SPACING_MOBILE, SIDEBAR_SPACING_MOBILE, SIDEBAR_SPACING_MOBILE)
        : theme.spacing(0, SIDEBAR_SPACING, 5, SIDEBAR_SPACING),
    paddingTop: isMobile && theme.spacing(SIDEBAR_SPACING_MOBILE),
}))

export const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette["objects/object-black"].main,
    lineHeight: "34px",
}))

export const Button = styled(UiButton)(({ theme }) => ({
    minWidth: "unset",
    width: "fit-content",
    textTransform: "initial",
    padding: 0,
    marginRight: theme.spacing(9),
}))

export const TitleSideComponent = styled("div")(({ theme }) => ({
    marginLeft: theme.spacing(10),
    marginRight: 0,
    flexGrow: 1,
}))