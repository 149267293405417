import { useFormikContext } from "formik"

import { ButtonSubmit } from "../../components/ButtonSubmit"

export function FormikButtonSubmit(props) {
    const { isSubmitting } = useFormikContext()

    return (
        <ButtonSubmit
            {...props}
            isSubmitting={isSubmitting}
        />
    )
}

FormikButtonSubmit.propTypes = ButtonSubmit.propTypes

FormikButtonSubmit.defaultProps = {}
