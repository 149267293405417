import { logiroadBlack, logiroadLightBlue } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"

export function formatData(data, t) {

    if (data) {

        return ({
            accelerationChart: {
                title: t(I18N_NAMESPACE, "containers.incidentChart.charts.acceleration.title"),
                dataArray: [{
                    data: data.time.map((timeValue, i) => {
                        return ({
                            x: parseFloat(timeValue),
                            y: parseFloat(data.accx[i]),
                        })
                    }),
                    color: logiroadBlack,
                }, {
                    data: data.time.map((timeValue, i) => {
                        return ({
                            x: parseFloat(timeValue),
                            y: parseFloat(data.accy[i]),
                        })
                    }),
                    color: logiroadLightBlue,
                }],
            },
            speedChart: {
                title: t(I18N_NAMESPACE, "containers.incidentChart.charts.speed.title"),
                dataArray: [{
                    data: data.time.map((timeValue, i) => {
                        return ({
                            x: parseFloat(timeValue),
                            y: parseFloat(data.speedGps[i]),
                        })
                    }),
                    color: logiroadBlack,
                }],
            },
        })
    }

    return null
}