import { useMemo } from "react"

import { CancelIcon, CheckCircleIcon, LensIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./StepIcon.styled"

export const StepIcon = (props) => {

    const {
        active,
        className,
        color,
        icon,
        stepNumber,
    } = props

    const Icon = useMemo(() => {
        switch (icon) {
            case "checkIcon":
                return <CheckCircleIcon color={color} />
            case "cancelIcon":
                return <CancelIcon color="objects/object-grey" />
            case "numberIcon":
                return <Styled.Wrapper>
                    <LensIcon color={active ? color : "objects/object-grey"} />
                    <Styled.Number>{stepNumber}</Styled.Number>
                </Styled.Wrapper>
            default:
                return null
        }
    }, [active, color, icon, stepNumber])

    return (
        <div className={className}>
            {Icon}
        </div >
    )
}

StepIcon.defaultProps = {
    active: false,
    color: "cta-bg/cta-bg-primary",
}

StepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string.isRequired,
    stepNumber: PropTypes.number.isRequired,
}