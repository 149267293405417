import themeJSON from "../theme.json"

const COLLECTION_BASE = "Base"
const COLLECTION_EFFECTS = "Effects"
const COLLECTION_TOKENS = "Tokens"
const COLLECTION_TYPOGRAPHY = "Typography"

const TEXT_BACKGROUND = "-bg"
const TOKEN_TEXT = "-text"

const INNER_SHADOW_TYPE = "INNER_SHADOW"

const FontWeightMapping = {
    "Bold": 700,
    "SemiBold": 600,
    "Regular": 400,
    "Light": 300,
}


function formatBoxshadow() {
    const boxshadowsCollection = getCollection(COLLECTION_EFFECTS)

    return boxshadowsCollection.reduce((acc, boxshadow) => {
        const boxshadowValue = boxshadow.value.effects[0]
        const colorString = `rgba(${boxshadowValue.color.r}, ${boxshadowValue.color.g}, ${boxshadowValue.color.b}, ${boxshadowValue.color.a})`
        const shadowType = boxshadowValue.type === INNER_SHADOW_TYPE ? " inset" : ""
        const boxShadow = `${boxshadowValue.offset.x}px ${boxshadowValue.offset.y}px ${boxshadowValue.radius}px ${boxshadowValue.spread}px ${colorString}${shadowType}`
        return acc.concat(boxShadow)
    }, [])
}

function formatTypography() {
    const typographyCollection = getCollection(COLLECTION_TYPOGRAPHY)
    return typographyCollection.reduce((acc, typography) => {
        typography.value.fontWeight = FontWeightMapping[typography.value.fontWeight]
        return ({
            ...acc,
            [typography.name]: typography.value,
        })
    }, {})
}

function formatPalette() {
    const baseCollection = getCollection(COLLECTION_BASE)
    const tokensCollection = getCollection(COLLECTION_TOKENS)

    const basePalette = baseCollection.reduce((acc, item) => {
        if (item.type === "color") {
            return ({
                ...acc,
                [item.name]: {
                    ...acc[item.name],
                    main: item.value,
                },
            })
        }

        return acc
    }, {})

    const tokensPalette = tokensCollection.reduce((acc, token) => {
        const color = baseCollection.find(base => base.name === token.value.name)?.value || token.value
        if (color) {
            if (token.name.includes(TOKEN_TEXT)) {
                const tokenName = token.name.replaceAll(TOKEN_TEXT, TEXT_BACKGROUND)

                return ({
                    ...acc,
                    [tokenName]: {
                        ...acc[tokenName],
                        main: color,
                        contrastText: color,
                    },
                })
            } else {
                return ({
                    ...acc,
                    [token.name]: {
                        ...acc?.[token.name],
                        main: color,
                    },
                })
            }
        }

        return acc
    }, {})
    tokensPalette["cta-bg/cta-bg-primary"].dark = "#114A3A"

    return {
        ...basePalette,
        ...tokensPalette,
    }
}

function getCollection(name) {
    return themeJSON.collections
        .find(collection => collection.name === name).modes[0].variables
}

export const themeFromJson = {
    boxshadows: formatBoxshadow(),
    palette: formatPalette(),
    typography: formatTypography(),
}
