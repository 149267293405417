import { useMemo } from "react"

import { VisibilityIcon, VisibilityOffIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Badge } from "@l2r-front/l2r-ui"

export const IncidentPublicationIcon = (props) => {
    const {
        isPublished,
        reasonNotPublished,
        ...iconProps
    } = props

    const Icon = useMemo(() => {
        return isPublished ? VisibilityIcon : VisibilityOffIcon
    }, [isPublished])

    return (
        <Badge color="surfaces/surface-error" variant="dot" invisible={isPublished || !!reasonNotPublished}>
            <Icon style={{ color: "white" }} {...iconProps} />
        </Badge>
    )
}

IncidentPublicationIcon.propTypes = {
    isPublished: PropTypes.bool.isRequired,
    reasonNotPublished: PropTypes.string,
}