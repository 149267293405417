export const networkConditionsCD91Small = [
    {
        "uuid": "e32640f9-4f07-47a7-9de4-9685ee840051",
        "createdAt": "2023-07-05T14:02:56.888000",
        "updatedAt": "2023-07-05T14:02:56.888000",
        "survey": "e26884f8-7951-4e71-b07b-452952494ee6",
        "yearRelative": 0,
        "year": 2023,
        "isSimulated": false,
        "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
        "damageTypes": [
            {
                "parent": null,
                "name": "Affaissement en rive",
                "code": "ACBDA",
                "cause": "ST",
                "geometryTypeid": 1,
            },
            {
                "parent": null,
                "name": "Arrachement",
                "code": "AHHAB",
                "cause": "SU",
                "geometryTypeid": 1,
            },
            {
                "parent": null,
                "name": "Autre fissure",
                "code": "AFEAC",
                "cause": "BO",
                "geometryTypeid": 1,
            },
            {
                "parent": null,
                "name": "Déformation hors rive",
                "code": "AABEA",
                "cause": "ST",
                "geometryTypeid": 0,
            },
            {
                "parent": null,
                "name": "Fissure longitudinale BDR",
                "code": "AFFBA",
                "cause": "ST",
                "geometryTypeid": 1,
            },
            {
                "parent": null,
                "name": "Fissure transversale",
                "code": "AFBAC",
                "cause": "BO",
                "geometryTypeid": 0,
            },
            {
                "parent": null,
                "name": "Nid de poule",
                "code": "AIBAB",
                "cause": "SU",
                "geometryTypeid": 0,
            },
            {
                "parent": null,
                "name": "Orniérage",
                "code": "ABBAA",
                "cause": "ST",
                "geometryTypeid": 1,
            },
            {
                "parent": null,
                "name": "Autre réparation < demie voie",
                "code": "AJGAB",
                "cause": "BO",
                "geometryTypeid": 1,
            },
            {
                "parent": null,
                "name": "Tranchée longitudinale affaissée",
                "code": "AKBAA",
                "cause": "ST",
                "geometryTypeid": 1,
            },
            {
                "parent": null,
                "name": "Tranchée transversale affaissée",
                "code": "AKDAA",
                "cause": "ST",
                "geometryTypeid": 0,
            },
            {
                "parent": null,
                "name": "Glaçage ou Ressuage",
                "code": "AEDAB",
                "cause": "SU",
                "geometryTypeid": 1,
            },
        ],
    },
]

export const networkConditionsMulhouse = []

export const allRoadNetworkConditions =
{
    "cd91_small": networkConditionsCD91Small,
    "mulhouse": networkConditionsMulhouse,
}

export const getRoadNetworkConditions = (networkSlug, survey, year) => {
    return allRoadNetworkConditions[networkSlug].filter(nc => (!survey || nc.survey === survey) && (!year || nc.year.toString() === year))
}

export const findNetworkConditionByYear = (networkSlug, year) => {
    return allRoadNetworkConditions[networkSlug].find(nc => nc.year === year)
}