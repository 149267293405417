import { CTAButton, Typography as UiTypography, styled } from "@l2r-front/l2r-ui"

export const PageWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
})

export const Title = styled(UiTypography)(({ theme }) => ({
    marginBottom: theme.spacing(5),
}))

export const Button = styled(CTAButton)(({ theme }) => ({
    width: "fit-content",
    marginRight: "auto",
    marginTop: theme.spacing(6),
}))
