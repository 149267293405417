import { Autocomplete, Chip, TextField } from "@mui/material"

export const AutoCompleteWithChips = (props) => {

    const {
        label,
        id,
        ...autocompleteProps
    } = props

    const { getOptionLabel } = autocompleteProps

    return <Autocomplete
        {...autocompleteProps}
        multiple
        id={id}
        renderInput={(params) => (
            <TextField
                {...params}
                color="cta-bg/cta-bg-primary"
                label={label}
            />
        )}
        renderTags={(value, getTagProps) =>
            value.map((option, index) => (
                <Chip
                    {...getTagProps({ index })}
                    color="cta-bg/cta-bg-primary"
                    label={getOptionLabel ? getOptionLabel(option) : option}
                    variant="filled"
                />
            ))
        }
    />
}
