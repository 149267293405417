import {
    DatePicker as UiDatePicker,
    theme,
    TextField as UiTextField,
    styled,
} from "@l2r-front/l2r-ui"

export const FiltersContainer = styled("div")(() => ({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
}))

export const DatePicker = styled(UiDatePicker)(({ isMobile }) => ({
    margin: isMobile && theme.spacing(2, 0, 0, 2),
}))

export const TextField = styled(UiTextField)({
    margin: theme.spacing(2, 0, 2, 0),
})
