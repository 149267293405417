import { ButtonBase, theme, Typography, styled } from "@l2r-front/l2r-ui"

export const Image = styled("img")({
    height: "50%",
    width: "50%",
})

export const Button = styled(ButtonBase)(({ background }) => ({
    alignItems: "center",
    backgroundImage: `url(${background})`,
    borderRadius: theme.spacing(2),
    display: "flex",
    height: "48px !important",
    padding: theme.spacing(1),
    width: "48px !important",
}))

export const Text = styled(Typography)(({ color, theme }) => ({
    color: theme?.palette?.[color]?.main,
    height: "auto",
    textAlign: "center",
    width: "50%",
}))

export const ItemsWrapper = styled("div")({
    display: "flex",
    justifyContent: "center",
    width: "100%",
})