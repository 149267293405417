import { useMatch } from "react-router-dom"

import { useRoadQueries, useRoadQuery } from "../../../../../common/hooks/queries/useRoadQuery"
import { networkGradingsQueryKeys } from "./queryKeys"

export function useNetworkGradingsForMultipleSurveysQuery(surveys, queryParameters, config = {}) {
    const url = "network_gradings/"
    const matchStreetView = useMatch(":slug/road/:moduleId/streetView/*")

    const queriesData = (surveys || []).map(survey => {
        const parameters = { ...queryParameters, survey: survey.uuid }
        const queryKey = [...networkGradingsQueryKeys["list"](parameters), matchStreetView && "streetView"]

        return {
            url: url,
            queryKey: queryKey,
            queryParameters: parameters,
            config: config,
        }
    })

    return useRoadQueries(queriesData)
}

export function useListNetworkGradings(queryParameters, config = {}) {
    const url = "network_gradings/"

    const parameters = { ...queryParameters }
    const queryKey = [...networkGradingsQueryKeys["list"](parameters)]

    return useRoadQuery(
        url,
        queryKey,
        parameters,
        config,
    )
}