import { useCallback } from "react"

import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

import { useRoadworksDispatchContext } from "../../../contexts/RoadworksContext"
import { useRoadworksProject } from "../../queries/useRoadworksProjectQuery"
import { useRoadworksMutation } from "./useRoadworksMutation"
import { useOnSuccessRoadwork } from "./utils"

export function useRoadworkDelete(
    roadwork,
    queryParameters,
    config = {},
) {
    const url = `roadworks/${roadwork?.uuid}/`
    const { data: roadworkProject } = useRoadworksProject()

    const { enabled = true } = config
    const { onSuccess } = useOnSuccessRoadwork(roadwork)
    const { setSelectedRoadwork } = useRoadworksDispatchContext()

    const onDeleteSuccess = useCallback((data) => {
        onSuccess(data)
        setSelectedRoadwork(null)
    }, [onSuccess, setSelectedRoadwork])

    return useRoadworksMutation(
        url,
        axiosInstance.delete,
        {
            ...queryParameters,
        },
        {
            ...config,
            enabled: roadworkProject && roadwork?.uuid && enabled,
            onSuccess: onDeleteSuccess,
        },
    )
}