import * as yup from "yup"

import { I18N_NAMESPACE } from "../../constants/i18n"

export const validationSchema = (t) => yup.object({
    password: yup
        .string()
        .min(8, t(I18N_NAMESPACE, "forms.recoverPasswordDoneForm.fields.password.minLengthMessage"))
        .matches(/\D/, t(I18N_NAMESPACE, "forms.recoverPasswordDoneForm.fields.password.needLetterMessage"))
        .required(t(I18N_NAMESPACE, "forms.recoverPasswordDoneForm.fields.password.requireMessage")),
    confirmPassword: yup
        .string()
        .required(t(I18N_NAMESPACE, "forms.recoverPasswordDoneForm.fields.confirmPassword.requireMessage"))
        .oneOf([yup.ref("password"), null], t(I18N_NAMESPACE, "forms.recoverPasswordDoneForm.errors.passwordsMustMatch")),
})
