import { LinkIcon as L2RLinkIcon } from "@l2r-front/l2r-icons"
import {
    Typography as UiTypography,
    CTAButton as UiCTAButton,
    Typography,
    styled,
    SIDEBAR_SPACING,
    SIDEBAR_SPACING_MOBILE,
    styledForMobile,
} from "@l2r-front/l2r-ui"

export const IncidentDetailsSidebarWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    height: "100%",
})

export const ErrorWrapper = styled("div")(({ theme }) => ({
    color: theme.palette.error.main,
    margin: "30% auto auto auto",
}))

export const Title = styled(UiTypography)({
    textAlign: "center",
})

export const ReturnButton = styled(UiCTAButton)(({ theme }) => ({
    width: "fit-content",
    marginBottom: theme.spacing(2),
}))

export const Button = styled(ReturnButton)({
    marginRight: "auto",
})

export const ButtonsWrapper = styledForMobile("div")(({ theme, isMobile }) => ({
    display: "flex",
    flexDirection: "column",
    marginTop: "auto",
    width: "100%",
    padding: isMobile ? theme.spacing(0, SIDEBAR_SPACING_MOBILE, 0, SIDEBAR_SPACING_MOBILE) : theme.spacing(0, SIDEBAR_SPACING, 0, SIDEBAR_SPACING),
}))

export const ShareWrapper = styled("div")(({ theme }) => ({
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    marginRight: "auto",
    marginBottom: theme.spacing(4),
    textTransform: "none",
}))

export const ShareText = styled(Typography)(({ theme }) => ({
    marginRight: theme.spacing(6),
}))

export const Wrapper = styled("div")({
    display: "flex",
})

export const LinkIcon = styled(L2RLinkIcon)(({ color, theme }) => ({
    border: `1px solid ${theme.palette[color].main}`,
    borderRadius: 4,
    height: `calc(${theme.components.icons.defaultHeight}px + ${theme.spacing(1)})`,
    padding: theme.spacing(1),
    width: `calc(${theme.components.icons.defaultWidth}px + ${theme.spacing(1)})`,
}))