import { useParams } from "react-router-dom"

import {
    LINEAR_ABSOLUTE_END_URL_PARAM,
    LINEAR_ABSOLUTE_START_URL_PARAM,
} from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useUrlParams } from "@l2r-front/l2r-utils"

import {
    YearGradingBadge,
    YearGradingBadgeError,
    YearGradingBadgeSkeleton,
} from "../../components/YearGradingBadge"
import { GLOBAL_GRADE_TYPE } from "../../constants/roadInfoConfig"
import { useFeaturesYearGrading } from "../../hooks/queries/networkGradings/useFeaturesYearGrading"

export function FeaturesYearGrading(props) {

    const {
        className,
        year,
    } = props

    const { getParam } = useUrlParams()

    const { road } = useParams()
    const absStart = getParam(LINEAR_ABSOLUTE_START_URL_PARAM)
    const absEnd = getParam(LINEAR_ABSOLUTE_END_URL_PARAM)

    const { data, isLoading, isError } = useFeaturesYearGrading(year, road, absStart, absEnd)

    if (isLoading) {
        return <YearGradingBadgeSkeleton className={className} />
    }

    if (isError) {
        return <YearGradingBadgeError className={className} />
    }

    return <YearGradingBadge
        className={className}
        id={`features-grade-${year}`}
        grade={data?.[GLOBAL_GRADE_TYPE]}
        year={year}
    />
}


FeaturesYearGrading.propTypes = {
    className: PropTypes.string,

}