import { Typography, Button as UiButton, styled } from "@l2r-front/l2r-ui"

export const Content = styled("div")({
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
})

export const Title = styled(Typography)(({ theme }) => ({
    paddingLeft: theme.spacing(9),
}))

export const AccountSettingsBlock = styled("div")(({ theme }) => ({
    width: "100%",
    marginTop: theme.spacing(20),
}))

export const AccountSettingsCategory = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(9),
}))

export const ReturnButton = styled(UiButton)(({ theme }) => ({
    width: "fit-content",
    display: "relative",
    marginTop: "auto",
    marginLeft: theme.spacing(9),
}))