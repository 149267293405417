import { PropTypes } from "@l2r-front/l2r-proptypes"

import { AvatarWithAccountMenu } from "../../containers/AvatarWithAccountMenu/AvatarWithAccountMenu"
import { SimpleAppBar } from "../../containers/SimpleAppBar"
import * as Styled from "./MessagePage.styled"

export const MessagePage = (props) => {

    const {
        children,
    } = props

    return (
        <Styled.PageContainer>
            <SimpleAppBar>
                <Styled.WrapperLeft>
                    <Styled.Logo id="logiroad-logo" src={"assets/resources/logiroad-center-logo.svg"} />
                </Styled.WrapperLeft>
                <Styled.WrapperRight>
                    <AvatarWithAccountMenu />
                </Styled.WrapperRight>
            </SimpleAppBar>
            {children}
        </Styled.PageContainer>
    )
}

MessagePage.propTypes = {
    children: PropTypes.node,
}