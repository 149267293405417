import { useContext } from "react"

import { RoadworksStateContext, RoadworksDispatchContext } from "./RoadworksContext.context"

export const useRoadworksStateContext = () => {
    const context = useContext(RoadworksStateContext)
    if (context === undefined) {
        throw new Error("useRoadworksStateContext must be used within an RoadworksStateContext")
    }
    return context
}

export const useRoadworksDispatchContext = () => {
    const context = useContext(RoadworksDispatchContext)
    if (context === undefined) {
        throw new Error("useRoadworksDispatchContext must be used within an RoadworksStateContext")
    }
    return context
}