import {
    EVENT_LIGHT_CONDITION,
    LIGHT_CONDITION_DAY,
    LIGHT_CONDITION_NIGHT,
} from "./event"

export const eventConditionsSettings = {
    [EVENT_LIGHT_CONDITION]: {
        [LIGHT_CONDITION_NIGHT]: {
            label: "components.incidentDetails.conditions.lightCondition.night",
        },
        [LIGHT_CONDITION_DAY]: {
            label: "components.incidentDetails.conditions.lightCondition.day",
        },
    },
}