import { Button as MuiButton } from "@mui/material"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"

import { styledForMobile } from "../../utils/styledForMobile"

export const KpiCard = styled(MuiButton)(({ theme }) => ({
    alignItems: "center",
    backgroundColor: "white",
    transform: "scale(0.98)",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
        backgroundColor: "white",
        transform: "scale(1)",
        transition: "0.2s",
    },
    borderRadius: 16,
    boxShadow: theme.shadows[27],
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(8),
    width: "100%",
    color: theme.palette["objects/object-black"].main,
}))

export const Dummy = styled("span")(() => ({
    flexGrow: 1,
}))

export const IconWrapper = styledForMobile("div")(({ theme, isMobile }) => ({
    height: "30%",
    width: "100%",
    marginTop: isMobile ? theme.spacing(4) : "auto",
    marginBottom: theme.spacing(isMobile ? 4 : 5),
    flexShrink: 1,

    "& svg": {
        width: "100%",
        height: "100%",
    },
}))

export const Title = styledForMobile(Typography)(({ isMobile, theme }) => ({
    color: theme.palette["objects/object-black"].main,
    marginTop: isMobile ? 0 : "auto",
    marginBottom: isMobile ? "auto" : theme.spacing(2),
    flexShrink: 1,
    textAlign: "center",
}))

export const Data = styledForMobile(Typography)(({ isMobile, theme }) => ({
    color: theme.palette["objects/object-black"].main,
    marginTop: isMobile ? 0 : "auto",
    marginBottom: theme.spacing(isMobile ? 4 : 5),
    flexShrink: 1,
}))