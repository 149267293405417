import FormControlLabel from "@mui/material/FormControlLabel"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./Checkbox.styled"

export const Checkbox = (props) => {

    const {
        color,
        indeterminate,
        label,
        labelPosition,
        onChange,
        value,
        ...textFieldProps
    } = props

    return <FormControlLabel
        {...textFieldProps}
        control={<Styled.Checkbox
            {...textFieldProps}
            checked={value}
            color={color}
            indeterminate={indeterminate}
        />}
        label={label}
        labelPlacement={labelPosition || "start"}
        onClick={onChange}
    />
}

Checkbox.propTypes = {
    label: PropTypes.string,
    labelPlacement: PropTypes.string,
}