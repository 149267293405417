import { ColoredBadge as UiColoredBadge, Skeleton as UiSkeleton, styled } from "@l2r-front/l2r-ui"

export const ColoredBadge = styled(UiColoredBadge)({
    height: 40,
    width: 55,
})

export const Skeleton = styled(UiSkeleton)({
    height: 40,
    width: 55,
    transform: "scale(1)",
})