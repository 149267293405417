import { useTranslation } from "@l2r-front/l2r-i18n"
import { LocalSeeIcon } from "@l2r-front/l2r-icons"
import { useIsMobileDevice, Switch } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"
import * as Styled from "./ImageLayerSwitch.styled"

export const ImageLayerSwitch = (props) => {

    const {
        id,
        checked,
        handleChange,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const isMobile = useIsMobileDevice()

    return <Styled.Container id={id} isMobile={isMobile}>
        <LocalSeeIcon color="cta-bg/cta-bg-primary" />
        {!isMobile && <Styled.Label>{t(I18N_NAMESPACE, "components.imageLayerSwitch.label")}</Styled.Label>}
        <Switch
            checked={checked}
            onChange={handleChange}
            color="cta-bg/cta-bg-primary" />
    </Styled.Container>
}