import { FILTERS_SEARCH_PARAM_PREFIX, STATUSES_FILTER, TECHNIQUES_FILTER, YEARS_FILTER } from "../../constants/filters"
import { STATUSES } from "../../constants/status"
import { roadworksFiltersConfig } from "../../utils/roadworksFiltersConfig"

export function setFiltersSearchParams(filters, setParam, deleteParam) {
    Object.entries(filters || {}).forEach(([filterKey, filterValue]) => {
        const filterParam = roadworksFiltersConfig[filterKey].getUrlParams(filterValue)
        if (filterValue?.length) {
            setParam(`${FILTERS_SEARCH_PARAM_PREFIX}${filterKey}`, filterParam)
        } else {
            deleteParam(`${FILTERS_SEARCH_PARAM_PREFIX}${filterKey}`)
        }
    })
}

export function deleteSearchParamsByKeys(searchParams, keysToDelete) {
    const matchKey = function (key, keyToMatch) {
        return key === keyToMatch
            || keyToMatch === "*"
            || (keyToMatch.endsWith("*") && key.startsWith(keyToMatch.substring(0, keyToMatch.length - 1)))
    }

    Array.from(searchParams.entries()).forEach(([key]) => {
        for (const keyToDelete of keysToDelete) {
            if (matchKey(key, keyToDelete)) {
                searchParams.delete(key)
                break
            }
        }
    })
    return searchParams
}

export function getQueryParamsFromFilters(filters, locale, dateFormatter, userIsStaff) {
    return Object.entries(filters || {}).reduce((acc, [filterKey, filterValue]) => {
        const params = roadworksFiltersConfig[filterKey].getQueryParams(filterKey, filterValue, locale, dateFormatter, userIsStaff)
        if (params) {
            params.forEach(param => {
                acc = {
                    ...acc,
                    [param.name]: param.value,
                }
            })
        }
        return acc
    }, {})

}

export function getFiltersFromSearchParams(getParams) {
    const acceptedFiltersKeys = Object.keys(roadworksFiltersConfig)
    return Array.from(getParams().entries()).reduce((acc, [key, value]) => {
        if (key.includes(FILTERS_SEARCH_PARAM_PREFIX)) {
            const filterKey = key.replace(FILTERS_SEARCH_PARAM_PREFIX, "")
            if (!acceptedFiltersKeys.includes(filterKey)) {
                return acc
            }
            if (filterKey === YEARS_FILTER) {
                const yearsInSearchParams = [...(value.split(","))]
                const numericalYears = yearsInSearchParams.map(v => {
                    const intValue = parseInt(v)
                    if (intValue && new Date(intValue, 0).toString() !== "Invalid Date") {
                        return intValue
                    }
                    return null
                }).filter(Boolean)
                return ({
                    ...acc,
                    [filterKey]: numericalYears,
                })
            }
            if (filterKey === STATUSES_FILTER) {
                const statusesStringInSearchParams = [...(value.split(","))]
                const statusesInSearchParams = statusesStringInSearchParams.map(status => parseInt(status))
                const validStatuses = statusesInSearchParams.filter(status => STATUSES.includes(status))
                return ({
                    ...acc,
                    [filterKey]: validStatuses,
                })
            }
            if (filterKey === TECHNIQUES_FILTER) {
                const techniquesInSearchParams = [...(value.split(","))]
                return ({
                    ...acc,
                    [filterKey]: techniquesInSearchParams,
                })
            }

            return ({
                ...acc,
                [filterKey]: value,
            })
        }
        return acc
    }, {})
}

export function getSearchParamsFromFilters(filters) {
    return Object.entries(filters).reduce((acc, [key, value]) => {
        if (key === YEARS_FILTER) {
            const filterValue = roadworksFiltersConfig[YEARS_FILTER].getUrlParams(value)
            return ({
                ...acc,
                ...(value && value.length && { [FILTERS_SEARCH_PARAM_PREFIX + YEARS_FILTER]: filterValue }),
            })
        }

        if (key === STATUSES_FILTER) {
            const filterValue = roadworksFiltersConfig[STATUSES_FILTER].getUrlParams(value)
            return ({
                ...acc,
                ...(value && value.length && { [FILTERS_SEARCH_PARAM_PREFIX + STATUSES_FILTER]: filterValue }),
            })
        }

        if (key === TECHNIQUES_FILTER) {
            const filterValue = roadworksFiltersConfig[TECHNIQUES_FILTER].getUrlParams(value)
            return ({
                ...acc,
                ...(value && value.length && { [FILTERS_SEARCH_PARAM_PREFIX + TECHNIQUES_FILTER]: filterValue }),
            })
        }

        return ({
            ...acc,
            ...(value && { [FILTERS_SEARCH_PARAM_PREFIX + key]: value }),
        })
    }, {})
}