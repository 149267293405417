import { Form as FormikForm } from "formik"

import {
    Box as UiBox,
    Button as UiButton,
    IconButton as UiIconButton,
    FormikTextField as UiFormikTextField,
    Skeleton as UiSkeleton,
    Typography,
    styled,
    styledForMobile,
} from "@l2r-front/l2r-ui"

import { STEPPER_HEIGHT } from "../../constants/layout"

export const ButtonsWrapper = styledForMobile(UiBox, ["nextButton"])(({ isMobile, nextButton, theme }) => ({
    alignItems: "center",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: isMobile === "true" ? "flex-start" : "flex-end",
    paddingTop: 2,
    marginTop: isMobile === "true" && !nextButton ? 0 : theme.spacing(8),
}))

export const ButtonWrapper = styledForMobile(ButtonsWrapper, ["displayedText"])(({ displayedText, isMobile }) => ({
    justifyContent: (isMobile && displayedText) ? "space-between" : "flex-end",
}))

export const SwitchWrapper = styledForMobile("div")(({ isMobile }) => ({
    alignItems: "center",
    display: "flex",
    flexDirection: isMobile ? "row-reverse" : "row",
    width: "fit-content",
}))

export const FormButtonsWrapper = styled(ButtonsWrapper)({
    marginTop: 0,
})

export const ReportStepWrapper = styled(UiBox)({
    height: `calc(100% - ${STEPPER_HEIGHT}px)`,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
})

export const Wrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
})

export const Button = styled(UiButton)(({ theme }) => ({
    margin: theme.spacing(0, 4, 0, 4),
}))

export const IconButton = styled(UiIconButton)(({ theme }) => ({
    marginLeft: theme.spacing(5),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette["objects/object-lightgrey"].main,

    "&:hover": {
        boxShadow: `inset 0px 0px 0px 2px ${theme.palette["cta-bg/cta-bg-primary"].main}`,
    },
}))

export const Form = styled(FormikForm)({
    height: "100%",
    display: "flex",
    flexDirection: "column",
})

export const FormikTextField = styled(UiFormikTextField)(({ theme }) => ({
    height: "100%",

    "& > *": {
        margin: theme.spacing(10, 0, 0, 0),
        height: "100%",
    },
    "& > div > div": {
        padding: `${theme.spacing(4)} !important`,
    },
    "& > div > textarea": {
        fontSize: `${theme.typography.body2.fontSize}`,
        lineHeight: `${theme.typography.body2.lineHeight}`,
        height: "100% !important",
        overflow: "auto !important",
    },
}))

export const Text = styled(Typography)(({ theme }) => ({
    display: "flex",
    height: "100%",
    margin: theme.spacing(10, 0, 0, 0),
    overflowX: "hidden",
    overflowY: "auto",
    padding: theme.spacing(4),
    whiteSpace: "pre-line",
}))

export const Skeleton = styled(UiSkeleton)(({ theme }) => ({
    height: `calc(100% - ${theme.spacing(17)})`,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(7),
    transform: "scale(1)",
    width: "100%",
}))
