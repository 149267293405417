import { styled } from "@l2r-front/l2r-ui"

import { MobileRoadworksMap as RoadworksMap } from "../../containers/MobileRoadworksMap"
import { RoadworksRoadSidebar } from "../../containers/RoadworksRoadSidebar"

export const DetailWorksPanel = styled(RoadworksRoadSidebar)(({ theme }) => ({
    paddingTop: theme.spacing(6),
}))

export const Map = styled(RoadworksMap)({
    height: "100%",
    width: "100%",
})