import { useCallback, useEffect } from "react"

import { useMatch, useParams } from "react-router-dom"

import { ImageCollectionLayer, ImageSelectedLayer, useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { NetworkReferentialLayer, useNavigateToLinearLocation, useNetworksDispatchContext } from "@l2r-front/l2r-networks"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { APP_ROAD } from "../../../../common/constants/appRoad"
import { MODULE_ROADWAY } from "../../../../common/constants/modules"
import {
    DAMAGES_URL_PREFIX_PARAM,
    NETWORK_ASSESMENT_TYPE_URL_PARAM,
    NETWORK_GRADING_PREFIX_PARAM,
} from "../../../../common/constants/urlParams"
import { DamagesLayer } from "../../containers/DamagesLayer"
import { NotationLayer } from "../../containers/NotationLayer"
import { useRoadwayDispatchContext } from "../../contexts/RoadwayContext"

export function RoadwayMapLayersRouterMobile() {

    const { getParam } = useUrlParams()
    const match = useMatch(`:slugId/${APP_ROAD}/${MODULE_ROADWAY}/:road`)
    const params = useParams()
    const road = params?.road
    const isSegmentView = !!match

    const navigateToLinearLocation = useNavigateToLinearLocation()
    const { setSelectedSegment } = useRoadwayDispatchContext()
    const { setSegmentFeatureIdClicked } = useImageCollectionDispatchContext()
    const { setSearchRoadCallback } = useMapDispatchContext()
    const { setRoadSearched } = useNetworksDispatchContext()

    const currentNetworkAssesmentType = getParam(NETWORK_ASSESMENT_TYPE_URL_PARAM)?.replace(/-(.*)/, "-") || null

    const processNavigation = useCallback((feature, location) => {
        if (location && location.road && location.road !== road) {
            setSegmentFeatureIdClicked(feature?.properties?.uuid)
            setSelectedSegment(feature)
            navigateToLinearLocation(location, "..", { saveMapBoundingBox: !isSegmentView })
        }
    }, [setSegmentFeatureIdClicked, setSelectedSegment, navigateToLinearLocation, isSegmentView, road])

    const onFeatureClick = useCallback(feature => {
        const linearLocation = JSON.parse(feature.properties.linearLocation)
        if (linearLocation?.[0]?.road !== road) {
            setRoadSearched(null)
            processNavigation(feature, linearLocation?.[0])
        }
    }, [processNavigation, setRoadSearched, road])

    useEffect(() => {
        setSearchRoadCallback((searchedRoad) => {
            processNavigation(null, { road: searchedRoad })
        })

        return () => setSearchRoadCallback(null)
    }, [
        setSearchRoadCallback,
        processNavigation])

    switch (currentNetworkAssesmentType) {
        case DAMAGES_URL_PREFIX_PARAM:
            return (
                <>
                    <NetworkReferentialLayer onClickOnFeature={onFeatureClick} />
                    <DamagesLayer isFocused={!isSegmentView} />
                    {isSegmentView && <ImageCollectionLayer road={road} />}
                    {isSegmentView && <ImageSelectedLayer />}
                </>
            )
        case NETWORK_GRADING_PREFIX_PARAM:
            return <>
                <NotationLayer onClickOnFeature={onFeatureClick} />
                {isSegmentView && <ImageCollectionLayer road={road} />}
                {isSegmentView && <ImageSelectedLayer />}
            </>
        default:
            return null
    }
}