import { useMemo } from "react"

import { Group } from "@visx/group"
import { Text } from "@visx/text"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { getDisplayedFigures } from "../../../../common/utils/getDisplayedFigures"
import { GRADE_ANNOTATION_GAP, GRADE_ANNOTATION_HEIGHT, GRADE_ANNOTATION_WIDTH } from "../../constants/layout"

export function GradeAnnotation(props) {
    const {
        x,
        y,
        backgroundColor,
        grade,
        textColor,
        ...otherProps
    } = props

    const elementsPositioning = useMemo(() => {
        const tooHigh = y - GRADE_ANNOTATION_HEIGHT - GRADE_ANNOTATION_GAP < 0
        return {
            top: tooHigh ? y + GRADE_ANNOTATION_HEIGHT + GRADE_ANNOTATION_GAP : y - GRADE_ANNOTATION_HEIGHT - GRADE_ANNOTATION_GAP,
            left: tooHigh ? x + GRADE_ANNOTATION_WIDTH / 2 : x - GRADE_ANNOTATION_WIDTH / 2,
            textDx: tooHigh ? -GRADE_ANNOTATION_WIDTH / 2 : GRADE_ANNOTATION_WIDTH / 2,
            textDy: tooHigh ? -GRADE_ANNOTATION_WIDTH / 2 : GRADE_ANNOTATION_WIDTH / 2,
            rotation: tooHigh ? 180 : 0,
        }
    }, [x, y])

    return (
        <Group top={elementsPositioning.top} left={elementsPositioning.left} >
            <Group transform={`rotate(${elementsPositioning.rotation})`}>
                <svg
                    width={GRADE_ANNOTATION_WIDTH}
                    height={GRADE_ANNOTATION_HEIGHT}
                    viewBox={`0 0 ${GRADE_ANNOTATION_WIDTH} ${GRADE_ANNOTATION_HEIGHT}`}
                    fill={backgroundColor}
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.331055 29.3266L7.30268 36.7841L14.1673 43.9169L21.1354 36.8691L28.1071 29.8214L28.331 0.172091L0.330998 0.00195325L0.331055 29.3266Z" />
                </svg>
            </Group>
            <Text
                dx={elementsPositioning.textDx}
                dy={elementsPositioning.textDy}
                fontSize={14}
                lineHeight={15}
                width={GRADE_ANNOTATION_WIDTH}
                fontWeight={600}
                fontFamily="Poppins"
                textAnchor="middle"
                verticalAnchor="middle"
                fill={textColor}
                {...otherProps}
            >
                {getDisplayedFigures(grade, 2)}
            </Text>
        </Group >
    )
}

GradeAnnotation.propTypes = {
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    grade: PropTypes.number.isRequired,
    textColor: PropTypes.string.isRequired,
}