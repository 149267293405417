import {
    ListItemButton as UiListItemButton,
    ListItemSkeleton,
    Tooltip as UiTooltip,
    Typography,
    styled,
} from "@l2r-front/l2r-ui"

export const ListItemButton = styled(UiListItemButton)(({ selected, theme }) => ({
    backgroundColor: `${theme.palette["surfaces/surface-primary"].main} !important`,
    border: `2px solid ${selected ? theme.palette["cta-bg/cta-bg-primary"].main : theme.palette["surfaces/surface-primary"].main}`,
    borderRadius: 12,
    display: "flex",
    fontSize: selected ? theme.typography["H3"].fontSize : theme.typography["Regular"].fontSize,
    fontWeight: selected ? theme.typography["H3"].fontWeight : theme.typography["Regular"].fontWeight,
    justifyContent: "space-between",
    height: "fit-content",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1, 2),
    width: "100%",

    "&:hover": {
        backgroundColor: `${theme.palette.action.hover} !important`,
    },
}))

export const Label = styled(Typography)(({ selected, theme }) => ({
    marginLeft: "8%",
    marginRight: "auto",
    fontSize: selected ? theme.typography["H3"].fontSize : theme.typography["Regular"].fontSize,
    fontWeight: selected ? theme.typography["H3"].fontWeight : theme.typography["Regular"].fontWeight,
    textTransform: "capitalize !important",
}))

export const Tooltip = styled(UiTooltip)(() => ({
    marginLeft: "auto",
    marginRight: "6%",
}))

export const RoadInfoItemSkeleton = styled(ListItemSkeleton)(({ theme }) => ({
    borderRadius: 8,
    height: 46,
    marginBottom: theme.spacing(5),
    width: "100%",
}))