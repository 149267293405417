import { addSeconds } from "date-fns"

export function formatEventDate(dateTime, dateFormatter) {
    return dateFormatter(new Date(dateTime + "-01"), "MMM yyyy")
}

export function formatEventTimeSlot(timeSlot, dateFormatter) {
    const timeSlotStartDate = new Date("2022-01-01T" + timeSlot[0])
    const timeSlotStartFormatted = dateFormatter(timeSlotStartDate, "HH:mm")
    let timeSlotEndDate = new Date("2022-01-01T" + timeSlot[1])
    timeSlotEndDate = addSeconds(timeSlotEndDate, 1)
    const timeSlotEndFormatted = dateFormatter(timeSlotEndDate, "HH:mm")

    return `[${timeSlotStartFormatted} - ${timeSlotEndFormatted}]`
}