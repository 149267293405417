import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./ColoredBadge.styled"

export function ColoredBadge(props) {

    const {
        id,
        className,
        text,
        textColor,
        backgroundColor,
        textVariant,
        ...otherProps
    } = props

    return <Styled.Wrapper
        id={id}
        className={className}
        color={backgroundColor}
        {...otherProps}
    >
        <Styled.Typography
            color={textColor}
            variant={textVariant}
        >
            {text}
        </Styled.Typography>
    </Styled.Wrapper>
}

ColoredBadge.defaultProps = {
    textVariant: "H3",
}

ColoredBadge.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
    ]).isRequired,
    textColor: PropTypes.string,
    textVariant: PropTypes.string.isRequired,
}