import { useField, useFormikContext } from "formik"

import { Checkbox } from "../../components/Checkbox"

export function FormikCheckbox(props) {
    const [fieldProps, metaProps] = useField(props)
    const { setStatus } = useFormikContext()

    const handleChange = (event) => {
        fieldProps.onChange(event)
        setStatus()
    }

    return (
        <Checkbox {...fieldProps} {...props}
            label="Text on screen"
            type="checkbox"
            {...fieldProps}
            {...props}
            {...((metaProps.error && metaProps.touched) ? {
                error: true,
                helperText: metaProps.error,
            } : {})}
            onChange={handleChange}
        />
    )
}

FormikCheckbox.propTypes = Checkbox.propTypes