import { createContext } from "react"

export const initialState = {
    selectedNetworkYearlyStatus: null,
    selectedNetworkAssesmentType: null,
    selectedSegmentUuid: null,
}

const initialDispatch = {}

export const RoadwayStateContext = createContext(initialState)
export const RoadwayDispatchContext = createContext(initialDispatch)