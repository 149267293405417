import { useCallback, useMemo } from "react"

import { localPoint } from "@visx/event"
import { scaleLinear, scaleOrdinal } from "@visx/scale"
import { useTooltip } from "@visx/tooltip"
import { Zoom } from "@visx/zoom"
import { minBy, maxBy } from "lodash"
import { useParams } from "react-router-dom"
import { useMeasure } from "react-use"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { LineChart, logiroadLightBlue, useTheme } from "@l2r-front/l2r-ui"
import { rescaleChart } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useEventQuery } from "../../hooks/queries/events/useEvent"
import { useEventChartQuery } from "../../hooks/queries/events/useEventChart"
import { constrainZoom } from "../../utils/graphs/constrainZoom"
import { formatData } from "../../utils/graphs/formatData"
import { generateComfortTresholds } from "../../utils/graphs/generateComfortTresholds"
import { getDataFromTime } from "../../utils/graphs/getDataFromTime"
import { getMinMaxData } from "../../utils/graphs/getMinMaxData"
import * as Styled from "./IncidentCharts.styled"

const AXIS_PADDING_LEFT = 30
const AXIS_PADDING_BOTTOM = 50
const COMFORT_TRESHOLD = [-3, 3]
const INCIDENT_BAR_WIDTH = 2
const CHART_BOTTOM_PADDING = 40

export const IncidentCharts = (props) => {

    const {
        className,
    } = props

    const {
        showTooltip,
        hideTooltip,
        tooltipData,
        tooltipLeft = 0,
    } = useTooltip()

    const { t } = useTranslation([I18N_NAMESPACE])
    const { eventId } = useParams()
    const { data, isError: isChartError } = useEventChartQuery(eventId, { "fields": "accx,accy,speed_gps" }, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.incidentChart.errors.snackbars.chart"),
    })
    const { data: event, isError: isEventError } = useEventQuery(eventId, {}, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.incidentChart.errors.snackbars.event"),
    })

    const theme = useTheme()
    const [ref, { width: containerWidth, height: containerHeight }] = useMeasure()
    const width = containerWidth
    const height = (containerHeight / 2 - CHART_BOTTOM_PADDING)

    const chartsData = useMemo(() => {
        return formatData(data, t)
    }, [data, t])

    const minMaxValues = useMemo(() => {
        if (chartsData) {
            return ({
                x: getMinMaxData(chartsData, "accelerationChart", 0, "x"),
                accx: getMinMaxData(chartsData, "accelerationChart", 0, "y"),
                accy: getMinMaxData(chartsData, "accelerationChart", 1, "y"),
                speed: getMinMaxData(chartsData, "speedChart", 0, "y"),
            })
        }
        return null
    }, [chartsData])

    const timeScale = useMemo(() => {
        if (minMaxValues) {
            return scaleLinear({
                domain: [minMaxValues.x[0], minMaxValues.x[1]],
                range: [0, width],
            })
        }
    }, [minMaxValues, width])

    const yAccelerationScale = useMemo(() => {
        if (minMaxValues) {
            return scaleLinear({
                domain: [
                    Math.min(minMaxValues.accy[0], minMaxValues.accx[0]),
                    Math.max(minMaxValues.accy[1], minMaxValues.accx[1]),
                ],
                range: [height - AXIS_PADDING_BOTTOM, 0],
            })
        }
    }, [minMaxValues, height])

    const ySpeedScale = useMemo(() => {
        if (chartsData) {
            return scaleLinear({
                domain: [
                    minBy(chartsData.speedChart.dataArray[0].data, value => value.y).y,
                    maxBy(chartsData.speedChart.dataArray[0].data, value => value.y).y,
                ],
                range: [height - AXIS_PADDING_BOTTOM, 0],
            })
        }
    }, [chartsData, height])

    const initialZoom = useMemo(() => ({
        scaleX: 1,
        scaleY: 1,
        translateX: 0,
        translateY: 0,
        skewX: 0,
        skewY: 0,
    }), [])

    const yAccTickValues = useMemo(() => {
        if (minMaxValues) {
            const maxTicks = Math.max(Math.floor((height - AXIS_PADDING_BOTTOM) / 16), 0)
            const minValue = Math.floor(Math.min(minMaxValues.accy[0], minMaxValues.accx[0]))
            const maxValue = Math.ceil(Math.max(minMaxValues.accy[1], minMaxValues.accx[1]))
            if (minValue && maxValue) {
                const tickStep = Math.ceil((maxValue - minValue) / (maxTicks))
                const tickValues = []
                for (let i = minValue; i <= maxValue; i += tickStep) {
                    tickValues.push(i)
                }
                return tickValues
            }
        }
        return []
    }, [minMaxValues, height])

    const ySpeedTickValues = useMemo(() => {
        if (minMaxValues) {
            const maxTicks = Math.max((height - AXIS_PADDING_BOTTOM) / 16, 0)

            const minValue = Math.round(minMaxValues.speed[0] / 5) * 5
            const maxValue = Math.ceil(minMaxValues.speed[1])
            if (minValue >= 0 && maxValue >= 0) {
                const tickStep = Math.ceil((maxValue - minValue) / (maxTicks))
                const tickValues = []
                for (let i = minValue; i <= maxValue; i += tickStep <= 5 ? 5 : 10) {
                    tickValues.push(i)
                }
                return tickValues
            }
        }
        return []
    }, [minMaxValues, height])

    const handleTooltip = useCallback(
        (event, scale) => {
            const { x } = localPoint(event) || { x: 0 }
            const time = scale.invert(x)
            const accXData = getDataFromTime(time, chartsData.accelerationChart.dataArray[0].data)
            const accYData = getDataFromTime(time, chartsData.accelerationChart.dataArray[1].data)
            const speedData = getDataFromTime(time, chartsData.speedChart.dataArray[0].data)

            showTooltip({
                tooltipData: {
                    acceleration: {
                        data: [
                            {
                                value: t(I18N_NAMESPACE, "containers.incidentChart.charts.acceleration.labels.accx", { value: accXData.y }),
                                top: yAccelerationScale(accXData.y),
                            },
                            {
                                value: t(I18N_NAMESPACE, "containers.incidentChart.charts.acceleration.labels.accy", { value: accYData.y }),
                                top: yAccelerationScale(accYData.y),
                            },
                        ],
                        top: yAccelerationScale(accXData.y),
                        time: time.toFixed(2),
                    },
                    speed: {
                        data: [{
                            value: t(I18N_NAMESPACE, "containers.incidentChart.charts.speed.labels.valueLabel", { value: speedData.y }),
                            top: ySpeedScale(speedData.y),
                        }],
                        top: ySpeedScale(speedData.y),
                        time: time.toFixed(2),

                    },
                },
                tooltipLeft: x,
            })
        }, [chartsData, showTooltip, t, yAccelerationScale, ySpeedScale],
    )

    const comfortZone = useMemo(() => {
        if (minMaxValues) {
            return generateComfortTresholds(COMFORT_TRESHOLD, yAccelerationScale)
        }
    }, [minMaxValues, yAccelerationScale])

    const incidentValue = useMemo(() => {
        if (minMaxValues && event) {
            const minValue = Math.min(minMaxValues.accx[0], minMaxValues.accy[0])
            const maxValue = Math.max(minMaxValues.accx[1], minMaxValues.accy[1])
            return ({
                x: parseFloat(event.triggerMain?.timeStart),
                y0: yAccelerationScale(minValue - 1),
                y: yAccelerationScale(maxValue + 1),
            })
        }
        return null
    }, [event, minMaxValues, yAccelerationScale])

    const accelerationLegend = scaleOrdinal({
        domain: [
            t(I18N_NAMESPACE, "containers.incidentChart.charts.acceleration.legend.accx"),
            t(I18N_NAMESPACE, "containers.incidentChart.charts.acceleration.legend.accy"),
        ],
        range: [theme.palette["objects/object-black"].main, logiroadLightBlue],
    })

    const baseChartProps = {
        bottomPadding: AXIS_PADDING_BOTTOM,
        handleTooltip: handleTooltip,
        height: height,
        hideTooltip: hideTooltip,
        leftPadding: AXIS_PADDING_LEFT,
        timeScale: timeScale,
        tooltipData: tooltipData,
        tooltipLeft: tooltipLeft,
        width: width,
    }

    if (isChartError || isEventError) {
        return <Styled.Container className={className}>
            <Styled.Error
                height={height - 10}
                padding={10}
                width={width}
            />

            <Styled.Error
                height={height - 10}
                padding={10}
                width={width}
            />
        </Styled.Container>
    }

    if (!chartsData || !event) {
        return <Styled.Container className={className}>
            <Styled.Skeleton
                width={width}
                height={height - 10}
                padding={10} />
            <Styled.Skeleton
                width={width}
                height={height - 10}
                padding={10}
            />
        </Styled.Container>
    }

    return (
        <Styled.Container className={className} ref={ref}>
            <Zoom
                constrain={(transformMatrix, _) => constrainZoom(transformMatrix, _, width, height)}
                height={height}
                initialTransformMatrix={initialZoom}
                wheelDelta={(e) => {
                    return e.deltaY < 0 ? {
                        scaleX: 1.5,
                        scaleY: 1,
                    } : {
                        scaleX: 2 / 3,
                        scaleY: 1,
                    }
                }}
                width={width}
            >
                {(zoom) => {
                    const xScaleWithZoom = rescaleChart(timeScale, zoom)

                    return <Styled.IncidentChartsWrapper id="incident-details-charts" ref={zoom.containerRef}>
                        <LineChart
                            {...baseChartProps}
                            axisXLabel={t(I18N_NAMESPACE, "containers.incidentChart.charts.acceleration.axisX")}
                            axisYLabel={t(I18N_NAMESPACE, "containers.incidentChart.charts.acceleration.axisY")}
                            chartData={chartsData.accelerationChart}
                            id="acceleration"
                            legend={accelerationLegend}
                            minMaxValues={minMaxValues}
                            xScaleWithZoom={xScaleWithZoom}
                            yScale={yAccelerationScale}
                            yTickValues={yAccTickValues}
                            zoom={zoom}
                        >
                            {comfortZone && <rect
                                fill={theme.palette["objects/object-grey"].main}
                                height={comfortZone.y0 - comfortZone.y}
                                opacity={.3}
                                x={0}
                                y={comfortZone.y}
                                width={width}
                            />}
                            {
                                incidentValue && <rect
                                    fill="red"
                                    height={incidentValue.y0 - incidentValue.y}
                                    x={xScaleWithZoom(incidentValue.x)}
                                    y={incidentValue.y}
                                    width={INCIDENT_BAR_WIDTH}
                                />
                            }
                        </LineChart>
                        <LineChart
                            {...baseChartProps}
                            axisXLabel={t(I18N_NAMESPACE, "containers.incidentChart.charts.speed.labels.axisX")}
                            axisYLabel={t(I18N_NAMESPACE, "containers.incidentChart.charts.speed.labels.axisY")}
                            chartData={chartsData.speedChart}
                            id="speed"
                            xScaleWithZoom={xScaleWithZoom}
                            yScale={ySpeedScale}
                            yTickValues={ySpeedTickValues}
                            zoom={zoom}
                        >
                            {
                                incidentValue && <rect
                                    fill="red"
                                    height={incidentValue.y0 - incidentValue.y}
                                    x={xScaleWithZoom(incidentValue.x)}
                                    y={incidentValue.y}
                                    width={INCIDENT_BAR_WIDTH}
                                />
                            }
                        </LineChart>
                    </Styled.IncidentChartsWrapper>
                }}
            </Zoom>
        </Styled.Container>
    )
}

IncidentCharts.propTypes = {
    className: PropTypes.string,
}
