export const mulhouseImageCollections = [
    {
        "uuid": "7b9f8f80-a779-4d63-b595-8700a970e5b3",
        "type": "PHOTO",
        "date": "2021-10-18",
        "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
    },
    {
        "uuid": "7c1fa6c8-13b8-4fa8-9c3e-1f32f892e894",
        "type": "PANO",
        "date": "2025-10-30",
        "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
    },
]

export const allImageCollections = {
    "mulhouse": mulhouseImageCollections,
}

export const getImageCollectionsBySlug = (networkSlug, date = null) => {
    const networkImageCollections = allImageCollections[networkSlug] || []
    return networkImageCollections.filter(ic => {
        return !date || new Date(ic.date) <= new Date(date)
    })
}

export const mockImageUrl = "https://shotlogiroadcenter.blob.core.windows.net/media/Media/test_pano/20230909_Montargis1_Track01_Sphere_00029.jpg?se=2024-02-25T10%3A25%3A51Z&sp=r&sv=2023-11-03&sr=b&sig=SmivWyDfKtS6IRo21fuMm3wIk95c5jUVHUFQd93qbKs%3D" 
