import { I18N_NAMESPACE } from "../../../common/constants/i18n"

export const roadworksQuantityUnits = (t) => {
    return [
        "u",
        "m",
        "m²",
        "m³",
        "t",
        "h",
        t(I18N_NAMESPACE, "form.roadworkTaskSubForm.unit.package"),
    ]
} 