import { useMemo } from "react"

import { useFormikContext } from "formik"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { Select } from "../../components/Select"

export const FormikSelect = (props) => {
    const {
        name,
        onChange,
        select,
        ...selectProps
    } = props

    const { values, setFieldValue } = useFormikContext()

    const SelectComponent = select || Select

    const defaultValue = useMemo(() => {
        return props.multiple ? [] : ""
    }, [props.multiple])

    const onValueChange = (e) => {
        const newValue = e.target.value
        const isNewValueResetting = !newValue || (Array.isArray(newValue) && newValue.indexOf(null) >= 0)
        const value = isNewValueResetting ? defaultValue : newValue
        setFieldValue(name, value)
        onChange?.(value)
    }

    return (
        <SelectComponent
            value={values[name] ?? defaultValue}
            onChange={onValueChange}
            {...selectProps}
        />
    )
}

FormikSelect.propTypes = {
    name: PropTypes.string.isRequired,
    ...Select.propTypes,
}

FormikSelect.defaultProps = {
    color: "border/border-primary",
}

