import { styled } from "@l2r-front/l2r-ui"

import {
    YearGradingBadge as L2RYearGradingBadge,
    YearGradingBadgeError as L2RYearGradingBadgeError,
    YearGradingBadgeSkeleton as L2RYearGradingBadgeSkeleton,
} from "../../components/YearGradingBadge"

export const YearGradingBadgeSkeleton = styled(L2RYearGradingBadgeSkeleton)(({ theme }) => ({
    marginRight: theme.spacing(3),
}))

export const YearGradingBadge = styled(L2RYearGradingBadge)(({ theme }) => ({
    marginRight: theme.spacing(3),
}))

export const YearGradingBadgeError = styled(L2RYearGradingBadgeError)(({ theme }) => ({
    marginRight: theme.spacing(3),
}))