import { styled } from "@l2r-front/l2r-ui"

import { APPBAR_HEIGHT } from "../../../../common/constants/layout"

export const ContentWrapper = styled("div")(({ theme }) => ({
    height: "100%",
    paddingTop: APPBAR_HEIGHT,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    width: "100%",
    overflowY: "scroll",
    position: "relative",
}))