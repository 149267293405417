import { Skeleton as UiSkeleton, Typography, styled } from "@l2r-front/l2r-ui"

import { IncidentChartsError } from "./IncidentCharts.error"

export const IncidentChartsWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
})

export const Skeleton = styled(UiSkeleton)(({ padding }) => ({
    transform: "scale(1)",
    marginBottom: padding,
}))

export const Error = styled(IncidentChartsError)(({ height, padding, width }) => ({
    height,
    marginBottom: padding,
    width,
}))

export const ErrorWrapper = styled("div")({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
})

export const ErrorMessage = styled(Typography)(({ theme }) => ({
    color: theme.palette["surfaces/surface-error"].main,
    textAlign: "center",
}))

export const Container = styled("div")({
    height: "100%",
    width: "100%",
})
