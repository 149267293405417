import { Fab as UiFab, Typography as UiTypography, styled } from "@l2r-front/l2r-ui"

import { MOBILE_FILTER_BUTTON_PADDING } from "../../constants/layout"

export const Fab = styled(UiFab)({
    position: "relative",
})

export const FilterCount = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.common.black,
    borderRadius: "50%",
    position: "absolute",
    right: 0,
    top: 0,
    transform: "translateX(35%) translateY(-35%)",
    width: 20,
    height: 20,
}))

export const Wrapper = styled("div")(({ theme }) => ({
    bottom: theme.spacing(10),
    position: "absolute",
    right: MOBILE_FILTER_BUTTON_PADDING,
    zIndex: theme.zIndex.mobileStepper,
}))

export const Typography = styled(UiTypography)(({ theme }) => ({
    color: theme.palette.common.white,
}))