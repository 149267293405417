import { RoadStructureIcon, RoadSurfaceAndStructureIcon, RoadSurfaceIcon } from "@l2r-front/l2r-icons"

export const damagesCauseConfig = {
    BO: {
        icon: RoadSurfaceAndStructureIcon,
    },
    SU: {
        icon: RoadSurfaceIcon,
    },
    ST: {
        icon: RoadStructureIcon,
    },
}
