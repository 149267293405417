import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./AuthenticatedMobileLayout.styled"

export function AuthenticatedMobileLayout(props) {
    const { children } = props

    return (
        <>
            <Styled.MobileAppBar />
            <Styled.ChildrenWrapper>
                {children}
            </Styled.ChildrenWrapper>
            <Styled.MobileBottomAppBar />
        </>
    )
}

AuthenticatedMobileLayout.propTypes = {
    children: PropTypes.node.isRequired,
}
