import { useState, useRef, useEffect } from "react"

export function useCrossTabState(stateKey, defaultValue) {
    const [state, setState] = useState(defaultValue)
    const isNewSession = useRef(true)

    useEffect(() => {
        if (isNewSession.current) {
            const currentState = localStorage.getItem(stateKey)
            if (currentState && currentState !== "null" && currentState !== "undefined") {
                setState(JSON.parse(currentState))
            } else {
                setState(defaultValue)
            }
            isNewSession.current = false
        } else if (state !== defaultValue) {
            try {
                localStorage.setItem(stateKey, JSON.stringify(state))
            } catch (error) {
                console.error("Error setting value in local storage")
            }
        }
    }, [state, stateKey, defaultValue])

    useEffect(() => {
        const onReceieveMessage = (e) => {
            const { key, newValue } = e
            if (key === stateKey) {
                setState(JSON.parse(newValue))
            }
        }
        window.addEventListener("storage", onReceieveMessage)
        return () => window.removeEventListener("storage", onReceieveMessage)
    }, [stateKey, setState])

    return [state, setState]
}