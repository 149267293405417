import { PropTypes } from "@l2r-front/l2r-proptypes"

export const SvgLinearGradient = (props) => {
    const {
        colorStart,
        colorEnd,
        ...gradientProps
    } = props

    return (
        <svg width={0} height={0} style={{ position: "absolute" }}>
            <defs>
                <linearGradient {...gradientProps}>
                    <stop offset={0} stopColor={colorStart} />
                    <stop offset={1} stopColor={colorEnd} />
                </linearGradient>
            </defs>
        </svg>
    )
}

SvgLinearGradient.propTypes = {
    id: PropTypes.string.isRequired,
    x1: PropTypes.cssSize,
    y1: PropTypes.cssSize,
    x2: PropTypes.cssSize,
    y2: PropTypes.cssSize,
    colorStart: PropTypes.string.isRequired,
    colorEnd: PropTypes.string.isRequired,
}

SvgLinearGradient.defaultProps = {
    x1: 0,
    y1: 0,
    x2: 1,
    y2: 0,
}


