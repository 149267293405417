import { styled } from "@mui/material"
import { default as MuiTab } from "@mui/material/Tab"
import { default as MuiTabs } from "@mui/material/Tabs"

export const Tabs = styled(MuiTabs)(() => ({
    "& .MuiTabs-indicator": {
        height: 3,
    },
}))

export const Tab = styled(MuiTab)(({ theme }) => ({
    fontSize: theme.typography.fontSize,
}))