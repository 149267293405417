export const networkConditionCD91Small2023 = {
    "type": "FeatureCollection",
    "features": [
        {
            "id": "3badaf9f-1b6f-461c-b3f5-34b5a4ee4455",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223888357097191,
                        48.60172451185469,
                    ],
                    [
                        2.223671254822997,
                        48.601751114400976,
                    ],
                    [
                        2.223190204019645,
                        48.60181274497323,
                    ],
                    [
                        2.223063739127896,
                        48.6018293997934,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 24,
                    "end": 86,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "14ca325d-f07d-491f-a4ed-ed555a8b8b69",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.218375635459934,
                        48.60245462374361,
                    ],
                    [
                        2.218277327671386,
                        48.60246785262015,
                    ],
                    [
                        2.217618202080539,
                        48.602554768590586,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 490,
                    "end": 547,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "55e4b576-b3d1-442f-9d3c-ef9e0f8c783d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.21971675976285,
                        48.60227390534327,
                    ],
                    [
                        2.219225956778939,
                        48.602340042986896,
                    ],
                    [
                        2.219099561790573,
                        48.60235720523168,
                    ],
                    [
                        2.219052873713803,
                        48.60236348817371,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 389,
                    "end": 439,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "07105f01-5b0a-4fde-aa82-c888809dba65",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235929791959982,
                        48.60008101602789,
                    ],
                    [
                        2.235804629626861,
                        48.60009436391608,
                    ],
                    [
                        2.235723129595221,
                        48.60010283633747,
                    ],
                    [
                        2.235614375322645,
                        48.60012012932657,
                    ],
                    [
                        2.235611091230772,
                        48.600121037986526,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1022,
                    "end": 1046,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "3d9789a8-c201-4fd2-9fa6-31fd40914d83",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227663944431005,
                        48.60120948144517,
                    ],
                    [
                        2.227446692231247,
                        48.60123683659587,
                    ],
                    [
                        2.226815580920845,
                        48.601330004837926,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1383,
                    "end": 1447,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "db606331-7cca-47db-ad86-85497550f149",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.22530135915798,
                        48.60153518592711,
                    ],
                    [
                        2.225217776375166,
                        48.6015461938982,
                    ],
                    [
                        2.224929265360382,
                        48.60158505635984,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1561,
                    "end": 1589,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "e801651b-9e31-44bb-aa4b-836d1e192aba",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.226205624775,
                        48.601416088808506,
                    ],
                    [
                        2.225899770688355,
                        48.6014563724274,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1493,
                    "end": 1516,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "3efb5b89-28c3-4a7f-af05-320b7e0f1b36",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.241310737902917,
                        48.599396517739,
                    ],
                    [
                        2.241294018616715,
                        48.599400830363315,
                    ],
                    [
                        2.241226081845299,
                        48.599409392984334,
                    ],
                    [
                        2.241158145051668,
                        48.59941795556659,
                    ],
                    [
                        2.241090208235824,
                        48.59942651810995,
                    ],
                    [
                        2.241022271397766,
                        48.599435080614505,
                    ],
                    [
                        2.241005458235251,
                        48.59943719967609,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1957,
                    "end": 1980,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "a8b80850-1fe4-412f-a465-91fa389fba41",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.242874688348504,
                        48.599143408712216,
                    ],
                    [
                        2.242394955547445,
                        48.59923692509447,
                    ],
                    [
                        2.242102153401937,
                        48.599278569158784,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1838,
                    "end": 1897,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "47224b1d-2707-4910-adea-97d91394fe65",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.237657843798531,
                            48.599870560582524,
                        ],
                        [
                            2.237312702935555,
                            48.599915127110165,
                        ],
                    ],
                    [
                        [
                            2.243238222615819,
                            48.59907137196787,
                        ],
                        [
                            2.243069589055696,
                            48.599105151837236,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1784,
                    "end": 1823,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "7a1de9a0-c23a-43a7-af53-f1233ecb838f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.22245269797798,
                        48.601909868939465,
                    ],
                    [
                        2.22189478920119,
                        48.60198333802496,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 132,
                    "end": 174,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "b1f2b217-27d2-4afc-a073-bca1fbcb6893",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.220978221384022,
                            48.60210403155152,
                        ],
                        [
                            2.220938370513529,
                            48.602109278935814,
                        ],
                    ],
                    [
                        [
                            2.233311532827964,
                            48.60048313682141,
                        ],
                        [
                            2.233008583172303,
                            48.60051716786905,
                        ],
                        [
                            2.232946580444002,
                            48.60052588813796,
                        ],
                    ],
                    [
                        [
                            2.245671371299025,
                            48.59859745301532,
                        ],
                        [
                            2.245646585621087,
                            48.598623383239875,
                        ],
                        [
                            2.245623194897372,
                            48.5986466236143,
                        ],
                        [
                            2.245582045143288,
                            48.59867874705579,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 243,
                    "end": 284,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "9c71992e-402f-46d3-9fdd-8f15a0c9d737",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220779030416389,
                        48.602130752657594,
                    ],
                    [
                        2.220035441568682,
                        48.60223096053451,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 309,
                    "end": 365,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "1591386d-24f3-4b08-8f50-a5f1778f9f33",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.215928456581404,
                            48.60277050555471,
                        ],
                        [
                            2.215755120975147,
                            48.602791299127105,
                        ],
                    ],
                    [
                        [
                            2.235031286121271,
                            48.6005769593983,
                        ],
                        [
                            2.235014841414818,
                            48.60058854802445,
                        ],
                        [
                            2.2349478377561,
                            48.600625899023164,
                        ],
                        [
                            2.234887706792464,
                            48.600656997225784,
                        ],
                        [
                            2.234835606850471,
                            48.60067107034398,
                        ],
                    ],
                    [
                        [
                            2.245103768329297,
                            48.59883403794259,
                        ],
                        [
                            2.245061676900529,
                            48.59883736926856,
                        ],
                        [
                            2.24501691213587,
                            48.598837984941454,
                        ],
                        [
                            2.24496819573179,
                            48.59883047948265,
                        ],
                        [
                            2.24492356065399,
                            48.59882210032941,
                        ],
                        [
                            2.244884350048308,
                            48.598813755567264,
                        ],
                        [
                            2.244837067618137,
                            48.59880086178197,
                        ],
                        [
                            2.244758698362738,
                            48.59878057427097,
                        ],
                        [
                            2.24472905843712,
                            48.598766892806,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 674,
                    "end": 734,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "1537458c-cecc-47cb-9ee9-616c1581baaa",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.235166277606007,
                            48.60040448804558,
                        ],
                        [
                            2.235148424980801,
                            48.60044997434405,
                        ],
                        [
                            2.235120776397827,
                            48.60048577915673,
                        ],
                        [
                            2.235093390526168,
                            48.60050359442633,
                        ],
                        [
                            2.235065610497883,
                            48.60054839399227,
                        ],
                        [
                            2.235024531606149,
                            48.60057511687376,
                        ],
                    ],
                    [
                        [
                            2.245704591021183,
                            48.59826842397631,
                        ],
                        [
                            2.245731194862849,
                            48.59830457500901,
                        ],
                        [
                            2.245730616295194,
                            48.59834474801249,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1211,
                    "end": 1242,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "4032bc62-0956-4b68-9789-215a094bed39",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.226165730790384,
                        48.601421343238194,
                    ],
                    [
                        2.225753492480586,
                        48.601475638227775,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1496,
                    "end": 1527,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "d79d7ec0-3f7a-4aa0-a6b5-9bddfd548947",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.228329928620117,
                            48.601125621884286,
                        ],
                        [
                            2.22803689583967,
                            48.60116252055076,
                        ],
                    ],
                    [
                        [
                            2.233717932180096,
                            48.600468749266206,
                        ],
                        [
                            2.233710505868008,
                            48.600467714348966,
                        ],
                        [
                            2.233534468949251,
                            48.60044858988615,
                        ],
                        [
                            2.233303395893349,
                            48.600483084418464,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1302,
                    "end": 1355,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "9cc4ca1c-9959-4187-a524-3e8c9ec48c64",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.224411092295551,
                            48.60165485241844,
                        ],
                        [
                            2.224198508074539,
                            48.60168348604533,
                        ],
                    ],
                    [
                        [
                            2.234371997625077,
                            48.600678869445545,
                        ],
                        [
                            2.234291022503224,
                            48.60065136181902,
                        ],
                        [
                            2.234230749022198,
                            48.60062414715705,
                        ],
                    ],
                    [
                        [
                            2.245276293069955,
                            48.59881444192839,
                        ],
                        [
                            2.245235480005451,
                            48.59882317878818,
                        ],
                        [
                            2.245167544627809,
                            48.59883174366248,
                        ],
                        [
                            2.245099738879783,
                            48.59883131370682,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1628,
                    "end": 1669,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "db48e16e-464e-4449-a508-f355f80ec61a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.238600340855221,
                        48.59974885457485,
                    ],
                    [
                        2.237790590127885,
                        48.59985341934347,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1713,
                    "end": 1774,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "cc6dca7f-35f5-4af3-ac73-d1236435178a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.241797417965823,
                        48.59932190961015,
                    ],
                    [
                        2.24172902179892,
                        48.59933163701516,
                    ],
                    [
                        2.241660954944716,
                        48.59934919466858,
                    ],
                    [
                        2.24157945702026,
                        48.5993576711042,
                    ],
                    [
                        2.241511520351494,
                        48.599366233888425,
                    ],
                    [
                        2.241443583660515,
                        48.599374796633825,
                    ],
                    [
                        2.241362085661038,
                        48.59938327292042,
                    ],
                    [
                        2.241294018616715,
                        48.599400830363315,
                    ],
                    [
                        2.241226081845299,
                        48.599409392984334,
                    ],
                    [
                        2.241158145051668,
                        48.59941795556659,
                    ],
                    [
                        2.241090208235824,
                        48.59942651810995,
                    ],
                    [
                        2.241022271397766,
                        48.599435080614505,
                    ],
                    [
                        2.240954334537494,
                        48.59944364308023,
                    ],
                    [
                        2.240886397655007,
                        48.59945220550715,
                    ],
                    [
                        2.240885637402146,
                        48.59945225663264,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1920,
                    "end": 1989,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "9a294c33-e983-4f7e-a1c1-d87dbdd986b8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.240101460613831,
                        48.59955292448549,
                    ],
                    [
                        2.239714023454268,
                        48.599596499053476,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2048,
                    "end": 2077,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "35b5d3f5-a51b-40ac-b97c-fdf3f19dacd8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.232353034956526,
                        48.600608180540384,
                    ],
                    [
                        2.231940828143991,
                        48.600660100337365,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2159,
                    "end": 2190,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "dd31a85a-b470-4cdc-9f7b-1a9cf8f23b19",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.229748590975034,
                    48.600945367294116,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 811,
                    "end": 812,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AABEA",
            },
        },
        {
            "id": "f9425653-0454-418f-9e68-b783a95ef206",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.228847065960161,
                    48.601063378747256,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 879,
                    "end": 880,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AABEA",
            },
        },
        {
            "id": "a0d1da26-7c4c-47d5-9b3a-288528c96c47",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.226172379788374,
                    48.60142046750085,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 1495,
                    "end": 1496,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AABEA",
            },
        },
        {
            "id": "11069015-68ab-417a-b205-290213e7e484",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.220201421468889,
                    48.60220859310819,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 352,
                    "end": 353,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "30ded920-6887-44f1-8a6c-8f00385b74fd",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.216614828340937,
                    48.60268707146754,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 622,
                    "end": 623,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "b3bc3340-6f2f-46bd-a9e0-ee3622534c55",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.229960713917483,
                    48.6009175988996,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 795,
                    "end": 796,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "25c6f709-ffd5-4a04-9578-620c8b18dc84",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.229960713917483,
                    48.6009175988996,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 795,
                    "end": 796,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "1e543546-6049-418c-844e-b092685f6fe0",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.235542425285877,
                    48.60014003681607,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 1051,
                    "end": 1052,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "a7150af3-f01d-424c-8922-49670bc475a7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.238806096672386,
                    48.599722283959586,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 1697,
                    "end": 1698,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "c25d0aa8-46ca-4d7a-a2d9-ba47c5ab8be2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.232701015401318,
                    48.60055229283454,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 2132,
                    "end": 2133,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "28deedec-71b0-4837-acd5-e337eec7476b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.235413504529228,
                    48.60018843810575,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 1062,
                    "end": 1063,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AIBAB",
            },
        },
        {
            "id": "06a9bfbb-904d-4d9a-9397-5ee4cee3fb4d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.235039156613392,
                    48.60056560293313,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 1231,
                    "end": 1232,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AIBAB",
            },
        },
        {
            "id": "1b354798-de87-47e8-b1f5-95d9b82416ca",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.226491352443643,
                    48.601377867857764,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 1471,
                    "end": 1472,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AIBAB",
            },
        },
        {
            "id": "63e04f8f-b885-4f5f-8fd4-b1bee7f0fab3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.231189545883467,
                    48.60075472467774,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 2246,
                    "end": 2247,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AIBAB",
            },
        },
        {
            "id": "186416f1-988b-4b8d-83ac-d67b7f53da9e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223928309326487,
                        48.601719616277016,
                    ],
                    [
                        2.223671254822997,
                        48.601751114400976,
                    ],
                    [
                        2.223635381294534,
                        48.60175571046609,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 21,
                    "end": 43,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "dc9cbc7c-c9b5-439b-b081-9e2e71b0be56",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.222120609608935,
                        48.60195360085345,
                    ],
                    [
                        2.221881505639807,
                        48.60198508725698,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 157,
                    "end": 175,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "d08e01f7-414e-4f88-b9c8-fc22c4f371dd",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.233230323736996,
                        48.600492259309725,
                    ],
                    [
                        2.233008583172303,
                        48.60051716786905,
                    ],
                    [
                        2.232933151432434,
                        48.60052777683393,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 263,
                    "end": 285,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "9a85e032-23c2-4b8f-b642-0b804978353b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.219915935952744,
                        48.602247064937934,
                    ],
                    [
                        2.219570697093841,
                        48.60229358809503,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 374,
                    "end": 400,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "9de1efe3-2d53-4db5-bbb5-8a084c50db8b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.216541734691602,
                        48.60269670909446,
                    ],
                    [
                        2.216522834511342,
                        48.60269920113463,
                    ],
                    [
                        2.215968457086378,
                        48.60276570700213,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 628,
                    "end": 671,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "25203540-a60d-482b-864f-db3c4cc56275",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.2346504212638,
                            48.600696908306006,
                        ],
                        [
                            2.23455889806271,
                            48.60069716294671,
                        ],
                        [
                            2.23447355192483,
                            48.60069031724779,
                        ],
                        [
                            2.234381490752197,
                            48.60067893049698,
                        ],
                    ],
                    [
                        [
                            2.245582045143288,
                            48.59867874705579,
                        ],
                        [
                            2.245551847893061,
                            48.59870374340352,
                        ],
                        [
                            2.245537905662384,
                            48.59871348762378,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 748,
                    "end": 773,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "3c337af4-dd69-4a10-9b9d-14db8139d72e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.229423777283477,
                        48.600987886914524,
                    ],
                    [
                        2.229026045514287,
                        48.60103995054691,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 836,
                    "end": 866,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "a2969626-0d4f-4392-b432-0117f8a04dd7",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.234116037096767,
                            48.600560280088466,
                        ],
                        [
                            2.233994509140937,
                            48.60052351574594,
                        ],
                        [
                            2.233900258122193,
                            48.60050499725037,
                        ],
                    ],
                    [
                        [
                            2.245736752378461,
                            48.59844155766139,
                        ],
                        [
                            2.24572886309848,
                            48.59846648132159,
                        ],
                        [
                            2.245714783861307,
                            48.598502374575084,
                        ],
                        [
                            2.245700704604838,
                            48.59853826782582,
                        ],
                        [
                            2.245672934692484,
                            48.59858306993856,
                        ],
                        [
                            2.245659244051239,
                            48.598591978801664,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1253,
                    "end": 1288,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "69cf8471-b8ba-44a4-ae2a-43c33420860c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.2312759767242,
                        48.6007438389334,
                    ],
                    [
                        2.230943550217031,
                        48.60078570683692,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2240,
                    "end": 2265,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "7b6a3df0-4ede-43ea-9311-1b76ec1f4101",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.230491670300733,
                        48.600843405857844,
                    ],
                    [
                        2.23023240338809,
                        48.6008770654562,
                    ],
                    [
                        2.230205280079046,
                        48.600876890076265,
                    ],
                    [
                        2.230185728247241,
                        48.60087963941364,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2299,
                    "end": 2322,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "33f82dac-b5b5-47a3-889e-dc977f978d68",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.221695535687269,
                        48.60200957634939,
                    ],
                    [
                        2.221589267064874,
                        48.60202356998584,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 189,
                    "end": 197,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AKBAA",
            },
        },
        {
            "id": "2c408344-10f3-4ff7-9473-f6ccfd6863ef",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245405871419748,
                        48.598777208209434,
                    ],
                    [
                        2.245383866770249,
                        48.59878453878993,
                    ],
                    [
                        2.245381115661667,
                        48.598787220034914,
                    ],
                    [
                        2.245370800368112,
                        48.59879075892211,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 785,
                    "end": 788,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AKBAA",
            },
        },
        {
            "id": "f2a9493b-6eb3-4792-9e74-37ca6e3a1ddb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.236116774767751,
                        48.60006107506122,
                    ],
                    [
                        2.236063351122917,
                        48.60006677251027,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1008,
                    "end": 1012,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AKBAA",
            },
        },
        {
            "id": "be5896f3-20ba-457b-9ce3-25a3970b3b1a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.242049155971017,
                        48.59928610668473,
                    ],
                    [
                        2.241996158524841,
                        48.59929364418705,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1901,
                    "end": 1905,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AKBAA",
            },
        },
        {
            "id": "2b19b120-749c-420e-8098-e8be20b5bddc",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.217717874691758,
                    48.602541625458194,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 539,
                    "end": 540,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AKDAA",
            },
        },
        {
            "id": "f878f2bd-929a-48b5-929a-c60cbc91ac56",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.245698931317819,
                    48.598541128730616,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 1264,
                    "end": 1265,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AKDAA",
            },
        },
        {
            "id": "90c0fdce-ec25-4b9e-a410-e4872275ab56",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.240081420776561,
                    48.599555178373414,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0097",
                    "start": 2049,
                    "end": 2050,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AKDAA",
            },
        },
        {
            "id": "ddf0ac22-dfcc-4380-b5b7-df54320a27d5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.233338811295164,
                        48.59109658465474,
                    ],
                    [
                        2.233625567115902,
                        48.591072112341124,
                    ],
                    [
                        2.233827966425581,
                        48.591048227437646,
                    ],
                    [
                        2.234546584455256,
                        48.5909610950254,
                    ],
                    [
                        2.234849149363289,
                        48.59094954689306,
                    ],
                    [
                        2.2353120752234,
                        48.59093109123152,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1036,
                    "end": 1183,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "ACBDA",
            },
        },
        {
            "id": "8c490af6-bfcb-4270-a364-252bd2073e4e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.26438834662294,
                        48.5947504665717,
                    ],
                    [
                        2.2644719728534,
                        48.594793912856424,
                    ],
                    [
                        2.264590594797886,
                        48.59484502124902,
                    ],
                    [
                        2.264728390608184,
                        48.594882754523915,
                    ],
                    [
                        2.264811680976603,
                        48.59490324723445,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 678,
                    "end": 714,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "47ea4a0e-d94d-4a72-86e2-ddd2ee5fc1a8",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.244621742678838,
                            48.590786176379915,
                        ],
                        [
                            2.244672835728857,
                            48.59066595859743,
                        ],
                    ],
                    [
                        [
                            2.245480854457734,
                            48.58879180415836,
                        ],
                        [
                            2.245647367592548,
                            48.588399829839354,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 954,
                    "end": 1013,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "4cafcce5-4d38-48eb-aaa5-5d2ee3633916",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.233311930114299,
                            48.59109887871172,
                        ],
                        [
                            2.233625567115902,
                            48.591072112341124,
                        ],
                        [
                            2.233827966425581,
                            48.591048227437646,
                        ],
                        [
                            2.23383456115422,
                            48.59104742784808,
                        ],
                    ],
                    [
                        [
                            2.244680134716858,
                            48.59064878462574,
                        ],
                        [
                            2.244749474864165,
                            48.59048563186046,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1015,
                    "end": 1073,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "c16ac98c-0335-4e9f-b77b-ca02ece417b0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.219473881781263,
                        48.58944568298035,
                    ],
                    [
                        2.219710894959912,
                        48.589456035471116,
                    ],
                    [
                        2.220037900239427,
                        48.589441986519525,
                    ],
                    [
                        2.220107331223948,
                        48.58943550732969,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1268,
                    "end": 1315,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "1442250c-374a-49a5-a467-8cf447ae3810",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.250117256006625,
                        48.58739305471465,
                    ],
                    [
                        2.250307106215488,
                        48.587408261418275,
                    ],
                    [
                        2.250453380342712,
                        48.58741997753347,
                    ],
                    [
                        2.250681329606181,
                        48.58743823521106,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1496,
                    "end": 1538,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "9823a28c-b1e7-4314-9c9e-e169c7eb3435",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.256690954905424,
                        48.5907953241798,
                    ],
                    [
                        2.257240809134047,
                        48.59095065310794,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1793,
                    "end": 1837,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "154e83b2-d804-4f22-9f5a-ede994ba829b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.255564268905603,
                        48.590458825767534,
                    ],
                    [
                        2.255881763770058,
                        48.59056139934653,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1702,
                    "end": 1728,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "2d1c69f0-93a1-42e9-bd52-e504b246fabc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227989672604809,
                        48.59049187855755,
                    ],
                    [
                        2.228358439452722,
                        48.59058063130069,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1933,
                    "end": 1962,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "b6acf939-f0ee-4048-878a-7b903135a380",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.227502345364066,
                            48.590383940033504,
                        ],
                        [
                            2.227699762059131,
                            48.590422103490546,
                        ],
                        [
                            2.227811647677203,
                            48.5904490319945,
                        ],
                    ],
                    [
                        [
                            2.257775071506881,
                            48.59110697490044,
                        ],
                        [
                            2.257870597170255,
                            48.591137590796926,
                        ],
                        [
                            2.257958336814422,
                            48.59116602435011,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1880,
                    "end": 1919,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "e4af6706-1b9e-40d7-a456-60880235f2a8",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.228968814912378,
                            48.59072752986813,
                        ],
                        [
                            2.228994247297491,
                            48.59073365057348,
                        ],
                    ],
                    [
                        [
                            2.2626585117207,
                            48.59346753263306,
                        ],
                        [
                            2.262924474421715,
                            48.59374039230387,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2010,
                    "end": 2048,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "39cd23cb-8e59-468c-95ee-ca0c793a3f78",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.238252460816152,
                            48.59115938573596,
                        ],
                        [
                            2.23827964063419,
                            48.59115914807497,
                        ],
                    ],
                    [
                        [
                            2.26341207991028,
                            48.59424063332947,
                        ],
                        [
                            2.263527440420881,
                            48.59435898150543,
                        ],
                        [
                            2.263584825309199,
                            48.59442500460388,
                        ],
                        [
                            2.263665464761674,
                            48.59447677852108,
                        ],
                        [
                            2.263769358781453,
                            48.5945143032116,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2114,
                    "end": 2157,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "c24ed36b-22d0-4210-9390-89982d5b480d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.252072967855186,
                        48.588613098850956,
                    ],
                    [
                        2.252162199402648,
                        48.588821459755245,
                    ],
                    [
                        2.252232480474539,
                        48.58893254817359,
                    ],
                    [
                        2.252287877154054,
                        48.58901988127385,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2296,
                    "end": 2344,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "524153ae-5281-4f8f-9ba5-4f71b614f191",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.241982511337319,
                        48.59112986613894,
                    ],
                    [
                        2.242447209285567,
                        48.591217642110514,
                    ],
                    [
                        2.242544856065356,
                        48.59123420378526,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2427,
                    "end": 2470,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "2e0188f6-d140-401e-bd42-515f9e442d25",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.231464096077683,
                            48.59106175967696,
                        ],
                        [
                            2.23158324720736,
                            48.59108053465442,
                        ],
                        [
                            2.231800061224829,
                            48.591090929854175,
                        ],
                    ],
                    [
                        [
                            2.267079533202802,
                            48.59529845852845,
                        ],
                        [
                            2.267242004769074,
                            48.595317449996585,
                        ],
                        [
                            2.267475298439871,
                            48.595355815601906,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2625,
                    "end": 2680,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "e18c7aa6-658b-417a-96a1-2bf20f2831fb",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.222677507175272,
                            48.58993422792878,
                        ],
                        [
                            2.222794955783171,
                            48.58996012083359,
                        ],
                    ],
                    [
                        [
                            2.245094058236017,
                            48.589665792806606,
                        ],
                        [
                            2.245278339223788,
                            48.589261076289795,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2984,
                    "end": 3040,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "b4ecf714-7c31-43a9-93ef-dce3f8abfcd0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.249366691708824,
                        48.587329330557516,
                    ],
                    [
                        2.249902493114038,
                        48.587372250089345,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3325,
                    "end": 3365,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "dbd2c92b-5d03-424b-bbed-71ec191f84e3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.247538702724738,
                        48.58717622488,
                    ],
                    [
                        2.248023516024568,
                        48.58721782322955,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3411,
                    "end": 3447,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "7cefc253-5f29-4e8f-8ced-b463e9d456cb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220582991575013,
                        48.58947784827592,
                    ],
                    [
                        2.220663401156132,
                        48.58950725462925,
                    ],
                    [
                        2.220933638614959,
                        48.589571993734936,
                    ],
                    [
                        2.221274708105751,
                        48.589630937309295,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3506,
                    "end": 3560,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "b6512445-ab35-419d-be26-e466b98bdd61",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.221950666183278,
                        48.58976117025403,
                    ],
                    [
                        2.222294958686098,
                        48.58984314549838,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3612,
                    "end": 3639,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "2e0622ba-03b1-43e6-8f87-681e9ffa375e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.222371468277508,
                        48.58986136208335,
                    ],
                    [
                        2.222626500633185,
                        48.58992208367615,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3645,
                    "end": 3665,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "ae0d85c6-1887-4798-b578-0d954c931509",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.246130463596313,
                            48.58724989101739,
                        ],
                        [
                            2.246148620588371,
                            48.58720657851872,
                        ],
                    ],
                    [
                        [
                            2.260195851843144,
                            48.59202644313497,
                        ],
                        [
                            2.260495032979556,
                            48.59215380249637,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3849,
                    "end": 3880,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "2ca5aaf5-8324-4c4f-9e62-cb2e948da9d3",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.246031128522139,
                            48.586890984811696,
                        ],
                        [
                            2.246126552727344,
                            48.5868556064813,
                        ],
                        [
                            2.24615475496263,
                            48.58685147644418,
                        ],
                    ],
                    [
                        [
                            2.246148620588371,
                            48.58720657851872,
                        ],
                        [
                            2.246135709672301,
                            48.587161518252096,
                        ],
                        [
                            2.246136227452157,
                            48.587125538739265,
                        ],
                        [
                            2.246123187119717,
                            48.58708947334693,
                        ],
                        [
                            2.246083160054717,
                            48.58704424130872,
                        ],
                    ],
                    [
                        [
                            2.260518046973386,
                            48.592163599338626,
                        ],
                        [
                            2.260667638143122,
                            48.592227278702936,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3882,
                    "end": 3924,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "67ad5f7d-f232-4ac1-919a-16e784c44b60",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.223799023130384,
                            48.590202422372286,
                        ],
                        [
                            2.224110211212378,
                            48.590249429826805,
                        ],
                        [
                            2.224489194381047,
                            48.590296878202295,
                        ],
                    ],
                    [
                        [
                            2.260991288624389,
                            48.59235522757761,
                        ],
                        [
                            2.261025624497292,
                            48.59236948268343,
                        ],
                    ],
                    [
                        [
                            2.263949799495756,
                            48.5944557690397,
                        ],
                        [
                            2.263985734800781,
                            48.59446076798191,
                        ],
                        [
                            2.264026288555832,
                            48.594470014350414,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3952,
                    "end": 4013,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "260f809c-1c68-4f10-a403-ff87badba3ff",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.230684760524253,
                            48.59093997473226,
                        ],
                        [
                            2.230987966507441,
                            48.59098673327829,
                        ],
                    ],
                    [
                        [
                            2.252935019347687,
                            48.5895882467518,
                        ],
                        [
                            2.253123944366242,
                            48.589652402117025,
                        ],
                        [
                            2.253164364236825,
                            48.589670647089456,
                        ],
                        [
                            2.253284052477421,
                            48.58970954830751,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4256,
                    "end": 4308,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "6beff993-fd3f-488b-8db2-78df076d00a9",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.246083160054717,
                            48.58704424130872,
                        ],
                        [
                            2.246067856631181,
                            48.58699916587276,
                        ],
                    ],
                    [
                        [
                            2.259416855481338,
                            48.59172302901166,
                        ],
                        [
                            2.259696823314087,
                            48.59183443186032,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4626,
                    "end": 4655,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "afd59899-4828-4def-acee-4a481113e06e",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.220422289710097,
                            48.58930777002277,
                        ],
                        [
                            2.220462697921511,
                            48.589326026103784,
                        ],
                        [
                            2.220503106161091,
                            48.589344282170806,
                        ],
                        [
                            2.220502570753402,
                            48.58938026150509,
                        ],
                        [
                            2.220502035344977,
                            48.58941624083836,
                        ],
                        [
                            2.220497087487253,
                            48.58942577335339,
                        ],
                    ],
                    [
                        [
                            2.226522304347073,
                            48.59034920260416,
                        ],
                        [
                            2.22669889825379,
                            48.59033824215887,
                        ],
                        [
                            2.227011018903668,
                            48.590322278191614,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4843,
                    "end": 4895,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": null,
                "damage_type": "AHHAB",
            },
        },
        {
            "id": "85a4e52c-1914-4f46-bc64-f6acc2a8e9a3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.225552346205816,
                        48.590411109357255,
                    ],
                    [
                        2.225621096760776,
                        48.590413995263745,
                    ],
                    [
                        2.226024701014474,
                        48.59038543367043,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 40,
                    "end": 75,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "080617fb-9f1a-4a52-bc98-9835fb980b82",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.226092144350114,
                            48.59038066081629,
                        ],
                        [
                            2.226307962941572,
                            48.59036538742608,
                        ],
                    ],
                    [
                        [
                            2.227016415923118,
                            48.59032411238071,
                        ],
                        [
                            2.227046218994584,
                            48.5903261050659,
                        ],
                        [
                            2.227217122117071,
                            48.59034744208891,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 80,
                    "end": 111,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "3ca5aef9-c79e-42c0-ad37-c0a05ca28f53",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245303228746893,
                        48.589210531166934,
                    ],
                    [
                        2.245443851200478,
                        48.588878909513916,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 119,
                    "end": 157,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "5ad3d441-1080-44df-9272-5941e3f48bd1",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.24098758207596,
                            48.59105552480923,
                        ],
                        [
                            2.241028423738649,
                            48.591055677293575,
                        ],
                    ],
                    [
                        [
                            2.265178429598887,
                            48.59499348094964,
                        ],
                        [
                            2.265367163138829,
                            48.59504346229116,
                        ],
                    ],
                    [
                        [
                            2.266503486283121,
                            48.59527601958153,
                        ],
                        [
                            2.266605163071593,
                            48.59527844518431,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 229,
                    "end": 255,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "ac617d80-de0b-4b6b-9e16-2709f590485c",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.252364117327363,
                            48.5891150878087,
                        ],
                        [
                            2.252562136166252,
                            48.58929617036056,
                        ],
                    ],
                    [
                        [
                            2.266397108271432,
                            48.595262252013704,
                        ],
                        [
                            2.266503486283121,
                            48.59527601958153,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 335,
                    "end": 368,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "c870356d-0723-42ec-bb5d-e2266a34ec42",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.245647367592548,
                            48.588399829839354,
                        ],
                        [
                            2.245716708781603,
                            48.5882361879183,
                        ],
                    ],
                    [
                        [
                            2.252641344085139,
                            48.58936860328158,
                        ],
                        [
                            2.252809846590139,
                            48.58952269217839,
                        ],
                        [
                            2.252947183755751,
                            48.58959102180422,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 378,
                    "end": 431,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "f02efd04-fc78-4979-8984-4458788be6c1",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.245749554456791,
                            48.588158673302374,
                        ],
                        [
                            2.245884585655773,
                            48.58784000195692,
                        ],
                    ],
                    [
                        [
                            2.257958336814422,
                            48.59116602435011,
                        ],
                        [
                            2.258091327147169,
                            48.59120901561142,
                        ],
                    ],
                    [
                        [
                            2.260037981848081,
                            48.591970588370955,
                        ],
                        [
                            2.260195750208991,
                            48.592033639020954,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 440,
                    "end": 502,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "91eb6225-185a-48b0-885c-d144b14c42d8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.23383456115422,
                        48.59104742784808,
                    ],
                    [
                        2.234546584455256,
                        48.5909610950254,
                    ],
                    [
                        2.234554524135104,
                        48.59096079199794,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1073,
                    "end": 1127,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "7a60b5db-853a-4ef0-82b6-231449c91fc2",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.233311930114299,
                            48.59109887871172,
                        ],
                        [
                            2.233625567115902,
                            48.591072112341124,
                        ],
                        [
                            2.233781199256635,
                            48.59105374640857,
                        ],
                    ],
                    [
                        [
                            2.244716629585073,
                            48.590562914757015,
                        ],
                        [
                            2.244749474864165,
                            48.59048563186046,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1025,
                    "end": 1069,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "970d224e-7426-4b50-9154-144c12c06e86",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.219608766573867,
                        48.58945157466711,
                    ],
                    [
                        2.219710894959912,
                        48.589456035471116,
                    ],
                    [
                        2.220037900239427,
                        48.589441986519525,
                    ],
                    [
                        2.22018764658215,
                        48.58942801237472,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1278,
                    "end": 1321,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "3bf486c6-7de1-46a9-abd7-a8d9bddda762",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.218411516972762,
                            48.5893872386649,
                        ],
                        [
                            2.218452030915029,
                            48.58938605810762,
                        ],
                    ],
                    [
                        [
                            2.234730400397032,
                            48.590954079331134,
                        ],
                        [
                            2.234849149363289,
                            48.59094954689306,
                        ],
                        [
                            2.235393236627949,
                            48.59092785534854,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1140,
                    "end": 1192,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "cc25b8b2-5927-4add-b23e-35048fec568a",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.24456700727779,
                            48.59091446711807,
                        ],
                        [
                            2.244595059451889,
                            48.590849876314,
                        ],
                        [
                            2.244614567721796,
                            48.59080330491996,
                        ],
                    ],
                    [
                        [
                            2.269730287551343,
                            48.59541636059007,
                        ],
                        [
                            2.269961143770789,
                            48.59540972027586,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1450,
                    "end": 1480,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "c47efd8d-a4d9-4526-9468-e1e9320968a7",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.251097550831397,
                            48.58747222645478,
                        ],
                        [
                            2.251114313509566,
                            48.58747361437618,
                        ],
                        [
                            2.251634370899419,
                            48.587517366133646,
                        ],
                    ],
                    [
                        [
                            2.254489680129126,
                            48.590111647306394,
                        ],
                        [
                            2.254599580600094,
                            48.590147154644335,
                        ],
                    ],
                    [
                        [
                            2.272927829586205,
                            48.59515710966892,
                        ],
                        [
                            2.272964292413546,
                            48.59516812710916,
                        ],
                        [
                            2.272989919542462,
                            48.59517817879916,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1569,
                    "end": 1623,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "0df29cdb-e307-4837-8258-908e32c28766",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.228320291100161,
                        48.5905714500357,
                    ],
                    [
                        2.228828936900503,
                        48.59069386589109,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1959,
                    "end": 1999,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "d8abb1c8-5ed6-468a-8ec7-bc575ca95ff9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.241851899358577,
                        48.59110519469314,
                    ],
                    [
                        2.242447209285567,
                        48.591217642110514,
                    ],
                    [
                        2.24255801323446,
                        48.59123643533942,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2417,
                    "end": 2471,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "21b232a5-0b12-4a99-a9b2-9f67745f66db",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.241028423738649,
                            48.591055677293575,
                        ],
                        [
                            2.241123408361638,
                            48.59105822845689,
                        ],
                    ],
                    [
                        [
                            2.252161915141959,
                            48.588820795992625,
                        ],
                        [
                            2.252162199402648,
                            48.588821459755245,
                        ],
                        [
                            2.252232480474539,
                            48.58893254817359,
                        ],
                        [
                            2.252304092029148,
                            48.58904544403208,
                        ],
                        [
                            2.252364117327363,
                            48.5891150878087,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2320,
                    "end": 2363,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "4b4ccd4b-1610-40eb-9d96-c1d840aa8a04",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.245026245404668,
                            48.58981720976851,
                        ],
                        [
                            2.245082295523098,
                            48.58969162576154,
                        ],
                    ],
                    [
                        [
                            2.245926788466945,
                            48.587734120164626,
                        ],
                        [
                            2.245937997634592,
                            48.587709003238885,
                        ],
                    ],
                    [
                        [
                            2.271567609761538,
                            48.595352994120354,
                        ],
                        [
                            2.271798634957881,
                            48.5953184251789,
                        ],
                        [
                            2.271920802543982,
                            48.5953101768557,
                        ],
                        [
                            2.272042970091606,
                            48.59530192840703,
                        ],
                        [
                            2.272151702320987,
                            48.59528460207387,
                        ],
                        [
                            2.272237911739468,
                            48.59527798869603,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3157,
                    "end": 3225,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "56371235-3684-4553-93f7-c9a78014591e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.247188560264826,
                        48.5871461803985,
                    ],
                    [
                        2.247848444466367,
                        48.58720280183128,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3385,
                    "end": 3434,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "d69e41d4-a8f2-4648-80e8-4c88dbf57e55",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.245937997634592,
                            48.587709003238885,
                        ],
                        [
                            2.246039678185658,
                            48.58746645344621,
                        ],
                    ],
                    [
                        [
                            2.246468020634707,
                            48.586936616058125,
                        ],
                        [
                            2.246558599179702,
                            48.586984282138495,
                        ],
                        [
                            2.246679845839838,
                            48.58703902382132,
                        ],
                        [
                            2.246801222083986,
                            48.58708477049887,
                        ],
                        [
                            2.246949973397527,
                            48.5871126988657,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3755,
                    "end": 3824,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "3f98259e-5539-4387-b0a9-87581cab56e0",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.246148620588371,
                            48.58720657851872,
                        ],
                        [
                            2.246135709672301,
                            48.587161518252096,
                        ],
                        [
                            2.246136227452157,
                            48.587125538739265,
                        ],
                        [
                            2.246123187119717,
                            48.58708947334693,
                        ],
                        [
                            2.246096927569767,
                            48.58705979911163,
                        ],
                    ],
                    [
                        [
                            2.260218865725061,
                            48.592036240036094,
                        ],
                        [
                            2.260667638143122,
                            48.592227278702936,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3856,
                    "end": 3912,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "46f3a230-0d55-4fcd-8d72-82b908ef8363",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.224196170882927,
                            48.590260192019116,
                        ],
                        [
                            2.224489194381047,
                            48.590296878202295,
                        ],
                    ],
                    [
                        [
                            2.260991288624389,
                            48.59235522757761,
                        ],
                        [
                            2.261368984254199,
                            48.5925120331878,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3988,
                    "end": 4043,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "2339aab7-eb2f-4f2d-823d-e728051ca69f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.229370687775955,
                        48.590774194436186,
                    ],
                    [
                        2.229648394300119,
                        48.59078916152099,
                    ],
                    [
                        2.229773859222315,
                        48.5908038692652,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4157,
                    "end": 4187,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "41443870-0025-4a44-a0a2-467d7d924bde",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.258868588591748,
                        48.59150486316289,
                    ],
                    [
                        2.259195215124965,
                        48.59163483461562,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4579,
                    "end": 4607,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "9c9e3b8e-9bed-434c-9195-2e62a9c55d82",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.237955596561477,
                            48.591103377620364,
                        ],
                        [
                            2.238240257579361,
                            48.59115930763737,
                        ],
                    ],
                    [
                        [
                            2.258227005477598,
                            48.59124955944555,
                        ],
                        [
                            2.258786932211707,
                            48.591472370157526,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4502,
                    "end": 4572,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "8d473518-285f-458f-8dbb-8e1fcf41f770",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.224489194381047,
                            48.590296878202295,
                        ],
                        [
                            2.22471337927162,
                            48.59032578708697,
                        ],
                    ],
                    [
                        [
                            2.246073977997435,
                            48.587017196047576,
                        ],
                        [
                            2.246031128522139,
                            48.586890984811696,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4653,
                    "end": 4684,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "28aa8f36-e3ac-4c7f-839a-d639d3b06c93",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.226535867676704,
                        48.59034836079446,
                    ],
                    [
                        2.22669889825379,
                        48.59033824215887,
                    ],
                    [
                        2.226997436660539,
                        48.59032297289691,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4844,
                    "end": 4878,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AFEAC",
            },
        },
        {
            "id": "294b4348-33f4-455c-90df-8a8241d74c29",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.240395377993701,
                    48.591053312211436,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 185,
                    "end": 186,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AABEA",
            },
        },
        {
            "id": "d3dbbd01-decc-4034-9814-a90a09c926e9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.23345305628757,
                    48.59108683484484,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 1044,
                    "end": 1045,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AABEA",
            },
        },
        {
            "id": "db8b34f4-168e-4a2b-b619-8dfce792d7fd",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.244616361461496,
                    48.59079902278501,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 1480,
                    "end": 1481,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AABEA",
            },
        },
        {
            "id": "53328792-025a-4066-bdc8-a6363e2f2ac1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.255313937293011,
                    48.59037794984248,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 1681,
                    "end": 1682,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AABEA",
            },
        },
        {
            "id": "d49cd63b-2e43-4b6e-9512-5779fc3cf8af",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.260710358308287,
                    48.59224369709311,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 2537,
                    "end": 2538,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AABEA",
            },
        },
        {
            "id": "932c647a-9300-4e3f-bd94-ec0090339bb9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.231126837491205,
                    48.59100861617512,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 2599,
                    "end": 2600,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AABEA",
            },
        },
        {
            "id": "273e1314-784e-4b7d-b3fc-b5550f82cb15",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.272471780623025,
                    48.595249848286386,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 3242,
                    "end": 3243,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AABEA",
            },
        },
        {
            "id": "212f84bb-a1ca-4670-bc5e-86a29d378a75",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.223741475209893,
                    48.59018995254354,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 3733,
                    "end": 3734,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AABEA",
            },
        },
        {
            "id": "7dd43a9a-750f-4b09-8b78-dd98058fe93f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.266503486283121,
                        48.59527601958153,
                    ],
                    [
                        2.266605163071593,
                        48.59527844518431,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 232,
                    "end": 240,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFFBA",
            },
        },
        {
            "id": "fd8a8187-10b5-4b90-86e8-c1f3e62d2357",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.222677507175272,
                        48.58993422792878,
                    ],
                    [
                        2.222964603977341,
                        48.58999752149055,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3031,
                    "end": 3053,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AFFBA",
            },
        },
        {
            "id": "7abee461-82c8-479e-bfe4-c117b72c3322",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220895604795957,
                        48.58956288225667,
                    ],
                    [
                        2.220933638614959,
                        48.589571993734936,
                    ],
                    [
                        2.221312346577803,
                        48.589637441915784,
                    ],
                    [
                        2.22174515683694,
                        48.589712238356995,
                    ],
                    [
                        2.222677507175272,
                        48.58993422792878,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3531,
                    "end": 3669,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AFFBA",
            },
        },
        {
            "id": "8096f93b-e86d-4195-9b30-3f58271fb196",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.265859433431952,
                        48.59517066580465,
                    ],
                    [
                        2.265899984492156,
                        48.5951805440306,
                    ],
                    [
                        2.266150256571084,
                        48.595224366594614,
                    ],
                    [
                        2.266335628808611,
                        48.595254295222354,
                    ],
                    [
                        2.266503486283121,
                        48.59527601958153,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 294,
                    "end": 343,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AFFBA",
            },
        },
        {
            "id": "ec88a1bb-edb5-4687-b2aa-0d830af72d8f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.266605175666583,
                        48.595277545700284,
                    ],
                    [
                        2.266740591122585,
                        48.595285295017455,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4706,
                    "end": 4716,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFFBA",
            },
        },
        {
            "id": "5e52e2f2-443d-4624-8ff8-837f9a6377af",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.244347734470707,
                    48.59143326520453,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 884,
                    "end": 885,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "a7da6362-4d62-4916-a629-3ba9ad4b7c1f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.255521529307269,
                    48.590445017720405,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 1698,
                    "end": 1699,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "c0918551-6896-4b26-a0fd-e529e15bef19",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.227970598491494,
                    48.59048728786718,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 1931,
                    "end": 1932,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "9ee4fb80-d069-43c4-a40d-4ea08a234161",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.263327117676388,
                    48.594153470279494,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 2102,
                    "end": 2103,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "22792c52-422e-47d1-ac50-8a67ef8495cb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.236816534588784,
                    48.59093423628896,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 2964,
                    "end": 2965,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "3be6bccc-9c86-4748-8eaa-003be333dfb9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.245154832067951,
                    48.589532322512255,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 2999,
                    "end": 3000,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "db1a190b-3fe0-40eb-806c-cfe637d2e593",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.244750941693148,
                    48.59046817553883,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 3069,
                    "end": 3070,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "02f39eeb-fd7e-4ed7-8158-22094d8e70b3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.244809284772929,
                    48.590330222609964,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 3085,
                    "end": 3086,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "b8761944-30cf-4912-9944-5d7109f3f253",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.272337053282519,
                    48.595262232046224,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 3232,
                    "end": 3233,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "5b0f5ad5-08a8-40de-960f-7a9bdd99dc76",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.223600802630706,
                    48.59015947062228,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 3722,
                    "end": 3723,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "3691e6b2-1d7e-4c96-a4cf-105e37602ce9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.261351816221967,
                    48.5925049056865,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 4041,
                    "end": 4042,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "93e935b6-ded9-4267-b913-750e6907f9b7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.261565341375303,
                    48.5925991883329,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 4060,
                    "end": 4061,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "dfd1d65a-2fc6-4956-b667-1c0f41bd3060",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.237042889111169,
                    48.59096346522914,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 4432,
                    "end": 4433,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "7d75ada8-a907-409f-a5e8-2c47c9cc7f0b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.237042889111169,
                    48.59096346522914,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 4432,
                    "end": 4433,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "99669d74-4941-44d4-9fa3-d835a9fddd8e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.23802676175896,
                    48.59111736018867,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 4507,
                    "end": 4508,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AFBAC",
            },
        },
        {
            "id": "53676455-2025-4bec-9807-54757eefc3a2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.240517902972132,
                    48.59105377023203,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 194,
                    "end": 195,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AIBAB",
            },
        },
        {
            "id": "7cf8c062-8347-4eca-8869-ec945a447561",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.266231944818657,
                    48.59523755532544,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 322,
                    "end": 323,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AIBAB",
            },
        },
        {
            "id": "3daf1176-a178-4fb4-8a1e-cddda70ded0d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.238691802462438,
                    48.5911395868324,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 2187,
                    "end": 2188,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AIBAB",
            },
        },
        {
            "id": "3a5766ff-4ea3-4b8b-98ab-227e76b31f4c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.2673769431564,
                    48.59533964094386,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 2672,
                    "end": 2673,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AIBAB",
            },
        },
        {
            "id": "8831a252-be2f-4ee2-9751-7cd0d3ba1ce3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.235416133289571,
                    48.59092256888157,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 2860,
                    "end": 2861,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AIBAB",
            },
        },
        {
            "id": "49c66f4f-62bd-4ae7-8b7d-e5311479a773",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.235470060548775,
                    48.59091853952748,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 2864,
                    "end": 2865,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AIBAB",
            },
        },
        {
            "id": "cbde7565-c0ff-4114-8bad-fb669dd265ba",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.22445589623559,
                    48.59029270935423,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 4007,
                    "end": 4008,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AIBAB",
            },
        },
        {
            "id": "0001bb73-e208-4e71-b5ef-2ee22e7a927c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.253680984233132,
                    48.589838558267395,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 4340,
                    "end": 4341,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AIBAB",
            },
        },
        {
            "id": "d3654866-38ce-4e21-af92-58417db20abf",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.253960345761966,
                    48.58993190350691,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 4363,
                    "end": 4364,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AIBAB",
            },
        },
        {
            "id": "71a3280c-d727-45cf-9d8f-e56480da752b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.225028955575456,
                        48.59036691863758,
                    ],
                    [
                        2.225267381442702,
                        48.59039999905972,
                    ],
                    [
                        2.225443567781628,
                        48.59040654313984,
                    ],
                    [
                        2.225621096760776,
                        48.590413995263745,
                    ],
                    [
                        2.22564701810777,
                        48.590412160947125,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1,
                    "end": 47,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "ABBAA",
            },
        },
        {
            "id": "7a1f19a8-7bba-4c82-b2fa-3cb33500937c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.264623487297282,
                        48.594854028376396,
                    ],
                    [
                        2.264728390608184,
                        48.594882754523915,
                    ],
                    [
                        2.265077257438941,
                        48.5949685890038,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 699,
                    "end": 735,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "ABBAA",
            },
        },
        {
            "id": "a849117d-9877-41f9-83e5-fe0d9832383e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.224329363331193,
                        48.59027686764649,
                    ],
                    [
                        2.224489194381047,
                        48.590296878202295,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3998,
                    "end": 4010,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "ABBAA",
            },
        },
        {
            "id": "f2878db9-2b6e-45a7-b9ca-af8dc3f3297b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.224489194381047,
                        48.590296878202295,
                    ],
                    [
                        2.225003501262026,
                        48.59036319795697,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4667,
                    "end": 4706,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "ABBAA",
            },
        },
        {
            "id": "cc5d6767-6913-4c5f-85bc-aa1bed99c8c7",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.252364117327363,
                            48.5891150878087,
                        ],
                        [
                            2.252443324698633,
                            48.58918752087219,
                        ],
                    ],
                    [
                        [
                            2.266094002879282,
                            48.595214516642855,
                        ],
                        [
                            2.266150256571084,
                            48.595224366594614,
                        ],
                        [
                            2.266335628808611,
                            48.595254295222354,
                        ],
                        [
                            2.266503486283121,
                            48.59527601958153,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 312,
                    "end": 353,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "f6ba26f9-22e5-4be7-840c-7040a2ff8e77",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.24569481161081,
                        48.588287864321146,
                    ],
                    [
                        2.245753203970256,
                        48.58815006056641,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 425,
                    "end": 441,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "86f438e1-273f-4789-8e5e-9364548b347c",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.257958336814422,
                            48.59116602435011,
                        ],
                        [
                            2.258127597275804,
                            48.59122074047482,
                        ],
                    ],
                    [
                        [
                            2.260139404322209,
                            48.592011120956045,
                        ],
                        [
                            2.260195750208991,
                            48.592033639020954,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 486,
                    "end": 505,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "e6f7271d-d108-4f03-ac2d-341883e8cd05",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.243393046767975,
                            48.59135859321727,
                        ],
                        [
                            2.243902298549397,
                            48.59142042957733,
                        ],
                    ],
                    [
                        [
                            2.259992227564687,
                            48.59195231262886,
                        ],
                        [
                            2.260037981848081,
                            48.591970588370955,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 539,
                    "end": 581,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "7041ec9e-dd01-4ace-ab79-1e91b6c7c74d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.264723229599501,
                        48.594881341264525,
                    ],
                    [
                        2.264728390608184,
                        48.594882754523915,
                    ],
                    [
                        2.265102550469912,
                        48.59497481199837,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 707,
                    "end": 737,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "2fda94b1-edfb-46c8-ad61-3b25d9daa6a4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244393821516273,
                        48.59132427795032,
                    ],
                    [
                        2.244497055802411,
                        48.59108014640095,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 897,
                    "end": 925,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "06c08b65-d174-4ace-bcbd-1c250f621898",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.244556017589811,
                            48.59094063725535,
                        ],
                        [
                            2.244559324153404,
                            48.59093276322005,
                        ],
                        [
                            2.24456700727779,
                            48.59091446711807,
                        ],
                    ],
                    [
                        [
                            2.245443851200478,
                            48.588878909513916,
                        ],
                        [
                            2.245558560897419,
                            48.58860888285408,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 941,
                    "end": 975,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "84295376-b46b-4597-b594-87477f612021",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.244621742678838,
                            48.590786176379915,
                        ],
                        [
                            2.244727578022205,
                            48.59053715379304,
                        ],
                    ],
                    [
                        [
                            2.24562146571302,
                            48.58846080364585,
                        ],
                        [
                            2.245647367592548,
                            48.588399829839354,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 992,
                    "end": 1028,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "0a31eb3b-9397-4085-a2c9-a577833b786b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.255832918325364,
                        48.59054561885159,
                    ],
                    [
                        2.256025772580531,
                        48.590607924091245,
                    ],
                    [
                        2.256096011319825,
                        48.5906272539886,
                    ],
                    [
                        2.256266069780439,
                        48.590675295527596,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1724,
                    "end": 1759,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "1479ed6f-6357-4d28-98d0-295c39609696",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.257465750440185,
                        48.5910141960226,
                    ],
                    [
                        2.257633013725251,
                        48.59106144528901,
                    ],
                    [
                        2.257713921246416,
                        48.59108737623287,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1855,
                    "end": 1875,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "365f351c-1f54-4c83-b297-736a1a52105b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.228396587818655,
                        48.590589812553354,
                    ],
                    [
                        2.228638194446777,
                        48.59064796020148,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1965,
                    "end": 1984,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "847e634f-10b5-4e45-b65a-db2b8686e46d",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.243166079742469,
                            48.5913305611251,
                        ],
                        [
                            2.243393046767975,
                            48.59135859321727,
                        ],
                    ],
                    [
                        [
                            2.260670350036127,
                            48.59222729554731,
                        ],
                        [
                            2.260807521359274,
                            48.59228352936177,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2517,
                    "end": 2546,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "1bef8a3a-340b-482f-a9e2-9611030b8e95",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.260864676164177,
                            48.59230696007037,
                        ],
                        [
                            2.260933261997554,
                            48.59233507688387,
                        ],
                    ],
                    [
                        [
                            2.262097744337868,
                            48.59293961207076,
                        ],
                        [
                            2.262230166751009,
                            48.593039928050246,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2551,
                    "end": 2572,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "dfe3e177-70ae-456d-a516-8fa114efc244",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.231516999447459,
                            48.59107009582562,
                        ],
                        [
                            2.23158324720736,
                            48.59108053465442,
                        ],
                        [
                            2.231800061224829,
                            48.591090929854175,
                        ],
                    ],
                    [
                        [
                            2.267079533202802,
                            48.59529845852845,
                        ],
                        [
                            2.267242004769074,
                            48.595317449996585,
                        ],
                        [
                            2.267344158075408,
                            48.595334249373074,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2629,
                    "end": 2670,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "2d0cff41-ff45-41c6-adbc-c947a58d935a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.247054394957511,
                        48.5871324333822,
                    ],
                    [
                        2.247125841122561,
                        48.58714079857906,
                    ],
                    [
                        2.247444433561407,
                        48.587168136082525,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3375,
                    "end": 3404,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "d7e9f6fe-ec23-4834-bf79-3130dbbabdc3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.24796964784212,
                        48.58721320128829,
                    ],
                    [
                        2.248373659442171,
                        48.58724786525297,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3443,
                    "end": 3473,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "e57b840d-88eb-4088-9860-48d2a0976bd6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.221300928910937,
                        48.58963546873855,
                    ],
                    [
                        2.221312346577803,
                        48.589637441915784,
                    ],
                    [
                        2.22174515683694,
                        48.589712238356995,
                    ],
                    [
                        2.221772144569008,
                        48.589718664177546,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3562,
                    "end": 3598,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "a719f3d3-13b2-4370-a23e-307e9d83e9c7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223365800197469,
                        48.59010512749036,
                    ],
                    [
                        2.223542204409086,
                        48.59014677308882,
                    ],
                    [
                        2.223747869421928,
                        48.59019133808146,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3704,
                    "end": 3734,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "f1729cc6-a0a7-4756-880c-81ee5f0a25f4",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.245937997634592,
                            48.587709003238885,
                        ],
                        [
                            2.246006995254071,
                            48.58754441589428,
                        ],
                    ],
                    [
                        [
                            2.246845498313587,
                            48.587093083475175,
                        ],
                        [
                            2.246949973397527,
                            48.5871126988657,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3788,
                    "end": 3815,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "7e9f6b6f-6aac-40f1-9c4d-57885f00ac18",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.224422598095433,
                            48.59028854049684,
                        ],
                        [
                            2.224489194381047,
                            48.590296878202295,
                        ],
                    ],
                    [
                        [
                            2.260991288624389,
                            48.59235522757761,
                        ],
                        [
                            2.261208749468405,
                            48.59244550974442,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4005,
                    "end": 4029,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "45eb357d-53e4-4391-b8fa-9415a2ae79e9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.23038155508238,
                        48.59089321541209,
                    ],
                    [
                        2.230803406279295,
                        48.59095827164681,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4233,
                    "end": 4265,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "5105a4ce-274f-4b2c-9719-4c704f214286",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.227376502160008,
                            48.59036584201598,
                        ],
                        [
                            2.227498344010716,
                            48.59037941621639,
                        ],
                    ],
                    [
                        [
                            2.237036244598194,
                            48.590962686744255,
                        ],
                        [
                            2.237209001999704,
                            48.59098292723048,
                        ],
                    ],
                    [
                        [
                            2.260923871886935,
                            48.592327822064405,
                        ],
                        [
                            2.260991288624389,
                            48.59235522757761,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4417,
                    "end": 4445,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "c69f79d7-98be-4a68-8b22-85e82df4d678",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.251752829966686,
                            48.587862645772354,
                        ],
                        [
                            2.251805264624431,
                            48.58798891501211,
                        ],
                        [
                            2.251895162294983,
                            48.58820220961226,
                        ],
                    ],
                    [
                        [
                            2.267052350672836,
                            48.59529829116075,
                        ],
                        [
                            2.267079533202802,
                            48.59529845852845,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4739,
                    "end": 4780,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "2443d36f-e483-4322-aa36-3a0aed35f4b6",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.220422289710097,
                            48.58930777002277,
                        ],
                        [
                            2.220433729403899,
                            48.58931293838037,
                        ],
                    ],
                    [
                        [
                            2.226685064273866,
                            48.590339100785776,
                        ],
                        [
                            2.22669889825379,
                            48.59033824215887,
                        ],
                        [
                            2.227011018903668,
                            48.590322278191614,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4855,
                    "end": 4880,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AJGAB",
            },
        },
        {
            "id": "acbc6d36-114c-4c4e-aa63-96263b18eb3c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.254685058847186,
                        48.59017477139186,
                    ],
                    [
                        2.254721692409245,
                        48.59018660712182,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1630,
                    "end": 1633,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AKBAA",
            },
        },
        {
            "id": "7e056ce7-e148-4042-9708-cee276f0b984",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.23844271953723,
                        48.591157721978746,
                    ],
                    [
                        2.238497079169603,
                        48.59115724656368,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2169,
                    "end": 2173,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AKBAA",
            },
        },
        {
            "id": "b83dca71-2027-4c76-a227-0b1d8d7b5f17",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.239464535740275,
                        48.59106412688391,
                    ],
                    [
                        2.239505195578819,
                        48.5910621113841,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2245,
                    "end": 2248,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AKBAA",
            },
        },
        {
            "id": "c61e22b1-c3a6-46b7-977f-4b125ce42371",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244843925831152,
                        48.59024831303756,
                    ],
                    [
                        2.244858511507467,
                        48.5902138247919,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3095,
                    "end": 3099,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AKBAA",
            },
        },
        {
            "id": "9e82a1f7-3f13-44b7-8442-acd16f099014",
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [
                    [
                        [
                            2.248710336183967,
                            48.58727675084185,
                        ],
                        [
                            2.248777311177161,
                            48.58728211628488,
                        ],
                    ],
                    [
                        [
                            2.270047979859247,
                            48.5954066545515,
                        ],
                        [
                            2.270061540162361,
                            48.59540673770405,
                        ],
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3275,
                    "end": 3281,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AKBAA",
            },
        },
        {
            "id": "142648b0-673b-44f0-a854-842dee45cc3b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.247336697410389,
                        48.58715889165108,
                    ],
                    [
                        2.247377098462565,
                        48.587162358324306,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3396,
                    "end": 3399,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AKBAA",
            },
        },
        {
            "id": "db2695e1-2d9c-416e-b3e4-87787805fa67",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246608884696493,
                        48.587006985587365,
                    ],
                    [
                        2.246642546059426,
                        48.587022183360375,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3768,
                    "end": 3771,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AKBAA",
            },
        },
        {
            "id": "6ef138e1-ec48-4508-8a4f-8ade610f5606",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.261243085459669,
                        48.59245976478646,
                    ],
                    [
                        2.261277421469624,
                        48.59247401981844,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4032,
                    "end": 4035,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AKBAA",
            },
        },
        {
            "id": "25df021b-f6de-4d0d-9c31-4a3962cb70b6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.261346093545596,
                        48.59250252985218,
                    ],
                    [
                        2.261409223236956,
                        48.59252873883574,
                    ],
                    [
                        2.261414629366012,
                        48.59253117839924,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4041,
                    "end": 4047,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AKBAA",
            },
        },
        {
            "id": "3725e22a-c3ed-472d-881e-c42ace75e306",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.25382112091564,
                        48.58988462692304,
                    ],
                    [
                        2.253857440416541,
                        48.58989695996079,
                    ],
                ],
            },
            "linear_location": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4352,
                    "end": 4355,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AKBAA",
            },
        },
        {
            "id": "0280d797-c234-4a10-bacf-23a626392815",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.262527481939785,
                    48.59333487863712,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 635,
                    "end": 636,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AKDAA",
            },
        },
        {
            "id": "8cd74e5d-6638-4797-a858-559b203c0aa0",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.270239715901147,
                    48.595400941612745,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 781,
                    "end": 782,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AKDAA",
            },
        },
        {
            "id": "2b9d801c-0f69-4c0a-913e-0e16e5c3558b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.218781724367257,
                    48.58939735090335,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 1216,
                    "end": 1217,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 10,
                "damage_type": "AKDAA",
            },
        },
        {
            "id": "88a9a22c-06e0-4408-9512-97d8c4902c52",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.255839024004341,
                    48.59054759141457,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 1724,
                    "end": 1725,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AKDAA",
            },
        },
        {
            "id": "43ef43cb-803a-4fb4-9a63-5be9c688f182",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.257842336846243,
                    48.59112853339814,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 1885,
                    "end": 1886,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 1,
                "damage_type": "AKDAA",
            },
        },
        {
            "id": "fc03f759-b722-4fc4-804c-525c43313bb2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.232105410766764,
                    48.59109334621376,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 2749,
                    "end": 2750,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 20,
                "damage_type": "AKDAA",
            },
        },
        {
            "id": "3fd25948-049c-4243-92e4-0117e70b4f64",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.264106728480039,
                    48.594551916602136,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 2849,
                    "end": 2850,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AKDAA",
            },
        },
        {
            "id": "a1158c2a-5925-44ac-956d-4d4e73a4cbb1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    2.24635547294797,
                    48.586865089182744,
                ],
            },
            "linear_location": [
                {
                    "typeid": 0,
                    "road": "91 D0152",
                    "start": 3743,
                    "end": 3744,
                },
            ],
            "properties": {
                "layer": "08208c8b-a343-4e93-b240-4744f2f9ccf9",
                "severity": 30,
                "damage_type": "AKDAA",
            },
        },
    ],
}

export const allNetworkConditions = {
    "08208c8b-a343-4e93-b240-4744f2f9ccf9": networkConditionCD91Small2023,
}