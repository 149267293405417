
import { useGeoServerFeaturesList } from "@l2r-front/l2r-geodata"

import { networkGradingsQueryKeys } from "./queryKeys"

export function useNetworkGradingLayer(networkGradingLayer, queryParameters, config = {}) {
    const parameters = {
        ...queryParameters,
        layer: networkGradingLayer,
        as_geojson: true,
    }
    const { enabled = true } = config
    const queryKey = networkGradingsQueryKeys["layer"](networkGradingLayer)

    return useGeoServerFeaturesList(
        queryKey,
        parameters,
        {
            ...config,
            enabled: !!networkGradingLayer && enabled,
        },
    )
}
