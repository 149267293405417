import { uuidRegex } from "@l2r-front/l2r-utils"

import { APP_SAFETY } from "../constants/appSafety"
import { MODULE_INCIDENTS } from "../constants/modules"

export const getModulesBreadcrumb = (searchUrlparams, location) => {
    const eventId = location.pathname.match(uuidRegex)?.[0]
    searchUrlparams.delete("tab-incident-details")

    const searchUrl = searchUrlparams ? `?${searchUrlparams}` : ""

    return [
        {
            path: `/:network/${APP_SAFETY}/${MODULE_INCIDENTS}/:eventId/analysis`,
            newPath: `${APP_SAFETY}/${MODULE_INCIDENTS}/${eventId}`,
        },
        {
            path: `/:network/${APP_SAFETY}/${MODULE_INCIDENTS}/:eventId`,
            newPath: `${APP_SAFETY}/${MODULE_INCIDENTS}${searchUrl}`,
        },
        {
            path: `/:network/${APP_SAFETY}/${MODULE_INCIDENTS}/filters`,
            newPath: `${APP_SAFETY}/${MODULE_INCIDENTS}${searchUrl}`,
        },
    ]
}