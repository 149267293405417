import { queryKeyFactory } from "@l2r-front/l2r-query"

export const NETWORK_GRADINGS_ROOT_QUERY_KEY = "networkGradings"
export const NETWORK_DAMAGES_ROOT_QUERY_KEY = "networkDamages"

export const networkGradingsQueryKeys = queryKeyFactory(NETWORK_GRADINGS_ROOT_QUERY_KEY, {
    layer: (filters = {}) => [NETWORK_GRADINGS_ROOT_QUERY_KEY, "layer", filters] as const,
    linearLocation: (segmentUuid) => [NETWORK_GRADINGS_ROOT_QUERY_KEY, "linearLocation", segmentUuid] as const,
    segments: (layer, road, absStart, absEnd) => [NETWORK_GRADINGS_ROOT_QUERY_KEY, "segments", layer, road, absStart, absEnd] as const,
    listRelevants: (project) => [NETWORK_GRADINGS_ROOT_QUERY_KEY, "list-relevants", project] as const,
})

export const networkDamagesQueryKeys = queryKeyFactory(NETWORK_DAMAGES_ROOT_QUERY_KEY, {
    layer: (filters = {}) => [NETWORK_DAMAGES_ROOT_QUERY_KEY, "layer", filters] as const,
    linearLocations: () => [NETWORK_DAMAGES_ROOT_QUERY_KEY, "linearLocations"] as const,
    segments: (layer, road, absStart, absEnd) => [NETWORK_DAMAGES_ROOT_QUERY_KEY, "segments", layer, road, absStart, absEnd] as const,
})
