export const mulhouseYearsStats = {
    "count": {
        2011: {
            50: 700,
            100: 3,
        },
        2012: {
            50: 700,
            100: 3,
        },
        2013: {
            50: 700,
            100: 3,
        },
        2014: {
            50: 700,
            100: 3,
        },
        2015: {
            50: 700,
            100: 3,
        },
    },
    "cost": {
        2011: {
            50: "62.7",
            100: "63.4",
        },
        2012: {
            50: "72.7",
            100: "73.4",
        },
        2013: {
            50: "72.7",
            100: "83.4",
        },
        2014: {
            50: "93.4",
            100: "0",
        },
        2015: {
            50: "102.7",
            100: "0",
        },
    },
}

export const roadworksYearsStats = {
    mulhouse: mulhouseYearsStats,
}