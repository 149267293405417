import { CustomSelector, MenuItem as UiMenuItem, styled } from "@l2r-front/l2r-ui"

export const Select = styled(CustomSelector)({
    maxWidth: 180,
})

export const MenuItem = styled(UiMenuItem)(({ theme }) => ({
    "&.Mui-selected": {
        backgroundColor: `${theme.palette.common.white} !important`,
        color: theme.palette["texts/text-primary"].main,
        fontWeight: theme.typography["H3"].fontWeight,
        "&:hover": {
            backgroundColor: `${theme.palette.common.white} !important`,
            color: theme.palette["texts/text-primary"].main,
            fontWeight: theme.typography["H3"].fontWeight,
        },
    },
}))
