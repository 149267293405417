import { useCallback, useMemo } from "react"

import { useList } from "react-use"

import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"

import { useTranslation, useGetLocaleFromList } from "@l2r-front/l2r-i18n"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { cguLocales, getCguUrl } from "../../constants/cgu"
import { I18N_NAMESPACE } from "../../constants/i18n"
import { AnonymousLayout } from "../../layouts/AnonymousLayout/AnonymousLayout"
import { CguError } from "./Cgu.error"
import * as Styled from "./Cgu.styled"

export const Cgu = () => {

    const { t } = useTranslation([I18N_NAMESPACE])
    const currentLang = useGetLocaleFromList(cguLocales, cguLocales[0])
    const [pagesLoadedStates, { set: setPagesLoadedStates, updateAt: updatePagesLoadedStateAt }] = useList([])
    const mobile = useIsMobileDevice()

    const onDocumentLoadSuccess = useCallback(({ numPages }) => {
        setPagesLoadedStates(new Array(numPages))
    }, [setPagesLoadedStates])

    const pdfUrl = useMemo(() => {
        return getCguUrl(currentLang)
    }, [currentLang])


    const pages = useMemo(() => {
        return Array.from(
            pagesLoadedStates,
            (_, index) => (
                <Styled.Page
                    key={`cgu-page-${index}`}
                    loading={<Styled.Skeleton />}
                    onLoadSuccess={() => updatePagesLoadedStateAt(index, true)}
                    pageNumber={index + 1}
                    debug={true}
                />
            ),
        )
    }, [pagesLoadedStates, updatePagesLoadedStateAt])

    return (
        <AnonymousLayout mobile={mobile}>
            <Styled.PageWrapper>
                <Styled.Title variant="H1">
                    {t(I18N_NAMESPACE, "pages.cgu.label")}
                </Styled.Title>
                <Styled.SubTitle variant="Regular">{t(I18N_NAMESPACE, "pages.cgu.subtitle")}</Styled.SubTitle>
                <Styled.Wrapper>
                    {<Styled.Document
                        error={<CguError />}
                        file={pdfUrl}
                        loading={<Styled.Skeleton />}
                        noData={<Styled.Skeleton />}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {pages}
                    </Styled.Document>}
                </Styled.Wrapper>
                <Styled.CguFormPost />
            </Styled.PageWrapper>
        </AnonymousLayout >
    )
}
