import { useRoadworksQuery } from "../../../../common/hooks/queries/useRoadworksQuery"
import { constructTechniqueAcronym } from "../../utils/roadworkUtils"
import { roadworksQueryKeys } from "./queryKeys"
import { useTaskTypes } from "./useTaskType"

export function useRoadworks(
    filters = {},
    road = null,
    config = {},
) {
    const queryParameters = {
        ...filters,
        ...(road && { linear_location_road: road }),
    }
    const url = "roadworks/"
    const queryKey = roadworksQueryKeys.list(queryParameters)

    const { data: roadworks, isLoading: isLoadingRoadworks, isError: isErrorRoadworks, isStale: isRoadworksStale } = useRoadworksQuery(
        url,
        queryKey,
        queryParameters,
        config,
    )

    const { data: taskTypes, isLoading: isLoadingTaskTypes, isError: isErrorTaskTypes, isStale: isTaskTypesStale } = useTaskTypes()

    const isLoading = isLoadingRoadworks || isLoadingTaskTypes
    const isError = isErrorRoadworks || isErrorTaskTypes
    const isStale = isRoadworksStale || isTaskTypesStale

    const data = roadworks?.map(roadwork => {
        const techniqueAccronym = constructTechniqueAcronym(roadwork.technique, taskTypes)
        return {
            ...roadwork,
            technique: {
                ...roadwork.technique,
                acronym: techniqueAccronym,
            },
        }
    })

    return { data: isStale ? null : data, isLoading, isError }
}