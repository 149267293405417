import {
    Popper as UiPopper,
    Paper as UiPaper,
    styled,
    styledForMobile,
} from "@l2r-front/l2r-ui"

import { DATE_FILTERS_POPPER_DESKTOP_WIDTH } from "../../constants/layout"

export const Popper = styled(UiPopper)(({ theme }) => ({
    zIndex: theme.zIndex.modal,
}))

export const Paper = styledForMobile(UiPaper)(({ theme, isMobile }) => ({
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    padding: theme.spacing(3),
    width: isMobile ? `calc(100% - ${theme.spacing(8)})` : DATE_FILTERS_POPPER_DESKTOP_WIDTH,
}))

export const FilterWrapper = styled("div")(({ theme }) => ({
    margin: theme.spacing(2),
}))