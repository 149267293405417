import { PropTypes } from "@l2r-front/l2r-proptypes"
import { DetailElementsSkeleton, ChipListSkeleton } from "@l2r-front/l2r-ui"

import * as Styled from "./IncidentDetail.styled"

export const IncidentDetailSkeleton = (props) => {
    const {
        detailCount,
        tags,
        titleVariant,
    } = props

    return <Styled.ListItem>
        <Styled.IconSkeletonWrapper>
            <Styled.IconSkeleton variant="circular" />
        </Styled.IconSkeletonWrapper>
        <Styled.Elements>
            <Styled.SubtitleSkeleton variant={titleVariant || "button"} />
            <DetailElementsSkeleton detailCount={detailCount} />
            {tags && <ChipListSkeleton tagsCount={tags} />}
        </Styled.Elements>
    </Styled.ListItem>
}

IncidentDetailSkeleton.propTypes = {
    detailCount: PropTypes.number.isRequired,
    tags: PropTypes.number,
    titleVariant: PropTypes.string,
}