import { useCallback, useEffect } from "react"

import { useParams } from "react-router-dom"

import { useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { NetworkReferentialLayer, useNavigateToLinearLocation, useNetworksDispatchContext } from "@l2r-front/l2r-networks"

import { SELECTED_TECHNIQUE_URL_PARAM } from "../../constants/techniques"
import { RoadworkTechniquesLayer } from "../../containers/RoadworkTechniquesLayer"
import { useRoadworksDispatchContext } from "../../contexts/RoadworksContext"

export function RoadworksMapLayersMobile() {
    const navigateToLinearLocation = useNavigateToLinearLocation()
    const { setSelectedSegment, setSelectedTechnique } = useRoadworksDispatchContext()
    const { setSegmentFeatureIdClicked } = useImageCollectionDispatchContext()
    const { setSearchRoadCallback } = useMapDispatchContext()
    const { setRoadSearched } = useNetworksDispatchContext()
    const params = useParams()
    const road = params?.road

    const processNavigation = useCallback((feature, location) => {
        if (location && location.road) {
            setSegmentFeatureIdClicked(feature?.properties?.uuid)
            setSelectedSegment(feature)
            setSelectedTechnique(null)
            navigateToLinearLocation(location, "..", { saveMapBoundingBox: true, paramsToRemove: [SELECTED_TECHNIQUE_URL_PARAM] })
        }
    }, [setSegmentFeatureIdClicked,
        setSelectedSegment,
        setSelectedTechnique,
        navigateToLinearLocation])

    const onReferentialFeatureClick = useCallback(feature => {
        const linearLocation = JSON.parse(feature.properties.linearLocation)
        if (linearLocation?.[0]?.road !== road) {
            setRoadSearched(null)
            processNavigation(feature, linearLocation?.[0])
        }
    }, [processNavigation, setRoadSearched, road])

    useEffect(() => {
        setSearchRoadCallback((searchedRoad) => {
            processNavigation(null, { road: searchedRoad })
        })

        return () => setSearchRoadCallback(null)
    }, [
        setSearchRoadCallback,
        processNavigation])

    return <>
        <NetworkReferentialLayer onClickOnFeature={onReferentialFeatureClick} />
        <RoadworkTechniquesLayer />
    </>
}
