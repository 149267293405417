import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { appRoad } from "../../../../common/constants/appRoad"
import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { NotationTrendingKpi as NotationTrendingKpiComponent } from "../../components/NotationTrendingKpi/NotationTrendingKpi"
import { GLOBAL_GRADE_TYPE } from "../../constants/roadInfoConfig"
import { useRelevantNetworkGradings } from "../../hooks/queries/networkGradings/useRelevantNetworkGradings"
import * as Styled from "./NotationTrendingKpi.styled"

export function NotationTrendingKpi(props) {

    const { t } = useTranslation([I18N_NAMESPACE])
    const {
        selectedNetwork,
    } = useNetworksStateContext()

    const {
        isLoading,
        isError,
        sortedRelevantNetworkGradings,
    } = useRelevantNetworkGradings(selectedNetwork?.slug)

    const gradingValues = useMemo(() => {
        return sortedRelevantNetworkGradings?.map((networkGrading) => (
            {
                grade: networkGrading.gradesWeightedAverage[GLOBAL_GRADE_TYPE],
                date: new Date(networkGrading?.year, 7, 1),
            }
        ))
    }, [sortedRelevantNetworkGradings])

    const lastSurveyIndex = useMemo(() => {
        return sortedRelevantNetworkGradings?.findLastIndex(networkGrading => !networkGrading.isSimulated)
    }, [sortedRelevantNetworkGradings])

    if (isError) {
        return <Styled.KpiError
            title={t(I18N_NAMESPACE, "containers.notationKpi.error")}
            id="notation-trending-kpi-error"
            message={t(I18N_NAMESPACE, "containers.notationKpi.error")}
            path={`${appRoad.path}/${appRoad.modules.roadway.path}`}
            {...props}
        />
    }

    if (isLoading) {
        return <Styled.KpiSkeleton {...props} />
    }

    return (
        <Styled.KpiCard id="notation-trending-kpi" path={`${appRoad.path}/${appRoad.modules.roadway.path}`} {...props}>
            <Styled.Title variant="H1">{t(I18N_NAMESPACE, "containers.notationTrendingKpi.title")}</Styled.Title>
            <Styled.Desc>{t(I18N_NAMESPACE, "containers.notationTrendingKpi.desc")}</Styled.Desc>
            <Styled.ChartContainer>
                <NotationTrendingKpiComponent values={gradingValues} lastSurveyIndex={lastSurveyIndex} />
            </Styled.ChartContainer>
        </Styled.KpiCard >
    )
}