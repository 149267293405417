import { useCallback } from "react"

import { useTranslation as useTranslationI18n } from "react-i18next"

export function useTranslation(namespace, props = {}) {
    const [_t, i18n, ready] = useTranslationI18n(namespace, {
        ...props,
        useSuspense: true,
    })

    const t = useCallback(function (namespace, key, options = {}) {
        const _key = `${namespace}:${key}`
        return _t(_key, options)
    }, [_t])

    return { t, i18n, ready }
}
