import { TooltipWithBounds } from "@visx/tooltip"

import { PropTypes } from "@l2r-front/l2r-proptypes"

export const TOOLTIP_TIME_TOP_PADDING = 10

export const ChartToolTip = (props) => {

    const {
        bottomPadding,
        leftPadding,
        tooltipData,
        tooltipLeft,
        height,
    } = props

    return (
        <div key={Math.random()}>
            <TooltipWithBounds
                left={tooltipLeft + leftPadding}
                top={tooltipData.top - (bottomPadding / 2)}
            >
                {
                    tooltipData.data.map((data) => {
                        return data?.value ? <div key={Math.random()}>
                            {data.value}
                            <br />
                        </div> : null
                    })
                }
            </TooltipWithBounds>
            {tooltipData.time && <TooltipWithBounds
                left={tooltipLeft}
                top={height - bottomPadding + TOOLTIP_TIME_TOP_PADDING}
            >
                {tooltipData.time}
            </TooltipWithBounds>}
        </div>
    )
}

ChartToolTip.propTypes = {
    bottomPadding: PropTypes.number,
    leftPadding: PropTypes.number,
    height: PropTypes.number.isRequired,
    tooltipLeft: PropTypes.number.isRequired,
    tooltipData: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({
            top: PropTypes.number.isRequired,
            value: PropTypes.string.isRequired,
        })),
        top: PropTypes.number.isRequired,
        time: PropTypes.string.isRequired,
    }).isRequired,
}