import { parse } from "date-fns"

import { EVENT_INFOS_DATE, EVENT_INFOS_PUBLICATION_STATUS } from "../../../modules/incidents/constants/event"
import { DATE_FILTER_SEARCH_PARAM_KEY, DATE_KEYS, DATE_FORMATS, FILTERS_SEARCH_PARAM_PREFIX, TAGS_FILTER_SEARCH_PARAM_KEY, DATE_PARAMS } from "../../../modules/incidents/constants/filters"
import { eventFiltersConfig } from "../../../modules/incidents/utils/eventFiltersConfig"

export function getFiltersSearchParams(filters, searchParams, dateFormatter, initialFilters) {
    Object.entries(filters || {}).forEach(([filterKey, filterValue]) => {
        if (filterKey === EVENT_INFOS_DATE) {
            const dateFilterParams = eventFiltersConfig[filterKey].getSearchParams(filterValue, dateFormatter, initialFilters)
            DATE_PARAMS.forEach(filterValueName => {
                const dateParam = dateFilterParams.find(value => value.name === filterValueName)
                if (dateParam) {
                    searchParams.set(`${FILTERS_SEARCH_PARAM_PREFIX}${dateParam.name}`, dateParam.value)

                } else {
                    searchParams.delete(`${FILTERS_SEARCH_PARAM_PREFIX}${filterValueName}`)
                }
            })
        }
        if (filterValue?.length) {
            const filterParam = Array.isArray(filterValue) ? [].concat(filterValue.join(",")) : filterValue
            searchParams.set(`${FILTERS_SEARCH_PARAM_PREFIX}${filterKey}`, filterParam)
        } else {
            searchParams.delete(`${FILTERS_SEARCH_PARAM_PREFIX}${filterKey}`)
        }
    })
    return searchParams
}

export function deleteSearchParamsByKeys(searchParams, keysToDelete) {
    const matchKey = function (key, keyToMatch) {
        return key === keyToMatch
            || keyToMatch === "*"
            || (keyToMatch.endsWith("*") && key.startsWith(keyToMatch.substring(0, keyToMatch.length - 1)))
    }

    Array.from(searchParams.entries()).forEach(([key]) => {
        for (const keyToDelete of keysToDelete) {
            if (matchKey(key, keyToDelete)) {
                searchParams.delete(key)
                break
            }
        }
    })
    return searchParams
}

export function getQueryParamsFromFilters(filters, locale, dateFormatter, userIsStaff) {
    return Object.entries(filters || {}).reduce((acc, [filterKey, filterValue]) => {
        const params = eventFiltersConfig[filterKey].getQueryParams(filterKey, filterValue, locale, dateFormatter, userIsStaff)
        if (params) {
            params.forEach(param => {
                acc = {
                    ...acc,
                    [param.name]: param.value,
                }
            })
        }
        return acc
    }, {})

}

function getDateFiltersFromSearchParams(acc, value, key, initialFilters) {
    const dates = value.replace(DATE_FILTER_SEARCH_PARAM_KEY, "").split("_")
    const filterKey = key.replace(DATE_FILTER_SEARCH_PARAM_KEY, "")

    const dateFilterValue = dates
        .map(date => parse(date, DATE_FORMATS[filterKey], new Date()))
        .reduce((acc, dateValue, i) => {
            return ({
                ...acc,
                [DATE_KEYS[filterKey][i]]: dateValue,
            })
        }, {})

    return ({
        ...acc,
        filtersFromParams: {
            ...acc?.filtersFromParams,
            date: {
                ...initialFilters.date,
                ...acc?.filtersFromParams?.date,
                ...dateFilterValue,
            },
        },
    })
}

export function getFiltersFromSearchParams(searchParams, initialFilters, userIsStaff, setSearchParams) {
    return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
        if (key.includes(DATE_FILTER_SEARCH_PARAM_KEY)) {
            return getDateFiltersFromSearchParams(acc, value, key, initialFilters)
        }
        if (key.includes(EVENT_INFOS_PUBLICATION_STATUS) && !userIsStaff) {
            searchParams.delete(`${FILTERS_SEARCH_PARAM_PREFIX}${EVENT_INFOS_PUBLICATION_STATUS}`)
            setSearchParams(searchParams)
            return ({
                ...acc,
                errorMessage: "contexts.svrai.snackbar.notAppliedFilters",
            })
        }
        if (key.includes(FILTERS_SEARCH_PARAM_PREFIX)) {
            const filterKey = key.replace(FILTERS_SEARCH_PARAM_PREFIX, "")
            const filterValue = key === TAGS_FILTER_SEARCH_PARAM_KEY ? [].concat(value.split(",")) : value
            return ({
                ...acc,
                filtersFromParams: {
                    ...acc.filtersFromParams,
                    [filterKey]: filterValue,
                },
            })
        }
        return acc
    }, {})
}