import { createParametrics, initStateFromRoadworkFeatures } from "../utils"

export let FreeRoamMode = {}

// When the mode starts this function will be called.
// The `opts` argument comes from `draw.changeMode('lotsofpoints', {count:7})`.
// The value returned should be an object and will be passed to all other lifecycle functions
FreeRoamMode.onSetup = function (opts) {
    var state = {}

    state.roadSegments = opts.roadSegments
    state.startingFeatures = opts.startingFeatures

    this.map["dragPan"].enable()
    this.map.getCanvas().style.cursor = ""

    return state
}

FreeRoamMode.toDisplayFeatures = function (state, geojson, display) {
    display(geojson)
}

FreeRoamMode.onTrash = function (state) {
    const defaultParametrics = initStateFromRoadworkFeatures(state)
    createParametrics(this, state, defaultParametrics)
}