import { Button as UiButton, Skeleton as UiSkeleton, styled, Typography } from "@l2r-front/l2r-ui"

export const Button = styled(UiButton)(({ disabled, selected, theme }) => ({
    backgroundColor: disabled && theme.palette["surfaces/surface-variation"].main,
    borderRadius: theme.spacing(2),
    border: `2px solid ${selected ? theme.palette["cta-bg/cta-bg-primary"].main : "transparent"}`,
    marginRight: theme.spacing(1),
    padding: theme.spacing(2, 3),
    display: "block",
    minWidth: "fit-content",
}))

export const Text = styled(Typography)(({ color, theme }) => ({
    color: theme.palette[color].main,
}))

export const OptionsWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    marginTop: theme.spacing(2),
    maxWidth: "100%",
    overflowX: "auto",
}))

export const Wrapper = styled("div")({
})

export const Skeleton = styled(UiSkeleton)(({ theme }) => ({
    height: 82,
    marginTop: theme.spacing(2),
    transform: "scale(1)",
    width: "100%",
}))

export const ErrorWrapper = styled("div")(({ theme }) => ({
    height: 82,
    marginTop: theme.spacing(2),
    width: "100%",
}))

export const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette["texts/text-error"].main,
}))