import { useMemo } from "react"

import MuiAvatar from "@mui/material/Avatar"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { stringToColor } from "../../utils/avatarColors"

export const Avatar = (props) => {
    const {
        alt,
        children,
        ...avatarProps
    } = props

    const initials = useMemo(() => {
        const altSplitted = alt.split(" ")
        return altSplitted.reduce((acc, current) => {
            if (acc === "") {
                return current.charAt(0).toUpperCase() + current.charAt(1).toLowerCase()
            }
            return acc[0] + current.charAt(0).toUpperCase()
        }, "")
    }, [alt])

    const color = useMemo(() => stringToColor(alt), [alt])

    return <MuiAvatar {...avatarProps} sx={{ backgroundColor: color }}>
        {children || initials}
    </MuiAvatar>
}

Avatar.propTypes = {
    ...MuiAvatar.propTypes,
    alt: PropTypes.string.isRequired,
}