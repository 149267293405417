import { PropTypes } from "@l2r-front/l2r-proptypes"


export function TabPanel(props) {
    const {
        children,
        className,
        currentTabIndex,
        index,
    } = props

    return (
        <div
            className={className}
            hidden={currentTabIndex !== index}
        >
            {currentTabIndex === index && children}
        </div>
    )
}

TabPanel.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    currentTabIndex: PropTypes.number.isRequired,
}