import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./YearGradingBadge.styled"

export function YearGradingBadgeSkeleton(props) {

    const { className } = props

    return <Styled.Skeleton className={className} />
}


YearGradingBadgeSkeleton.propTypes = {
    className: PropTypes.string,
}