export function generateComfortTresholds (comfortTresholds, yScale) {

    if (comfortTresholds && yScale) {
        return ({
            x: 0,
            y0: yScale(comfortTresholds[0]),
            y: yScale(comfortTresholds[1]),
        })
    }

    return null
}