import React, { useMemo } from "react"

import { useParams } from "react-router-dom"

import { useRoadwayStateContext } from "../../contexts/RoadwayContext"
import { useDamagesStatsOnSegments } from "../../hooks/queries/damages/useDamagesStatsOnSegments"
import { DamagesList } from "../DamagesList"

export function RoadDamagesList() {

    const { road } = useParams()
    const { selectedNetworkAssesmentType } = useRoadwayStateContext()
    const {
        data: damagesStatsOnRoad,
        isLoading,
        isError,
    } = useDamagesStatsOnSegments(road)

    const fullDamagesStatsOnRoad = useMemo(() => {
        const roadDamages = (damagesStatsOnRoad || [])

        if (selectedNetworkAssesmentType) {
            const formattedSelectedNetworkAssesmentType = selectedNetworkAssesmentType.split("-")[1]
            const isSelectedNetworkAssesmentTypePresent = roadDamages.find(damage => damage.damageCode === formattedSelectedNetworkAssesmentType)

            if (!isSelectedNetworkAssesmentTypePresent) {
                return roadDamages.concat({
                    damageCode: formattedSelectedNetworkAssesmentType,
                    damageStat: 0,
                })
            }
        }

        return damagesStatsOnRoad
    }, [damagesStatsOnRoad, selectedNetworkAssesmentType])

    return <DamagesList
        damagesToDisplay={fullDamagesStatsOnRoad}
        isLoading={isLoading}
        isError={isError}
        noDamageTextKey="containers.roadwaySidebar.damages.noDamageForSegment" />
}
