import { useTranslation } from "@l2r-front/l2r-i18n"
import { ErrorOutlineRoundedIcon } from "@l2r-front/l2r-icons"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import * as Styled from "./IncidentCharts.styled"

export const IncidentChartsError = (props) => {

    const { className } = props
    const { t } = useTranslation(I18N_NAMESPACE)

    return <Styled.ErrorWrapper className={className}>
        <ErrorOutlineRoundedIcon color="error" fontSize="large" />
        <Styled.ErrorMessage>{t(I18N_NAMESPACE, "containers.incidentChart.errors.message")}</Styled.ErrorMessage>
    </Styled.ErrorWrapper>
}