import { useCallback } from "react"

import { endOfYear, isAfter, isBefore, isSameYear, max, min, startOfYear } from "date-fns"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useProjectStateContext } from "../../../../common/contexts/ProjectContext"
import { MonthYearFilter as L2rMonthYearFilter } from "../../components/MonthYearFilter"

export const MonthYearFilter = (props) => {

    const {
        onChangeStart,
        onChangeEnd,
        periodStartDate,
        periodEndDate,
    } = props

    const { projectStartDate, projectEndDate } = useProjectStateContext()

    const handleStartDateValueChange = useCallback((newValue) => {
        const newDate = getNewStartDate(newValue, projectStartDate, periodStartDate)

        onChangeStart(newDate)

        if (isAfter(newDate, periodEndDate)) {
            onChangeEnd(newDate)
        }
    }, [periodEndDate, periodStartDate, projectStartDate, onChangeStart, onChangeEnd])

    const handleEndDateValueChange = useCallback((newValue) => {
        const newDate = getNewEndDate(newValue, projectEndDate, periodEndDate)

        onChangeEnd(newDate)

        if (isBefore(newDate, periodStartDate)) {
            onChangeStart(newDate)
        }
    }, [periodEndDate, periodStartDate, projectEndDate, onChangeStart, onChangeEnd])

    return (
        <L2rMonthYearFilter
            startDateValue={periodStartDate}
            endDateValue={periodEndDate}
            onStartDateValueChange={handleStartDateValueChange}
            onEndDateValueChange={handleEndDateValueChange}
            minDate={new Date(projectStartDate || periodStartDate)}
            maxDate={new Date(projectEndDate || periodEndDate)}
        />
    )
}


const getNewStartDate = (newDate, firstEventDate, periodDate) => {
    if (isSameYear(periodDate, newDate)) {
        return newDate
    } else {
        return max([new Date(firstEventDate), startOfYear(newDate)])
    }
}

const getNewEndDate = (newDate, lastEventDate, periodDate) => {
    if (isSameYear(periodDate, newDate)) {
        return newDate
    } else {
        return min([new Date(lastEventDate), endOfYear(newDate)])
    }
}

MonthYearFilter.propTypes = {
    onChangeStart: PropTypes.func.isRequired,
    onChangeEnd: PropTypes.func.isRequired,
    periodStartDate: PropTypes.date,
    periodEndDate: PropTypes.date,
}