import { styled, Skeleton as UiSkeleton, Switch as UiSwitch } from "@l2r-front/l2r-ui"

export const Container = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    borderRadius: 4,
    gap: theme.spacing(2),
    padding: theme.spacing(0, 3),
}))

export const Switch = styled(UiSwitch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
        "&.Mui-checked": {
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette["surfaces/surface-clair"].main,
            },
        },
    },
    "& .MuiSwitch-thumb": {
        backgroundColor: theme.palette["objects/object-primary"].main,
    },
    "& .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette["surfaces/surface-clair"].main,
    },
}))

export const Skeleton = styled(UiSkeleton)({
    height: "100%",
    transform: "scale(1)",
    width: "100%",
})
