import { Alert as MuiAlert, Dialog as MuiDialog } from "@mui/material"

import { PropTypes } from "@l2r-front/l2r-proptypes"

export function Alert(props) {

    const {
        children,
        open,
        handleClose,
        severity,
    } = props

    return <MuiDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <MuiAlert severity={severity}>
            {children}
        </MuiAlert>
    </MuiDialog>
}

Alert.propTypes = {
    children: PropTypes.node,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    severity: PropTypes.string,
}

Alert.defaultProps = {
    severity: "warning",
}