import { useQueryClient } from "@tanstack/react-query"

import { useAuthMutation } from "@l2r-front/l2r-auth"
import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

export function useUserMutation(config = {}) {
    const url = "me/"
    const queryClient = useQueryClient()

    const axiosFn = (url, values) => {
        const formData = new FormData()
        Object.entries(values).forEach(([key, value]) => {
            if (!(key === "avatar" && (typeof value === "string" || value === null))) {
                formData.append(key, value)
            }
        })

        const headers = formData.avatar ? { "Content-Type": "multipart/form-data" } : {}

        return axiosInstance.patch(url, formData, {
            headers: headers,
        })
    }
    return useAuthMutation(url, axiosFn, {}, {
        ...config,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["user"] })
            queryClient.refetchQueries({ queryKey: ["user"] })
        },
    })
}
