import Card from "@mui/material/Card"

import { ErrorOutlineRoundedIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { Kpi } from "./Kpi"

export const KpiError = (props) => {
    const {
        title,
        message,
        ...kpiProps
    } = props

    return (
        <Kpi
            color="cta-bg/cta-bg-tertiary"
            value={title}
            title={message}
            icon={<ErrorOutlineRoundedIcon color="surfaces/surface-error" />}
            {...kpiProps} />
    )
}

KpiError.propTypes = {
    ...Card.propTypes,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
}
