import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { useSvraiContext } from "../../../../common/contexts/SvraiContext"
import { ActiveFiltersList } from "../../components/ActiveFiltersList/ActiveFiltersList"
import { IncidentPaginatedList } from "../IncidentPaginatedList"
import * as Styled from "./IncidentsSidebarContent.styled"

export function IncidentsSidebarContent(props) {
    const {
        displayFilters,
        onCloseFilters,
    } = props

    const [state, dispatch] = useSvraiContext()
    const { filters } = state
    const { setFilters } = dispatch
    const isMobile = useIsMobileDevice()

    return (
        <>
            <Styled.HeaderWrapper isMobile={isMobile}>
                <Styled.IncidentFilters displayed={displayFilters} close={onCloseFilters} isMobile={isMobile} />
                {filters && !displayFilters && <ActiveFiltersList
                    activeFilters={filters}
                    setFilters={setFilters}
                />}
            </Styled.HeaderWrapper>
            <IncidentPaginatedList />
        </>
    )
}

IncidentsSidebarContent.propTypes = {
    displayFilters: PropTypes.bool.isRequired,
    onCloseFilters: PropTypes.func.isRequired,
}