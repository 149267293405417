import { useFormikContext } from "formik"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { ToggleButtonGroup } from "../../components/ToggleButtonGroup"

export const FormikToggleButtonGroup = (props) => {
    const {
        enforceValue,
        name,
        onChange,
        ...toggleGroupProps
    } = props

    const { values, setFieldValue } = useFormikContext()

    const onValueChange = (_, newValue) => {
        if (enforceValue && newValue || !enforceValue) {
            setFieldValue(name, newValue)
            onChange?.(newValue)
        }
    }

    return (
        <ToggleButtonGroup
            value={values[name]}
            onChange={onValueChange}
            {...toggleGroupProps}
        />
    )
}

FormikToggleButtonGroup.propTypes = {
    enforceValue: PropTypes.bool,
    name: PropTypes.string.isRequired,
    ...ToggleButtonGroup.propTypes,
}

FormikToggleButtonGroup.defaultProps = {
    enforceValue: false,
}

