import { useCallback } from "react"

import { useNavigate } from "react-router-dom"

import * as Styled from "./Kpi.styled"


export const KpiCard = (props) => {
    const {
        className,
        path,
        children,
        ...cardProps
    } = props

    const navigate = useNavigate()

    const handleClick = useCallback(() => {
        if (path) {
            navigate(path)
        }
    }, [navigate, path])

    return (
        < Styled.KpiCard className={className} onClick={handleClick} {...cardProps}>
            {children}
        </Styled.KpiCard >
    )
}
