import { useContext } from "react"

import { ImageCollectionDispatchContext, ImageCollectionStateContext } from "./ImageCollectionContext.context"


export const useImageCollectionStateContext = () => {
    const context = useContext(ImageCollectionStateContext)
    if (context === undefined) {
        throw new Error("useImageCollectionStateContext must be used within an ImageCollectionContext")
    }
    return context
}

export const useImageCollectionDispatchContext = () => {
    const context = useContext(ImageCollectionDispatchContext)
    if (context === undefined) {
        throw new Error("useImageCollectionDispatchContext must be used within an ImageCollectionContext")
    }
    return context
}

export const useRoadwayStreetViewContext = () => {
    return [useImageCollectionStateContext(), useImageCollectionDispatchContext()]
}
