import { subSeconds } from "date-fns"

import {
    getQueryParamsForAlias,
    getQueryParamsForArray,
    getValueListForAlias,
    getValueListForArray,
    removeValueForArray,
} from "@l2r-front/l2r-utils"

import { EVENT_INFOS_DATE, EVENT_INFOS_HOURS } from "../constants/event"
import { PERIOD_PARAM, TIMESLOT_PARAM } from "../constants/filters"
import { incidentReportStatusSettings } from "./statusHelpers"

const getDateValueList = (value, dateFormatter, initialFilters) => {
    const valueList = []

    const isInitialtimeSlotStartHour = initialFilters?.date?.timeSlotStartHour.getTime() === value?.timeSlotStartHour?.getTime()
    const isInitialtimeSlotEndHour = initialFilters?.date?.timeSlotEndHour.getTime() === value?.timeSlotEndHour?.getTime()
    const isInitialTimeSlot = isInitialtimeSlotStartHour && isInitialtimeSlotEndHour

    if (!isInitialTimeSlot) {
        const formattedTimeSlotStartHour = dateFormatter(value.timeSlotStartHour, "HH")
        const formattedTimeSlotEndHour = dateFormatter(value.timeSlotEndHour, "HH")
        const hourValue = {
            key: EVENT_INFOS_DATE,
            name: EVENT_INFOS_HOURS,
            label: `${formattedTimeSlotStartHour} - ${formattedTimeSlotEndHour}`,
            value: `${formattedTimeSlotStartHour} - ${formattedTimeSlotEndHour}`,

        }
        valueList.push(hourValue)
    }

    const isInitialPeriodStartDate = initialFilters?.date?.periodStartDate?.getTime() === value?.periodStartDate?.getTime()
    const isInitialPeriodEndDate = initialFilters?.date?.periodEndDate?.getTime() === value?.periodEndDate?.getTime()
    const isInitialPeriod = isInitialPeriodStartDate && isInitialPeriodEndDate
    const existingPeriod = !!value?.periodStartDate && !!value?.periodEndDate

    if (!isInitialPeriod && existingPeriod) {
        const formattedPeriodStartDate = dateFormatter(value.periodStartDate, "MM/yy")
        const formattedPeriodEndDate = dateFormatter(value.periodEndDate, "MM/yy")
        const dateValue = {
            key: EVENT_INFOS_DATE,
            name: EVENT_INFOS_DATE,
            label: `${formattedPeriodStartDate} - ${formattedPeriodEndDate}`,
            value: `${formattedPeriodStartDate} - ${formattedPeriodEndDate}`,

        }
        valueList.push(dateValue)
    }

    return valueList
}

const getDateQueryParams = (value, dateFormatter) => {
    const { periodStartDate, periodEndDate, timeSlotStartHour, timeSlotEndHour } = value
    const apiFormattedPeriodStartDate = dateFormatter(periodStartDate, "yyyy-MM")
    const apiFormattedPeriodEndDate = dateFormatter(periodEndDate, "yyyy-MM")
    const apiFormattedTimeSlotStartHour = dateFormatter(timeSlotStartHour, "HH:mm:ss").toString()
    const apiFormattedTimeSlotEndHour = dateFormatter(subSeconds(timeSlotEndHour, 1), "HH:mm:ss").toString().concat(".999999")

    return [{
        name: "date_from",
        value: apiFormattedPeriodStartDate,
    }, {
        name: "date_to",
        value: apiFormattedPeriodEndDate,

    }, {
        name: "time_after",
        value: apiFormattedTimeSlotStartHour,
    }, {
        name: "time_before",
        value: apiFormattedTimeSlotEndHour,
    }]
}

const getDateSearchParams = (value, dateFormatter, initialFilters) => {
    const res = []
    const dateQueryParams = getDateQueryParams(value, dateFormatter)
    const isInitialtimeSlotStartHour = initialFilters.date.timeSlotStartHour.getTime() === value.timeSlotStartHour.getTime()
    const isInitialtimeSlotEndHour = initialFilters.date.timeSlotEndHour.getTime() === value.timeSlotEndHour.getTime()
    const isInitialTimeSlot = isInitialtimeSlotStartHour && isInitialtimeSlotEndHour
    const apiFormattedTimeSlotEndHour = dateFormatter(value.timeSlotEndHour, "HH:mm:ss").toString()


    if (!isInitialTimeSlot) {
        const timeSlot = dateQueryParams.slice(2, 4)
        const timeSlotStart = timeSlot[0]

        res.push({
            name: TIMESLOT_PARAM,
            value: `${timeSlotStart.value.replaceAll(":", "-")}_${apiFormattedTimeSlotEndHour.replaceAll(":", "-")}`,
        })
    }

    const isInitialPeriodStartDate = initialFilters.date.periodStartDate?.getTime() === value.periodStartDate?.getTime()
    const isInitialPeriodEndDate = initialFilters.date.periodEndDate?.getTime() === value.periodEndDate?.getTime()
    const isInitialPeriod = isInitialPeriodStartDate && isInitialPeriodEndDate

    if (!isInitialPeriod) {
        const periodStart = dateQueryParams[0]
        const periodEnd = dateQueryParams[1]

        res.push({
            name: PERIOD_PARAM,
            value: `${periodStart.value}_${periodEnd.value}`,
        })
    }
    return res
}

const removeDateValue = (filterName, initialFilters, currentDateFilter) => {
    if (filterName === EVENT_INFOS_HOURS) {
        return ({
            ...currentDateFilter,
            timeSlotStartHour: initialFilters.date.timeSlotStartHour,
            timeSlotEndHour: initialFilters.date.timeSlotEndHour,
        })
    }
    if (filterName === EVENT_INFOS_DATE) {
        return ({
            ...currentDateFilter,
            periodStartDate: initialFilters.date.periodStartDate,
            periodEndDate: initialFilters.date.periodEndDate,
        })
    }
}

export const eventFiltersConfig = {
    date: {
        getQueryParams: (_, value, __, dateFormatter) => getDateQueryParams(value, dateFormatter),
        getSearchParams: (value, dateFormatter, initialFilters) => getDateSearchParams(value, dateFormatter, initialFilters),
        getValueList: (_, value, __, ___, dateFormatter, initialFilters) => getDateValueList(value, dateFormatter, initialFilters),
        removeValue: (_, __, filterName, initialFilters, currentDateFilter) => removeDateValue(filterName, initialFilters, currentDateFilter),
    },
    tags: {
        getQueryParams: getQueryParamsForArray,
        getValueList: getValueListForArray,
        removeValue: removeValueForArray,
    },
    incidentType: {
        getQueryParams: (key, value) => getQueryParamsForAlias(key, value, incidentTypeConfig),
        getValueList: (key, value, t, namespace) => getValueListForAlias(key, value, incidentTypeConfig, t, namespace),
        removeValue: () => {
            return null
        },
    },
    publicationStatus: {
        getQueryParams: (key, value) => getQueryParamsForAlias(key, value, eventPublicationStatusConfig),
        getValueList: (key, value, t, namespace) => getValueListForAlias(key, value, eventPublicationStatusConfig, t, namespace),
        removeValue: () => {
            return null
        },
    },
    status: {
        getQueryParams: (key, value) => getQueryParamsForAlias(key, value, incidentReportStatusSettings),
        getValueList: (key, value, t, namespace) => getValueListForAlias(key, value, incidentReportStatusSettings, t, namespace),
        removeValue: () => {
            return null
        },
    },
}

export const eventPublicationStatusConfig = {
    notAnalysed: {
        label: "components.incidentDetails.publicationStatus.notAnalyzed",
        queryParams: {
            is_published: false,
            reason_not_published__isnull: true,
        },
    },
    rejected: {
        label: "components.incidentDetails.publicationStatus.rejected",
        queryParams: {
            is_published: false,
            reason_not_published__isnull: false,
        },
    },
    accepted: {
        label: "components.incidentDetails.publicationStatus.accepted",
        queryParams: {
            is_published: true,
        },
    },
}

export const incidentTypeConfig = {
    unknown: {
        label: "components.incidentDetails.incidentType.unknown",
        queryParams: {
            incident_type__in: -1,
        },
    },
    braking: {
        label: "components.incidentDetails.incidentType.braking",
        queryParams: {
            incident_type__in: 0,
        },
    },
    cornering: {
        label: "components.incidentDetails.incidentType.cornering",
        queryParams: {
            incident_type__in: 1,
        },
    },
    combined: {
        label: "components.incidentDetails.incidentType.combined",
        queryParams: {
            incident_type__in: 2,
        },
    },
}