import { RoadContentRouter } from "../../routers/RoadContentRouter"
import { RoadDesktopMapRouter } from "../../routers/RoadDesktopMapRouter"
import { SidebarRouter } from "../../routers/SidebarRouter"
import * as Styled from "./RoadDesktopLayout.styled"

export function RoadDesktopLayout() {

    return (
        <Styled.Wrapper>
            <SidebarRouter />
            <RoadContentRouter />
            <Styled.Map >
                <RoadDesktopMapRouter />
            </Styled.Map>
        </Styled.Wrapper>
    )
}
