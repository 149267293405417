import { http, HttpResponse } from "msw"

import {
    allNetworks,
    getNetworkBySlug,
} from "../fixtures"

export const getReadOnlyHandlers = (apiUrl) => {
    return [
        http.get(`${apiUrl}network/networks`, () => {
            const readOnlyNetworks = allNetworks.map(network => ({ ...network, role: "READER" }))
            return HttpResponse.json(readOnlyNetworks)
        }),
        http.get(`${apiUrl}network/networks/:slug`, ({ params }) => {
            const network = getNetworkBySlug(params.slug)
            return HttpResponse.json({ ...network, role: "READER" })
        }),
    ]
}