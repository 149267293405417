import { useAuthenticationStateContext } from "@l2r-front/l2r-auth"

import { AppBar } from "../../components/AppBar"
import { AvatarWithAccountMenu } from "../AvatarWithAccountMenu"
import * as Styled from "./SimpleAppBar.styled"

export const SimpleAppBar = () => {
    const { isAuthenticated } = useAuthenticationStateContext()

    return (
        <AppBar
            position="fixed"
            elevation={0}>
            <Styled.WrapperLeft>
                <Styled.Link to="/">
                    <Styled.Logo id="logiroad-logo" src={"assets/resources/logiroad-center-logo.svg"} />
                </Styled.Link>
            </Styled.WrapperLeft>
            {isAuthenticated && <Styled.WrapperRight>
                <AvatarWithAccountMenu />
            </Styled.WrapperRight>}
        </AppBar>
    )
}