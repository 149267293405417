import MuiLoadingButton from "@mui/lab/LoadingButton"

import { PropTypes } from "@l2r-front/l2r-proptypes"

export function ButtonSubmit(props) {
    const {
        className,
        children,
        isSubmitting,
        ...otherProps
    } = props

    return (
        <MuiLoadingButton
            {...otherProps}
            fullWidth
            type="submit"
            variant="contained"
            color="cta-bg/cta-bg-primary"
            loading={isSubmitting}
            className={className}
            disableElevation={true}
        >
            {children}
        </MuiLoadingButton>
    )
}

ButtonSubmit.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    isSubmitting: PropTypes.bool,
}

ButtonSubmit.defaultProps = {
    isSubmitting: false,
}
