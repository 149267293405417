import { Route, Routes } from "react-router-dom"

import { IncidentsSidebarRouter } from "../../../modules/incidents/routers/IncidentsSidebarRouter"
import { MODULE_INCIDENTS } from "../../constants/modules"

export const SidebarRouter = () => {

    return <Routes>
        <Route
            element={<IncidentsSidebarRouter />}
            path={`/${MODULE_INCIDENTS}/*`}
        />
    </Routes>
}