import { Route, Routes } from "react-router-dom"

import { RoadDesktopLayout } from "../../layouts/RoadDesktopLayout"

export function RoadDesktopRouter() {
    return (
        <Routes>
            <Route
                path="/*"
                element={
                    <RoadDesktopLayout />
                }
            />
        </Routes>
    )
}