import { useMemo } from "react"

import { useMyUserContext } from "@l2r-front/l2r-auth"
import { useDateFormatter, useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { ListItem } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { IncidentListItem as IncidentListItemComponent, IncidentListItemSkeleton } from "../../components/IncidentListItem"
import { INCIDENT_TYPE_RULE_4 } from "../../constants/event"
import { useEventQuery } from "../../hooks/queries/events/useEvent"
import { formatEventDate, formatEventTimeSlot } from "../../utils/formatEventDate"
import { getStatusFromReport } from "../../utils/statusHelpers"

export const IncidentListItem = (props) => {
    const {
        uuid,
        ...listItemProps
    } = props

    const { data, isLoading, isError } = useEventQuery(uuid)
    const { user, isLoading: isLoadingUser } = useMyUserContext()
    const { t } = useTranslation([I18N_NAMESPACE])
    const dateFormatter = useDateFormatter()

    const dateDisplay = useMemo(() => {
        if (!data) {
            return ""
        }

        const date = formatEventDate(data.date, dateFormatter)
        const timeSlot = formatEventTimeSlot(data.timeSlot, dateFormatter)

        return `${date} ${timeSlot}`
    }, [data, dateFormatter])

    const status = useMemo(() => {
        if (data) {
            return getStatusFromReport(data?.report)
        }
    }, [data])

    if (isLoading || isLoadingUser) {
        return <IncidentListItemSkeleton />
    }

    if (isError) {
        return <div>
            {t(I18N_NAMESPACE, "containers.incidentListItem.errors.title")}
        </div>
    }

    if (!data) {
        return null
    }

    return (
        <IncidentListItemComponent
            identifier={data.identifier}
            date={dateDisplay}
            status={status}
            type={INCIDENT_TYPE_RULE_4}
            isPublished={data.isPublished}
            reasonNotPublished={data.reasonNotPublished}
            userIsStaff={user && user.isStaff}
            {...listItemProps}
        />
    )
}

IncidentListItem.propTypes = {
    ...ListItem.propTypes,
    uuid: PropTypes.string.isRequired,
}