import {
    Typography,
    Chip,
    IconButton as UiIconButton,
    styled,
    styledForMobile,
    SIDEBAR_SPACING,
    SIDEBAR_SPACING_MOBILE,
} from "@l2r-front/l2r-ui"

import { RoadwayYearSelector as L2RRoadwayYearSelector } from "../RoadwayYearSelector/RoadwayYearSelector"

export const Container = styled("div")(() => ({
    height: "100%",
    overflowY: "auto",
}))

export const RoadwayTabPanelHeader = styled("div")(({ theme }) => ({
    display: "flex",
    height: 40,
    margin: theme.spacing(0, 0, 3, 0),
    alignItems: "center",
}))

export const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette["objects/object-black"].main,
}))

export const IconButton = styled(UiIconButton)(() => ({
    marginLeft: "auto",
}))

export const HeaderWrapper = styledForMobile("div")(({ theme, isMobile }) => ({
    margin: isMobile ? theme.spacing(0, SIDEBAR_SPACING_MOBILE, 5, SIDEBAR_SPACING_MOBILE) : theme.spacing(0, SIDEBAR_SPACING, 5, SIDEBAR_SPACING),
}))

export const Count = styled(Chip)(({ theme }) => ({
    color: theme.palette["objects/object-black"].main,
    marginLeft: theme.spacing(3),
}))

export const YearSelector = styled(L2RRoadwayYearSelector)(() => ({
    width: "100%",
}))

export const NetworkGradingSelectorDescription = styled(Typography)(({ theme }) => ({
    display: "block",
    paddingTop: theme.spacing(2),
    width: "100%",
}))