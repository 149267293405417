import { Route, Routes, Navigate } from "react-router-dom"

import { useRoleIsReadOnly } from "@l2r-front/l2r-networks"

import { Report } from "../../../modules/incidents/containers/Report"
import { ReportContextProvider } from "../../../modules/incidents/contexts/ReportContext"
import { MODULE_INCIDENTS } from "../../constants/modules"
import { SvraiDesktopLayout } from "../../layouts/SvraiDesktopLayout"

export function SvraiDesktopRouter() {
    const readOnly = useRoleIsReadOnly()

    return (
        <Routes>
            {
                readOnly ? <Route
                    path={`${MODULE_INCIDENTS}/:eventId/analysis/*`}
                    element={<Navigate replace to={`../${MODULE_INCIDENTS}`} />}
                />
                    : <Route
                        path={`${MODULE_INCIDENTS}/:eventId/analysis/*`}
                        element={
                            <ReportContextProvider>
                                <Report />
                            </ReportContextProvider>
                        }
                    />
            }
            <Route
                path="/*"
                element={
                    <SvraiDesktopLayout />
                }
            />
        </Routes>
    )
}
