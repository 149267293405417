import { styled } from "@l2r-front/l2r-ui"

import { MobileRoadwayMap as RoadwayMap } from "../../containers/MobileRoadwayMap"
import { RoadwayDetailSidebar } from "../../containers/RoadwayDetailSidebar"

export const RoadwayDetailNotations = styled(RoadwayDetailSidebar)(({ theme }) => ({
    paddingTop: theme.spacing(6),
}))

export const Map = styled(RoadwayMap)({
    height: "100%",
    width: "100%",
})
