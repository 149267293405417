import { string } from "prop-types"

import { isEmail } from "../utils/isEmail"

export const email = function emailValidator(props, propName, componentName, ...rest) {
    const { [propName]: propValue } = props

    if (propValue == null) {
        return null
    }

    const stringError = string(props, propName, componentName, ...rest)
    if (stringError) {
        return stringError
    }

    if (!isEmail(propValue)) {
        return new TypeError(`Invalid prop '${propName}' supplied to '${componentName}'. Invalid email.`)
    }

    return null
}

email.isRequired = function requiredEmailValidator(...args) {
    const stringError = string.isRequired(...args)
    if (stringError) {
        return stringError
    }

    return email(...args)
}

