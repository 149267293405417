import { useMemo } from "react"

import { Formik } from "formik"
import { useNavigate } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { STATUS_CODE_BAD_REQUEST } from "@l2r-front/l2r-query"
import { CTAButton } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { validationSchema } from "./RecoverPasswordDoneForm.schema"
import * as Styled from "./RecoverPasswordDoneForm.styled"

export const RecoverPasswordDoneForm = (props) => {
    const {
        initialValues,
        onSubmit,
        className,
    } = props

    const { t } = useTranslation([I18N_NAMESPACE])
    const navigate = useNavigate()

    const formikProps = useMemo(() => {
        return {
            enableReinitialize: true,
            validationSchema: validationSchema(t),
            initialValues: initialValues,
            onSubmit: async (values, { setStatus }) => {
                try {
                    await onSubmit(values)
                } catch (error) {
                    if (error.response?.status === STATUS_CODE_BAD_REQUEST) {
                        if (error.response.data?.password) {
                            setStatus(error.response.data.password[0])
                            return
                        }
                        if (error.response.data?.token) {
                            setStatus(error.response.data.token[0])
                            return
                        }
                    }
                    setStatus(t(I18N_NAMESPACE, "forms.recoverPasswordDoneForm.errors.unknown"))
                }
            },
        }
    }, [initialValues, onSubmit, t])

    return (
        <Formik {...formikProps}>
            {(formikState) => (
                <Styled.Form className={className}>
                    {formikState.status && (
                        <Styled.FormHelperText error id="submit-error">{formikState.status}</Styled.FormHelperText>
                    )}
                    <Styled.TokenInput
                        fullWidth
                        type="hidden"
                        name="token"
                        margin="none"
                    />
                    <Styled.PasswordInput
                        autoFocus
                        id="password"
                        type="password"
                        name="password"
                        label={t(I18N_NAMESPACE, "forms.recoverPasswordDoneForm.fields.password.label")}
                    />
                    <Styled.Text id="recover-password-done-helper" variant="Small">
                        {t(I18N_NAMESPACE, "forms.recoverPasswordDoneForm.fields.password.helper")}
                    </Styled.Text>
                    <Styled.FormikTextField
                        id="confirm-password"
                        type="password"
                        name="confirmPassword"
                        label={t(I18N_NAMESPACE, "forms.recoverPasswordDoneForm.fields.confirmPassword.label")}
                    />
                    <Styled.ButtonsWrapper>
                        <CTAButton onClick={() => navigate("/")} variant="outlined">
                            {t(I18N_NAMESPACE, "forms.recoverPasswordDoneForm.actions.return")}
                        </CTAButton>
                        <Styled.FormikButtonSubmit>{t(I18N_NAMESPACE, "forms.recoverPasswordDoneForm.actions.continue")}</Styled.FormikButtonSubmit>
                    </Styled.ButtonsWrapper>
                </Styled.Form>
            )}
        </Formik>
    )
}

RecoverPasswordDoneForm.defaultProps = {
    initialValues: {
        token: "",
        password: "",
        confirmPassword: "",
    },
}

RecoverPasswordDoneForm.propTypes = {
    initialValues: PropTypes.exact({
        token: PropTypes.string.isRequired,
        password: PropTypes.string,
        confirmPassword: PropTypes.string,
    }),
    onSubmit: PropTypes.func.isRequired,
    className: PropTypes.string,
}