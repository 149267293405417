import { styled } from "@mui/material"
import { Axis as VisxAxis, AxisBottom as VisxAxisBottom } from "@visx/axis"

import { ChartLegend as UiChartLegend } from "./../ChartLegend"

export const LineChartWrapper = styled("div")({
    position: "relative",
    height: "100%",
    width: "100%",
})

export const ChartLegend = styled(UiChartLegend)(({ top, left }) => ({
    position: "absolute",
    top: top,
    left: left,
}))

export const AxisWrapper = styled("svg")({
    left: 0,
    position: "absolute",
    top: 0,
})

export const ChartWrapper = styled("svg")(({ left }) => ({
    left: left,
    position: "absolute",
    top: 0,
}))

export const Axis = styled(VisxAxis)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
}))

export const AxisBottom = styled(VisxAxisBottom)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
}))