import {useState} from "react"

import { PasswordFormModal } from "../forms/PasswordFormModal/PasswordFormModal"

export const usePasswordFormModal = (onSubmit, onError, title, desc = "") => {

    const [formValues, setFormValues] = useState(null)

    return ({
        modal: <PasswordFormModal
            formValues={formValues}
            onSubmit={onSubmit}
            onError={onError}
            setFormValues={setFormValues}
            title={title}
            desc={desc}
        />,
        setFormValues: setFormValues,
    })
}
