import { useContext } from "react"

import { MyUserContext } from "./MyUserContext.context"

export const useMyUserContext = () => {
    const context = useContext(MyUserContext)
    if (context === undefined) {
        throw new Error("useMyUserContext must be used within an MyUserContextProvider")
    }
    return context
}
