export function getDataFromTime (time, chartData, retries = 0) {
    const formattedTime = +time.toFixed(2)
    const timeIndex = chartData.findIndex(v => {
        return v.x === formattedTime
    })
    if (timeIndex === -1) {
        if (retries > 20) {
            return 0
        }
        const newTime = time - .01
        return getDataFromTime(newTime, chartData, retries + 1)
    }
    const data = chartData[timeIndex].y
    return ({
        x: formattedTime,
        y: +data.toFixed(2),
    })
}