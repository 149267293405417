import { I18N_NAMESPACE } from "../../../common/constants/i18n"

export const formatEventDetails = (displayedDetails, incident, detailSettings, t) => {
    const details = displayedDetails.map(displayedDetail => {
        return ({
            [displayedDetail]: incident[displayedDetail],
        })
    })
    return getDetails(details, detailSettings, t)
}

const getDetails = (details, detailSettings, t) => {
    return details.map(detail => {
        return Object.entries(detail || {}).reduce((_, [detailKey, detailValue]) => {
            return detailSettings[detailKey][detailValue]
        }, {})
    })
        .map(detail => t(I18N_NAMESPACE, detail?.label))
}
