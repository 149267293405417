import { styled, Typography as UiTypography } from "@l2r-front/l2r-ui"

export const Typography = styled(UiTypography)(({ theme }) => ({
    marginTop: theme.spacing(12),
    textAlign: "center",
}))

export const TotalCostContainer = styled("div")(({ theme }) => ({
    display: "flex",
    marginTop: theme.spacing(3),
    flexDirection: "row",
}))

export const TotalCost = styled(UiTypography)(({ theme }) => ({
    marginLeft: theme.spacing(5),
}))

export const Header = styled("div")(({ theme }) => ({
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
}))

export const TextWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
})