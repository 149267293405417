import { styled, Skeleton as UiSkeleton } from "@l2r-front/l2r-ui"

export const Container = styled("div")(({ theme }) => ({
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette["surfaces/surface-clair"].main,
}))

export const TopContainer = styled("div")(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 101,
}))

export const LoadingContainer = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-clair"].main,
    position: "absolute",
    top: 0,
    height: "100%",
    width: "100%",
    zIndex: 100,
}))

export const Skeleton = styled(UiSkeleton)({
    height: "100%",
    transform: "scale(1)",
    width: "100%",
})