import { Route, Routes } from "react-router-dom"

import { IncidentDetailsLayers } from "../../containers/IncidentDetailsLayers"
import { IncidentsLayers } from "../../containers/IncidentsLayers"

export function IncidentsMapLayersRouter() {

    return <Routes>
        <Route
            path="/"
            element={<IncidentsLayers />}
        />
        <Route
            path="/:eventId/*"
            element={
                <>
                    <IncidentsLayers />
                    <IncidentDetailsLayers />
                </>
            }
        />
    </Routes>
}
