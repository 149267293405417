import { Typography as MuiTypography } from "@mui/material"
import { styled } from "@mui/material/styles"

export const Wrapper = styled("div")(({ color, theme }) => ({
    alignItems: "center",
    backgroundColor: theme?.palette?.[color]?.main || color,
    borderRadius: theme.spacing(2),
    minWidth: 50,
    padding: theme.spacing(2, 3),
    textAlign: "center",
}))

export const Typography = styled(MuiTypography)(({ color }) => ({
    color: color,
    display: "block",
    overflow: "hidden",
    textAlign: "center",
    textOverflow: "ellipsis",
    width: "100%",
}))
