import { Typography } from "@mui/material"

import { PropTypes } from "@l2r-front/l2r-proptypes"

export function DetailElements(props) {

    const {
        detailElements,
        variant,
    } = props

    return <>
        {
            detailElements.map((element, index) => {
                return <Typography
                    key={`incident-element-${index}`}
                    variant={variant || "incidentDetail"}
                >
                    {element}
                </Typography>
            })
        }
    </>
}

DetailElements.propTypes = {
    detailElements: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    variant: PropTypes.string,
}