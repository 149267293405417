import { useMemo } from "react"

import * as turf from "@turf/turf"
import { useParams } from "react-router-dom"

import { useGeoServerRetrieveFeature, sanitizeData } from "@l2r-front/l2r-geodata"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { Source, useMapDispatchContext } from "@l2r-front/l2r-map"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { roadworksQueryKeys } from "../../hooks/queries/queryKeys"

export function RoadworkSectionSource(props) {

    const { t } = useTranslation(I18N_NAMESPACE)
    const { setError: setMapError } = useMapDispatchContext()
    const { roadworkUuid } = useParams()

    const { data, isError } = useGeoServerRetrieveFeature(roadworkUuid, roadworksQueryKeys.section(roadworkUuid), { as_geojson: true }, {
        onError: () => setMapError(true),
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.roadworksLayer.error"),
    })


    // Need to copy id into properties because of that mapbox issue:
    // link:https://github.com/mapbox/mapbox-gl-js/issues/2716
    const sanitizedData = useMemo(() => {
        if (!data || isError) {
            return null
        }
        return sanitizeData(turf.featureCollection([data]))
    }, [data, isError])

    if (!sanitizedData) {
        return null
    }

    return (
        <Source {...props} type="geojson" data={sanitizedData} tolerance={.001} />
    )
}
