import { useMemo } from "react"

import { Navigate, useRoutes } from "react-router-dom"

import { VerticalSigningDesktopRoutes } from "../../../modules/verticalSigning/routers/VerticalSigningRoutes"
import { MODULE_VERTICAL_SIGNING } from "../../constants/modules"

export const CityContentRouter = () => {

    const cityRoutes = useMemo(() => ([
        {
            path: `/${MODULE_VERTICAL_SIGNING}/*`,
            element: <VerticalSigningDesktopRoutes />,
        },
        {
            path: "*",
            element: <Navigate replace to={`${MODULE_VERTICAL_SIGNING}`} />,
        },
    ]), [])

    return useRoutes(cityRoutes)
}