import * as turf from "@turf/turf"

export function sanitizeData(data) {
    if (!data) {
        return null
    }

    const dataFeatures = []
    turf.geomEach(data, (currentGeometry, featureIndex, featureProperties, featureBBox, featureId) => {
        const feature = data.features[featureIndex]
        dataFeatures.push({
            bbox: featureBBox,
            geometry: currentGeometry,
            id: featureId,
            properties: {
                ...transformSnakeCase(featureProperties),
                linearLocation: feature.linear_location,
                uuid: featureId,
            },
        })
    })

    return turf.featureCollection(dataFeatures)
}

function transformSnakeCase(featureProperties) {
    return Object.entries(featureProperties).reduce((acc, [k, v]) => ({
        ...acc,
        [snakeToCamel(k)]: v,
    }), {})
}


function snakeToCamel(snake_str) {
    return snake_str.replace(/(_\w)/g, function (match) {
        return match[1].toUpperCase()
    })
}