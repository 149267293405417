import { useContext } from "react"

import { ReportDispatchContext, ReportStateContext } from "./ReportContext.context"


export const useReportStateContext = () => {
    const context = useContext(ReportStateContext)
    if (context === undefined) {
        throw new Error("useReportStateContext must be used within an ReportContextProvider")
    }
    return context
}

export const useReportDispatchContext = () => {
    const context = useContext(ReportDispatchContext)
    if (context === undefined) {
        throw new Error("useReportDispatchContext must be used within an ReportContextProvider")
    }
    return context
}

export const useReportContext = () => {
    return [useReportStateContext(), useReportDispatchContext()]
}
