import { LegendOrdinal } from "@visx/legend"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { slugify } from "@l2r-front/l2r-utils"

import * as Styled from "./ChartLegend.styled"

const LEGEND_GLYPH_SIZE = 10

export const ChartLegend = (props) => {

    const {
        className,
        id,
        legend,
        title,
    } = props

    return (
        <Styled.LegendWrapper className={className} id={id}>
            {title && <Styled.Title id="title">{title}</Styled.Title>}
            {legend && <LegendOrdinal scale={legend}>
                {(labels) => (
                    <Styled.LabelWrapper>
                        {labels.map((label, i) => (
                            <Styled.LegendItem
                                key={`legend-quantile-${i}`}
                                margin={2}
                                id={`${slugify(label.datum)}-legend`}
                            >
                                <svg
                                    width={LEGEND_GLYPH_SIZE}
                                    height={LEGEND_GLYPH_SIZE}
                                >
                                    <rect
                                        fill={label.value}
                                        width={LEGEND_GLYPH_SIZE}
                                        height={LEGEND_GLYPH_SIZE}
                                    />
                                </svg>
                                <Styled.LegendLabel id={`${slugify(label.datum)}-text`}>
                                    {label.text}
                                </Styled.LegendLabel>
                            </Styled.LegendItem>
                        ))}
                    </Styled.LabelWrapper>
                )}
            </LegendOrdinal>}
        </Styled.LegendWrapper>
    )
}

ChartLegend.propTypes = {
    className: PropTypes.string,
    legend: PropTypes.func,
    title: PropTypes.string,
}