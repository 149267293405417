import { useCallback } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useNetworksDispatchContext } from "@l2r-front/l2r-networks"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"


import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useRoadworksDispatchContext } from "../../contexts/RoadworksContext"
import { RoadworksRoadSidebarContent } from "../RoadworksRoadSidebarContent"
import * as Styled from "./RoadworksRoadSidebar.styled"

export function RoadworksRoadSidebar() {

    const { t } = useTranslation(I18N_NAMESPACE)
    const navigate = useNavigateWithSearchParams()
    const { setSelectedRoadwork } = useRoadworksDispatchContext()
    const { setRoadSearched } = useNetworksDispatchContext()

    const navigateBack = useCallback(() => {
        setSelectedRoadwork(null)
        setRoadSearched(null)

        navigate("..")
    }, [navigate, setSelectedRoadwork, setRoadSearched])

    return <Styled.SidebarContent id="sidebar-detail-content" onBack={navigateBack} title={t(I18N_NAMESPACE, "containers.roadworksDetailSidebar.title")}>
        <RoadworksRoadSidebarContent />
    </Styled.SidebarContent>
}