import { useContext } from "react"

import { MapStateContext, MapDispatchContext } from "./MapContext.context"


export const useMapStateContext = () => {
    const context = useContext(MapStateContext)
    if (context === undefined) {
        throw new Error("useMapStateContext must be used within an MapContextProvider")
    }
    return context
}

export const useMapDispatchContext = () => {
    const context = useContext(MapDispatchContext)
    if (context === undefined) {
        throw new Error("useMapDispatchContext must be used within an MapContextProvider")
    }
    return context
}

export const useMapContext = () => {
    return [useMapStateContext(), useMapDispatchContext()]
}
