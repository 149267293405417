import { useContext } from "react"

import { SvraiStateContext, SvraiDispatchContext } from "./SvraiContext.context"


export const useSvraiStateContext = () => {
    const context = useContext(SvraiStateContext)
    if (context === undefined) {
        throw new Error("useSvraiStateContext must be used within an SvraiContextProvider")
    }
    return context
}

export const useSvraiDispatchContext = () => {
    const context = useContext(SvraiDispatchContext)
    if (context === undefined) {
        throw new Error("useSvraiDispatchContext must be used within an SvraiContextProvider")
    }
    return context
}

export const useSvraiContext = () => {
    return [useSvraiStateContext(), useSvraiDispatchContext()]
}
