import { styled, Select as MuiSelect } from "@mui/material"

export const Select = styled(MuiSelect)(({ theme }) => ({
    "& .MuiSelect-select": {
        padding: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: 0,
    },
    "& .MuiSelect-icon": {
        color: theme.palette["objects/object-black"].main,
    },
}))