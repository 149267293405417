import { useMediaQuery, theme } from "@l2r-front/l2r-ui"

import { SimpleAppBar } from "../../../../common/containers/SimpleAppBar"
import { AccountSettingsSidebar } from "../../containers/AccountSettingsSidebar"
import { AccountSettingsRouter } from "../../routers/AccountSettingsRouter"
import * as Styled from "./AccountSettingsDesktopLayout.styled"

export function AccountSettingsDesktopLayout() {
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))

    return (
        <>
            <SimpleAppBar />
            <Styled.ContentWrapper>
                <AccountSettingsSidebar />
                <Styled.PageWrapper>
                    <AccountSettingsRouter />
                </Styled.PageWrapper>
                {isLargeScreen && <Styled.Image src="/assets/resources/team-account.png" />}
            </Styled.ContentWrapper>
        </>
    )
}