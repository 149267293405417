import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./RoadworkDetailsForm.styled"

export function RoadworkDetailsFormSkeleton(props) {

    const { className } = props

    return <Styled.RoadworkDetailWrapper className={className}>
        <Styled.RoadworkDetailSkeleton />
    </Styled.RoadworkDetailWrapper>
}

RoadworkDetailsFormSkeleton.propTypes = {
    className: PropTypes.string,
}