import { useCallback, useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useRoleIsReadOnly } from "@l2r-front/l2r-networks"
import { MobileModulePage } from "@l2r-front/l2r-ui"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"


import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { MobileReportFAB } from "../../components/MobileReportFAB"
import * as Styled from "./MobileIncidentDetailsPage.styled"

export const MobileIncidentDetailsPage = () => {

    const { t } = useTranslation(I18N_NAMESPACE)
    const navigate = useNavigateWithSearchParams()
    const readOnly = useRoleIsReadOnly()

    const incidentDetailsTabs = useMemo(() => ([
        {
            element: <Styled.IncidentDetails />,
            id: "tab-info",
            label: t(I18N_NAMESPACE, "pages.incidentsDetails.tabs.details"),
        },
        {
            element: <Styled.IncidentVideo />,
            id: "tab-video",
            label: t(I18N_NAMESPACE, "pages.incidentsDetails.tabs.video"),
            fullScreen: true,
        },
        {
            element: <Styled.IncidentCharts />,
            id: "tab-charts",
            label: t(I18N_NAMESPACE, "pages.incidentsDetails.tabs.charts"),
        },
        {
            element: <Styled.Map />,
            id: "tab-map",
            label: t(I18N_NAMESPACE, "pages.incidentsDetails.tabs.map"),
        },
    ]), [t])

    const handleReportClick = useCallback(() => {
        return navigate("analysis")
    }, [navigate])

    return <>
        {!readOnly && <MobileReportFAB onClick={handleReportClick} />}
        <MobileModulePage paramKey="tab-incident-details" tabsConfig={incidentDetailsTabs} />
    </>
}