import { string } from "prop-types"

import { isTimeStamp } from "../utils/isTimestamp"

export const timestamp = function timestampValidator(props, propName, componentName, ...rest) {
    const { [propName]: propValue } = props

    if (propValue == null) {
        return null
    }

    const stringError = string(props, propName, componentName, ...rest)
    if (stringError) {
        return stringError
    }

    if (!isTimeStamp(propValue)) {
        return new TypeError(`Invalid prop '${propName}' supplied to '${componentName}'. Invalid timestamp.`)
    }

    return null
}

timestamp.isRequired = function requiredTimestampValidator(...args) {
    const stringError = string.isRequired(...args)
    if (stringError) {
        return stringError
    }

    return timestamp(...args)
}