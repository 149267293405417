import { string } from "prop-types"

import { isUuid } from "../utils/isUuid"

export const uuid = function uuidValidator(props, propName, componentName, ...rest) {
    const { [propName]: propValue } = props

    if (propValue == null) {
        return null
    }

    const stringError = string(props, propName, componentName, ...rest)
    if (stringError) {
        return stringError
    }

    if (!isUuid(propValue)) {
        return new TypeError(`Invalid prop '${propName}' supplied to '${componentName}'. Invalid uuid.`)
    }

    return null
}

uuid.isRequired = function requiredUuidValidator(...args) {
    const stringError = string.isRequired(...args)
    if (stringError) {
        return stringError
    }

    return uuid(...args)
}