import Skeleton from "@mui/material/Skeleton"

import * as Styled from "./Kpi.styled"

export const KpiSkeleton = () => (
    <Styled.KpiCard>
        <Skeleton
            width="100%"
            height="100%"
            animation="wave"
            variant="rectangular"
        />
    </Styled.KpiCard>
)
