import { geoServerQueryKeys } from "./queryKeys"
import { useGeoServerQuery } from "./useGeoServerQuery"

export function useGeoServerRetrieveFeature(featureUuid, queryKey = null, queryParameters = {}, config = {}) {
    const url = `features/${featureUuid}/`

    const { enabled = true } = config

    if (!queryKey) {
        queryKey = geoServerQueryKeys.feature(featureUuid)
    }

    return useGeoServerQuery(url, queryKey, queryParameters, {
        ...config,
        enabled: !!featureUuid && enabled,
    })
}
