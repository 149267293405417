import { useTranslation } from "@l2r-front/l2r-i18n"

import { I18N_NAMESPACE } from "../../constants/i18n"
import * as Styled from "./Cgu.styled"

export const CguError = () => {

    const { t } = useTranslation(I18N_NAMESPACE)

    return <Styled.ErrorWrapper>
        <Styled.ErrorText variant="body1">
            {t(I18N_NAMESPACE, "pages.cgu.error")}
        </Styled.ErrorText>
    </Styled.ErrorWrapper>
}   