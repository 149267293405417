import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { useRoadQuery } from "./useRoadQuery"

export function useNetwork(
    queryParameters,
    config = {},
) {
    const { enabled = true } = config
    const { selectedNetwork } = useNetworksStateContext()
    const url = `projects/${selectedNetwork?.slug}/`

    return useRoadQuery(
        url,
        ["road", "network"],
        queryParameters,
        {
            ...config,
            enabled: !!selectedNetwork?.slug && enabled,
        },
    )
}
