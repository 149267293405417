import {
    ListItemButton as UiListItemButton,
    ListItemSkeleton as UiListItemSkeleton,
    Typography,
    styled,
} from "@l2r-front/l2r-ui"

import { INCIDENT_LIST_ITEM_HEIGHT } from "../../constants/layout"

export const ListItemButton = styled(UiListItemButton)(({ theme }) => ({
    border: `1px solid ${theme.palette["objects/object-lightgrey"].main}`,
    borderRadius: 4,
    display: "flex",
    height: INCIDENT_LIST_ITEM_HEIGHT,
    marginBottom: theme.spacing(3),
    padding: 0,
    width: "100%",
}))

export const ListItem = styled("div")(({ theme }) => ({
    alignItems: "start",
    boxShadow: theme.shadows[27],
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(4, 6),
}))

export const ListItemHeader = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    height: "30px",
    justifyContent: "start",
    marginBottom: theme.spacing(3),
    width: "100%",
}))

export const TypeIcon = styled("div")(({ theme }) => ({
    height: "100%",
    marginRight: theme.spacing(4),
    width: 27.5,

    "& svg": {
        width: "100%",
        height: "100%",
    },
}))

export const StatusIcon = styled("div")(() => ({
    marginLeft: "auto",
    height: 30,
    "& svg": {
        width: 30,
        height: 30,
    },
}))

export const PrimaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette["objects/object-black"].main,
}))

export const SecondaryTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette["texts/text-primary"].main,
}))

export const IncidentListItemSkeleton = styled(UiListItemSkeleton)(({ theme }) => ({
    borderRadius: theme.spacing(1, 0, 0, 1),
    height: INCIDENT_LIST_ITEM_HEIGHT,
    marginBottom: theme.spacing(3),
    width: "100%",
}))

export const Wrapper = styled("div")({
})

export const IconWrapper = styled("div")(({ theme }) => ({
    alignItems: "center",
    backgroundColor: theme.palette["cta-bg/cta-bg-primary"].main,
    borderRadius: "0 4px 4px 0",
    boxShadow: theme.shadows[27],
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-evenly",
    padding: theme.spacing(3),
}))