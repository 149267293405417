import { Fade, Modal, Backdrop } from "@mui/material"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { CTAButton } from "../CTAButton"
import * as Styled from "./ConfirmDialog.styled"

export const ConfirmDialog = (props) => {

    const {
        cancelText,
        className,
        close,
        closeWithCallbacks,
        confirmText,
        id,
        open,
        text,
        title,
    } = props

    return <Modal
        slots={{
            backdrop: Backdrop,
        }}
        slotProps={{
            backdrop: { timeout: 500 },
        }}
        closeAfterTransition
        open={open}
        onClose={close}
        id={id ?? "alert"}
    >
        <Fade in={open}>
            <Styled.Wrapper className={className}>
                {title && <Styled.Title id="alert-title" variant="H2">
                    {title}
                </Styled.Title>}
                {text && <Styled.Text id="alert-text" variant="Regular">
                    {`${text}`}
                </Styled.Text>}
                <Styled.ButtonWrapper>
                    {close && cancelText && <CTAButton
                        id="alert-cancel-button"
                        onClick={close}
                        variant="outlined"
                    >
                        {cancelText}
                    </CTAButton>}
                    {closeWithCallbacks && confirmText && <CTAButton
                        id="alert-confirm-button"
                        onClick={closeWithCallbacks}
                        variant="contained"
                        autoFocus
                    >
                        {confirmText}
                    </CTAButton>}
                </Styled.ButtonWrapper>
            </Styled.Wrapper>
        </Fade>
    </Modal>
}

ConfirmDialog.propTypes = {
    cancelText: PropTypes.string,
    className: PropTypes.string,
    close: PropTypes.func,
    closeWithCallbacks: PropTypes.func,
    confirmText: PropTypes.string,
    id: PropTypes.string,
    open: PropTypes.bool,
    text: PropTypes.string,
    title: PropTypes.string,
}