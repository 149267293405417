import { useMemo } from "react"

import { Navigate, Route, Routes } from "react-router-dom"
import urlJoin from "url-join"

import { ImageCollectionContextProvider, ImageViewer } from "@l2r-front/l2r-images"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { ComponentsChainer } from "@l2r-front/l2r-utils"

import { AccountSettingsLayout } from "../../modules/accountSettings/layouts/AccountSettingsLayout"
import { Dashboard } from "../../modules/dashboard/components/Dashboard"
import { useGetActiveModules } from "../hooks/useGetActiveModules"
import { AuthenticatedLayout } from "../layouts/AuthenticatedLayout"
import { LoadingNetworkPage } from "../pages/LoadingNetworkPage"

export const AuthenticatedRoutes = () => {
    const { selectedNetwork } = useNetworksStateContext()
    const appsWithActiveModules = useGetActiveModules()

    const appsContexts = useMemo(() => {
        const externalContexts = Object.values(appsWithActiveModules).map(app => app.externalContexts).flat()
        return externalContexts.filter(Boolean)
    }, [appsWithActiveModules])

    const appsRoutes = useMemo(() => {
        return Object.values(appsWithActiveModules).map(app => {
            if (app.path) {
                return <Route path={urlJoin(app.path, "*")} element={<AuthenticatedLayout>{app.component}</AuthenticatedLayout>} key={app.path} />
            } else {
                return null
            }
        })
    }, [appsWithActiveModules])

    return (
        <Routes>
            <Route path="/login/*" element={<Navigate replace to="/" />} />
            <Route path="/account_settings/*" element={<AccountSettingsLayout />} />
            {selectedNetwork && (<Route path={`/${selectedNetwork.slug}/*`} element={
                <ImageCollectionContextProvider>
                    <ComponentsChainer components={appsContexts}>
                        <Routes>
                            <Route path="/image_viewer" element={<ImageViewer />} />
                            <Route path="/" element={<AuthenticatedLayout><Dashboard /></AuthenticatedLayout>} />
                            {appsRoutes}
                            <Route path="*" element={<Navigate replace to="/" />} />
                        </Routes>
                    </ComponentsChainer>
                </ImageCollectionContextProvider>
            } />)}
            <Route path="*" element={<LoadingNetworkPage />}
            />
        </Routes>
    )
}
