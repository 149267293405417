import { useMemo } from "react"

import { Navigate, useRoutes } from "react-router-dom"

import { IncidentsDesktopRoutes } from "../../../modules/incidents/routers/IncidentsRoutes"
import { MODULE_INCIDENTS } from "../../constants/modules"

export const SvraiContentRouter = () => {

    const svraiRoutes = useMemo(() => ([
        {
            path: `/${MODULE_INCIDENTS}/*`,
            element: <IncidentsDesktopRoutes />,
        },
        {
            path: "*",
            element: <Navigate replace to={`${MODULE_INCIDENTS}`} />,
        },
    ]), [])

    return useRoutes(svraiRoutes)
}