import { styled } from "@mui/material/styles"
import { Document as ReactPdfDocument, Page as ReactPdfPage } from "react-pdf"

import { Typography, Skeleton as UiSkeleton } from "@l2r-front/l2r-ui"

import { CguFormPost as L2rCguFormPost } from "../../forms/CguForm"

export const PageWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    height: "90%",
})

export const Wrapper = styled("div")({
    height: "100%",
    overflowY: "scroll",
    textJustify: "auto",
    width: "100%",
})

export const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(5),
}))

export const CguFormPost = styled(L2rCguFormPost)(({ theme }) => ({
    marginTop: theme.spacing(13),
}))

export const Text = styled("p")({
    textAlign: "justify",
})

export const SubTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(5),
}))

export const Skeleton = styled(UiSkeleton)({
    minHeight: "100%",
    transform: "scale(1)",
    width: "100%",
})

export const Document = styled(ReactPdfDocument)({
    height: "100%",
    width: "100%",

    "& > .react-pdf__message--loading": {
        height: "100%",
    },
    "& > *": {
        height: "100%",
        width: "100% !important",
    },
})

export const Page = styled(ReactPdfPage)(() => ({
    height: "auto",
    minWidth: "100% !important",
    width: "100%",

    "& > .react-pdf__message--loading": {
        height: "100% !important",
    },

    "& > *": {
        minWidth: "100% !important",
        width: "100% !important",
    },
}))

export const ErrorWrapper = styled("div")({
    minHeight: "100%",
    width: "100%",
})

export const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette["texts/text-error"].main,
}))