import { useMemo } from "react"

import { Formik } from "formik"

import { useDateFormatter, useTranslation } from "@l2r-front/l2r-i18n"
import { ModeEditIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useAlertsDispatchContext } from "@l2r-front/l2r-query"
import { CTAButton, Switch, Typography, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useReportStateContext } from "../../contexts/ReportContext"
import { reportStepConfig } from "../../propTypes"
import { ReportStepForm } from "./ReportStep.form"
import * as Styled from "./ReportStep.styled"


export const ReportStep = (props) => {

    const {
        displayInput,
        displayToggle,
        handleBack,
        handleNext,
        handleSubmit,
        handleToggle,
        nextButtonLabel,
        previousButtonDisabled,
        stepConfig,
        toggleDisplayInput,
        toggleKey,
        ...reportStepProps
    } = props

    const { currentStepData } = useReportStateContext()
    const isMobile = useIsMobileDevice()
    const previousStepTranslation = `components.reportStep.buttons${isMobile ? ".mobile" : ""}.previousStep`

    const toggleChecked = useMemo(() => {
        return currentStepData?.values?.[toggleKey] ?? stepConfig?.toggle?.defaultToggleState
    }, [currentStepData, stepConfig, toggleKey])

    const { setSnackbar } = useAlertsDispatchContext()
    const dateFormatter = useDateFormatter()
    const { t } = useTranslation(I18N_NAMESPACE)

    const lastSavedCredentials = useMemo(() => {
        if (currentStepData?.updatedAt && currentStepData?.author) {
            const currentDate = currentStepData?.updatedAt
            return ({
                author: currentStepData.author,
                date: dateFormatter(new Date(currentDate), "dd MMMM yyyy"),
            })
        }
        return null
    }, [currentStepData, dateFormatter])

    const displayedText = useMemo(() => {
        if (currentStepData?.values?.content) {
            return currentStepData?.values?.content
        }
        return ""
    }, [currentStepData])

    const closeForm = (formikState) => {
        setSnackbar({
            label: t(I18N_NAMESPACE, "containers.reportSteps.snackbar.save"),
            severity: "success",
            anchorOrigin: {
                horizontal: "left",
                vertical: "bottom",
            },
        })
        toggleDisplayInput(false)
        formikState.setSubmitting(false)
        formikState.resetForm({
            values: {
                [stepConfig?.type]: (formikState?.values?.[stepConfig?.type] ?? ""),
            },
        })
    }

    const formikProps = useMemo(() => {
        return {
            initialValues: {
                [stepConfig?.type]: currentStepData?.values?.content ?? "",
            },
        }
    }, [currentStepData, stepConfig])

    const nextButton = useMemo(() => {
        return !nextButtonLabel ? null
            : <CTAButton
                id="next-button"
                onClick={handleNext}
                variant="contained"
            >
                <Typography variant="Regular">
                    {nextButtonLabel}
                </Typography>
            </CTAButton>

    }, [handleNext, nextButtonLabel])

    if (!currentStepData || !stepConfig) {
        return <Styled.Skeleton />
    }

    return (
        <Styled.ReportStepWrapper {...reportStepProps}>
            <Formik {...formikProps} enableReinitialize>
                {
                    displayInput ? (formikState) => {
                        return <ReportStepForm
                            closeForm={closeForm}
                            formikState={formikState}
                            handleSubmit={handleSubmit}
                            lastSavedCredentials={lastSavedCredentials}
                            stepConfig={stepConfig}
                        />
                    }
                        : <>
                            <Styled.Text variant="Regular">
                                {`${displayedText}`}
                            </Styled.Text>
                            <Styled.ButtonWrapper isMobile={isMobile} displayedText={displayedText}>
                                {lastSavedCredentials && <Typography variant="Small">
                                    {t(I18N_NAMESPACE, "components.reportStep.lastSavedCredentials", lastSavedCredentials)}
                                </Typography>}
                                <Styled.IconButton
                                    color="cta-bg/cta-bg-primary    "
                                    id="edit-button"
                                    onClick={() => toggleDisplayInput()}
                                    type="submit"
                                >
                                    <ModeEditIcon />
                                </Styled.IconButton>
                            </Styled.ButtonWrapper>
                        </>
                }
            </Formik>
            <Styled.Wrapper>
                <Styled.ButtonsWrapper isMobile={isMobile} nextButton={nextButton}>
                    {
                        displayToggle && <Styled.SwitchWrapper id="switch-input-wrapper" isMobile={isMobile}>
                            <Typography variant="Regular">{t(I18N_NAMESPACE, stepConfig.toggle.title)}</Typography>
                            <Switch
                                checked={toggleChecked}
                                color="cta-bg/cta-bg-primary"
                                id="switch-input"
                                onChange={handleToggle}
                            />
                        </Styled.SwitchWrapper>
                    }
                    {!isMobile && <Styled.Button
                        color="cta-bg/cta-bg-primary"
                        disabled={previousButtonDisabled}
                        id="back-button"
                        onClick={() => {
                            handleBack()
                            toggleDisplayInput(false)
                        }}
                        variant="outlined"
                    >
                        <Typography variant="Regular">
                            {t(I18N_NAMESPACE, previousStepTranslation)}
                        </Typography>
                    </Styled.Button>}
                    {!isMobile && nextButton}
                </Styled.ButtonsWrapper>
            </Styled.Wrapper>
        </Styled.ReportStepWrapper>
    )
}

ReportStep.propTypes = {
    displayInput: PropTypes.bool.isRequired,
    displayToggle: PropTypes.bool.isRequired,
    handleBack: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleToggle: PropTypes.func.isRequired,
    previousButtonDisabled: PropTypes.bool.isRequired,
    nextButtonLabel: PropTypes.string,
    stepConfig: reportStepConfig,
    toggleDisplayInput: PropTypes.func.isRequired,
    toggleKey: PropTypes.string,
}
