import * as turf from "@turf/turf"

export function getNearestFeature(data, position, maxDistance) {
    if (!data || !data.features || !data.features.length || !position) {
        return null
    }

    const targetPoint = turf.point(position)
    const nearestPoint = turf.nearestPoint(targetPoint, data)
    const distance = turf.distance(targetPoint, nearestPoint)
    if (distance > maxDistance) {
        return null
    }

    return nearestPoint
}