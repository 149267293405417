import { useCallback, useRef, useState } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { RoadworkQuartersBudget } from "../RoadworkQuartersBudget"
import { RoadworksQuartersScale } from "../RoadworksQuartersScale"
import * as Styled from "./RoadworksYearlyBudgetKpi.styled"

export function RoadworksYearlyBudgetKpiComponent(props) {

    const {
        budget,
        currency,
        quartersCost,
        totalDoneCost,
    } = props

    const scrollRef = useRef(null)
    const blockClick = useRef(false)

    const [scroll, setScroll] = useState({
        isDragging: false,
        startX: 0,
        scrollLeft: 0,
    })

    const handleMouseDown = useCallback((event) => {
        event.stopPropagation()
        setScroll(prevState => ({
            ...prevState,
            isDragging: true,
            startX: event.clientX - scrollRef.current.offsetLeft,
        }))
    }, [])

    const handleMouseUp = useCallback(() => {
        setScroll(prevState => ({
            ...prevState,
            isDragging: false,
        }))
        setTimeout(() => {
            blockClick.current = false
        }, 200)
    }, [])

    const handleMouseMove = useCallback((event) => {
        if (scroll.isDragging) {
            blockClick.current = true
            const deltaX = event.clientX - scrollRef.current.offsetLeft - scroll.startX
            scrollRef.current.scrollLeft = scroll.scrollLeft - deltaX
        }
    }, [scroll])

    const handleMouseLeave = useCallback(() => {
        setScroll(prevState => ({
            ...prevState,
            isDragging: false,
        }))
    }, [])

    const handleScroll = useCallback(() => {
        setScroll(prevState => ({
            ...prevState,
            scrollLeft: scrollRef.current.scrollLeft,
        }))
    }, [])

    const maxPercentage = Math.max(100, Math.round(totalDoneCost / budget * 100))

    return <Styled.ScrollableWrapper
        id="roadworks-quarters-kpi-scrollable"
        ref={scrollRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onScroll={handleScroll}
        onTouchStart={handleMouseDown}
        onTouchEnd={handleMouseDown}
        onClick={event => {
            if (blockClick.current) {
                event.stopPropagation()
            }
        }}
        style={{ cursor: scroll.isDragging ? "grabbing" : "grab" }}
    >
        <Styled.ChartWrapper>
            <RoadworkQuartersBudget
                budget={budget}
                currency={currency}
                quartersCost={quartersCost}
                totalDoneCost={totalDoneCost}
            />
            <RoadworksQuartersScale
                budget={budget}
                quartersCost={quartersCost}
                maxPercentage={maxPercentage}
            />
        </Styled.ChartWrapper>
    </Styled.ScrollableWrapper>
}

RoadworksYearlyBudgetKpiComponent.propTypes = {
    budget: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    quartersCost: PropTypes.quartersCost.isRequired,
    totalDoneCost: PropTypes.number.isRequired,
}