import { useMemo, useCallback, useState, useRef } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ArrowDropDownIcon, CheckCircleIcon, PlaylistPlayRoundedIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { CTAButton, Menu, MenuItem, Typography } from "@l2r-front/l2r-ui"
import { formatMetersToDistance } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { formatNumber } from "../../utils/formatting"
import { computeRoadworkLength } from "../../utils/roadworkUtils"
import * as Styled from "./TechniqueItemCard.styled"

export function TechniqueItemCard(props) {

    const {
        currency,
        deleteRoadwork,
        doneAt,
        handleClick,
        handleComplete,
        handleGoToDetails,
        technique,
        isSelected,
        isDisabled,
        ...otherProps
    } = props

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const menuRef = useRef(null)

    const formattedTechnique = useMemo(() => {
        const techniqueLength = computeRoadworkLength(technique.linearLocation)
        return ({
            ...technique,
            cost: formatNumber(technique.cost),
            impact: technique.impact,
            length: technique.linearLocation ? formatMetersToDistance(techniqueLength) : null,
        })
    }, [technique])

    const {
        acronym,
        cost,
        impact,
        length,
        year,
    } = formattedTechnique

    const { t } = useTranslation(I18N_NAMESPACE)

    const handleDetailsButtonClick = useCallback(e => {
        e.stopPropagation()
        handleGoToDetails()
    }, [handleGoToDetails])

    const handleCompleteButtonClick = useCallback(e => {
        e.stopPropagation()
        handleComplete()
    }, [handleComplete])

    const handleMenuClick = useCallback((event) => {
        setAnchorEl(menuRef.current)
        event.stopPropagation()
    }, [])

    const handleMenuClose = useCallback((event) => {
        setAnchorEl(null)
        event.stopPropagation()
    }, [])

    const displayButtonsZone = handleGoToDetails || handleComplete

    return <Styled.Card
        role="button"
        elevation={0}
        {...otherProps}
        onClick={handleClick}
        disabled={isDisabled}
        selected={isSelected}>
        <Styled.BadgeWrapper>
            {doneAt ? <Styled.DoneAtWrapper>
                <CheckCircleIcon color="objects/object-primary" />
                <Typography variant="H3">{doneAt}</Typography>
            </Styled.DoneAtWrapper> :
                <Typography variant="Regular">{year}</Typography>
            }
            <Styled.ColoredBadge id={"impact-badge"} impact={impact} />
        </Styled.BadgeWrapper>
        <Styled.Element>
            <Typography variant="H3">{acronym}</Typography>
        </Styled.Element>
        {length && <Styled.Element>
            <Styled.TextWrapper>
                <Typography variant="H3">{t(I18N_NAMESPACE, "components.techniqueCardItem.length")}</Typography>
                <Typography variant="Regular">{length}</Typography>
            </Styled.TextWrapper>
        </Styled.Element>}
        <Styled.Element>
            <Styled.TextWrapper>
                <Typography variant="H3">{t(I18N_NAMESPACE, "components.techniqueCardItem.cost")}</Typography>
                <Typography variant="Regular">{cost} {currency}</Typography>
            </Styled.TextWrapper>
        </Styled.Element>
        {displayButtonsZone && <Styled.Element>
            <Styled.ButtonsWrapper selected={isSelected}>
                {handleGoToDetails && <Styled.DetailButton
                    id="detail-button"
                    variant="outlined"
                    color="cta-bg/cta-bg-primary"
                    onClick={handleDetailsButtonClick}>
                    <PlaylistPlayRoundedIcon />
                </Styled.DetailButton>
                }
                {handleComplete && !isDisabled && <Styled.ButtonGroup ref={menuRef}>
                    <CTAButton
                        id="close-button"
                        onClick={handleCompleteButtonClick}
                        variant="outlined"
                    >
                        {t(I18N_NAMESPACE, "components.techniqueCardItem.closeButton.label")}
                    </CTAButton>
                    {deleteRoadwork && <Styled.IconButton id="open-menu-button" onClick={handleMenuClick}>
                        <ArrowDropDownIcon />
                    </Styled.IconButton>}
                </Styled.ButtonGroup>
                }
            </Styled.ButtonsWrapper>
            {deleteRoadwork && <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: "center",
                    vertical: "bottom",
                }}
                transformOrigin={{
                    horizontal: "center",
                    vertical: "top",
                }}
                open={open}
                onClose={handleMenuClose}
            >
                <MenuItem key="roadwork-delete" id="roadwork-delete" onClick={(event) => {
                    deleteRoadwork()
                    event.stopPropagation()
                    handleMenuClose(event)
                }}>
                    {t(I18N_NAMESPACE, "components.roadworkTask.delete")}
                </MenuItem>
            </Menu>}
        </Styled.Element>}
    </Styled.Card>
}

TechniqueItemCard.defaultProps = {
    isDisabled: false,
    isSelected: false,
}

TechniqueItemCard.propTypes = {
    currency: PropTypes.string.isRequired,
    deleteRoadwork: PropTypes.func,
    doneAt: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
    handleComplete: PropTypes.func,
    handleGoToDetails: PropTypes.func,
    isDisabled: PropTypes.bool,
    isSelected: PropTypes.bool,
    technique: PropTypes.technique.isRequired,
}
