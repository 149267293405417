import { styled } from "@l2r-front/l2r-ui"

export const Container = styled("div")(() => ({
    position: "relative",
    height: "100%",
}))

export const LayerIndicatorWrapper = styled("div")(({ theme }) => ({
    position: "absolute",
    width: `calc(100% - ${theme.spacing(12)}) !important`,
    top: theme.spacing(5),
    margin: theme.spacing(0, 6, 0, 6),
    zIndex: 3,
}))