import { useCallback, useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Typography } from "@l2r-front/l2r-ui"
import { handleRoundNumber } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { RoadworksList } from "../../components/RoadworksList"
import {
    TechniqueItemCard,
    TechniqueItemCardError,
    TechniqueItemCardSkeleton,
} from "../../components/TechniqueItemCard"
import { impactsOrder } from "../../constants/impacts"
import { STATUS_DONE } from "../../constants/status"
import {
    useRoadworksDispatchContext,
    useRoadworksStateContext,
} from "../../contexts/RoadworksContext"
import { useGetTechniquesQuery } from "../../hooks/queries/useGetTechniquesQuery"
import { formatNumber } from "../../utils/formatting"
import * as Styled from "./NetworkTechniquesList.styled"

export function NetworkTechniquesList(props) {

    const {
        className,
    } = props

    const { apiFilters } = useRoadworksStateContext()
    const { data: techniques, isError, isLoading } = useGetTechniquesQuery(apiFilters)
    const { t } = useTranslation(I18N_NAMESPACE)
    const { currency } = useRoadworksStateContext()
    const { setSelectedTechnique } = useRoadworksDispatchContext()

    const displayedTechniques = useMemo(() => {
        if (techniques?.length) {
            return sortTechniques(techniques)
        }
        return null
    }, [techniques])

    const defaultTechniquesArray = [...Array(4).keys()]

    const { selectedTechnique } = useRoadworksStateContext()

    const totalTechniquesCost = useMemo(() => {
        return techniques?.reduce((acc, technique) => {
            const techniqueCost = handleRoundNumber(technique.cost, 0)
            return acc + techniqueCost
        }, 0)
    }, [techniques])

    const formattedTotalCost = formatNumber(totalTechniquesCost)

    const Header = useCallback(() => {
        return <Styled.Header>
            <Styled.TextWrapper>
                <Typography variant="H2">{t(I18N_NAMESPACE, "containers.networkTechniquesList.title")}</Typography>
                {Number.isInteger(totalTechniquesCost) ? <Styled.TotalCostContainer>
                    <Typography variant="H3">{t(I18N_NAMESPACE, "containers.roadworksList.totalCost")}</Typography>
                    <Styled.TotalCost id={"roadworks-total-cost"}>{formattedTotalCost} {currency?.symbol}</Styled.TotalCost>
                </Styled.TotalCostContainer>
                    : null}
            </Styled.TextWrapper>
        </Styled.Header>
    }, [currency?.symbol, formattedTotalCost, t, totalTechniquesCost])

    if (!isLoading && isError) {
        return <RoadworksList
            className={className}
            roadworksItems={
                defaultTechniquesArray.map(() => <TechniqueItemCardError />)
            }
        />
    }

    if (isLoading || !currency?.symbol) {
        return <RoadworksList
            className={className}
            roadworksItems={
                defaultTechniquesArray.map(() => <TechniqueItemCardSkeleton />)
            }
        />
    }

    if (!displayedTechniques) {
        return <>
            <Header />
            <Styled.Typography id="no-data-message">
                {t(I18N_NAMESPACE, "containers.networkTechniquesList.noData")}
            </Styled.Typography>
        </>
    }

    return (
        <>
            <Header />
            <RoadworksList
                className={className}
                roadworksItems={
                    displayedTechniques.map((technique, index) => {

                        const clickOnTechnique = () => {
                            setSelectedTechnique(`${technique.code}-${technique.year}-${technique.status}`)
                        }

                        const isSelected = selectedTechnique === `${technique.code}-${technique.year}-${technique.status}`
                        const isDisabled = technique.status === STATUS_DONE
                        const doneAt = isDisabled ? technique.year.toString() : null

                        return <TechniqueItemCard
                            currency={currency?.symbol}
                            doneAt={doneAt?.toString()}
                            handleClick={clickOnTechnique}
                            id={`task-card-${index}${isSelected ? "-selected" : ""}`}
                            index={index}
                            isDisabled={isDisabled}
                            isSelected={isSelected}
                            technique={technique}
                        />
                    })
                }
                totalCost={totalTechniquesCost}
                currency={currency}
            />
        </>
    )
}

NetworkTechniquesList.propTypes = {
    className: PropTypes.string,
}

function sortTechniques(techniques) {
    return techniques.sort((a, b) => {
        if (a.status !== b.status) {
            if (a.status === STATUS_DONE) {
                return 1
            } else {
                return -1
            }
        }

        if (a.year !== b.year) {
            return a.year - b.year
        }

        if (a.impact !== b.impact) {
            return impactsOrder[b.impact] - impactsOrder[a.impact]
        }
        return b.cost - a.cost
    })
}