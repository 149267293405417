import { Trans as I18nTrans } from "react-i18next"

export const Trans = (props) => {
    const {
        namespace,
        i18nKey,
        ...transProps
    } = props

    return (
        <I18nTrans i18nKey={namespace + ":" + i18nKey} {...transProps} />
    )
}