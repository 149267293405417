import { Route, Routes } from "react-router-dom"

import { VerticalSigningMapLayersRouter } from "../../../modules/verticalSigning/routers/VerticalSigningMapLayersRouter"
import { MODULE_VERTICAL_SIGNING } from "../../constants/modules"

export const CityDesktopMapRouter = () => {

    return (
        <Routes>
            <Route
                element={
                    <VerticalSigningMapLayersRouter />
                }
                path={`/${MODULE_VERTICAL_SIGNING}/*`}
            />
        </Routes>
    )
}