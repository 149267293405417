import { useMemo } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { RoadSearch as RoadSearchComponent } from "../../components/RoadSearch/RoadSearch"
import { useNetworkRoads } from "../../hooks/useNetworkRoads"

export function RoadSearch(props) {
    const {
        className,
        disableExpend,
    } = props

    const { data: networksRoads } = useNetworkRoads()

    const options = useMemo(() => {
        return (networksRoads || []).map((road) => {
            return { label: road }
        })
    }, [networksRoads])

    return (
        <RoadSearchComponent
            className={className}
            disableExpend={disableExpend}
            options={options}
        />
    )
}

RoadSearch.propTypes = {
    className: PropTypes.string,
    disableExpend: PropTypes.bool,
}

RoadSearch.defaultProps = {
    disableExpend: true,
}