import { useQuery } from "@tanstack/react-query"

import { externalApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

import { GEOCODING_ROOT_QUERY_KEY } from "./queryKeys"

async function queryFn(
    axiosInstance,
    url,
    queryParameters,
) {
    const response = await axiosInstance.get(url, {
        params: { ...queryParameters },
    })
    return response.data
}

export function useReverseGeocoding(lat, long, zoom = null, config = {}) {
    const url = "https://nominatim.openstreetmap.org/reverse"
    const queryParameters = {
        lat: lat,
        lon: long,
        zoom: zoom,
        format: "json",
    }
    const queryKey = [GEOCODING_ROOT_QUERY_KEY, queryParameters]
    return useQuery({
        queryKey,
        queryFn: () => queryFn(axiosInstance, url, queryParameters),
        config,
        enabled: !!lat && !!long,
    })
}
