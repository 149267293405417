import { useMemo } from "react"

import { sanitizeData } from "@l2r-front/l2r-geodata"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { Source, useMapDispatchContext } from "@l2r-front/l2r-map"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { useNetworkReferential } from "../../hooks"

export function NetworkReferentialSource(props) {

    const { t } = useTranslation(I18N_NAMESPACE)
    const { setError: setMapError } = useMapDispatchContext()

    const { data } = useNetworkReferential({}, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.networkReferentialLayer.error"),
        onError: () => setMapError(true),
    })

    // Need to copy id into properties because of that mapbox issue:
    // link:https://github.com/mapbox/mapbox-gl-js/issues/2716
    const sanitizedData = useMemo(() => {
        return sanitizeData(data)
    }, [data])

    if (!sanitizedData) {
        return null
    }

    return (
        <Source {...props} type="geojson" data={sanitizedData} tolerance={.001} />
    )
}

NetworkReferentialSource.propTypes = Source.propTypes