import { useCallback, useMemo } from "react"

import { useDateFormatter, useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { DetailElements, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import {
    EVENT_CONDITION_LIST,
    EVENT_INFOS_CONDITIONS,
    EVENT_INFOS_INCIDENT_TYPE,
    EVENT_INFOS_STATUS,
    EVENT_INFOS_TAGS,
    EVENT_INFOS_TIME,
} from "../../constants/event"
import { eventConditionsSettings } from "../../constants/eventConditionsSettings"
import { incidentDetailsSettings } from "../../constants/incidentDetailsSettings"
import { incidentStatusSettings } from "../../constants/incidentStatusSettings"
import { incident } from "../../propTypes"
import { incidentTypeConfig } from "../../utils/eventFiltersConfig"
import { formatEventDate, formatEventTimeSlot } from "../../utils/formatEventDate"
import { formatEventDetails } from "../../utils/formatEventDetails"
import { getStatusFromReport } from "../../utils/statusHelpers"
import { IncidentDetail } from "../IncidentDetail"
import { IncidentStatusIcon } from "../IncidentStatusIcon"
import { IncidentTags } from "../IncidentTags"
import * as Styled from "./IncidentDetails.styled"

export const IncidentDetails = (props) => {
    const {
        incident,
        displayedElements,
    } = props

    const { t } = useTranslation([I18N_NAMESPACE])
    const {
        date,
        report,
        tags,
        timeSlot,
    } = incident
    const isMobile = useIsMobileDevice()

    const sortedTags = useMemo(() => {
        return tags.sort((tag1, tag2) => {
            return tag1.localeCompare(tag2, undefined, { sensitivity: "base" })
        })
    }, [tags])

    const status = useMemo(() => {
        return getStatusFromReport(report)
    }, [report])

    const incidentType = useMemo(() => {
        const incidentTypeConfigEntry = Object.entries(incidentTypeConfig).find(([_, value]) => {
            return value.queryParams.incident_type__in === incident.incidentType
        })
        return incidentTypeConfigEntry[0]
    }, [incident])

    const displayedConditions = useMemo(() => {
        return formatEventDetails(EVENT_CONDITION_LIST, incident, eventConditionsSettings, t)
    }, [incident, t])

    const getIcon = useCallback((key) => {
        return incidentDetailsSettings[key].icon
    }, [])

    const dateFormatter = useDateFormatter()

    const dateDisplay = useMemo(() => {
        if (!(date && timeSlot)) {
            return ""
        }

        const formattedDate = formatEventDate(date, dateFormatter)
        const formattedTimeSlot = formatEventTimeSlot(timeSlot, dateFormatter)

        return [formattedDate, formattedTimeSlot]
    }, [dateFormatter, date, timeSlot])

    return (
        <Styled.Wrapper id="incident-details-list-wrapper">
            <Styled.ListWrapper id="incident-details-list">
                <Styled.List isMobile={isMobile}>
                    {displayedElements.includes(EVENT_INFOS_TAGS) && (
                        <IncidentDetail
                            key="detailList-tags"
                            icon={getIcon([EVENT_INFOS_TAGS])}
                            id="detailList-tags"
                            title={t(I18N_NAMESPACE, incidentDetailsSettings[EVENT_INFOS_TAGS].title)}
                        >
                            <IncidentTags tags={sortedTags} />
                        </IncidentDetail>)
                    }
                    {displayedElements.includes(EVENT_INFOS_STATUS) && status && (
                        <IncidentDetail
                            key="detailList-status"
                            icon={() => <IncidentStatusIcon color="cta-bg/cta-bg-primary" status={status} />}
                            id="detailList-status"
                            title={t(I18N_NAMESPACE, incidentDetailsSettings[EVENT_INFOS_STATUS].title)}
                        >
                            <DetailElements
                                detailElements={[t(I18N_NAMESPACE, incidentStatusSettings[status].label)]}
                            />
                        </IncidentDetail>)
                    }
                    {displayedElements.includes(EVENT_INFOS_INCIDENT_TYPE) && (
                        <IncidentDetail
                            key="detailList-incidentType"
                            icon={getIcon(EVENT_INFOS_INCIDENT_TYPE)}
                            id="detailList-incidentType"
                            title={t(I18N_NAMESPACE, incidentDetailsSettings[EVENT_INFOS_INCIDENT_TYPE].title)}
                        >
                            <DetailElements
                                detailElements={[t(I18N_NAMESPACE, incidentTypeConfig[incidentType].label)]}
                            />
                        </IncidentDetail>)
                    }
                    {displayedElements.includes(EVENT_INFOS_CONDITIONS) && (
                        <IncidentDetail
                            key="detailList-conditions"
                            icon={getIcon(EVENT_INFOS_CONDITIONS)}
                            id="detailList-conditions"
                            title={t(I18N_NAMESPACE, incidentDetailsSettings[EVENT_INFOS_CONDITIONS].title)}
                        >
                            <DetailElements detailElements={displayedConditions} />
                        </IncidentDetail>)
                    }
                    {displayedElements.includes(EVENT_INFOS_TIME) && (
                        <IncidentDetail
                            key="detailList-time"
                            icon={getIcon(EVENT_INFOS_TIME)}
                            id="detailList-time"
                            title={t(I18N_NAMESPACE, incidentDetailsSettings[EVENT_INFOS_TIME].title)}
                        >
                            <DetailElements
                                detailElements={dateDisplay}
                            />
                        </IncidentDetail>)
                    }
                </Styled.List>
            </Styled.ListWrapper>
        </Styled.Wrapper>
    )
}

IncidentDetails.propTypes = {
    displayedElements: PropTypes.arrayOf(PropTypes.string).isRequired,
    incident: incident.isRequired,
}
