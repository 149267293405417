export const adminLogiroad = {
    "uuid": "c096d7e1-c5e3-439b-88b6-ac1b5f7bbe72",
    "email": "admin@logiroad.fr",
    "avatar": "https://api.l2r.rleboeuf/media/l2r_auth/user/avatar_c096d7e1-c5e3-439b-88b6-ac1b5f7bbe72.png",
    "firstName": "Admin",
    "lastName": "Logiroad",
    "hasTermsAccepted": true,
    "isActive": true,
    "isStaff": true,
    "isSuperuser": true,
}