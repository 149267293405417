import { useMemo } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { useNetwork } from "../../hooks/queries/useNetwork"
import { ProjectStateContext } from "./ProjectContext.context"

export const ProjectContextProvider = (props) => {
    const { children } = props

    const { data: project, isLoading, isError } = useNetwork()

    const stateValue = useMemo(() => {
        return {
            isError,
            isLoading,
            project: project,
        }
    }, [isLoading, isError, project])

    return (
        <ProjectStateContext.Provider value={stateValue}>
            {children}
        </ProjectStateContext.Provider>
    )
}

ProjectContextProvider.propTypes = {
    children: PropTypes.node,
}