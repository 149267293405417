import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { svraiQueryKeys } from "./queryKeys"
import { useSvraiQuery } from "./useSvraiQuery"

export function useNetwork(
    queryParameters,
    config = {},
) {
    const { enabled = true } = config
    const { selectedNetwork } = useNetworksStateContext()
    const url = `projects/${selectedNetwork?.slug}/`

    return useSvraiQuery(
        url,
        svraiQueryKeys["network"](),
        queryParameters,
        {
            ...config,
            enabled: !!selectedNetwork?.slug && enabled,
        },
    )
}
