import { applyMatrixToPoint } from "@visx/zoom"

export function rescaleChart(scale, zoom) {
    const newDomain = scale.range().map((r) => {
        return scale.invert(rescale(r, zoom))
    })

    return scale.copy().domain(newDomain)
}

export function rescaleChartBandwidth(scale, zoom, margin) {
    const range = scale.range()
    const scaledRange = scale.range().map(v => v / zoom.transformMatrix.scaleX)
    const newRange = [rescaleBandwidth(range[1], zoom) + scaledRange[1], rescaleBandwidth(range[0], zoom) + scaledRange[1] + margin?.left]

    return scale.copy().range(newRange)
}

function rescaleBandwidth(value, zoom) {
    return ((zoom.transformMatrix.translateX - value) / zoom.transformMatrix.scaleX)
}

function rescale(value, zoom) {
    return (value - zoom.transformMatrix.translateX) / zoom.transformMatrix.scaleX
}

export function constrainZoom(width, transformMatrix, prevTransformMatrix) {
    const min = applyMatrixToPoint(transformMatrix, { x: 0, y: 0 })
    const max = applyMatrixToPoint(transformMatrix, { x: width, y: 0 })
    if (max.x < width) {
        return prevTransformMatrix
    } else if (min.x > 0) {
        return prevTransformMatrix
    }
    return transformMatrix
}

export function constrainBandwithZoom(width, scale, transformMatrix, prevTransformMatrix) {
    const min = applyMatrixToPoint(transformMatrix, { x: 0, y: 0 })
    const max = applyMatrixToPoint(transformMatrix, { x: width, y: 0 })

    const scaledWidth = width / scale
    const scaledMaxX = max.x / scale
    const scaledMinX = min.x / scale
    const displayedWidth = scaledMaxX - scaledMinX
    const maxScrollX = scaledWidth - displayedWidth

    if (min.x > 0) {
        return prevTransformMatrix
    } else if (maxScrollX < Math.abs(scaledMinX)) {
        return prevTransformMatrix
    }
    return transformMatrix
}