import WebFont from "webfontloader"

import { PropTypes } from "@l2r-front/l2r-proptypes"

export const ResourceLoader = (props) => {

    const {
        children,
    } = props

    WebFont.load({
        google: {
            families: ["Poppins:400,600,700", "sans serif"],
        },
    })

    return children
}

ResourceLoader.propTypes = {
    children: PropTypes.node,
}