import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./HeaderWithLogo.styled"

export const HeaderWithLogo = (props) => {

    const { children } = props

    return <>
        <Styled.Logo
            id="logiroad-logo"
            onMouseDown={(event) => event.preventDefault()}
            src={"assets/resources/logiroad-center-logo.svg"}
        />
        {children}
    </>
}

HeaderWithLogo.propTypes = {
    children: PropTypes.node,
}