import { string, shape, number, oneOf, oneOfType } from "prop-types"

import { timestamp } from "../timestamp"
import { uuid } from "../uuid"

export const taskStat = shape({
    code: string.isRequired,
    cost: number.isRequired,
    quantity: oneOfType([number, string]).isRequired,
})

export const task = shape({
    uuid: uuid,
    createdAt: timestamp,
    updatedAt: timestamp,
    roadwork: uuid,
    taskType: string,
    cost: oneOfType([number, string]),
    quantity: oneOfType([number, string]),
    unit: string,
    comment: string,
    impact: oneOf([10, 20, 30, 40]),
})

export const taskContent = shape({
    task,
    cost: string.isRequired,
})
