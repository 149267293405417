import { Route, Routes } from "react-router-dom"

import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { IncidentsMapLayersRouter } from "../../../modules/incidents/routers/IncidentsMapLayersRouter"
import { IncidentsMapLayersRouterMobile } from "../../../modules/incidents/routers/IncidentsMapLayersRouter/IncidentsMapLayersRouter.mobile"
import { MODULE_INCIDENTS } from "../../constants/modules"

export const MapLayersRouter = () => {

    const isMobile = useIsMobileDevice()

    if (isMobile) {
        return <IncidentsMapLayersRouterMobile />
    }

    return <Routes>
        <Route
            element={<IncidentsMapLayersRouter />}
            path={`/${MODULE_INCIDENTS}/*`}
        />
    </Routes>
}