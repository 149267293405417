import { styled } from "@l2r-front/l2r-ui"

import { RoadworkQuarterBudget as L2rRoadworkQuarterBudget } from "../RoadworkQuarterBudget/RoadworkQuarterBudget"

export const RoadworkQuartersBudgetWrapper = styled("div")({
    display: "flex",
    flexDirection: "row",
    height: "fit-content",
    width: "100%",
})

export const RoadworkQuarterBudget = styled(L2rRoadworkQuarterBudget)(({ theme }) => ({
    margin: theme.spacing(0, 1),
    alignSelf: "flex-end",
}))