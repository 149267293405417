import { useContext } from "react"

import { RoadwayStateContext, RoadwayDispatchContext } from "./RoadwayContext.context"

export const useRoadwayStateContext = () => {
    const context = useContext(RoadwayStateContext)
    if (context === undefined) {
        throw new Error("useRoadwayStateContext must be used within an RoadwayStateContext")
    }
    return context
}

export const useRoadwayDispatchContext = () => {
    const context = useContext(RoadwayDispatchContext)
    if (context === undefined) {
        throw new Error("useRoadwayDispatchContext must be used within an RoadwayStateContext")
    }
    return context
}